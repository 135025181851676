import React, { Component } from 'react';
import { Trans, withTranslation } from "react-i18next";
import "../../config/i18n";

import { NavLink } from 'react-router-dom';
//import { GoogleComponent } from 'react-google-location';
import Autocomplete from 'react-google-autocomplete';
import axios from 'axios';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Swiper from 'react-id-swiper';
import FilterResults from 'react-filter-search';
import Loader from 'react-loader-spinner';
import Moment from 'react-moment';

import ls from "local-storage"; 
import { options } from "../../config/options"; 

Moment.globalFormat = 'D MMM YYYY';
const API_KEY = "AIzaSyDVVob6U7eho9MpD99_7UCFmEzoH6N3Rrw";

var mine = "";

class Profile extends Component {
    constructor(props) {
        super(props);
        this.onSubmitlocationsearch = this.onSubmitlocationsearch.bind(this);
        this.state = {
          suc_msg:'', 
          ischecked:'',
          user_id:'',place: null,lat: null,lon: null,user_details:'',errors:  {},loc:'',data: [],
          value: '',interests: [],se_interest:[],
          items:[],commentarylist:[],modal1: false,
          modal2: false,modal4: false,openModal:false,
          nointerest:'',
          permium_superlikeCount: 0, 
          permium_boostCount: 0,  
          lang: ls.get('LangKeySet') || options[0],
          langKey: ls.get('LangValue'),  
          modal3: false,api_url:localStorage.getItem('api_url'),
          itemss: [],activeItems:'',selected_premium:'',
          currency:'',payment:'',license_key:'',premium_price:'',loader:'',profilespace:'none'
        };
    
        this.closeModal = this.closeModal.bind(this);
      }
    componentDidMount(){
      let errors = {};

      this.setState({
        loader:'loder-icon'
        });
        setTimeout(() => {
            this.setState({
                profilespace: '',
                loader: 'none'
            });
          }, 100);


          axios({
            method: 'get',
            url: this.state.api_url+'subscriptionDetails',                
            
          }).then(res => {
            const data = res.data;
            console.log(data);
            this.setState({                         
                    currency: data.currency,
                    price: data.price,
                    noofdays: data.noofdays,
                  });
                  localStorage.setItem('subscription_price',JSON.stringify(this.state.price));
                  localStorage.setItem('currency',JSON.stringify(this.state.currency));
                  localStorage.setItem('noofdays',JSON.stringify(this.state.noofdays));
             
          });
            

        if(localStorage.getItem('boost_premium_popup') === '1') {
            /* setTimeout(() => {
              localStorage.setItem('boost_premium_popup','0');
            }, 2000); */
            localStorage.setItem('boost_premium_popup','');  

            const message = "Boost Purchased Successfully";
            errors["profile"] = message;
            this.setState({
                errors: errors,
                suc_msg: 'successMsg floatingAlerts1',
            });
            
            setTimeout(() => {
              this.setState({
                  suc_msg: 'successMsghide'
              });   
            }, 5000); 
        }

        if(localStorage.getItem('superlike_premium_popup') === '1') { 
            localStorage.setItem('superlike_premium_popup','');

            const message = "Superlike Purchased Successfully";
            errors["profile"] = message;
            this.setState({
                errors: errors,
                suc_msg: 'successMsg floatingAlerts1',
            });
            
            setTimeout(() => {
              this.setState({
                  suc_msg: 'successMsghide'
              });
            }, 5000);    
        }

        if(localStorage.getItem('premium_popup') === '1') {  
            this.setState({
                modal4: true,
            });
            localStorage.setItem('premium_popup','');
        }

        let data = new FormData();
        data.append('user_id', JSON.parse(localStorage.getItem('session_userid')));  
        data.append('language', this.state.langKey); 
          
        axios({
            method: 'post',
            url: this.state.api_url+'webprofile',
            data: data, 
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        }).then(res => {
          if(res.data.status !== false) {
            const user_details = res.data.result;
            
            this.setState({
              user_details: user_details,
              loc: user_details.location,
            });
            mine = JSON.parse(user_details.interest);  
            this.setState({ se_interest: user_details.interest });
          } else {
            this.props.history.push("/login"); 
          }        
        }).catch(error => {
           this.props.history.push("/login"); 
        }); 

        axios.get(this.state.api_url+`admindatas`)
        .then(res => {
          const items = res.data.result.peoplefor;
          const interests = res.data.result.interests;
          // console.log(res.data);
          this.setState({ items });
          this.setState({ interests });
          
        })

        var premiumlist = new FormData();
          premiumlist.set('platform', 'web');
          premiumlist.set('language', this.state.langKey); 
          axios({
            method: 'post',
            url: this.state.api_url+'premiumlistweb', 
            data: premiumlist,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
              })
              .then(res => {
                const data = res.data;
                // console.log("premium list",res.data);
                this.setState({
                  itemss: data.result,
                  activeItems: data.result[0].id,
                  selected_premium: data.result[0].id,
                  premium_price: data.result[0].price,
                  permium_superlikeCount: data.result[0].superlikecount, 
                  permium_boostCount: data.result[0].boostcount,   
                  currency: data.currency,
                  payment: data.payment,
                  license_key: data.license_key, 
              });
              localStorage.setItem('selected_premium',JSON.stringify(this.state.selected_premium));
              localStorage.setItem('premium_price',JSON.stringify(this.state.premium_price));
              localStorage.setItem('currency',JSON.stringify(this.state.currency));
              });
    }

    componentWillMount() {
      let data = new FormData();
      data.append('user_id', JSON.parse(localStorage.getItem('session_userid')));  
      data.append('language', this.state.langKey); 
        
      axios({ 
          method: 'post',
          url: this.state.api_url+'admindatass',
          data: data, 
          config: { headers: {'Content-Type': 'multipart/form-data' }}
      }).then(res => { 
        if(res.data.status !== false) {
          const data = res.data.result.interests; 
          this.setState({ data });
        } else {
          this.props.history.push("/login");    
        }
      }) 
    }

    
    onToggle(index, e){     
        if(e.target.value){
            var cur_val = e.target.value;
            // console.log(mine.includes(cur_val));
            if(mine === null)
            {
            	
					
            	    mine = [];
            	         mine.push(cur_val);

            }else{ 
            index = mine.indexOf(cur_val);
            if (index > -1) {
              mine.splice(index, 1);
             this.setState({ checked: !this.state.checked });
              
            }
            else{
                mine.push(cur_val);
              
                  // console.log(mine);
            }
            }
         
            
        }
        
      }

      onSubmitlocationsearch(e){
        e.preventDefault();
        let errors = {};
       // alert(this.state.lat);
        if(this.state.lat === "" || this.state.lat === null || this.state.lon === "" || this.state.place=== "" || this.state.place=== null){
            errors["locationsearch"] = "please select the location";
           // return false;
            
        }
         else if(typeof(this.state.lat) === 'undefined' || typeof(this.state.lon) === 'undefined')
                {
                         errors["locationsearch"] = "please select the location from dropdown";
                      // console.log(this.state.lat);
                        // console.log(this.state.lon);
                }
        else{
               
            // this.setState({
            //     loc: this.state.place
            // });
            var location = new FormData();
            if(this.state.place){
                            var address=this.state.place;
                var value=address.split(","); 
                var count=value.length; 
                var country=value[count-1]; 
                var state=value[count-2]; 
                var city=value[count-3]; 
                // console.log(city+" "+state+" "+country);  

                if(city != null || typeof(city) !== 'undefined'){
                    //alert(city)
                    location.append('place', city+", "+state+", "+country);
                    this.setState({
                        loc: city+", "+state+", "+country
                    });
                }else{
                    location.append('place', state+", "+country);
                    this.setState({
                        loc: state+", "+country
                    });
                }
            }
            location.append('lat', this.state.lat);
            location.append('lon', this.state.lon);
            location.append('user_id', JSON.parse(localStorage.getItem('session_userid')));
            location.append('language', this.state.langKey);  

        axios.defaults.headers.common['Authorization'] = JSON.parse(localStorage.getItem('session_token'));
        
        axios({
            method: 'post',
            url: this.state.api_url+'websetprofile',
            data: location,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(res => {
            const status = res.data.status;
            // console.log(res.data);
            if(status === "true"){
                this.setState({
                    modal1: false,
                });
                
              window.location.reload();
            }else{

            }
            
            })
        }
        this.setState({
      errors: errors
      });
      
      }
    // onSubmit = (e) => {      
    //     e.preventDefault();
    //     let arr = [];
    //     for (var key in this.state.interests) {
    //       if(this.state.interests[key].checked === true) {
    //         arr.push(this.state.interests[key].name);
    //       }
    //     }
    //     // console.log("change_int",arr)
    //     var interest_change = new FormData();
    //     interest_change.set('check', arr);
    //     interest_change.set('user_id', JSON.parse(localStorage.getItem('session_userid')));
    //     axios({
    //         method: 'post',
    //         url: this.state.api_url+'websetprofile',
    //         data: interest_change,
    //         config: { headers: {'Content-Type': 'multipart/form-data' }}
    //         })
    //         .then(res => {
    //             const message = res.data.message;
    //             // console.log(message);
    //             })
                        
    // }
    onSubmit = (e) => {      
        e.preventDefault();
           // let errors = {};
        // console.log("change_int",mine);
     
        if(mine.length === 0)
        {
               this.setState({
                    nointerest: 'Please select the interest',
                });
        }else{
        var interest_change = new FormData();

        interest_change.set('check', mine);
        interest_change.set('language', this.state.langKey);    
        interest_change.set('user_id', JSON.parse(localStorage.getItem('session_userid')));
        axios({
            method: 'post',
            url: this.state.api_url+'websetprofile',
            data: interest_change,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
            })
            .then(res => {
                // const message = res.data.message;  
                window.location.reload();
                })
          }
                        
    }

    closeModal(tabId) {
        this.setState({
            [tabId]: false
        });
    }
    closeModall(tabId) {
        this.setState({
            [tabId]: false
        });
        window.location.reload();
    }
    showModal(modal) {
        this.setState({
            [modal]: true
        });
        // console.log(this.state);
    }
    showModall(modal) {
        if(this.state.user_details.premium_member === 'true'){
        this.setState({
            [modal]: true
        });
    }else{
        this.setState({
            modal2: true
        });

    }
        // console.log(this.state);
    }
    handleItemClick(index) {
        this.setState({
          activeItems: index,
          selected_premium: index,
        })
        var premiumlist = new FormData();
        premiumlist.set('premiumid', index);
        premiumlist.set('language', this.state.langKey);  
        axios({
          method: 'post',
          url: this.state.api_url+'premiumid',
          data: premiumlist,
          config: { headers: {'Content-Type': 'multipart/form-data' }}
            })
            .then(res => {
              const data = res.data;
              this.setState({
                premium_price: data.result.price,
                permium_superlikeCount: data.result.superlikecount, 
                permium_boostCount: data.result.boostcount, 
            });
            localStorage.setItem('selected_premium',JSON.stringify(index));
            localStorage.setItem('premium_price',JSON.stringify(data.result.price));  
            });

      }
    handleChange = event => {
        const { value } = event.target;
        this.setState({ value });
           this.setState({ se_interest: mine })
           // console.log(this.state.se_interest);
           // console.log('after');
           // console.log(mine); 
      };
      render() {
        const { t } = this.props;   
        const { data, value } = this.state;
        let premium_validity,non_premium,premium_icon;

        if(this.state.user_details.premium_member === 'true'){
            premium_icon = <div className="preIco2"></div>
            premium_validity =  <div className="mt-5 text-center"><Trans i18nKey="Your premium membership will expire on">Your premium membership will expire on</Trans> {this.state.user_details.membership_valid_web}</div>
        }else{
            premium_validity = ""
            premium_icon = ""
          //  alert(this.state.user_details);
            if(this.state.user_details.subscribed == "true" && this.state.user_details.subscribed_status != "expired")
                non_premium = <div onClick={this.showModal.bind(this, 'modal2')} className="btn-common  mt-5"><Trans i18nKey="Become a Premium Member">Become a Premium Member</Trans></div>  
            else  non_premium = <div onClick={this.showModal.bind(this, 'openModal')} className="btn-common  mt-5"><Trans i18nKey="Become a Premium Member">Become a Premium Member</Trans></div>  
            
            } 

        let ss;
        if(this.state.user_details.subscribed == "false" && this.state.user_details.subscribed_status == "expired"){
            ss = "false";
        }
        
        const params = {
          pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
          },
          spaceBetween: 1,
          rebuildOnUpdate: true   
        } 

        const params2 = {
          navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev'
            },   
            slidesPerView: 3,
            slidesPerColumn: 1,
            breakpoints: {
                360: {
                    slidesPerView: 3,
                },
                359: {
                    slidesPerView: 2,
                }
            }
        };
         var style = {
            zIndex:10,
            backgroundColor:"white",
            height:"80vh",
            width:"100%",
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
            //width:"100%",
            overflowY:"hidden"
          };
        return (

            <div className="setting-page">

                <div className="d-flex profile-header-top stickyProfileHead justify-content-between apd15 apr0 apl0 white-bg amb10 ">
                    <div class="heading-left-top d-flex">
                        <div className="align-self-center ">
                            <NavLink to="/app/PeopleNear">
                                <div className="iconHolder align-self-center">
                                    <div className="backGlow d-flex d-lg-none">
                                        <div className="backIco"></div>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <span class="arrow-center align-self-center bold-font ml-1">
                          <Trans i18nKey="Profile">Profile</Trans> 
                        </span>
                    </div>

                    <div className="iconHolder align-self-center">
                        <div className="backGlow">
                            <NavLink to="/app/Settings">
                                <div class="settingsIcoRose d-block d-lg-none"></div>
                            </NavLink>
                        </div>
                    </div>
                </div>
                
                
               <div className="settingsPageWidth profileSectionView">
                <div className={this.state.loader}>
                        <div style={style} id="loader">
                            <Loader type="Bars" color="#000000" height={30} width={50} />
                        </div>
                    </div>
                    <div className="ProfileSpace">    
                        <div className="col-12 d-flex justify-content-center posRel">
                            <div className="posRel">
                                <img src={this.state.user_details.images} alt="" className="profilePageDp" />
                                {premium_icon}
                                {(this.state.user_details.subscribed == "true" && this.state.user_details.subscribed_status != "expired") ? 
                                <NavLink to="/app/Profile/ProfilePhotoEdit">
                                    <div className="editProfileIcon"> <img src={require('../../assets/img/pen.png')} alt="penIcon" /></div>
                                </NavLink> : ""}
                            </div>
                        </div>
                    </div>
                    
                    {premium_validity}
                    {non_premium}
                    <div className="ProfileSpace apt50 apk-16">

                        <NavLink to={{
                                pathname: "/app/Profile/EditProfile",
                                userProps: {subscribed: this.state.user_details.subscribed, subscribed_status: this.state.user_details.subscribed_status},
                                }} >
                            <div className="d-flex justify-content-between apd20 apl0 apr0 border-bottom pointCurs">
                                <div className="gray-color"><Trans i18nKey="Basic info">Basic info</Trans></div>
                                <div className="d-flex">
                                    <div className="align-self-center mx-3 text-dark">{this.state.user_details.user_name}</div>
                                    <div className="rightArrow align-self-center"></div>
                                </div>
                            </div>
                        </NavLink>

                        <div className="d-flex justify-content-between apd20 apl0 apr0 border-bottom pointCurs" onClick={this.showModal.bind(this, 'modal3')}>
                            <div className="gray-color">{this.state.user_details.ex1} <Trans i18nKey="interest">interest</Trans> </div>
                            <div className="d-flex overflow-hidden">
                                <div className="align-self-center text-dark text-truncate mx-3">
                                  <Trans i18nKey={this.state.user_details.interest1}>{this.state.user_details.interest1}</Trans></div>
                                <div className="rightArrow align-self-center"></div>
                            </div>
                        </div>


                        <div className="d-flex justify-content-between apd20 border-bottom apl0 apr0 pointCurs" data-toggle="modal" onClick={(this.state.user_details.subscribed == "false" || this.state.user_details.subscribed_status == "expired") ? this.showModal.bind(this, 'openModal') : this.showModal.bind(this, 'modal1')}>
                            <div className="gray-color"><Trans i18nKey="Location">Location</Trans></div> 
                            <div className="d-flex overflow-hidden">
                                <div className="align-self-center text-dark text-truncate mx-3">{this.state.loc}</div>  
                                <div className="rightArrow align-self-center"></div>
                            </div>
                        </div>

                      { /* <div className="d-flex justify-content-between apd20 border-bottom apl0 apr0 pointCurs">
                            <div className="gray-color"><Trans i18nKey="Remaining Superlike">Remaining Superlike</Trans></div> 
                            <div className="d-flex overflow-hidden">
                                <div className="align-self-center text-dark text-truncate mx-3">{this.state.user_details.superlike_count}</div>  
                            </div>
                        </div>

                        <div className="d-flex justify-content-between apd20  apl0 apr0 pointCurs">
                            <div className="gray-color"><Trans i18nKey="Remaining Boost">Remaining Boost</Trans></div> 
                            <div className="d-flex overflow-hidden">
                                <div className="align-self-center text-dark text-truncate mx-3">{this.state.user_details.boost_count}</div>  
                            </div> 
                        </div>*/ }   

                        <div className={this.state.suc_msg}>
                          <Trans i18nKey={this.state.errors.profile}>{this.state.errors.profile}</Trans> 
                        </div>
                    </div>
                     


                </div> 
  


                   {/* Interest Popup  */}
                <Modal isOpen={this.state.modal3} toggle={this.closeModal.bind(this, 'modal3')} className="premiumPopup vertically-center  apd10">
                    <ModalHeader toggle={this.closeModal.bind(this, 'modal3')}>
                      <h4 class="modal-title">
                        <Trans i18nKey="My Interest">My Interest</Trans>
                      </h4>
                    </ModalHeader>
                    <ModalBody>
                    <form onSubmit={this.onSubmit}>

                                              <div className="modal-body profile-page ">

                                <div className="input-group border-bottom apb10">
                                    <span className="input-group-append">
                                        <button className="btn search-btn border-none" type="button">
                                            <img src={require("../../assets/img/search.png")} alt="logo" className="" />
                                        </button>
                                    </span>
                                <input className="form-control py-2  border-none"  type="search" value={value} onChange={this.handleChange} placeholder={t("Search your Interest")} />


                              </div>

                          <div className="interst-list appodDayoverflow">
   <FilterResults value={value} data={data} renderResults={results => (
                                <div>
                              {results.map((el,i) => {
                                 if(mine !== null){

                                   if(mine.indexOf(el.name) > -1){
                                      return <label key={el.name} className="interst-option amb0 apt10 apb10 border-bottom"><span><Trans i18nKey={el.name}>{el.name}</Trans></span>
                                      <input type="checkbox" onChange={this.onToggle.bind(this, i)} name="interestcheck" value={el.name}  defaultChecked={mine.indexOf(el.name) > -1 ? 'true':''}/>
                                      <span className="checkmark"></span>
                                  </label>
                                  }else{
                                    return <label key={el.name} className="interst-option amb0 apt10 apb10 border-bottom"><span><Trans i18nKey={el.name}>{el.name}</Trans></span>
                                    <input type="checkbox" onChange={this.onToggle.bind(this, i)} name="interestcheck" value={el.name}/>
                                    <span className="checkmark"></span>
                                </label>
                                  }
                                  
                             }
                             else
                             {
                                return <label key={el.name} className="interst-option amb0 apt10 apb10 border-bottom"><span><Trans i18nKey={el.name}>{el.name}</Trans></span> 
                                    <input type="checkbox" onChange={this.onToggle.bind(this, i)} name="interestcheck" value={el.name}/>
                                    <span className="checkmark"></span>
                                </label>
                             }
                                
                              
                              })}
                            </div>
                          )}
                        />

                    </div>


                            </div>




                     



                            <div className="errorMsg" style={{ paddingLeft: "25px" }}>{this.state.nointerest}</div>
                            <div className="modal-footer interest-save border-top-none">
                                <div className="premium amt15 amb15">
                                {(this.state.user_details.subscribed == "false" || this.state.user_details.subscribed_status == "expired") ?  <button type="button" onClick={this.showModal.bind(this, 'openModal')} className="btn btn-bg width100"> <Trans i18nKey="Save">Save</Trans> </button>
                                    :
                                    <button type="submit" className="btn btn-bg"> <Trans i18nKey="Save">Save</Trans> </button> }</div>
                            </div>

                </form>
                    </ModalBody>
                </Modal>



                <div className="modal fade" id="pricinplan" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog vertically-center" role="document">
                        <div className="modal-content border-radius amg10">
                            <div className="modal-header my-interst border-bottom-none">
                                <h3 className="modal-title apk-24"><Trans i18nKey="Change Your Location">Change Your Location</Trans></h3>
                                <button type="button" class="close apt20 interest-close" data-dismiss="modal"><img className="close-size" src={require("../../assets/img/close-v.png")} alt="close" /></button>
                            </div>
                            <div className="modal-body">
                                <form className="" onSubmit={this.onSubmitlocationsearch}>
                                    <div className="form-group">
                                        <Autocomplete
                                            apiKey={API_KEY}
                                            onPlaceSelected={(place) => {
                                            this.setState({ place: place.formatted_address, lat: place.geometry.location.lat() , lon: place.geometry.location.lng() })
                                            }}
                                            types={['(regions)']}
                                            //componentRestrictions={{country: "ru"}}
                                        />
                                    </div>
                                    <div className="errorMsg">
                                      <Trans i18nKey={this.state.errors.locationsearch}>{this.state.errors.locationsearch}</Trans>
                                    </div>
                                    <div className="premium amb15 apd15"><button type="submit" className="btn btn-bg width100 justify-content-center"> <Trans i18nKey="Change Location">Change Location</Trans> </button></div>
                                </form>

                            </div>
                           

                        </div>
                    </div>
                </div>

                <Modal isOpen={this.state.modal1} toggle={this.closeModal.bind(this, 'modal1')} className="premiumPopup vertically-center apd5">
                    <ModalHeader toggle={this.closeModal.bind(this, 'modal1')}><h4 class="modal-title">
                      <Trans i18nKey="Change Location">Change Location</Trans></h4></ModalHeader>
                    <ModalBody>
                    <div className="modal-body edit-profile-page">
                                    <form className="" onSubmit={this.onSubmitlocationsearch}>
                                        <div className="user-input-wrp amb35">
                                            <br />
                                            <Autocomplete
												apiKey={API_KEY}
												onPlaceSelected={(place) => {
												this.setState({ place: place.formatted_address, lat: place.geometry.location.lat() , lon: place.geometry.location.lng() })
												}}
												types={['(regions)']}
												//componentRestrictions={{country: "ru"}}
											/>
                                        </div>
                                        <div className="errorMsg">
                                          <Trans i18nKey={this.state.errors.locationsearch}>{this.state.errors.locationsearch}</Trans></div>
                                        <div className="premium"><button type="Submit" className="btn btn-bg width100 justify-content-center amg0"> 
                                          <Trans i18nKey="Change Location">Change Location</Trans> </button></div>
                                    </form>
                            </div>
                    </ModalBody>
                </Modal>





                {/* Premium Popup  */}
                <Modal isOpen={this.state.modal2} toggle={this.closeModal.bind(this, 'modal2')} className="premiumPopup vertically-center apd5">
                    <ModalHeader toggle={this.closeModal.bind(this, 'modal2')}>
                      <h4 class="modal-title">
                        <Trans i18nKey="Join Premium">Join Premium</Trans>
                      </h4></ModalHeader>
                    <ModalBody>
                    <div className="premiumPop">
                            <div className="premiumImgs">
                                <Swiper {...params} shouldSwiperUpdate>  
                                 {this.state.permium_superlikeCount > 0 ?
                                  <div>
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sub_superlike.png")} alt="" className="img-fluid" />
                                      </div> 
                                      <div className="premiumInfoHolder">
                                          <div className="bold-font">
                                              <Trans i18nKey="Get">Get</Trans> {this.state.permium_superlikeCount} <Trans i18nKey="free super likes">free super likes</Trans>  
                                          </div>
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextSuper">Giving a Super like is a way to tell someone that you're especially interested in them</Trans>
                                          </div>
                                      </div>
                                  </div> : ''
                                 }

                                 {this.state.permium_boostCount > 0 ?
                                  <div>
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sub_boost.png")} alt="" className="img-fluid" />
                                      </div>
                                      <div className="premiumInfoHolder">  
                                          <div className="bold-font">
                                              <Trans i18nKey="Get">Get</Trans> {this.state.permium_boostCount} <Trans i18nKey="free boost">free boost</Trans>     
                                          </div>
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextBoost">Be the top profile in your area for 30minutes to get more matches, Keep Swipe right!</Trans>
                                          </div>
                                      </div>
                                  </div> : ''
                                 }
                  
                                  <div>
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sub_location.png")} alt="" className="img-fluid" />
                                      </div>
                                      <div className="premiumInfoHolder"> 
                                          <div className="bold-font">
                                              <Trans i18nKey="Change Location">Change Location</Trans>
                                          </div>
                                          <div className="premiumInfoSubText  secondaryText esmh-14 "> 
                                              <Trans i18nKey="premiumTextLocation">Get match and chat with others around the world by simply searching for a city</Trans>
                                          </div>
                                      </div>
                                  </div>

                                  <div> 
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sub_morelike.png")} alt="" className="img-fluid" />
                                      </div>
                                      <div className="premiumInfoHolder">
                                          <div className="bold-font">
                                              <Trans i18nKey="Unlimited Likes">Unlimited Likes</Trans>  
                                          </div>
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextUnlimited">Feel special again with 'Unlimited Likes'. Swipe right as much as you want!</Trans> 
                                          </div>
                                      </div>
                                  </div>


                                  <div>
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sud_add.png")} alt="" className="img-fluid" />
                                      </div>
                                      <div className="premiumInfoHolder"> 
                                          <div className="bold-font">
                                              <Trans i18nKey="Rewind">Rewind</Trans>
                                          </div>
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextRewind">Accidentally swiped on someone? Rewind lets you take back your last Like or Nope</Trans>
                                          </div>
                                      </div>
                                  </div>  

                                  <div> 
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sub_morelike.png")} alt="" className="img-fluid" />
                                      </div>
                                      <div className="premiumInfoHolder">
                                          <div className="bold-font">
                                              <Trans i18nKey="See who Likes you">See who Likes you</Trans>   
                                          </div> 
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextLikes">Likes You feature lets you see who likes you before you decide whether to Like or Nope</Trans> 
                                          </div>
                                      </div>
                                  </div>

                                  <div>
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sub_profile.png")} alt="" className="img-fluid" />
                                      </div>
                                      <div className="premiumInfoHolder">
                                          <div className="bold-font"> 
                                              <Trans i18nKey="Control your profile">Control your profile</Trans>   
                                          </div>  
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextProfile">Limit what others see and make parts of your profile information invisible to other people</Trans>
                                          </div>  
                                      </div>
                                  </div> 

                                  <div>
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sub_visited.png")} alt="" className="img-fluid" />
                                      </div>
                                      <div className="premiumInfoHolder">
                                          <div className="bold-font">
                                              <Trans i18nKey="See who visited your profile">See who visited your profile</Trans> 
                                          </div>
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextVisited">A Visitors feature lets you see who looked at your profile or ‘visited’ your profile
</Trans>
                                          </div>
                                      </div>
                                  </div>



                                  <div>
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sud_add.png")} alt="" className="img-fluid" />
                                      </div> 
                                      <div className="premiumInfoHolder">
                                          <div className="bold-font">
                                              <Trans i18nKey="Turn Off Ads">Turn Off Ads</Trans> 
                                          </div>
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextAds">Have fun swiping with uninterrupted ads</Trans>
                                          </div>  
                                      </div>
                                  </div> 

                              </Swiper> 
                            </div>  
                             
                            <div className="premiumPopPlans">
                            <Swiper {...params2}>
                            {this.state.itemss.map((item, index) =>
                                <div className={this.state.activeItems === item.id ? 'navigation--active' : ''} onClick={this.handleItemClick.bind(this, item.id)} alt={item.id} value={item.id}>
                                <div className="premiumPrice">
                                    <div className="apd10 daysPrice">
                                    <div className="bold-font apk-30">{item.days}</div>
                                    <div className=" bold-font apk-24 mt-n2"><Trans i18nKey={item.type}>{item.type}</Trans></div> 
                                    </div>
                                    <div className="apd5 apk-16 mini-price">{this.state.currency}{item.price}</div>
                                </div>
                                </div>)}

                            </Swiper>
                            </div>


                            <div className="sidebarLastContent text-center">
                            <NavLink to="/app/Payment"> <button className="btn-common btn-block"><Trans i18nKey="Continue">Continue</Trans></button></NavLink>
                            </div>


                        </div>
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.modal4} toggle={this.closeModall.bind(this, 'modal4')} className="premiumBenifit vertically-center apd5 mt-3 mt-md-0">
          <ModalHeader toggle={this.closeModall.bind(this, 'modal4')}>
            <div className="amtN45 relPos">
              <img src={this.state.user_details.images} alt="" className="premiumBDP" />     
              <div class="preIcon"></div>
            </div>

          </ModalHeader>
          <ModalBody>
            <div className="premiumBenifitPopup">
              <div className="mb-4">
                <div className="apk-24 pink-color text-center bold-font"><Trans i18nKey="A new way to date">A new way to date</Trans>!</div>
                <div className="apk-20 text-center bold-font"><Trans i18nKey="The most secure way of dating">The most secure way of dating</Trans>.</div> 
              </div>
              <div className="premiumBenifitOverflow">
                <div className="form-group">
                  <div className="apk-22 d-flex"> <div class="tickIco mr-2"></div> <Trans i18nKey="Unlimited likes">Unlimited likes</Trans></div> 
                  <div className="aml35"><Trans i18nKey="premiumText">Includes this striking feature that allows users to change their location and search for new matches or people who live in another area.</Trans></div>
                </div>
                <div className="form-group">
                  <div className="apk-22 d-flex"> <div class="tickIco mr-2"></div> <Trans i18nKey="Meet someone interesting">Meet someone interesting</Trans></div>
                  <div className="aml35"><Trans i18nKey="premiumText">Includes this striking feature that allows users to change their location and search for new matches or people who live in another area.</Trans></div>
                </div>
                <div className="form-group">
                  <div className="apk-22 d-flex"> <div class="tickIco mr-2"></div> <Trans i18nKey="Go inginito">Go inginito</Trans></div>
                  <div className="aml35"><Trans i18nKey="premiumText">Includes this striking feature that allows users to change their location and search for new matches or people who live in another area.</Trans></div>
                </div>
                <div className="form-group">
                  <div className="apk-22 d-flex"> <div class="tickIco mr-2"></div> <Trans i18nKey="Hide your age">Hide your age</Trans></div>
                  <div className="aml35"><Trans i18nKey="premiumText">Includes this striking feature that allows users to change their location and search for new matches or people who live in another area.</Trans></div>
                </div>
                <div className="form-group">
                  <div className="apk-22 d-flex"> <div class="tickIco mr-2"></div><Trans i18nKey="No ads">No ads</Trans></div>
                  <div className="aml35"><Trans i18nKey="premiumText">Includes this striking feature that allows users to change their location and search for new matches or people who live in another area.</Trans></div>
                </div>

              </div>
              <div class="apd10 text-center">
                <button class="border-0 pink-color white-bg text-center apk-24" onClick={this.closeModall.bind(this, 'modal4')}><Trans i18nKey="Continue">Continue</Trans></button>
              </div>
            </div>   
          </ModalBody>
        </Modal>

        <Modal isOpen={this.state.openModal} toggle={this.closeModal.bind(this, 'openModal')}  className="premiumPopup vertically-center apd5">
          <ModalHeader toggle={this.closeModal.bind(this, 'openModal')} >            
          </ModalHeader>  
          <ModalBody>    
          <div>                         
            <div className="premiumInfoHolder reducemargin">
                <div className="bold-font">
                <h4 >
                <Trans i18nKey=">Activate your account">Activate your account</Trans> 
                </h4>
                </div>
                <div className="premiumInfoSubText  secondaryText esmh-14 ">
                    <Trans i18nKey="subscribeTextSuper">Get unlimited access nearby friends, likes, superlike, chat, etc</Trans>
                </div>
            </div>
            
        </div>     
        <div className="premiumPopPlans subscribeprice">
            <div class="swiper-slide navigation--active swiper-slide-active" style={{width:"152px"}}>
        <div class="premiumPrice" ><div class="apd10 daysPrice"><div class="bold-font apk-30"  style={{color:"black"}}>{this.state.noofdays}</div><div class=" bold-font apk-24 mt-n2" style={{color:"black"}}><Trans i18nKey="Days">Days</Trans></div></div><div class="apd5 apk-16 mini-price">{this.state.currency}{this.state.price}</div></div>
        </div>
        </div>
        <div className="sidebarLastContent text-center">
              <NavLink to="/app/SubscribePayment"> <button className="btn-common btn-block" onClick={this.closeModal.bind(this, 'modal4')}><Trans i18nKey="Subscribe">Subscribe</Trans></button></NavLink>  
        </div>
          </ModalBody>
        </Modal>


            </div>

        );
    }
}

export default withTranslation("translations")(Profile); 
import React, { Component } from 'react';
import { Trans } from "react-i18next";

import { NavLink } from 'react-router-dom';
import axios from 'axios';
import Swiper from 'react-id-swiper';

import "../../config/i18n";
import { withTranslation } from "react-i18next";

import ls from "local-storage"; 
import { options } from "../../config/options"; 

import { Modal, ModalHeader, ModalBody } from 'reactstrap';
// import { BrowserRouter as Route } from "react-router-dom"; 
// import Slider from "react-slick"; 
// import Loader from 'react-loader-spinner'; 


class Boost extends Component { 
    constructor(props) {
        super(props);
        // this.filterchange = this.filterchange.bind(this);
        this.handleBoostItemClick = this.handleBoostItemClick.bind(this);

        this.state = {
            user_id:'',
            loader:'', profilespace:'none',
            api_url:localStorage.getItem('api_url'),
            boostStatus: '',
            boostCount: 0,
            boostPlanStatus: 0,
            clickOnce: 0, 
            boostItems: [], selected_boostpremium:'',
            currency:'',payment:'',license_key:'',boostpremium_price:'',premium_button:'none',
            lang: ls.get('LangKeySet') || options[0],
            langKey: ls.get('LangValue')

        }

        this.toggle = this.toggle.bind(this);
        this.boostModal = this.boostModal.bind(this); 
        this.useBoostModal = this.useBoostModal.bind(this); 
        this.showsubscriptionModel = this.showsubscriptionModel.bind(this);
        

    } 

  

    updateShared() {
        this.props.updateShared('run');
    }

    toggle() {
        this.setState(prevState => ({ 
            modal: !prevState.modal
        }));
    }


    componentDidMount() {
        this.setState({
            loader:'loder-icon'
        });
        setTimeout(() => {
            this.setState({
                profilespace: 'ProfileSpace',
                loader: 'none'
            });
        }, 300);     

       
    
       // const { match: { params } } = this.props;   

        var premiumlist = new FormData();
        premiumlist.append('user_id',JSON.parse(localStorage.getItem('session_userid')));
        premiumlist.append('platform', 'web');
        premiumlist.append('language', this.state.langKey); 

        axios({
            method: 'post',
            url: this.state.api_url+'webboostlist',
            data: premiumlist,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        }).then(res => {
            if(res.data.status === "true") {
                const data = res.data; 
                this.setState({
                    boostItems: data.result,
                    activeItem: data.result[0].id,
                    selected_boostpremium: data.result[0].id,
                    boostpremium_price: data.result[0].price,
                    currency: data.currency,
                    payment: data.payment,
                    license_key: data.license_key,
                    boostPlanStatus: 1, 
                });
                localStorage.setItem('selected_boostpremium',JSON.stringify(this.state.selected_boostpremium));
                localStorage.setItem('boostpremium_price',JSON.stringify(this.state.boostpremium_price));
                localStorage.setItem('currency',JSON.stringify(this.state.currency));
            } 
        }); 
               

    } 
    
    closeModal(tabId) {
        this.setState({
            [tabId]: false
        });
    }
    showModal(modal) { 
        this.setState({
            [modal]: true
        }); 
    } 

    useBoostModal() { 
        var profile = new FormData();
        profile.append('user_id',JSON.parse(localStorage.getItem('session_userid')));
        profile.append('language', this.state.langKey); 
        var boostClick = this.state.clickOnce;

        if(boostClick === 0) {
            this.setState({
                clickOnce: 1
            });
            axios({
                method: 'post',
                url: this.state.api_url+'webactivateboost',
                data: profile,
                config: { headers: {'Content-Type': 'multipart/form-data' }}
            }).then(res => { 
                if(res.data.status === "true") {
                    this.setState({
                        boostCount: res.data.boost_count
                    }); 
                    this.closeModal('modal4');   
                    this.showModal('modal5');  
                } else {
                    this.setState({
                        boostCount: res.data.boost_count
                    }); 
                    this.closeModal('modal4');   
                    this.showModal('modal');  
                }
                this.setState({
                    clickOnce: 0 
                });   

            })
        } 
    }

    showsubscriptionModel(){
        axios({
            method: 'get',
            url: this.state.api_url+'subscriptionDetails',                
            
          }).then(res => {
            const data = res.data;
            console.log(data);
            this.setState({                         
                    currency: data.currency,
                    price: data.price,
                    noofdays: data.noofdays,
                  });
                  this.showModal('openModal');  
                  localStorage.setItem('subscription_price',JSON.stringify(this.state.price));
                  localStorage.setItem('currency',JSON.stringify(this.state.currency));
                  localStorage.setItem('noofdays',JSON.stringify(this.state.noofdays));
             
          });
    }
    boostModal() { 
        var profile = new FormData();
        profile.append('user_id',JSON.parse(localStorage.getItem('session_userid')));
        profile.append('language', this.state.langKey); 

        axios({
            method: 'post',
            url: this.state.api_url+'webcheckboost',
            data: profile,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        }).then(res => { 
            if(res.data.status === "true") {
                this.setState({
                    boostStatus:'activated',
                    boostCount: res.data.boost_count
                }); 
                this.showModal('modal5');  
            } else if(res.data.status === "false") {
                var responseBoostStatus = (res.data.boost_count > 0) ? 'available' : 'expired';
                this.setState({
                    boostStatus: responseBoostStatus,
                    boostCount: res.data.boost_count
                });
                if(responseBoostStatus === "available")
                    this.showModal('modal4'); 
                else
                    this.showModal('modal'); 
            } else {
                this.setState({
                    boostStatus: 'Error'
                }); 
            } 
        })     
    }

    handleBoostItemClick(index) { 
        this.setState({
            activeItem: index,
            selected_boostpremium: index,
        })
        
        var premiumboostlist = new FormData();
        premiumboostlist.set('premiumboostid', index);
        premiumboostlist.set('language', this.state.langKey);    
        
        axios({
            method: 'post',
            url: this.state.api_url+'premiumboostid',
            data: premiumboostlist, 
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        }).then(res => {
            const data = res.data;
            this.setState({
                boostpremium_price: data.result.price,
            });
            localStorage.setItem('selected_boostpremium',JSON.stringify(index));
            localStorage.setItem('boostpremium_price',JSON.stringify(data.result.price));   
        }); 
    }  

    render() {  
        const params2 = { 
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev'
            },   
            slidesPerView: 3,
            slidesPerColumn: 1,
            breakpoints: {
                360: {
                    slidesPerView: 3, 
                },
                359: {
                    slidesPerView: 2,
                }
            }
        }; 

        return ( 
            <div> 
                
                <div className="iconHolder align-self-center"> 
                    <div className="backGlow"> 
                    {(this.props.subscribed == "false")?
                    <button className="sidebarIcoPos boostIcon align-self-center" onClick={this.showsubscriptionModel}></button> 
    :  <button className="sidebarIcoPos boostIcon align-self-center" onClick={this.boostModal}></button> 
      }
      </div>
                     
                </div>  

                <Modal isOpen={this.state.modal4} toggle={this.closeModal.bind(this, 'modal4')} className="premiumPopup boostPopup vertically-center apd5">
                    <ModalHeader toggle={this.closeModal.bind(this, 'modal4')}></ModalHeader>  
                    <ModalBody>
                            <div className="boostPopupIcon"></div>
                            <div className="text-center boostRemain">  
                              {this.state.boostCount} <Trans i18nKey="Remaining">Remaining</Trans>   
                            </div>
                            <div className="text-center boostContent">
                               <Trans i18nKey="You're being seen by more people.">You're being seen by more people.</Trans>
                            </div>
                            <div className="amb65 text-center boostContent">
                               <Trans i18nKey="Keep swiping for the best result.">Keep swiping for the best result.</Trans> 
                            </div>

                           <ul class="ub-ul text-center justify-content-end amt20">     
                                <li class="in-flex"><button class="amgAuto border-0 bg-white" onClick={this.closeModal.bind(this, 'modal4')}><Trans i18nKey="Cancel">Cancel</Trans></button></li>     
                                <li class="in-flex"><button class="amgAuto border-0 bg-white pink-color" onClick={this.useBoostModal} ><Trans i18nKey="Use Boost">Use Boost</Trans> </button> </li> 
                           </ul>  
                    </ModalBody>
                </Modal>
      
                <Modal isOpen={this.state.modal5} toggle={this.closeModal.bind(this, 'modal5')} className="premiumPopup boostPopup vertically-center apd5">  
                    <ModalHeader toggle={this.closeModal.bind(this, 'modal5')}></ModalHeader>   
                    <ModalBody>
                            <div className="boostPopupIcon"></div>
                            <div className="text-center boostRemain">  
                              {this.state.boostCount} <Trans i18nKey="Remaining">Remaining</Trans>   
                            </div>  
                            <div className="text-center boostContent">
                               <Trans i18nKey="You're being seen by more people.">You're being seen by more people.</Trans>
                            </div>
                            <div className="amb65 text-center boostContent">
                               <Trans i18nKey="Keep swiping for the best result.">Keep swiping for the best result.</Trans> 
                            </div>

                             <div className="ub-ul text-center justify-content-end amt20">
                               <div className="boostActive pink-color"><Trans i18nKey="Boost is active">Boost is active</Trans></div>   
                            </div> 
                    </ModalBody>
                </Modal> 

                <Modal isOpen={this.state.modal} toggle={this.toggle} className="premiumPopup boostPopup vertically-center apd5">
                    <ModalHeader toggle={this.toggle}></ModalHeader> 
                    <ModalBody>
                        <div className="boostPopupIcon"></div> 
                        <div className="premiumPop">   
                            { (this.state.boostPlanStatus === 1) ?
                                <div className="premiumPopPlans">
                                    <div className="text-center">
                                        <div class="bold-font">
                                            <Trans i18nKey="Skip the line">Skip the line</Trans>
                                        </div>
                                        <div class="premiumInfoSubText  secondaryText esmh-14 ">
                                            <Trans i18nKey="Be the top profile in your area for 30 minutes to get more matches.">
                                                Be the top profile in your area for 30 minutes to get more matches.  
                                            </Trans>
                                        </div>
                                    </div>  
                                    <Swiper {...params2}>
                                        {this.state.boostItems.map((item, index) =>
                                            <div className={this.state.activeItem === item.id ? 'navigation--active' : ''} onClick={this.handleBoostItemClick.bind(this, item.id)} alt={item.id} value={item.id}>
                                                <div className="premiumPrice">
                                                    <div className="apd10 daysPrice">
                                                        <div className="bold-font apk-30">{item.boost_count}</div>
                                                        <div className=" bold-font apk-24 mt-n2"><Trans i18nKey="Boosts">Boosts</Trans> </div>    
                                                    </div>
                                                    <div className="apd5 apk-16 mini-price">{this.state.currency}{item.price}</div>
                                                </div>
                                            </div>
                                        )} 
                                    </Swiper>    
                                    <ul class="ubp-ul text-center justify-content-end amt20">     
                                        <li class="in-flex"><button class="amgAuto border-0 bg-white" onClick={this.toggle}><Trans i18nKey="Cancel">Cancel</Trans></button></li>     
                                        <li class="in-flex"><NavLink to="/app/Boostpayment"><button class="amgAuto border-0 bg-white pink-color"><Trans i18nKey="Boost me">Boost me</Trans> </button></NavLink></li> 
                                    </ul>   
                                
                                </div>   
                            :
                                <div className="premiumPopPlans text-center"> <Trans i18nKey="No Plans Available">No Plans Available</Trans> </div> 
                            } 
                        </div>
                    </ModalBody>
                </Modal>  

        <Modal isOpen={this.state.openModal} toggle={this.closeModal.bind(this, 'openModal')} className="premiumPopup vertically-center apd5">
          <ModalHeader toggle={this.closeModal.bind(this, 'openModal')}>            
          </ModalHeader>  
          <ModalBody>    
          <div>                         
            <div className="premiumInfoHolder">
                <div className="bold-font">
                <h4 >
                <Trans i18nKey=">Activate your account">Activate your account</Trans> 
                </h4>
                </div>
                <div className="premiumInfoSubText  secondaryText esmh-14 ">
                    <Trans i18nKey="subscribeTextSuper">Get unlimited access nearby friends, likes, superlike, chat, etc</Trans>
                </div>
            </div>
            
        </div>     
        <div className="premiumPopPlans subscribeprice">
            <div class="swiper-slide navigation--active swiper-slide-active" style={{width:"152px"}}>
        <div class="premiumPrice" ><div class="apd10 daysPrice"><div class="bold-font apk-30">{this.state.noofdays}</div><div class=" bold-font apk-24 mt-n2"><Trans i18nKey="Days">Days</Trans></div></div><div class="apd5 apk-16 mini-price">{this.state.currency}{this.state.price}</div></div>
        </div>
        </div>
        <div className="sidebarLastContent text-center">
              <NavLink to="/app/SubscribePayment"> <button className="btn-common btn-block" onClick={this.closeModal.bind(this, 'modal4')}><Trans i18nKey="Subscribe">Subscribe</Trans></button></NavLink>  
        </div>
          </ModalBody>
        </Modal>

            </div> 

        );
    }
}


export default withTranslation("translations")(Boost);  
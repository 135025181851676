import React, { Component } from 'react';
import { Trans, withTranslation } from "react-i18next";
import "../../config/i18n";

import ls from "local-storage"; 
import { options } from "../../config/options"; 

import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import SideBar from "../main/SideBar";
import SideNav from "react-simple-sidenav";
import axios from 'axios';
import Admob from "../help/Admob";
import Loader from 'react-loader-spinner';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

class Friends extends Component {
    constructor(props) {
        super(props);
        this.friends = this.friends.bind(this);
        this.frndSearch = this.frndSearch.bind(this);
        this.loadMore = this.loadMore.bind(this);
        
        this.state = {
            showNav3: false,api_url:localStorage.getItem('api_url'),
            lang: ls.get('LangKeySet') || options[0],
            langKey: ls.get('LangValue'), subscribed: "false", 
            peopleDetails: [],clas1:'',clas2:'',clas3:'',clas4:'',clas5:'',txt1:'',txt2:'',frnd_search:'',limit:8,loadmore:'none'
            
        }
    }
    
    componentDidMount(){
        this.setState({
            loader:'loder-icon'
            });
            setTimeout(() => {
                this.setState({
                    profilespace: '',
                    loader: 'none'
                });
              }, 300);
            let data = new FormData();
            data.append('user_id', JSON.parse(localStorage.getItem('session_userid')));  
            //data.append('language', this.state.langKey); 
            axios.defaults.headers.common['Authorization'] = JSON.parse(localStorage.getItem('session_token')); 
            axios({
                method: 'post',
                url: this.state.api_url+'subscriptionStatus',
                data: data, 
                config: { headers: {'Content-Type': 'multipart/form-data' }}
            }).then(res => {              
              if(res.data.subscribed == 0 || res.data.subscribed_status == "expired") { 
                setTimeout(() => {
                    axios({
                        method: 'get',
                        url: this.state.api_url+'subscriptionDetails',                
                        
                      }).then(res => {
                        const data = res.data;
                        console.log(data);
                        this.setState({                         
                                currency: data.currency,
                                price: data.price,
                                noofdays: data.noofdays,                             
                                profilespace: 'none',     
                                loader: 'none',
                                feedscontainer: 'none',  
                                subscribed : 0,  
                                openModal:false
                                
                              });
        
                              setTimeout(() => {
                                this.setState({
                                    openModal: true
                                              
                                }); 
                            }, 30); 
        
                              localStorage.setItem('subscription_price',JSON.stringify(this.state.price));
                              localStorage.setItem('currency',JSON.stringify(this.state.currency));
                              localStorage.setItem('noofdays',JSON.stringify(this.state.noofdays));
                         
                      });
                }, 100);                
               
              }else{               
                  this.setState({                     
                      subscribed : "true",
                      profilespace: '',
                  });
               
              }
            })

        var profile = new FormData();
        profile.set('user_id', JSON.parse(localStorage.getItem('session_userid')));
        profile.set('limit', this.state.limit);
        profile.set('language', this.state.langKey); 
            axios({
                method: 'post',
                url: this.state.api_url+'webfriendslist',
                data: profile,
                config: { headers: {'Content-Type': 'multipart/form-data' }}
                            })
        .then(res => {
            if(res.data.status === "true"){
              const peopleDetails = res.data.peoples;
              // console.log("frienst_list",res.data);
              const tmp_limit = this.state.limit;
              this.setState({ peopleDetails,limit: this.state.limit + 8});
              if(tmp_limit  === peopleDetails.length){
                this.setState({ loadmore: 'loadMor btn-common'});
              }
            }else{
                this.setState({ clas1: 'noItemsContainers',clas2:'friendsNull',clas3:'nullFriends nullSize',clas4:'text-dark bold-font text-center apk-20',clas5:'secondaryText apk-16 regular-font text-center',txt1:'No Matches',txt2:'Start making friends now!' });
            }
        })
        this.scrollToBottom();
    }

    closeModal(tabId) {
        this.setState({
            [tabId]: false
        });
    }
  
    showModal() {
        this.setState(prevState => ({
            openModal: !prevState.openModal
        }));
      }

    componentDidUpdate() {
        this.scrollToBottom();
      }
    loadMore(){ 
       
        var profile = new FormData();
        profile.set('user_id', JSON.parse(localStorage.getItem('session_userid')));
        profile.set('limit', this.state.limit);
        profile.set('language', this.state.langKey); 
            axios({
                method: 'post',
                url: this.state.api_url+'webfriendslist',
                data: profile,
                config: { headers: {'Content-Type': 'multipart/form-data' }}
                            })
        .then(res => {
            if(res.data.status === "true"){
          const peopleDetails = res.data.peoples;
          // console.log("frienst_list",res.data);
          const tmp_limit = this.state.limit;
          this.setState({ peopleDetails,limit: this.state.limit + 8})
          if(tmp_limit  === peopleDetails.length){
            this.setState({ loadmore: 'loadMor btn-common'});
          }else{
            this.setState({ loadmore: 'none'});   
          }
            }else{
                this.setState({ clas1: 'noItemsContainers',clas2:'friendsNull',clas3:'nullFriends nullSize',clas4:'text-dark bold-font text-center apk-20',clas5:'secondaryText apk-16 regular-font text-center',txt1:'No Matches',txt2:'Start making friends now!' });
            }
        })
        this.scrollToBottom();
       
    }
    scrollToBottom = () => {
        this.messagesEnd.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
     }
    frndSearch(e){
        const tmp = e.target.value;
        this.setState({frnd_search:tmp});
       if(e.target.value != null){
        this.setState({limit:8});
        var message = new FormData();
        message.set('user_id', JSON.parse(localStorage.getItem('session_userid')));
        message.set('search_key', e.target.value);
        message.set('language', this.state.langKey); 
                axios({
                  method: 'post',
                  url: this.state.api_url+'webfriendslist',
                  data: message,
                  config: { headers: {'Content-Type': 'multipart/form-data' }}
                    })
                    .then(res => {
                        if(res.data.status === "true"){
                          const peopleDetails = res.data.peoples;
                          // console.log("frienst_list",res.data);
                          const tmp_limit = this.state.limit;
                          this.setState({ peopleDetails,limit: this.state.limit + 8})
                          if(tmp_limit  === peopleDetails.length){
                            this.setState({ loadmore: 'loadMor btn-common'});
                          }else{
                            this.setState({ loadmore: 'none'});   
                          }
                          this.setState({ peopleDetails,clas1: '',clas2:'',clas3:'',clas4:'',clas5:'',txt1:'',txt2:'' });
                          
                        }else{
                            this.setState({ clas1: 'noItemsContainers',clas2:'friendsNull',clas3:'nullFriends nullSize',clas4:'text-dark bold-font text-center apk-20',clas5:'secondaryText apk-16 regular-font text-center',txt1:'No Matches',txt2:'Start making friends now!',peopleDetails:[] });
                            this.setState({ loadmore: 'none'});
                        }
                    })
                   }             
            
    }  
    friends(e) {
       var online_value = e.target.value;
        var profile = new FormData();
        profile.set('user_id', JSON.parse(localStorage.getItem('session_userid')));
        profile.set('language', this.state.langKey);  
        if(online_value === '1'){
        profile.set('sort', 'online');
        }
            axios({ 
                method: 'post',
                url: this.state.api_url+'webfriendslist',
                data: profile,
                config: { headers: {'Content-Type': 'multipart/form-data' }}
                            })
        .then(res => {
            if(res.data.status === "true"){
          const peopleDetails = res.data.peoples;
          // console.log("frienst_list",res.data); 
          this.setState({ loadmore: 'none'});
          this.setState({ peopleDetails,clas1: '',clas2:'',clas3:'',clas4:'',clas5:'',txt1:'',txt2:'' });
            }else{
                this.setState({ loadmore: 'none'});
                this.setState({ peopleDetails:[], clas1: 'noItemsContainers',clas2:'friendsNull',clas3:'nullFriends nullSize',clas4:'text-dark bold-font text-center apk-20',clas5:'secondaryText apk-16 regular-font text-center',txt1:'No Matches',txt2:'Start making friends now!' });
            }
        })
        }
    render() {
          const { t } = this.props;  

          var style = {
            zIndex:10,
            backgroundColor:"white",
            position:"absolute",
            height:"80vh",
            width:"100%",
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
            //width:"100%",
            overflowY:"hidden"
          };
        return (

            <div className="setting-page">

                <div className="d-flex profile-header-top stickyProfileHead justify-content-between apd15 apr0 apl0 white-bg amb10 flex-column flex-md-row">
                    <div class="heading-left-top d-flex">
                        <div className="align-self-center ">
                            <div className="iconHolder align-self-center mr-1">
                                <div className="backGlow  d-block d-lg-none">
                                    <div className="sideBarToggler sidebarIcoPos" onClick={() => this.setState({ showNav3: true })}></div>
                                </div>
                            </div>
                        </div>
                        <select name="" id="" onChange={this.friends} className="arrow-center align-self-center ml-1 bold-font border-0 topHeaderDrops">
                            <option value="0">{t("All matches")}</option>
                            <option value="1">{t("Online")}</option>     
                        </select>
                    </div>
                    <div className="searchMessage">
                        <input type="text" onChange={this.frndSearch} value={this.state.frnd_search} name="" id="" placeholder={t("Search")} />  
                    </div>
                    
                </div>
                
                
                <div className={this.state.loader}>
                        <div style={style} id="loader">
                            <Loader type="Bars" color="#000000" height={30} width={50} />
                        </div>
                    </div>
            
                    <div className={this.state.profilespace}>
                <div className="peopleShowHolder">
                    <div className="row">
                        {this.state.peopleDetails.map((peopleDetails) =>
                            <div className="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-3 col-Resp col-xl-Resp">
                                <div className="peopleSpaceHolder">
                                    <div className="peopleViewHolder">
                                        <div className="peoplePic">
                                            <Link  to={`/app/Friends/FriendsNew/${peopleDetails.user_id}`}> 
                                            <img src={peopleDetails.user_image} alt="" className="" />
                                            </Link>
                                            <div className={peopleDetails.online === "1"? "onlineN":"none"}></div>
                                        </div>
                                        <div className="apd10 cardMin">
                                            <div className="media">
                                                <div className="media-body pFHidden align-self-center">
                                                <Link  to={`/app/Friends/FriendsNew/${peopleDetails.user_id}`}>  <div className="text-truncate bold-font BtoRcolor apk-16">{peopleDetails.user_name}</div>  </Link>
                            
                                                <Link  to={`/app/Friends/FriendsNew/${peopleDetails.user_id}`}>  <div className="text-truncate secondaryText esmh-14 regular-font">{peopleDetails.show_distance === '1' ? " " :peopleDetails.location }</div>  </Link> 
                                                </div>
                                                <div className="ml-1">
                                                {peopleDetails.show_age === 1 ? " " :peopleDetails.age}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div style={{ float:"left", clear: "both" }}
             ref={(el) => { this.messagesEnd = el; }}>
                     </div>
                     </div>
                    </div>
                </div>
                <div className={this.state.clas1}>

                <div class={this.state.clas2}>
                    <div className={this.state.clas3}></div>
                    <div className={this.state.clas4}>
                        <Trans i18nKey={this.state.txt1}>{this.state.txt1}</Trans></div>
                </div>

                </div>
                <div className="sidebarResp d-block d-lg-none">
                    <SideNav showNav={this.state.showNav3} onHideNav={() => this.setState({ showNav3: false })} title={<div></div>} items={[<SideBar />]} />
                </div>
                <div className={this.state.loadmore} onClick={this.loadMore}> 
                    <Trans i18nKey="Load More !">Load More !</Trans>  
                </div>
                <Admob></Admob>

                
             <Modal isOpen={this.state.openModal} toggle={this.closeModal.bind(this, 'openModal')} className="premiumPopup vertically-center apd5">
          <ModalHeader toggle={this.closeModal.bind(this, 'openModal')}>            
          </ModalHeader>  
          <ModalBody>    
          <div>                         
            <div className="premiumInfoHolder reducemargin">
                <div className="bold-font">
                <h4 >
                <Trans i18nKey=">Activate your account">Activate your account</Trans> 
                </h4>
                </div>
                <div className="premiumInfoSubText  secondaryText esmh-14 ">
                    <Trans i18nKey="subscribeTextSuper">Get unlimited access nearby friends, likes, superlike, chat, etc</Trans>
                </div>
            </div>
            
        </div>     
        <div className="premiumPopPlans subscribeprice">
            <div class="swiper-slide navigation--active swiper-slide-active" style={{width:"152px"}}>
        <div class="premiumPrice" ><div class="apd10 daysPrice"><div class="bold-font apk-30"  style={{color:"black"}}>{this.state.noofdays}</div><div class=" bold-font apk-24 mt-n2" style={{color:"black"}}><Trans i18nKey="Days">Days</Trans></div></div><div class="apd5 apk-16 mini-price">{this.state.currency}{this.state.price}</div></div>
        </div>
        </div>
        <div className="sidebarLastContent text-center">
              <NavLink to="/app/SubscribePayment"> <button className="btn-common btn-block" onClick={this.closeModal.bind(this, 'modal4')}><Trans i18nKey="Subscribe">Subscribe</Trans></button></NavLink>  
        </div>
          </ModalBody>
        </Modal>   
            </div>

        );
    }
}
 
export default withTranslation("translations")(Friends); 
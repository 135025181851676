import React, { Component } from 'react';
import { Trans } from "react-i18next";

import { NavLink } from 'react-router-dom';
import SideBar from "../main/SideBar";
import axios from 'axios';     
import SideNav from "react-simple-sidenav";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import "../../config/i18n";
import { withTranslation } from "react-i18next"; 

import ls from "local-storage"; 
import { options } from "../../config/options";

import Admob from "../help/Admob";   
import Slider from "react-slick";  
import Loader from 'react-loader-spinner'; 
import $ from 'jquery';  

class Feeds extends Component {   
   constructor(props) {
      super(props); 
      
      this.state = {
         showNav3: false, 
         peopleDetails: [],
         feedsAvailability: 0,
         items: [],api_url:localStorage.getItem('api_url'), 
         lang: ls.get('LangKeySet') || options[0],
         langKey: ls.get('LangValue'),  
         subscribed : 0,openModal:false
      } 
     this.toggle = this.toggle.bind(this);
   }

   toggle() {
      this.setState(prevState => ({ 
         modal: !prevState.modal
      }));
   }

   closeModal(tabId) {
      this.setState({
          [tabId]: false
      });
  }

  showModal() {
      this.setState(prevState => ({
          openModal: !prevState.openModal
      }));
    }

   componentDidMount() { 
      this.setState({
        loader:'loder-icon'
      });
   
      setTimeout(() => {
        this.setState({
            loader: 'none'
        });
      }, 300); 

           let data = new FormData();
            data.append('user_id', JSON.parse(localStorage.getItem('session_userid')));  
            //data.append('language', this.state.langKey); 
            axios.defaults.headers.common['Authorization'] = JSON.parse(localStorage.getItem('session_token')); 
            axios({
                method: 'post',
                url: this.state.api_url+'subscriptionStatus',
                data: data, 
                config: { headers: {'Content-Type': 'multipart/form-data' }}
            }).then(res => {
              
              if(res.data.subscribed == 0 || res.data.subscribed_status == "expired") {
               setTimeout(() => {
                  axios({
                      method: 'get',
                      url: this.state.api_url+'subscriptionDetails',                
                      
                    }).then(res => {
                      const data = res.data;
                      console.log(data);
                      this.setState({                         
                              currency: data.currency,
                              price: data.price,
                              noofdays: data.noofdays,                             
                              profilespace: 'none',     
                              loader: 'none',
                              feedscontainer: 'none',  
                              subscribed : 0 
                              
                            });
      
                            setTimeout(() => {
                              this.setState({
                                  openModal: true
                                            
                              }); 
                          }, 100); 
      
                            localStorage.setItem('subscription_price',JSON.stringify(this.state.price));
                            localStorage.setItem('currency',JSON.stringify(this.state.currency));
                            localStorage.setItem('noofdays',JSON.stringify(this.state.noofdays));
                       
                    });
              }, 100); 
                
                
              }else{
                
                  this.setState({
                     subscribed : 1  
                     
                  });
                
              }
            })

      var profile = new FormData();
      profile.set('user_id', JSON.parse(localStorage.getItem('session_userid')));
      profile.set('language', this.state.langKey);  
      
      axios({
         method: 'post',
         url: this.state.api_url+'webfeeds',
         data: profile,
         config: { headers: {'Content-Type': 'multipart/form-data' }}
      }).then(res => {
         const data = res.data;
         if(data.status === "true") {
            this.setState({
               feedsAvailability: 1, 
               peopleDetails: data.feeds, 
            });
         } 
      })

   }

   Message(receiverId) { 
      // console.log("User_id "+ JSON.parse(localStorage.getItem('session_userid')));
      // console.log("receiverId "+receiverId);
      
      var report = new FormData();
      report.append('sender_id', JSON.parse(localStorage.getItem('session_userid')));
      report.append('receiver_id',receiverId);
      report.append('language', this.state.langKey);  
      axios({
         method: 'post',
         url: this.state.api_url+'webcreatchat',
         data: report,
         config: { headers: {'Content-Type': 'multipart/form-data' }}
      }).then(res => {
         const chatid = res.data.chatid;  
         this.props.history.push(`/app/Message/MessageNew/${receiverId}/${chatid}`); 
      })

   }

   feedLike(FeedId) {     
      var feedLikeId =  "#feed-"+FeedId;

      if(!$(feedLikeId).hasClass("active")) {
         var report = new FormData();
         report.append('user_id', JSON.parse(localStorage.getItem('session_userid')));
         report.append('feed_id',FeedId); 
         report.append('language', this.state.langKey);  
         axios({
            method: 'post',
            url: this.state.api_url+'webfeedlike', 
            data: report,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
         }).then(res => {
            if(res.data.status === "true") {
               $(feedLikeId).addClass("active");   
               $(feedLikeId).removeAttr('onclick');  
            } 
         })
      }   
   }

   render() { 

      var style = {
         zIndex:10,
         backgroundColor:"white",
         height:"80vh",
         position:"absolute",
         width:"100%",
         display:"flex",
         justifyContent:"center",
         alignItems:"center",
         //width:"100%",
         overflowY:"hidden"
      };

      const settings = {
            dots: true,
            arrows:false,
            infinite: false,
            speed: 500,
            autoplay: true, 
            slidesToShow: 1,  
            slidesToScroll: 1,
          };   

      return (

         <div className="setting-page">

            <div className="d-flex profile-header-top stickyProfileHead justify-content-between apd15 apr0 apl0 white-bg ">
               <div class="heading-left-top d-flex">
                  <div className="align-self-center ">
                      <div className="iconHolder align-self-center mr-1">
                          <div className="backGlow  d-block d-lg-none">
                              <div className="sideBarToggler sidebarIcoPos" onClick={() => this.setState({ showNav3: true })}></div>
                          </div>
                      </div>
                  </div>
                  <span class="arrow-center align-self-center ml-1 bold-font"><Trans i18nKey="Feeds">Feeds</Trans></span>    
               </div>
               <div className="backGlow white-bg"></div>
            </div>
              
               
            <div className={this.state.loader}>
               <div style={style} id="loader">
                  <Loader type="Bars" color="#000000" height={30} width={50} />
               </div>
            </div>

            
            <div className="">
                <div className="peopleShowHolder">
                    <div className="row">
                     {(this.state.feedsAvailability === 1 && this.state.subscribed == 1) ?
                        this.state.peopleDetails.map(peopleDetails =>
                        <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-Resp col-xl-Resp offset-xl-3 offset-lg-3 offset-md-3 offset-sm-3">    
                           <div className="peopleSpaceHolder" onClick={this.toggle}>
                              <div className="feedViewHolder">
                                 <div className="feedProfileBody">
                                    <div className="media">
                                       <div className="align-self-center mx-3 relPos"> 
                                          <NavLink to={`/app/Friends/FriendsNew/${peopleDetails.user_id}`}> 
                                             <img className="currentUserDP" alt="" src={peopleDetails.user_image} ></img> 
                                          </NavLink>  
                                       </div>

                                       <div className="media-body pFHidden align-self-center">
                                          {/*<NavLink to="/app/Profile"><div className="text-truncate text-dark bold-font">{this.state.user_details.user_name}</div></NavLink>*/}
                                          <div className="text-truncate text-dark">{peopleDetails.user_name}</div>
                                          <div className="text-truncate secondaryText esmh-12 regular-font ">
                                             <Trans i18nKey={ (peopleDetails.feed_type === "image") ? "Added new photo": ((peopleDetails.feed_type === "location") ? "Updated location": "Profile information changed")}>
                                                { (peopleDetails.feed_type === "image") ? "Added new photo": ((peopleDetails.feed_type === "location") ? "Updated location": "Profile information changed")}  
                                             </Trans>
                                          </div>
                                       </div>
                                    </div> 
                                 </div>

                                 <div className="peoplePic">
                                 { (peopleDetails.feed_type === "image") ?
                                    <Slider {...settings}>
                                       {peopleDetails.feed_content_images.map((image_tmp) => 
                                          <img onError={this.addDefaultSrc} src={image_tmp} id="current_image" alt="" value={image_tmp} onChange={()=>{this.handleswipe(image_tmp)}} className="" />
                                       )} 
                                    </Slider>
                                    :
                                    <img src={peopleDetails.user_image} alt="" />
                                 } 
                                 </div> 

                                 {(peopleDetails.feed_type !== "image") ?
                                    <div className="feedType pFHidden align-self-center">
                                       <div className="text-truncate esmh-14"><Trans i18nKey={peopleDetails.feed_type}>{peopleDetails.feed_type}</Trans></div> 
                                       <div className="text-truncate regular-font sma-16">  
                                          {peopleDetails.feed_content} 
                                       </div>
                                    </div>
                                    : ""
                                 }  

                                 <div className="apd10">
                                    <div className="media feedMedia">
                                       <div className="align-self-center mr-3 relPos">   
                                          { (peopleDetails.liked === "true") ?
                                          <button id={`feed-${peopleDetails.feed_id}`} className="currentLikeDP active"></button>   
                                          :
                                          <button id={`feed-${peopleDetails.feed_id}`} className="currentLikeDP" onClick={this.feedLike.bind(this, peopleDetails.feed_id)}></button>   
                                          } 
                                       </div> 
                                       <div className="align-self-center mr-3 relPos">
                                          <button className="currentChatDP" onClick={this.Message.bind(this, peopleDetails.user_id)}></button>    
                                       </div>  
                                    </div>

                                    <div className="media">
                                       <div className="align-self-center mx-2 relPos"> 
                                          <div className="feedGenderIcon"></div>
                                       </div>
                                       <div className="media-body pFHidden align-self-center">
                                          <div className="text-truncate apk-16 textcapfirst"><Trans i18nKey={peopleDetails.gender}>{peopleDetails.gender}</Trans></div>
                                       </div>  
                                    </div>
                                    <div className="media">
                                       <div className="media-body pFHidden align-self-center">
                                          <div className="text-truncate secondaryText esmh-12 regular-font">{peopleDetails.feed_time}</div>
                                       </div>  
                                    </div> 
                                 </div> 

                              </div>
                           </div>
                        </div>
                        )     
                     :
                        <div class="noFeedsContainers">  
                           <div class="visitorsNull">
                              <div class="nullVisitors nullSize"></div>
                              <div class="text-dark bold-font text-center apk-20"><Trans i18nKey="No Feeds">No Feeds</Trans></div>
                           </div>
                        </div> 
                     }    

                     </div>  
                </div>
            </div>
                
                                
            <div className="sidebarResp d-block d-lg-none">
               <SideNav showNav={this.state.showNav3} onHideNav={() => this.setState({ showNav3: false })} title={<div></div>} items={[<SideBar />]} />
            </div>
                
            <Admob></Admob> 

             <Modal isOpen={this.state.openModal} toggle={this.closeModal.bind(this, 'openModal')} className="premiumPopup vertically-center apd5">
          <ModalHeader toggle={this.closeModal.bind(this, 'openModal')}>            
          </ModalHeader>  
          <ModalBody>    
          <div>                         
            <div className="premiumInfoHolder reducemargin">
                <div className="bold-font">
                <h4 >
                <Trans i18nKey=">Activate your account">Activate your account</Trans> 
                </h4>
                </div>
                <div className="premiumInfoSubText  secondaryText esmh-14 ">
                    <Trans i18nKey="subscribeTextSuper">Get unlimited access nearby friends, likes, superlike, chat, etc</Trans>
                </div>
            </div>
            
        </div>     
        <div className="premiumPopPlans subscribeprice">
            <div class="swiper-slide navigation--active swiper-slide-active" style={{width:"152px"}}>
        <div class="premiumPrice" ><div class="apd10 daysPrice"><div class="bold-font apk-30"  style={{color:"black"}}>{this.state.noofdays}</div><div class=" bold-font apk-24 mt-n2" style={{color:"black"}}><Trans i18nKey="Days">Days</Trans></div></div><div class="apd5 apk-16 mini-price">{this.state.currency}{this.state.price}</div></div>
        </div>
        </div>
        <div className="sidebarLastContent text-center">
              <NavLink to="/app/SubscribePayment"> <button className="btn-common btn-block" onClick={this.closeModal.bind(this, 'modal4')}><Trans i18nKey="Subscribe">Subscribe</Trans></button></NavLink>  
        </div>
          </ModalBody>
        </Modal>     

         </div>

      );
   } 
}
 
export default withTranslation("translations")(Feeds);  
import React, { Component } from 'react';
import { Trans } from "react-i18next";
import SideNav from "react-simple-sidenav";
import { NavLink } from "react-router-dom";
import SideBar from "../main/SideBar";

import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Swiper from 'react-id-swiper';
//import { GoogleComponent } from 'react-google-location';
import Autocomplete from 'react-google-autocomplete';
import axios from 'axios';
import Admob from "../help/Admob"; 
 
import ls from "local-storage"; 
import { options } from "../../config/options"; 

import Boost from "../main/Boost"; 
import "../../config/i18n";
import { withTranslation } from "react-i18next";

const API_KEY = "AIzaSyDVVob6U7eho9MpD99_7UCFmEzoH6N3Rrw";

class FindNewPeople extends Component {

    constructor(props) {
        super(props);
        this.onSubmitlocationsearch = this.onSubmitlocationsearch.bind(this); 
        this.state = {
            openModal:false,
            modal1: false,
            modal2: false,
            modal: false,showNav3: false,place: null,lat: null,lon: null,user_details:'',errors:  {},interests: [],
            items:[],
            itemss: [],activeItems: '',
            user_id:'',change_loc:'none',change_loc2:'none',
            selected_premium:'',
            currency:'',payment:'',license_key:'',premium_price:'',appholder:'appHolder',peopleShowHolder:'none',
            premium_btn:'none',
            permium_superlikeCount: 0, 
            permium_boostCount: 0,   
            lang: ls.get('LangKeySet') || options[0],
            langKey: ls.get('LangValue'), 
            peopleDetails: [],api_url:localStorage.getItem('api_url'),tmp_image:'circle-ripple',circle_image:'none'

        };

    }

    addDefaultSrc(ev){
        ev.target.src =  require('../../assets/img/default_user.png')
    }  

    componentDidMount(){

        localStorage.setItem('filter_page','2');
        this.setState({
            appholder: 'appHolder',
            peopleShowHolder: 'none',
            premium_btn:'none',
            tmp_image:'circle-ripple',
        });
        this.setState({
            tmp_image:'none',
            circle_image:'circle-ripple' 
        });

        let data1 = new FormData();
        data1.append('user_id', JSON.parse(localStorage.getItem('session_userid')));  
        //data.append('language', this.state.langKey); 
        axios.defaults.headers.common['Authorization'] = JSON.parse(localStorage.getItem('session_token')); 
        axios({
            method: 'post',
            url: this.state.api_url+'subscriptionStatus',
            data: data1, 
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        }).then(res => {              
          if(res.data.subscribed == 0 || res.data.subscribed_status == "expired") {      
            setTimeout(() => {
                axios({
                    method: 'get',
                    url: this.state.api_url+'subscriptionDetails',                
                    
                  }).then(res => {
                    const data = res.data;
                    console.log(data);
                    this.setState({                         
                            currency: data.currency,
                            price: data.price,
                            noofdays: data.noofdays,
                            subscribed: "false",
                            
                          });
    
                          setTimeout(() => {
                            this.setState({
                                openModal: true
                                          
                            }); 
                        }, 100); 
    
                          localStorage.setItem('subscription_price',JSON.stringify(this.state.price));
                          localStorage.setItem('currency',JSON.stringify(this.state.currency));
                          localStorage.setItem('noofdays',JSON.stringify(this.state.noofdays));
                     
                  });
            }, 200); 
           
                       
            
          }else{               
              this.setState({                     
                  subscribed : "true"                 
              });
           
          }
        })
        let data = new FormData();
        data.append('user_id', JSON.parse(localStorage.getItem('session_userid')));  
        data.append('language', this.state.langKey); 
          
        axios({
            method: 'post',
            url: this.state.api_url+'webprofile',
            data: data, 
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        }).then(res => {
            if(res.data.status !== false) {
                const user_details = res.data.result;
                
                this.setState({
                    user_details: user_details,
                });
              
                var date = new Date();
                var details = new FormData();
                details.append('user_id', JSON.parse(localStorage.getItem('session_userid')));
                details.append('language', this.state.langKey);
                details.append('limit', 20);                 
                details.append('timestamp', date.getTime());
                
                axios.defaults.headers.common['Authorization'] = JSON.parse(localStorage.getItem('session_token'));
                axios({
                    method: 'post',
                    url: this.state.api_url+'webgetpeople',
                    data: details,
                    config: { headers: {'Content-Type': 'multipart/form-data' }}
                }).then(res => {
                    if(res.data.status === "false"){
                        this.setState({
                            appholder: 'appHolder',
                            peopleShowHolder: 'none',
                            premium_btn:'none',
                            change_loc:'amt20 apk-16 searchRipplePos',
                            change_loc2:'sidebarLastContent searchRipplePos2',
                        });
                    } else {
                        // console.log(res.data.peoples);
                        const dataa = res.data;
                        this.setState({
                            peopleDetails: dataa.peoples,
                        });  
                           
                        if(this.state.user_details.premium_member === 'true') {
                            setTimeout(() => {
                                this.setState({
                                    appholder: 'none',
                                    peopleShowHolder:'peopleShowHolder',
                                    premium_btn:'none'
                                });
                            }, 2000);

                        } else {
                            setTimeout(() => {
                                this.setState({
                                    appholder: 'none',
                                    peopleShowHolder:'peopleShowHolder',   
                                    premium_btn:'becomePremiumVisitorPagee d-flex flex-column align-items-center mt-3 mb-3'
                                });
                            }, 2000);
                        }      
                    }
                }).catch(error => {
                   this.props.history.push("/login"); 
                });     

                var premiumlist = new FormData();
                premiumlist.set('platform', 'web');
                premiumlist.set('language', this.state.langKey);
                
                axios({
                    method: 'post',
                    url: this.state.api_url+'premiumlistweb', 
                    data: premiumlist,
                    config: { headers: {'Content-Type': 'multipart/form-data' }}
                }).then(res => {
                    const data = res.data;
                
                    if(res.data.status==='true') {
                        this.setState({
                          itemss: data.result,
                          activeItems: data.result[0].id,
                          selected_premium: data.result[0].id,
                          premium_price: data.result[0].price,
                          permium_superlikeCount: data.result[0].superlikecount, 
                          permium_boostCount: data.result[0].boostcount,    
                          currency: data.currency,
                          payment: data.payment,
                          license_key: data.license_key,
                        });
                    }
                }).catch(error => {
                   this.props.history.push("/login");  
                }); 
                localStorage.setItem('selected_premium',JSON.stringify(this.state.selected_premium));
                localStorage.setItem('premium_price',JSON.stringify(this.state.premium_price));
                localStorage.setItem('currency',JSON.stringify(this.state.currency));
            } else {
                this.props.history.push("/login");  
            }
        }).catch(error => {
           this.props.history.push("/login"); 
        });

    }

    handleItemClick(index) {
        this.setState({
          activeItems: index,
          selected_premium: index,
        })
        var premiumlist = new FormData();
        premiumlist.set('premiumid', index);
        premiumlist.set('language', this.state.langKey);
        axios({
          method: 'post',
          url: this.state.api_url+'premiumid',
          data: premiumlist,
          config: { headers: {'Content-Type': 'multipart/form-data' }}
        }).then(res => { 
              const data = res.data;
              this.setState({
                premium_price: data.result.price,
                permium_superlikeCount: data.result.superlikecount, 
                permium_boostCount: data.result.boostcount,
            });
            localStorage.setItem('selected_premium',JSON.stringify(index));
            localStorage.setItem('premium_price',JSON.stringify(data.result.price));    
        }).catch(error => { 
           this.props.history.push("/login"); 
        });
    }
    
    onChangepack(e) {
        this.setState({
          selected_premium: e.target.value,
        })
    
    }

    closeModal(tabId) {
        this.setState({
            [tabId]: false
        });
    }

    showModal(modal) {
        if(this.state.user_details.premium_member === "true"){
            this.setState({
                modal1: true
            });
        }else{
            this.setState({
                modal2: true
            });
        }
    }
 
    onSubmitlocationsearch(e){
        e.preventDefault();
        let errors = {};
        
        if(this.state.lat === "" ||this.state.lat === null ||  this.state.lon === "" || this.state.place=== "" || this.state.place=== null) {
            errors["locationsearch"] = "please select the location";
        } else if(typeof(this.state.lat) === 'undefined' || typeof(this.state.lon) === 'undefined') {
            errors["locationsearch"] = "please select the location from dropdown";
        } else {
            var location = new FormData();
            if(this.state.place){
                var address=this.state.place;
                var value=address.split(","); 
                var count=value.length; 
                var country=value[count-1]; 
                var state=value[count-2]; 
                var city=value[count-3]; 
                
                if(city != null){
                    //alert(city)
                    location.append('place', city+", "+state+", "+country);
                    this.setState({
                        loc: city+", "+state+", "+country
                    });
                }else{
                    location.append('place', state+", "+country);
                    this.setState({
                        loc: +state+", "+country
                    });
                }
            }
            
            location.append('lat', this.state.lat);
            location.append('lon', this.state.lon);
            location.append('user_id', JSON.parse(localStorage.getItem('session_userid')));
            location.append('language', this.state.langKey);  
        
            axios.defaults.headers.common['Authorization'] = JSON.parse(localStorage.getItem('session_token'));
        
            axios({
                method: 'post',
                url: this.state.api_url+'websetprofile', 
                data: location,
                config: { headers: {'Content-Type': 'multipart/form-data' }}
            }).then(res => { 
                const status = res.data.status;
            
                if(status === "true") {
                    this.props.history.push("/app/PeopleNear")
                } 
            
            }).catch(error => {
               this.props.history.push("/login"); 
            }); 
        
        }
        this.setState({
			errors: errors
		}); 		
    }

    render() {
        const params = {
          pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
          },
          spaceBetween: 1,
          rebuildOnUpdate: true   
        }   

        const params2 = {
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev'
            },   
            slidesPerView: 3,
            slidesPerColumn: 1,
            breakpoints: {
                360: {
                    slidesPerView: 3,
                },
                359: {
                    slidesPerView: 2,
                }
            }
        };
        localStorage.setItem('place', JSON.stringify(this.state.place));
        localStorage.setItem('lat', JSON.stringify(this.state.lat));
        localStorage.setItem('lon', JSON.stringify(this.state.lon));
        return (

            <div className="setting-page peopleNpage">

                <div className="d-flex profile-header-top stickyProfileHead justify-content-between apd15 apr0 apl0 white-bg amb10 ">
                    <div class="heading-left-top d-flex">
                        <div className="align-self-center d-block d-lg-none">
                            <div className="iconHolder align-self-center">
                                <div className="backGlow">
                                    <div className="sideBarToggler sidebarIcoPos" onClick={() => this.setState({ showNav3: true })}></div>
                                </div>
                            </div>
                        </div>
                        <span class="arrow-center align-self-center d-none ml-2 bold-font">
                            <Trans i18nKey="Findnewpeople"><span className="floating-label">Find new people</span> </Trans>
                        </span>
                        <Boost />  
                    </div>   

                    <div className="align-self-center  d-block">
                        <span class="heading-left-top arrow-center align-self-center ml-2 bold-font">
                            <Trans i18nKey="Findnewpeople"><span className="floating-label">Find new people</span> </Trans>
                        </span>  
                        <img src={require('../../assets/img/logo-c-64.png')} className="d-none sideLogo commonLogoC" alt="logo" />
                    </div>

                    
                    <div className="iconHolder align-self-center">
                    { (this.state.user_details.subscribed == "true" &&  this.state.user_details.subscribed_status != "expired") ?
                        <NavLink to={"/app/Filter"} >
                            <div className="backGlow">
                                <div className="sidebarIcoPos filterIcon align-self-center"></div>
                            </div>
                        </NavLink> : '' }
                    </div> 
                </div>

                { (this.state.user_details.subscribed == "true" &&  this.state.user_details.subscribed_status != "expired") ?
                <div className={this.state.premium_btn}>
                    <div className="apk-30 bold-font mb-2"><Trans i18nKey="Find unlimited people">Find unlimited people</Trans></div> 
                    <button className="btn-common" onClick={this.showModal.bind(this, 'modal2')}><Trans i18nKey="Become a Premium Member">Become a Premium Member</Trans></button>
                </div>: '' }

                <div className={this.state.appholder}>
                { (this.state.user_details.subscribed == "true" &&  this.state.user_details.subscribed_status != "expired") ?
                    <div className="d-flex flex-column align-items-center relPos">
                        <div class={this.state.circle_image}>
                        <img src={this.state.user_details.images} alt="" class="profilePageDp" />
                        </div>
                        <div class={this.state.tmp_image}>
                        <img src={this.addDefaultSrc} alt="" class="profilePageDp" />
                        </div>
                       
                        <div className={this.state.change_loc}>
                            <Trans i18nKey="No people in your location">No people in your location</Trans>
                        </div>
                        <div class={this.state.change_loc2}><button class="btn-common w200px"   onClick={this.showModal.bind(this, 'modal2')}><Trans i18nKey="Change Location">Change Location</Trans></button>
                        <div></div></div>
                    </div> : '' }


                    <div className="sidebarResp d-block d-lg-none">
                        <SideNav showNav={this.state.showNav3} onHideNav={() => this.setState({ showNav3: false })} title={<div></div>} items={[<SideBar />]} />
                    </div>


                   
                    {/* Location Popup   */}                
                    <Modal isOpen={this.state.modal1} toggle={this.closeModal.bind(this, 'modal1')} className="premiumPopup vertically-center apd5">
                        <ModalHeader  toggle={this.closeModal.bind(this, 'modal1')}>
                            <h4 class="modal-title"><Trans i18nKey="Change Location">Change Location</Trans></h4></ModalHeader>
                        <ModalBody>
                        <div className="modal-body edit-profile-page">
                                    <form className="" onSubmit={this.onSubmitlocationsearch}>
                                        <div className="user-input-wrp amb35">
                                            <br />
                                            <Autocomplete
												apiKey={API_KEY}
												onPlaceSelected={(place) => {
												this.setState({ place: place.formatted_address, lat: place.geometry.location.lat() , lon: place.geometry.location.lng() })
												}}
												types={['(regions)']}
												//componentRestrictions={{country: "ru"}}
											/> 
                                        </div>
                                        <div className="errorMsg">
                                            <Trans i18nKey={this.state.errors.locationsearch}></Trans>
                                        </div>
                                        <div className="premium">
                                            <button type="Submit" className="btn btn-bg width100 justify-content-center amg0"> 
                                                <Trans i18nKey="Joinwith">Join with Blackroseinternational</Trans>  
                                            </button>
                                        </div>
                                    </form>
                            </div>
                        </ModalBody>
                    </Modal>
               

                   {/* Premium Popup  */}
                <Modal isOpen={this.state.modal2} toggle={this.closeModal.bind(this, 'modal2')} className="premiumPopup vertically-center apd5">
                    <ModalHeader toggle={this.closeModal.bind(this, 'modal2')}>
                        <h4 class="modal-title"><Trans i18nKey="Join Premium">Join Premium</Trans></h4> 
                    </ModalHeader>
                    <ModalBody>
                        <div className="premiumPop">
                            <div className="premiumImgs">
                                <Swiper {...params} shouldSwiperUpdate>  
                                 {this.state.permium_superlikeCount > 0 ?
                                  <div>
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sub_superlike.png")} alt="" className="img-fluid" />
                                      </div> 
                                      <div className="premiumInfoHolder">
                                          <div className="bold-font">
                                              <Trans i18nKey="Get">Get</Trans> {this.state.permium_superlikeCount} <Trans i18nKey="free super likes">free super likes</Trans>  
                                          </div>
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextSuper">Giving a Super like is a way to tell someone that you're especially interested in them</Trans>
                                          </div>
                                      </div>
                                  </div> : ''
                                 }

                                 {this.state.permium_boostCount > 0 ?
                                  <div>
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sub_boost.png")} alt="" className="img-fluid" />
                                      </div>
                                      <div className="premiumInfoHolder">  
                                          <div className="bold-font">
                                              <Trans i18nKey="Get">Get</Trans> {this.state.permium_boostCount} <Trans i18nKey="free boost">free boost</Trans>     
                                          </div>
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextBoost">Be the top profile in your area for 30minutes to get more matches, Keep Swipe right!</Trans>
                                          </div>
                                      </div>
                                  </div> : ''
                                 }
                  
                                  <div>
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sub_location.png")} alt="" className="img-fluid" />
                                      </div>
                                      <div className="premiumInfoHolder"> 
                                          <div className="bold-font">
                                              <Trans i18nKey="Change Location">Change Location</Trans>
                                          </div>
                                          <div className="premiumInfoSubText  secondaryText esmh-14 "> 
                                              <Trans i18nKey="premiumTextLocation">Get match and chat with others around the world by simply searching for a city</Trans>
                                          </div>
                                      </div>
                                  </div>

                                  <div> 
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sub_morelike.png")} alt="" className="img-fluid" />
                                      </div>
                                      <div className="premiumInfoHolder">
                                          <div className="bold-font">
                                              <Trans i18nKey="Unlimited Likes">Unlimited Likes</Trans>  
                                          </div>
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextUnlimited">Feel special again with 'Unlimited Likes'. Swipe right as much as you want!</Trans> 
                                          </div>
                                      </div>
                                  </div>


                                  <div>
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sud_add.png")} alt="" className="img-fluid" />
                                      </div>
                                      <div className="premiumInfoHolder"> 
                                          <div className="bold-font">
                                              <Trans i18nKey="Rewind">Rewind</Trans>
                                          </div>
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextRewind">Accidentally swiped on someone? Rewind lets you take back your last Like or Nope</Trans>
                                          </div>
                                      </div>
                                  </div>  

                                  <div> 
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sub_morelike.png")} alt="" className="img-fluid" />
                                      </div>
                                      <div className="premiumInfoHolder">
                                          <div className="bold-font">
                                              <Trans i18nKey="See who Likes you">See who Likes you</Trans>   
                                          </div> 
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextLikes">Likes You feature lets you see who likes you before you decide whether to Like or Nope</Trans> 
                                          </div>
                                      </div>
                                  </div>

                                  <div>
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sub_profile.png")} alt="" className="img-fluid" />
                                      </div>
                                      <div className="premiumInfoHolder">
                                          <div className="bold-font"> 
                                              <Trans i18nKey="Control your profile">Control your profile</Trans>   
                                          </div>  
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextProfile">Limit what others see and make parts of your profile information invisible to other people</Trans>
                                          </div>  
                                      </div>
                                  </div> 

                                  <div>
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sub_visited.png")} alt="" className="img-fluid" />
                                      </div>
                                      <div className="premiumInfoHolder">
                                          <div className="bold-font">
                                              <Trans i18nKey="See who visited your profile">See who visited your profile</Trans> 
                                          </div>
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextVisited">A Visitors feature lets you see who looked at your profile or ‘visited’ your profile
</Trans>
                                          </div>
                                      </div>
                                  </div>



                                  <div>
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sud_add.png")} alt="" className="img-fluid" />
                                      </div> 
                                      <div className="premiumInfoHolder">
                                          <div className="bold-font">
                                              <Trans i18nKey="Turn Off Ads">Turn Off Ads</Trans> 
                                          </div>
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextAds">Have fun swiping with uninterrupted ads</Trans>
                                          </div>  
                                      </div>
                                  </div> 

                              </Swiper> 
                            </div>  
                            <div className="premiumPopPlans">
                            <Swiper {...params2}>
                            {this.state.itemss.map((item, index) =>
                                <div className={this.state.activeItems === item.id ? 'navigation--active' : ''} onClick={this.handleItemClick.bind(this, item.id)} alt={item.id} value={item.id}>
                                <div className="premiumPrice">
                                    <div className="apd10 daysPrice">
                                    <div className="bold-font apk-30">{item.days}</div>
                                    <div className=" bold-font apk-24 mt-n2"><Trans i18nKey={item.type}>{item.type}</Trans></div>
                                    </div>
                                    <div className="apd5 apk-16 mini-price">{this.state.currency}{item.price}</div>
                                </div>
                                </div>)}

                            </Swiper>
                            </div>


                            <div className="sidebarLastContent text-center">
                            <NavLink to="/app/Payment"> 
                                <button className="btn-common btn-block">
                                    <Trans i18nKey="Continue">Continue</Trans>
                                </button>
                            </NavLink>
                            </div>


                        </div>
                    </ModalBody>
                </Modal>


              




                </div>
                
                { (this.state.user_details.subscribed == "true" &&  this.state.user_details.subscribed_status != "expired") ?
                <div className={this.state.peopleShowHolder}>
                    <div className="row">
                        {this.state.peopleDetails.map((peopleDetails) =>
                            <div className="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-3 col-Resp">
                                <div className="peopleSpaceHolder">
                                    <div className="peopleViewHolder">
                                        <div className="peoplePic">
                                        <NavLink to={`/app/PeopleNear/PeopleExplore/${peopleDetails.user_id}`}>
                                                <img src={peopleDetails.images} alt="" className="" />
                                            </NavLink>
                                            <div className={peopleDetails.online === "1"? "onlineN":"none"}></div>
                                        </div>
                                        <div className="apd10 cardMin">
                                            <div className="media">
                                                <div className="media-body pFHidden align-self-center">
                                                <NavLink to={`/app/PeopleNear/PeopleExplore/${peopleDetails.user_id}`}>  <div className="text-truncate bold-font BtoRcolor apk-16">{peopleDetails.user_name}</div>  </NavLink>
                                                <NavLink to={`/app/PeopleNear/PeopleExplore/${peopleDetails.user_id}`}> <div className="text-truncate secondaryText esmh-14 regular-font">{peopleDetails.location}</div>  </NavLink>
                                                </div>
                                                <div className="ml-1">
                                                    {peopleDetails.age}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div> :'' } 

                
                {/* Premium Modal*/}

                <Modal isOpen={this.state.modal} toggle={this.toggle} className="premiumPopup vertically-center apd5">
                    <ModalHeader toggle={this.toggle}>
                        <h4 class="modal-title">
                            <Trans i18nKey="Join Premium">Join Premium</Trans>
                        </h4>
                    </ModalHeader> 
                    <ModalBody>
                        <div className="premiumPop">
                            <div className="premiumImgs">
                                <Swiper {...params} shouldSwiperUpdate>  
                                 {this.state.permium_superlikeCount > 0 ?
                                  <div>
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sub_superlike.png")} alt="" className="img-fluid" />
                                      </div> 
                                      <div className="premiumInfoHolder">
                                          <div className="bold-font">
                                              <Trans i18nKey="Get">Get</Trans> {this.state.permium_superlikeCount} <Trans i18nKey="free super likes">free super likes</Trans>  
                                          </div>
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextSuper">Giving a Super like is a way to tell someone that you're especially interested in them</Trans>
                                          </div>
                                      </div>
                                  </div> : ''
                                 }

                                 {this.state.permium_boostCount > 0 ?
                                  <div>
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sub_boost.png")} alt="" className="img-fluid" />
                                      </div>
                                      <div className="premiumInfoHolder">  
                                          <div className="bold-font">
                                              <Trans i18nKey="Get">Get</Trans> {this.state.permium_boostCount} <Trans i18nKey="free boost">free boost</Trans>     
                                          </div>
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextBoost">Be the top profile in your area for 30minutes to get more matches, Keep Swipe right!</Trans>
                                          </div>
                                      </div>
                                  </div> : ''
                                 }
                  
                                  <div>
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sub_location.png")} alt="" className="img-fluid" />
                                      </div>
                                      <div className="premiumInfoHolder"> 
                                          <div className="bold-font">
                                              <Trans i18nKey="Change Location">Change Location</Trans>
                                          </div>
                                          <div className="premiumInfoSubText  secondaryText esmh-14 "> 
                                              <Trans i18nKey="premiumTextLocation">Get match and chat with others around the world by simply searching for a city</Trans>
                                          </div>
                                      </div>
                                  </div>

                                  <div> 
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sub_morelike.png")} alt="" className="img-fluid" />
                                      </div>
                                      <div className="premiumInfoHolder">
                                          <div className="bold-font">
                                              <Trans i18nKey="Unlimited Likes">Unlimited Likes</Trans>  
                                          </div>
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextUnlimited">Feel special again with 'Unlimited Likes'. Swipe right as much as you want!</Trans> 
                                          </div>
                                      </div>
                                  </div>


                                  <div>
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sud_add.png")} alt="" className="img-fluid" />
                                      </div>
                                      <div className="premiumInfoHolder"> 
                                          <div className="bold-font">
                                              <Trans i18nKey="Rewind">Rewind</Trans>
                                          </div>
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextRewind">Accidentally swiped on someone? Rewind lets you take back your last Like or Nope</Trans>
                                          </div>
                                      </div>
                                  </div>  

                                  <div> 
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sub_morelike.png")} alt="" className="img-fluid" />
                                      </div>
                                      <div className="premiumInfoHolder">
                                          <div className="bold-font">
                                              <Trans i18nKey="See who Likes you">See who Likes you</Trans>   
                                          </div> 
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextLikes">Likes You feature lets you see who likes you before you decide whether to Like or Nope</Trans> 
                                          </div>
                                      </div>
                                  </div>

                                  <div>
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sub_profile.png")} alt="" className="img-fluid" />
                                      </div>
                                      <div className="premiumInfoHolder">
                                          <div className="bold-font"> 
                                              <Trans i18nKey="Control your profile">Control your profile</Trans>   
                                          </div>  
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextProfile">Limit what others see and make parts of your profile information invisible to other people</Trans>
                                          </div>  
                                      </div>
                                  </div> 

                                  <div>
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sub_visited.png")} alt="" className="img-fluid" />
                                      </div>
                                      <div className="premiumInfoHolder">
                                          <div className="bold-font">
                                              <Trans i18nKey="See who visited your profile">See who visited your profile</Trans> 
                                          </div>
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextVisited">A Visitors feature lets you see who looked at your profile or ‘visited’ your profile
</Trans>
                                          </div>
                                      </div>
                                  </div>



                                  <div>
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sud_add.png")} alt="" className="img-fluid" />
                                      </div> 
                                      <div className="premiumInfoHolder">
                                          <div className="bold-font">
                                              <Trans i18nKey="Turn Off Ads">Turn Off Ads</Trans> 
                                          </div>
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextAds">Have fun swiping with uninterrupted ads</Trans>
                                          </div>  
                                      </div>
                                  </div> 

                              </Swiper> 
                            </div>      
                            <div className="premiumPopPlans">
                                <Swiper {...params2}>
                                    {this.state.items.map((item, index) =>
                                        <div className={this.state.activeItem === index ? 'navigation--active' : ''} onClick={this.handleItemClick.bind(this, index)}>
                                            <div className="premiumPrice">
                                                <div className="apd10 daysPrice">
                                                    <div className="bold-font apk-30">{item.number}</div>
                                                    <div className=" bold-font apk-24 mt-n2">{item.days}</div>
                                                </div>
                                                <div className="apd5 apk-16 mini-price">{item.amount}</div>
                                            </div>
                                        </div>)}

                                </Swiper>
                            </div>


                            <div className="sidebarLastContent text-center">
                                <button className="btn-common btn-block"><Trans i18nKey="Continue">Continue</Trans></button>
                            </div>


                        </div>
                    </ModalBody>
                </Modal>
                
                <Modal isOpen={this.state.openModal} toggle={this.closeModal.bind(this, 'openModal')} className="premiumPopup vertically-center apd5">
          <ModalHeader toggle={this.closeModal.bind(this, 'openModal')}>            
          </ModalHeader>  
          <ModalBody>    
          <div>                         
            <div className="premiumInfoHolder reducemargin">
                <div className="bold-font">
                <h4 >
                <Trans i18nKey=">Activate your account">Activate your account</Trans> 
                </h4>
                </div>
                <div className="premiumInfoSubText  secondaryText esmh-14 ">
                    <Trans i18nKey="subscribeTextSuper">Get unlimited access nearby friends, likes, superlike, chat, etc</Trans>
                </div>
            </div>
            
        </div>     
        <div className="premiumPopPlans subscribeprice">
            <div class="swiper-slide navigation--active swiper-slide-active" style={{width:"152px"}}>
        <div class="premiumPrice" ><div class="apd10 daysPrice"><div class="bold-font apk-30"  style={{color:"black"}}>{this.state.noofdays}</div><div class=" bold-font apk-24 mt-n2" style={{color:"black"}}><Trans i18nKey="Days">Days</Trans></div></div><div class="apd5 apk-16 mini-price">{this.state.currency}{this.state.price}</div></div>
        </div>
        </div>
        <div className="sidebarLastContent text-center">
              <NavLink to="/app/SubscribePayment"> <button className="btn-common btn-block" onClick={this.closeModal.bind(this, 'modal4')}><Trans i18nKey="Subscribe">Subscribe</Trans></button></NavLink>  
        </div>
          </ModalBody>
        </Modal>

                <div className="sidebarResp d-block d-lg-none">
                    <SideNav showNav={this.state.showNav3} onHideNav={() => this.setState({ showNav3: false })} title={<div></div>} items={[<SideBar />]} />
                </div>

                



                <Admob></Admob> 
                <div class="footerSection">
                    {/* <Footer /> */}
                </div>
                
            </div>

        );
    }
}

export default withTranslation("translations")(FindNewPeople);  
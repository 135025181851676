import React, { Component } from 'react';
import { Trans, withTranslation } from "react-i18next";
import "../../config/i18n";
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import ls from "local-storage"; 
import { options } from "../../config/options";  
import { Modal, ModalHeader, ModalBody } from 'reactstrap';


class EditProfile extends Component {    
    constructor(props) {
        super(props);
        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeabout = this.onChangeabout.bind(this);
        this.onChangejob = this.onChangejob.bind(this);
        this.onChangecompany = this.onChangecompany.bind(this);
        this.onChangeschool = this.onChangeschool.bind(this); 
        this.setGender = this.setGender.bind(this);
		this.onSubmit = this.onSubmit.bind(this); 
        this.state = {
          user_id:'',
          user_details:'',
          name:'',
          gender:'',api_url:localStorage.getItem('api_url'),
          bio:'',suc_msg:'',
          job:'',company:'',school:'',
          lang: ls.get('LangKeySet') || options[0],
          langKey: ls.get('LangValue'),  
          errors: {},
          subscribed : "false"
          //subscribed:props.location.userProps["subscribed"]
        };        

        this.closeModal = this.closeModal.bind(this);
    
    
      }
      closeModal(tabId) {
        this.setState({
            [tabId]: false
        });        
    }
    showModal(modal) {
        this.setState({
            [modal]: true
        });
        // console.log(this.state);
    }
    componentDidMount() {

        axios({
            method: 'get',
            url: this.state.api_url+'subscriptionDetails',                
            
          }).then(res => {
            const data = res.data;
            console.log(data);
            this.setState({                         
                    currency: data.currency,
                    price: data.price,
                    noofdays: data.noofdays,
                  });
                  localStorage.setItem('subscription_price',JSON.stringify(this.state.price));
                  localStorage.setItem('currency',JSON.stringify(this.state.currency));
                  localStorage.setItem('noofdays',JSON.stringify(this.state.noofdays));
             
          });

        let data = new FormData(); 
        data.append('user_id', JSON.parse(localStorage.getItem('session_userid')));  
        data.append('language', this.state.langKey); 
          
        axios({
            method: 'post',
            url: this.state.api_url+'webprofile',
            data: data, 
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        }).then(res => { 
            if(res.data.status !== false) {
                const user_details = res.data.result;
              
                this.setState({
                    user_details: user_details,
                    name: user_details.user_name,
                    gender: user_details.gender,
                    bio: user_details.bio,
                    job: user_details.job,
                    company: user_details.company,
                    school: user_details.school,
                });
            } else { 
                this.props.history.push("/login"); 
            }  
        }).catch(error => {
            this.props.history.push("/app/NotFound");
        });
    } 
    
    restrict = (event) => {
		const regex = new RegExp("^[a-zA-Z ]+$");
		const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
		if (!regex.test(key)) {
		event.preventDefault(); return false;
		}
	}

    onChangeName(e){
    this.setState({
        name:e.target.value,
    });
    localStorage.setItem('current_username',JSON.stringify(e.target.value));
    }
    setGender(e) {
    this.setState({
        gender:e.target.value
    });
    }
    onChangeabout(e){
        this.setState({
            bio:e.target.value,
        });
    }
    onChangejob(e){
        this.setState({
            job:e.target.value,
        });
    }
    onChangecompany(e){
        this.setState({
            company:e.target.value,
        });
    }
    onChangeschool(e){
        this.setState({
            school:e.target.value, 
        });
    }
    onSubmit(e){
        e.preventDefault();
        let errors = {};
        var editprofile = new FormData();
        editprofile.set('user_name', this.state.name);
        editprofile.set('gender', this.state.gender);
        editprofile.set('bio', this.state.bio);
        editprofile.set('job', this.state.job);
        editprofile.set('company', this.state.company);
        editprofile.set('school', this.state.school);
        editprofile.set('user_id', JSON.parse(localStorage.getItem('session_userid')));
        editprofile.set('language', this.state.langKey);    
            axios({
                method: 'post',
                url: this.state.api_url+'websetprofile',
                data: editprofile,
                config: { headers: {'Content-Type': 'multipart/form-data' }}
                            })
        .then(res => {
            const message = res.data.message;
            errors["editprofile"] = message;
            this.setState({
                errors: errors,
                suc_msg: 'successMsg floatingAlerts1',
                });
                setTimeout(() => {
                    this.setState({
                        suc_msg: 'successMsghide'
                    });
                    window.location.reload();
                  }, 2000);
                      
            })
            
        } 
    render() {
        const { t } = this.props; 

        return (
            <div className="edit-profile-page">

                <div className="d-flex profile-header-top stickyProfileHead justify-content-between apd15 apr0 apl0 white-bg amb10 ">
                    <div class="heading-left-top d-flex">
                        <div className="align-self-center ">
                            <NavLink to="/app/Profile">
                                <div className="iconHolder align-self-center mr-1">
                                    <div className="backGlow">
                                        <div className="backIco"></div>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <span class="arrow-center align-self-center bold-font">
                            <Trans i18nKey="Edit profile">Edit profile</Trans> 
                        </span>        
                    </div>
                    
                </div>


                <div className="">
                    <div className="d-flex justify-content-center">
                        <div className="edit-profile-width white-bg amt20 amb20">
                            <form className="apd20" onSubmit={this.onSubmit}>
                                <div className="user-input-wrp amb25">
                                    <br />
                                    <input type="text" id="name" value={this.state.name} onChange={this.onChangeName} maxLength={20} minLength={3} onKeyPress={(e) => this.restrict(e)} className="inputText" placeholder=" " required />
                                    <span className="floating-label"><Trans i18nKey="Name">Name</Trans></span>
                                </div>

                                <div className="user-input-wrp amb25">
                                    <br />
                                    <input readOnly id="email" value={this.state.user_details.email} className="inputText" required />
                                    <span className="floating-label"><Trans i18nKey="Email">Email</Trans></span>
                                </div>
                                <div className="user-input-wrp amb25">
                                    <br />
                                    <input readOnly id="dob" type="age" value={this.state.user_details.age} className="inputText" required />
                                    <span className="floating-label"><Trans i18nKey="Age">Age</Trans></span>
                                </div>

                                <div className="form-group">
                                    <label className="amb25"><Trans i18nKey="Age">Gender</Trans></label>  
                                    <div className="row">
                                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6">
                                            <label className="radio-btn"><Trans i18nKey="Female">Female</Trans>
                                             <input type="radio" checked={this.state.gender === "female"} onClick={this.setGender} value="female" name="radio" />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6">
                                            <label className="radio-btn"><Trans i18nKey="Male">Male</Trans> 
                                         <input type="radio" checked={this.state.gender === "male"} onClick={this.setGender} value="male" name="radio" />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>  
                                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6">
                                            <label className="radio-btn"><Trans i18nKey="Other">Other</Trans> 
                                         <input type="radio" checked={this.state.gender === "other"} onClick={this.setGender} value="other" name="radio" />
                                                <span className="checkmark"></span>    
                                            </label>
                                        </div>
                                    </div>
                                </div>


                                <div className="form-group">
                                    <label><Trans i18nKey="About You">About You</Trans></label>
                                    <textarea type="text" placeholder={t("Bio")} value={this.state.bio} onChange={this.onChangeabout} maxLength={60} className="form-control border-bottom-only apl0" rows="1" />
                                </div>

                                <div className="form-group"> 
                                    <label><Trans i18nKey="Job">Job</Trans></label>
                                    <textarea type="text" placeholder={t("Job")} value={this.state.job} onChange={this.onChangejob} maxLength={60} className="form-control border-bottom-only apl0" rows="1" />
                                </div>

                                <div className="form-group">
                                    <label><Trans i18nKey="Company">Company</Trans></label>
                                    <textarea type="text" placeholder={t("Company")} value={this.state.company} onChange={this.onChangecompany} maxLength={60} className="form-control border-bottom-only apl0" rows="1" />
                                </div>

                                <div className="form-group">
                                    <label><Trans i18nKey="School">School</Trans></label> 
                                    <textarea type="text" placeholder={t("School")} value={this.state.school} onChange={this.onChangeschool} maxLength={60} className="form-control border-bottom-only apl0" rows="1" />
                                </div>        

                                <div className={this.state.suc_msg}>{this.state.errors.editprofile}</div>
                                <div className="form-group">
                                    <div className="premium amt15 amb15">
                                    {(this.state.user_details.subscribed == "false" || this.state.user_details.subscribed_status == "expired") ?  <button type="button" onClick={this.showModal.bind(this, 'openModal')} className="btn btn-bg width100"> <Trans i18nKey="Save">Save</Trans> </button>
                                    :  <button type="submit" className="btn btn-bg width100"> <Trans i18nKey="Save">Save</Trans> </button> }
                                    </div>  
                                       
                                </div>
                            </form>
                        </div>


                    </div>
                </div>

                <Modal isOpen={this.state.openModal} toggle={this.closeModal.bind(this, 'openModal')}  className="premiumPopup vertically-center apd5">
          <ModalHeader toggle={this.closeModal.bind(this, 'openModal')} >            
          </ModalHeader>  
          <ModalBody>    
          <div>                         
            <div className="premiumInfoHolder reducemargin">
                <div className="bold-font">
                <h4 >
                <Trans i18nKey=">Activate your account">Activate your account</Trans> 
                </h4>
                </div>
                <div className="premiumInfoSubText  secondaryText esmh-14 ">
                    <Trans i18nKey="subscribeTextSuper">Get unlimited access nearby friends, likes, superlike, chat, etc</Trans>
                </div>
            </div>
            
        </div>     
        <div className="premiumPopPlans subscribeprice">
            <div class="swiper-slide navigation--active swiper-slide-active" style={{width:"152px"}}>
        <div class="premiumPrice" ><div class="apd10 daysPrice"><div class="bold-font apk-30"  style={{color:"black"}}>{this.state.noofdays}</div><div class=" bold-font apk-24 mt-n2" style={{color:"black"}}><Trans i18nKey="Days">Days</Trans></div></div><div class="apd5 apk-16 mini-price">{this.state.currency}{this.state.price}</div></div>
        </div>
        </div>
        <div className="sidebarLastContent text-center">
              <NavLink to="/app/SubscribePayment"> <button className="btn-common btn-block" onClick={this.closeModal.bind(this, 'modal4')}><Trans i18nKey="Subscribe">Subscribe</Trans></button></NavLink>  
        </div>
          </ModalBody>
        </Modal>

            </div>
        );
    }
}

export default withTranslation("translations")(EditProfile); 
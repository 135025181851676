import React, { Component } from 'react';
import { Trans } from "react-i18next";
import { NavLink } from 'react-router-dom';
import InputRange from 'react-input-range-rtl';  
//import { GoogleComponent } from 'react-google-location';
import Autocomplete from 'react-google-autocomplete';
import axios from 'axios';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Swiper from 'react-id-swiper';
import $ from 'jquery'; 
import ls from "local-storage"; 
import { options } from "../../config/options";

import "../../config/i18n";
import { withTranslation } from "react-i18next"; 

const API_KEY = "AIzaSyDVVob6U7eho9MpD99_7UCFmEzoH6N3Rrw";

var peopleArray = []; 

class Filter extends Component {
    constructor(props) {
        super(props);
        this.filterchange = this.filterchange.bind(this);
        // this.interestedmen = this.interestedmen.bind(this);
        // this.interestedwomen = this.interestedwomen.bind(this);
        this.onSubmitlocationsearch = this.onSubmitlocationsearch.bind(this); 

        this.state = {
            user_details:'',
            locationerr: '',
            place: null,lat: null,lon: null,
            items: [],errors:  {},
            peopleforid: '',
            gender: '',
            value3: '',
            // age_diff: '',
            men:'',women:'',
            loc:'',
            value4: {
                min: '',
                max: '',
            },
            max_age:'',api_url:localStorage.getItem('api_url'),
            itemss: [],activeItems: '',
            user_id:'',
            selected_premium:'',
            permium_superlikeCount: 0, 
            permium_boostCount: 0, 
            lang: ls.get('LangKeySet') || options[0],
            langKey: ls.get('LangValue'), 
            filterDirection: '',   
            currency:'',payment:'',license_key:'',premium_price:'',
            items2: [
                { "name": "men", "image": require('../../assets/img/user.png'), },
                { "name": "women", "image": require('../../assets/img/girl.png'), },
                { "name": "everyone", "image": require('../../assets/img/everyone.png'), },
            ],

        }

    }

    addDefaultSrc(ev){
        ev.target.src =  require('../../assets/img/howzu-logo-default.png')
    }

    componentDidMount(){
        if(this.state.langKey === "ar") {
            this.setState({ 
                filterDirection: "rtl"
            }); 
        } else {
            this.setState({ 
                filterDirection: "" 
            }); 
        }

        let data = new FormData();
        data.append('user_id', JSON.parse(localStorage.getItem('session_userid')));  
        data.append('language', this.state.langKey); 
          
        axios({
            method: 'post',
            url: this.state.api_url+'webprofile',
            data: data, 
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        }).then(res => {
            if(res.data.status !== false) {
                const user_details = res.data.result;
                const filter_age = user_details.filter_age;
                const age_fil = filter_age.split("-");
                if(user_details.filter_gender === "everyone") {
                    this.setState({ 
                        activeGenderItem: "everyone"
                    });
                } else if(user_details.filter_gender === "men") {
                    this.setState({
                        activeGenderItem: "men"
                    });
                } else {
                    this.setState({ 
                        activeGenderItem: "women"  
                    });
                } 
                
                peopleArray = [];
                const temp =  user_details.filter_peoplefor;  
                for (var i = 0; i < temp.length; i++) {
                      peopleArray.push(parseInt(temp[i]));   
                }  

                this.setState({
                    user_details: user_details,
                    loc: user_details.location,
                    activeItem: user_details.filter_peoplefor,
                    peopleforid: user_details.filter_peoplefor,
                    value3: user_details.filter_distance,
                    gender: user_details.filter_gender,
                    value4: { min: age_fil[0], max: age_fil[1] },
                });
            } else {   
                this.props.history.push("/login");  
            }
        }).catch(error => {
           this.props.history.push("/login"); 
        });  

        axios.get(this.state.api_url+`admindatas`).then(res => {
            const items = res.data.result.peoplefor;
            const max_age = res.data.result.max_age;
            const max_distance = res.data.result.max_distance;
            this.setState({ items,max_age,max_distance });
        })

        var premiumlist = new FormData();
        premiumlist.set('platform', 'web');
        premiumlist.set('language', this.state.langKey);  
        axios({
            method: 'post',
            url: this.state.api_url+'premiumlistweb',
            data: premiumlist,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        }).then(res => {
            const data = res.data;
            // console.log("premium list",res.data); 
            this.setState({
                itemss: data.result,
                activeItems: data.result[0].id,
                selected_premium: data.result[0].id,
                premium_price: data.result[0].price,
                permium_superlikeCount: data.result[0].superlikecount, 
                permium_boostCount: data.result[0].boostcount,   
                currency: data.currency,
                payment: data.payment,
                license_key: data.license_key,
            });
            localStorage.setItem('selected_premium',JSON.stringify(this.state.selected_premium));
            localStorage.setItem('premium_price',JSON.stringify(this.state.premium_price));
            localStorage.setItem('currency',JSON.stringify(this.state.currency));
        });
    }


    handleInterestClick(id, e) {  
        if(id) { 
            if($(e.target).hasClass('navigation--active')) {
                $(e.target).removeClass('navigation--active');
            } else {
                $(e.target).addClass('navigation--active');  
            } 

            var cur_val = parseInt(id); 
            if(peopleArray === null)
            {
                    peopleArray = []; 
                    peopleArray.push(cur_val);
            } else { 
                let index = peopleArray.indexOf(cur_val);
                if (index > -1) {
                    peopleArray.splice(index, 1);               
                } else {
                    peopleArray.push(cur_val);
                } 
            }
            console.log("MY ARR "+peopleArray);        
        }   
    }

    interestedIn(interested,e) {
        this.setState({
            activeItem2: interested,
            gender: interested,
        })
    }

    handleItemClick(index) {
        this.setState({
            activeItems: index,
            selected_premium: index,
        })

        var premiumlist = new FormData();
        premiumlist.set('premiumid', index);
        premiumlist.set('language', this.state.langKey);  
        axios({
            method: 'post',
            url: this.state.api_url+'premiumid',
            data: premiumlist,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        }).then(res => {
            const data = res.data;
            this.setState({
                premium_price: data.result.price,
                permium_superlikeCount: data.result.superlikecount, 
                permium_boostCount: data.result.boostcount, 
            });
            localStorage.setItem('selected_premium',JSON.stringify(index));
            localStorage.setItem('premium_price',JSON.stringify(data.result.price));
        });
    }

    onChangepack(e) { 
        this.setState({
          selected_premium: e.target.value,
        })
    }

    closeModal(tabId) {
        this.setState({
            [tabId]: false
        });
    }

    showModal(modal) {
        if(this.state.user_details.premium_member === "true"){
            this.setState({
                modal1: true
            });
        }else{
            this.setState({
                modal2: true
            });
        }
    }

    filterchange() {
        let errors = {};
        if(typeof(this.state.lat) === 'undefined' || typeof(this.state.lon) === 'undefined' || typeof(this.state.place) === 'undefined' || this.state.lat === 'undefined' || this.state.lat === "" || this.state.lon === "" || this.state.lon === 'undefined' || this.state.place=== "" || this.state.place === 'undefined') {
            errors["locationsearch"] = "Select location properly or change location";   
        } else { 
            var filter = new FormData(); 
            if(this.state.place) { 
                var address=this.state.place;
                var value=address.split(","); 
                var count=value.length; 
                var country=value[count-1]; 
                var state=value[count-2]; 
                var city=value[count-3]; 

                 if(city != null || typeof(city) !== 'undefined') {
                        filter.append('place', city+", "+state+", "+country);
                        this.setState({
                            loc: city+", "+state+", "+country
                        });
                } else {
                    filter.append('place', state+", "+country);
                    this.setState({
                        loc: +state+", "+country
                    });
                }
            }  

            filter.append('lat', this.state.lat);
            filter.append('lon', this.state.lon);
            filter.append('user_id', JSON.parse(localStorage.getItem('session_userid')));

            filter.append('age', ''+this.state.value4.min+'-'+this.state.value4.max);
            filter.append('distance', this.state.value3);

            filter.append('gender', this.state.activeGenderItem);  
            filter.append('people_for', peopleArray);   
            filter.append('language', this.state.langKey);  

            axios.defaults.headers.common['Authorization'] = JSON.parse(localStorage.getItem('session_token'));
           
            axios({
                method: 'post',
                url: this.state.api_url+'websetfilter',
                data: filter,
                config: { headers: {'Content-Type': 'multipart/form-data' }}
                     })
            .then(res => {
                const message = res.data.message;
                
                if(localStorage.getItem('filter_page') === '1') {
                    this.props.history.push("/app/PeopleNear")
                } else {
                    this.props.history.push("/app/FindNewPeople")
                }
                
                errors["message"] = message;
            }) 

            this.setState({
                errors: errors
            });  
        }
   
    }

    onSubmitlocationsearch(e){
        e.preventDefault();
        let errors = {};
        if(typeof(this.state.lat) === 'undefined' || typeof(this.state.lon) === 'undefined' || typeof(this.state.place) === 'undefined' || this.state.lat === 'undefined' || this.state.lat === "" || this.state.lat === null || this.state.lon === null || this.state.lon === "" || this.state.lon === 'undefined' || this.state.place=== "" || this.state.place=== null || this.state.place === 'undefined') {       
             this.setState({
                locationerr: "Select location properly or change location"
            });   
        } else { 
            var location = new FormData();

            if(this.state.place) { 
                var address=this.state.place;
                var value=address.split(","); 
                var count=value.length; 
                var country=value[count-1]; 
                var state=value[count-2]; 
                var city=value[count-3]; 
            
                if(city != null || typeof(city) !== 'undefined') {
                    location.append('place', city+", "+state+", "+country);
                    this.setState({
                        loc: city+", "+state+", "+country,
                        modal1: false 
                    });
                } else {
                    location.append('place', state+", "+country);
                    this.setState({
                        loc: +state+", "+country,
                        modal1: false 
                    });
                }
            }

            location.append('place', this.state.place);
            location.append('lat', this.state.lat);
            location.append('lon', this.state.lon);
            location.append('user_id', JSON.parse(localStorage.getItem('session_userid')));
            location.append('language', this.state.langKey);    

        
            axios.defaults.headers.common['Authorization'] = JSON.parse(localStorage.getItem('session_token'));
        
            axios({
                method: 'post',
                url: this.state.api_url+'websetprofile',
                data: location,
                config: { headers: {'Content-Type': 'multipart/form-data' }}
            }).then(res => {
                const status = res.data.status;
            
                if(status === "true"){
                      this.props.history.push("/app/PeopleNear");
                } else {
                      this.props.history.push("/app/PeopleNear");
                }
            })
        }

        this.setState({
			errors: errors
		});		 
    } 

    interestedGender(genderItem) { 
        this.setState({
            activeGenderItem: genderItem,
        })
    } 

    render() {
        const { t } = this.props; 

        const params = {
          pagination: { 
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
          },
          spaceBetween: 1,
          rebuildOnUpdate: true   
        }

        const params2 = {
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev'
            },    
            slidesPerView: 3,
            slidesPerColumn: 1,
            breakpoints: {
                360: {
                    slidesPerView: 3,
                },
                359: {
                    slidesPerView: 2,
                }
            }
        };
        return (

            <div className="setting-page">

                <div className="d-flex profile-header-top stickyProfileHead justify-content-between apd15 apr0 apl0 white-bg amb10 ">
                    <div class="heading-left-top d-flex">
                        <div className="align-self-center ">
                            <NavLink to="/app/PeopleNear">
                                <div className="iconHolder align-self-center mr-1">
                                    <div className="backGlow">
                                        <div className="backIco"></div>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <span class="arrow-center align-self-center bold-font">
                            <Trans i18nKey="Filter"><span className="floating-label">Filter</span> </Trans>
                        </span>
                    </div>
                    <div className="iconHolder align-self-center">
                        <div className="backGlow mr-2">
                            <button type="button" className="primary-color bold-font sma-16 setting-save bgT border-none apd0 amg0 "  onClick={this.filterchange}>
                                <Trans i18nKey="Save"><span className="floating-label">Save</span> </Trans>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="settingsPageWidth">
                    <div className="setFiltBox"> 

                        <div className="ProfileSpace apb15">
                            <div className="bold-font apd10 apl0 apt0"><Trans i18nKey="I'minterestedin"><span className="floating-label">I'm interested in</span> </Trans></div>

                            <div className="white-bg sma-16  settingInterestOverflow">
                                <div className="row justify-content-center justify-content-lg-start">  
                                    <div className="">
                                        <div className="text-center">
                                            <div className="iconimg apd15 apl20 apr20">
                                                <img onError={this.addDefaultSrc} src={this.state.items2[0].image} alt="logo" id="mensel" onClick={()=>{this.interestedGender(this.state.items2[0].name)}} className={this.state.activeGenderItem === this.state.items2[0].name ? 'navigation--active' : ''} />
                                                <h6 className="amb0 amt5"><Trans i18nKey={this.state.items2[0].name}>{this.state.items2[0].name}</Trans></h6>
                                            </div>
                                        </div> 
                                    </div>
                                    
                                    <div className="">
                                        <div className="text-center">
                                            <div className="iconimg apd15 apl20 apr20">
                                                <img onError={this.addDefaultSrc} src={this.state.items2[1].image} alt="logo" id="womensel" onClick={()=>{this.interestedGender(this.state.items2[1].name)}} className={this.state.activeGenderItem === this.state.items2[1].name ? 'navigation--active' : ''} />
                                                <h6 className="amb0 amt5" > <Trans i18nKey={this.state.items2[1].name}>{this.state.items2[1].name}</Trans> </h6>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="">
                                        <div className="text-center">
                                            <div className="iconimg apd15 apl20 apr20">
                                                <img onError={this.addDefaultSrc} src={this.state.items2[2].image} alt="logo" id="everysel" onClick={()=>{this.interestedGender(this.state.items2[2].name)}} className={this.state.activeGenderItem === this.state.items2[2].name ? 'navigation--active' : ''} /> 
                                                <h6 className="amb0 amt5" > <Trans i18nKey={this.state.items2[2].name}>{this.state.items2[2].name}</Trans> </h6>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>

                        <div className="ProfileSpace apb15">

                            <div className="bold-font apd10 apl0 apt0">
                                <Trans i18nKey="Iamherefor"><span className="floating-label">I am here for</span> </Trans>
                            </div>

                            <div className="white-bg sma-16 d-flex  settingInterestOverflow">
                                <div className="row justify-content-center justify-content-lg-start">
                                    {this.state.items.map((item, index) =>
                                          
                                        (peopleArray !== null) ? (
                                            (peopleArray.indexOf(item.id) > -1) ?  
                                                <div className="">
                                                    <div className="text-center" key={index} >
                                                        <div className="iconimg apd10 apl20 apr20">
                                                            <img onError={this.addDefaultSrc} src={item.icon} alt="logo" className={(peopleArray.indexOf(item.id) > -1) ? 'navigation--active' : ''} onClick={this.handleInterestClick.bind(this, item.id)} />
                                                            <h6 className="amb0 amt5" value={item.id}>
                                                                <Trans i18nKey={item.name}>{item.name}</Trans>
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            :
                                                <div className="">
                                                    <div className="text-center" key={index} >
                                                        <div className="iconimg apd10 apl20 apr20">
                                                            <img onError={this.addDefaultSrc} src={item.icon} alt="logo" className='' onClick={this.handleInterestClick.bind(this, item.id)} />
                                                            <h6 className="amb0 amt5" value={item.id}>
                                                                <Trans i18nKey={item.name}>{item.name}</Trans>
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </div>
                                        ) : <div className="">
                                                <div className="text-center" key={index} >
                                                    <div className="iconimg apd10 apl20 apr20">
                                                        <img onError={this.addDefaultSrc} src={item.icon} alt="logo" className='' onClick={this.handleInterestClick.bind(this, item.id)} />
                                                        <h6 className="amb0 amt5" value={item.id}>
                                                            <Trans i18nKey={item.name}>{item.name}</Trans>
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>  
                                    )}
                                </div>
                            </div>

                        </div>

                        <div className="ProfileSpace apb15">
                        <div className="bold-font apd10 apl0 apt20">  
                            <Trans i18nKey="location"><span className="floating-label">Location</span> </Trans>
                        </div>
                        <input type="text" placeholder={t("Enter Search Location")} value={this.state.loc} className="width100 changeLoc" />
                            <button class="btn-common w200px mt-3"   onClick={this.showModal.bind(this, 'modal1')}>
                                <Trans i18nKey="Change Location"><span className="floating-label">Change Location</span> </Trans>
                            </button>
                            {/* <button class="btn-common w200px"   onClick={this.showModal.bind(this, 'modal2')}>(Premium POPup)</button> */}
                        </div>



                        <div className="ProfileSpace apb15">
                            <div className="bold-font apd10 apl0 apt20 d-flex justify-content-between">
                                <span><Trans i18nKey="Show age"><span className="floating-label">Show age</span> </Trans></span>
                                <p className="amb0"> {this.state.value4.min} - {this.state.value4.max}</p>    
                            </div>

                            <form>
                                <div className="searchvalue apd10 apb0">
                                    <InputRange
                                        maxValue={this.state.max_age}
                                        minValue={18}
                                        value={this.state.value4}
                                        onChange={value => this.setState({ value4: value })}
                                        direction={this.state.filterDirection} 
                                        onChangeComplete={value => console.log("val com",''+this.state.value4.min+'-'+this.state.value4.max)} />
                                </div>
                            </form>

                        </div>



                        <div className="ProfileSpace apb20">
                            <div className="bold-font apd10 apl0 apt20 d-flex justify-content-between">
                                <span><Trans i18nKey="Distance within"><span className="floating-label">Distance within</span> </Trans></span>
                                <p className="amb0"> {this.state.value3} <Trans i18nKey="Km."><span className="floating-label">Km.</span> </Trans></p> 
                            </div>
                            <form>
                                <div className="searchvalue apd10 apb0">

                                    <InputRange
                                        maxValue={this.state.max_distance}
                                        minValue={0}
                                        value={this.state.value3}
                                        onChange={value => this.setState({ value3: value })}
                                        direction={this.state.filterDirection}  
                                        onChangeComplete={value => console.log(value)} />
                                </div>
                            </form>
                        </div>

                    </div>


                </div>

                <Modal isOpen={this.state.modal1} toggle={this.closeModal.bind(this, 'modal1')} className="premiumPopup vertically-center apd5">
                        <ModalHeader  toggle={this.closeModal.bind(this, 'modal1')}><h4 class="modal-title"><Trans i18nKey="Change Location">Change Location</Trans></h4></ModalHeader>
                        <ModalBody>
                        <div className="modal-body edit-profile-page">
                                    <form className="" onSubmit={this.onSubmitlocationsearch}>
                                        <div className="user-input-wrp amb35">
                                            <br />
                                            <Autocomplete
												apiKey={API_KEY}
												onPlaceSelected={(place) => {
												this.setState({ place: place.formatted_address, lat: place.geometry.location.lat() , lon: place.geometry.location.lng() })
												}}
												types={['(regions)']}
												//componentRestrictions={{country: "ru"}}
											/>                                        
                                        </div>
                                        <div className="errorMsg">{ this.state.locationerr }</div>
                                        <div className="premium"><button type="Submit" className="btn btn-bg width100 justify-content-center amg0"><Trans i18nKey="Change Location">Change Location</Trans></button></div>
                                    </form>
                            </div>
                        </ModalBody>
                    </Modal>

                    <Modal isOpen={this.state.modal2} toggle={this.closeModal.bind(this, 'modal2')} className="premiumPopup vertically-center apd5">
                    <ModalHeader toggle={this.closeModal.bind(this, 'modal2')}><h4 class="modal-title"><Trans i18nKey="Join Premium">Join Premium</Trans></h4></ModalHeader>
                    <ModalBody>
                        <div className="premiumPop">
                            <div className="premiumImgs">
                                <Swiper {...params} shouldSwiperUpdate>  
                                 {this.state.permium_superlikeCount > 0 ?
                                  <div>
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sub_superlike.png")} alt="" className="img-fluid" />
                                      </div> 
                                      <div className="premiumInfoHolder">
                                          <div className="bold-font">
                                              <Trans i18nKey="Get">Get</Trans> {this.state.permium_superlikeCount} <Trans i18nKey="free super likes">free super likes</Trans>  
                                          </div>
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextSuper">Giving a Super like is a way to tell someone that you're especially interested in them</Trans>
                                          </div>
                                      </div>
                                  </div> : ''
                                 }

                                 {this.state.permium_boostCount > 0 ?
                                  <div>
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sub_boost.png")} alt="" className="img-fluid" />
                                      </div>
                                      <div className="premiumInfoHolder">  
                                          <div className="bold-font">
                                              <Trans i18nKey="Get">Get</Trans> {this.state.permium_boostCount} <Trans i18nKey="free boost">free boost</Trans>     
                                          </div>
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextBoost">Be the top profile in your area for 30minutes to get more matches, Keep Swipe right!</Trans>
                                          </div>
                                      </div>
                                  </div> : ''
                                 }
                  
                                  <div>
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sub_location.png")} alt="" className="img-fluid" />
                                      </div>
                                      <div className="premiumInfoHolder"> 
                                          <div className="bold-font">
                                              <Trans i18nKey="Change Location">Change Location</Trans>
                                          </div>
                                          <div className="premiumInfoSubText  secondaryText esmh-14 "> 
                                              <Trans i18nKey="premiumTextLocation">Get match and chat with others around the world by simply searching for a city</Trans>
                                          </div>
                                      </div>
                                  </div>

                                  <div> 
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sub_morelike.png")} alt="" className="img-fluid" />
                                      </div>
                                      <div className="premiumInfoHolder">
                                          <div className="bold-font">
                                              <Trans i18nKey="Unlimited Likes">Unlimited Likes</Trans>  
                                          </div>
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextUnlimited">Feel special again with 'Unlimited Likes'. Swipe right as much as you want!</Trans> 
                                          </div>
                                      </div>
                                  </div>


                                  <div>
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sud_add.png")} alt="" className="img-fluid" />
                                      </div>
                                      <div className="premiumInfoHolder"> 
                                          <div className="bold-font">
                                              <Trans i18nKey="Rewind">Rewind</Trans>
                                          </div>
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextRewind">Accidentally swiped on someone? Rewind lets you take back your last Like or Nope</Trans>
                                          </div>
                                      </div>
                                  </div>  

                                  <div> 
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sub_morelike.png")} alt="" className="img-fluid" />
                                      </div>
                                      <div className="premiumInfoHolder">
                                          <div className="bold-font">
                                              <Trans i18nKey="See who Likes you">See who Likes you</Trans>   
                                          </div> 
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextLikes">Likes You feature lets you see who likes you before you decide whether to Like or Nope</Trans> 
                                          </div>
                                      </div>
                                  </div>

                                  <div>
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sub_profile.png")} alt="" className="img-fluid" />
                                      </div>
                                      <div className="premiumInfoHolder">
                                          <div className="bold-font"> 
                                              <Trans i18nKey="Control your profile">Control your profile</Trans>   
                                          </div>  
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextProfile">Limit what others see and make parts of your profile information invisible to other people</Trans>
                                          </div>  
                                      </div>
                                  </div> 

                                  <div>
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sub_visited.png")} alt="" className="img-fluid" />
                                      </div>
                                      <div className="premiumInfoHolder">
                                          <div className="bold-font">
                                              <Trans i18nKey="See who visited your profile">See who visited your profile</Trans> 
                                          </div>
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextVisited">A Visitors feature lets you see who looked at your profile or ‘visited’ your profile
</Trans>
                                          </div>
                                      </div>
                                  </div>



                                  <div>
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sud_add.png")} alt="" className="img-fluid" />
                                      </div> 
                                      <div className="premiumInfoHolder">
                                          <div className="bold-font">
                                              <Trans i18nKey="Turn Off Ads">Turn Off Ads</Trans> 
                                          </div>
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextAds">Have fun swiping with uninterrupted ads</Trans>
                                          </div>  
                                      </div>
                                  </div> 

                              </Swiper> 
                            </div>      
                            <div className="premiumPopPlans">
                            <Swiper {...params2}>
                            {this.state.itemss.map((item, index) =>
                                <div className={this.state.activeItems === item.id ? 'navigation--active' : ''} onClick={this.handleItemClick.bind(this, item.id)} alt={item.id} value={item.id}>
                                <div className="premiumPrice">
                                    <div className="apd10 daysPrice">
                                    <div className="bold-font apk-30">{item.days}</div>
                                    <div className=" bold-font apk-24 mt-n2"><Trans i18nKey={item.type}>{item.type}</Trans></div>    
                                    </div>
                                    <div className="apd5 apk-16 mini-price">{this.state.currency}{item.price}</div>
                                </div>
                                </div>)}

                            </Swiper>
                            </div>


                            <div className="sidebarLastContent text-center">
                            <NavLink to="/app/Payment"> <button className="btn-common btn-block"><Trans i18nKey="Continue">Continue</Trans></button></NavLink> 
                            </div>


                        </div>
                    </ModalBody>
                </Modal>    



            </div>

        );
    }
}


export default withTranslation("translations")(Filter); 
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import { NavLink,browserHistory } from "react-router-dom";
import Welcome from "./components/welcome/Welcome";
import Home from "./components/main/home";
import Signin from "./components/welcome/Signin"
import Helpmain from "./components/help/Helpmain";
import Loader from 'react-loader-spinner';
//import { messaging } from "./init-fcm";
import { license } from "./license/License";  
import axios from 'axios';
// import Cookies from 'universal-cookie';
import NotFound from './components/NotFound/NotFound';

let val = license();
class App extends Component {
    authenticate() {
        return new Promise(resolve => setTimeout(resolve, 1500))
    }
    constructor(props) {
      super(props);
      this.state = {
         email: ""
      }
   }
    componentWillMount(){
      
    }
    async componentDidMount() {
     
        var formdata = new FormData();
        formdata.set('hostname', val);
        axios({
            method: 'post',
            url: localStorage.getItem('api_url')+'checklicense',
            data: formdata,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
            }).then(res => {
                if(res.data){
                    //window.location=window.location.protocol+'//'+window.location.hostname+'/Invalidlicense.js';
                }
            })
        
        this.authenticate().then(() => {
            const ele = document.getElementById('loader')
            if (ele) {
                ele.classList.add('available')
                setTimeout(() => {
                    ele.outerHTML = ''
                }, 500)
            }
        })
    }
    
    render() {   
        var style = {
            zIndex:10,
            backgroundColor:"white",
            height:"100vh",
            width:"100vw",
            position:"absolute",
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
            //width:"100%",
            overflowY:"hidden"
          };

        //(here 'howzureact' is subdirectory name, so able to run app on http://domain/howzureact)

        return (
            
            <Router basename={'/'}>   
                <div>
                    <div className="loder-icon">
                        <div style={style} id="loader">
                            <Loader type="Bars" color="#000000" height={30} width={50} />
                        </div>
                    </div>
                    <Switch>
                    <Route exact path="/" component={Welcome} />
                    <Route  path="/login" component={Signin} />
                    <Route  path="/app" component={Home}></Route>
                    <Route exact path="/helpmain" component={Helpmain}></Route>
                    <Route component={NotFound} />
                    </Switch>
                </div>
            </Router>
            
        );
    }
}

export default App;

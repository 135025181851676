import React, { Component } from 'react';
import { Trans, withTranslation } from "react-i18next";
import "../../config/i18n";

import { NavLink } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import axios, { post } from 'axios';
import Slider from "react-slick";
import ls from "local-storage"; 
import { options } from "../../config/options";
import Loader from 'react-loader-spinner';  


class ProfilePhotoEdit extends Component {
    constructor(props) {
        super(props);
        this.imgUploader = this.imgUploader.bind(this);
        this.state = {
            dropdownOpen: false,
            user_details:'',   
            photoLoaderBreak: 0, 
            imagess:[],api_url:localStorage.getItem('api_url'),
            image:[],current_slide: 0,
            images:'',index_image:'',suc_msg:'',errors:{},
            lang: ls.get('LangKeySet') || options[0],
            langKey: ls.get('LangValue'),   
            current_value:'',afterChangeHandler:'',activeSlide2: 0,activeFirstlook:0,
            // profileImages: ["http://localhost/howzu3.1/uploads/user/1556172175.jpeg", "http://localhost/howzu3.1/uploads/user/1555311900.jpeg", "http://localhost/howzu3.1/uploads/user/1555311781.jpeg"],
        }
        this.onFormSubmit = this.onFormSubmit.bind(this)
        this.onChange = this.onChange.bind(this)
        this.fileUpload = this.fileUpload.bind(this)
        this.afterChangeHandler = this.afterChangeHandler.bind(this)
        this.onDeleteimage = this.onDeleteimage.bind(this)
        this.onFirstlook = this.onFirstlook.bind(this)
    }

    
    imgUploader() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }
    

    componentDidMount() {
        this.slider.slickGoTo('3');

        let data = new FormData();
        data.append('user_id', JSON.parse(localStorage.getItem('session_userid')));  
        data.append('language', this.state.langKey); 
          
        axios({
            method: 'post',
            url: this.state.api_url+'webprofile',
            data: data, 
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        }).then(res => { 
            if(res.data.status !== false) { 
                const user_details = res.data.result;
                console.log("afdasdasf");
                console.log(user_details);
                this.setState({
                    user_details: user_details,
                    image: user_details.imagess,
                    imagess: user_details.imagess,
                    //profileImages: user_details.images,
                });
            } else { 
                this.props.history.push("/login"); 
            }
        }).catch(error => {
           this.props.history.push("/login"); 
        });
      
    }
    onFormSubmit(e){
        //e.preventDefault() 
        this.fileUpload(this.state.images);
      }
      onChange(e) {
        this.setState({
          file: URL.createObjectURL(e.target.files[0])
        })
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length)
              return;
        this.createImage(files[0]);
      }
      createImage(file) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.setState({
            images: e.target.result
          })
        };
        reader.readAsDataURL(file);
        setTimeout(() => {
            this.onFormSubmit();
          }, 1000);
        //this.onFormSubmit();
      }  
    fileUpload(images){
        let errors = {};
        // alert(this.state.images);
    if(this.state.imagess[4]  === null || this.state.imagess[4] === '' || this.state.imagess[4] === undefined){ 
        if(this.state.images === null || this.state.images === ''){
            errors["uploadimage"] = 'select a image';
            this.setState({
                errors: errors,  
                suc_msg: 'errorMsg floatingAlerts1',
            });
            setTimeout(() => {
                this.setState({
                    suc_msg: 'errorMsghide'
                });
              }, 2000);

        } else {

            if(this.state.photoLoaderBreak === 0) {   

                const url = this.state.api_url+'uploadimageweb';
                const formData = {file: this.state.images, user_id: JSON.parse(localStorage.getItem('session_userid')), language: this.state.langKey};   
                this.setState({
                    images:'',
                    photoLoaderBreak: 1,  

                });
                return  post(url, formData)
                .then(res => { 
                    if(res.data.status === 'true') {
                        const image_url = res.data.imageurl;
                        const image_name = res.data.image_name;
                        var editprofile = new FormData();
                        editprofile.set('images', image_url);
                        editprofile.set('images_name', image_name);
                        editprofile.set('user_id', JSON.parse(localStorage.getItem('session_userid')));
                        editprofile.set('language', this.state.langKey);  
                        axios({
                            method: 'post',
                            url: this.state.api_url+'websetprofile',
                            data: editprofile,
                            config: { headers: {'Content-Type': 'multipart/form-data' }}
                        }).then(res => { 
                            const message = res.data.message;
                            errors["uploadimage"] = message;

                            let data = new FormData();
                            data.append('user_id', JSON.parse(localStorage.getItem('session_userid')));  
                            data.append('language', this.state.langKey); 
                              
                            axios({
                                method: 'post',
                                url: this.state.api_url+'webprofile',
                                data: data, 
                                config: { headers: {'Content-Type': 'multipart/form-data' }}
                            }).then(res => {
                                if(res.data.status !== false) {
                                    const user_details = res.data.result;
                                   
                                    this.setState({
                                      errors: errors,  
                                      user_details: user_details,
                                      image: user_details.imagess,
                                      imagess: user_details.imagess,
                                      suc_msg: 'successMsg floatingAlerts1',
                                      images:'',
                                      current_slide : (user_details.imagess).length - 1,
                                    });
                                    setTimeout(() => {
                                        this.setState({
                                            suc_msg: 'successMsghide'
                                        });
                                    }, 2000);
                                } else {  
                                    this.props.history.push("/login"); 
                                }
                            }).catch(error => {
                               this.props.history.push("/login"); 
                            }); 
                            
                        }) 

                        this.setState({
                            photoLoaderBreak: 0, 
                        });

                    } else {
                        errors["uploadimage"] = res.data.message;
                        this.setState({
                            errors: errors,  
                            suc_msg: 'errorMsg floatingAlerts1',
                        });
                        setTimeout(() => {
                            this.setState({
                                suc_msg: 'errorMsghide'
                            });
                          }, 2000);

                        this.setState({
                            photoLoaderBreak: 0,   
                        });

                    }
                   
                })
            }
        } 
    } else { 
        errors["uploadimage"] = 'Only 5 images you can upload';
        this.setState({
            errors: errors,  
            suc_msg: 'errorMsg floatingAlerts1',
        });
        setTimeout(() => {
            this.setState({
                suc_msg: 'errorMsghide'
            });
          }, 2000);

     }   
    } 
    handleChange = event => {
        const { current_value } = event.target;
        //alert(current_value);
        this.setState({ current_value });
      }; 
      handleswipe(image_tmp) {
          //alert("zsd");
          
      }
      onDeleteimage(){
        let errors = {};
          //alert(this.state.image[this.state.activeSlide2]);
            var image_url = this.state.image[this.state.activeSlide2];
            var array = this.state.image; // make a separate copy of the array 
            //console.log(this.state.activeSlide2);
            var index = this.state.activeSlide2;   
            if (index !== -1 && index !== 0) {
                array.splice(index, 1);
                this.setState({image: array});
            
                var delete_image = new FormData();
                delete_image.set('delete_image', image_url);
                delete_image.set('language', this.state.langKey); 
                delete_image.set('user_id', JSON.parse(localStorage.getItem('session_userid')));
                axios({
                    method: 'post',
                    url: this.state.api_url+'websetprofile',
                    data: delete_image,
                    config: { headers: {'Content-Type': 'multipart/form-data' }}
                }).then(res => {  
                    const status = res.data.status;  
                    if(status==="false"){                  
                        errors["uploadimage"] = 'Profile image cannot be deleted';
                        this.setState({
                            errors: errors,  
                            suc_msg: 'errorMsg floatingAlerts1',
                        });
                        setTimeout(() => { 
                            this.setState({
                                suc_msg: 'errorMsghide'
                            });
                        }, 3000); 
                    } else if(status==="true") {                  
                        errors["uploadimage"] = res.data.message;
                        this.setState({
                            errors: errors,  
                            suc_msg: 'successMsg floatingAlerts1',
                        });
                        setTimeout(() => {
                            this.setState({
                                suc_msg: 'successMsghide'
                            });
                          }, 3000);
                    }
                });
            } else {
                errors["uploadimage"] = 'Profile image cannot be deleted';
                this.setState({    
                    errors: errors,  
                    suc_msg: 'errorMsg floatingAlerts1',
                });
                setTimeout(() => { 
                    this.setState({
                        suc_msg: 'errorMsghide'
                    });
                }, 3000); 
            } 
      }
      onFirstlook(){
        
            var first_look = new FormData();
            first_look.set('user_image', this.state.image[this.state.activeSlide2]);
            first_look.set('user_id', JSON.parse(localStorage.getItem('session_userid')));
            first_look.set('language', this.state.langKey);  
            axios({
                method: 'post',
                url: this.state.api_url+'websetfirstlook', 
                data: first_look,
                config: { headers: {'Content-Type': 'multipart/form-data' }}
            })  

            let data = new FormData();
            data.append('user_id', JSON.parse(localStorage.getItem('session_userid')));  
            data.append('language', this.state.langKey); 

            axios({
                method: 'post',
                url: this.state.api_url+'webprofile',
                data: data, 
                config: { headers: {'Content-Type': 'multipart/form-data' }}
            }).then(res => {
                if(res.data.status !== false) {
                    const user_details = res.data.result;
                    
                    this.setState({
                        user_details: user_details,
                        image: user_details.imagess,
                        imagess: user_details.imagess,
                        
                    });
                } else {
                    this.props.history.push("/login"); 
                }
            }).catch(error => {
               this.props.history.push("/login"); 
            }); 
                   
            window.location.reload();                
        }    
        
        afterChangeHandler(currentSlide) {
            this.props.updateInitialSlide(currentSlide);
        }
    
    render() {
      
       /* const settings = {
            dots: true,
            arrows:false,
            infinite: false,
            speed: 500,
            autoplay: true, 
            slidesToShow: 1, 
            slidesToScroll: 1,
            afterChange: current => this.setState({ activeSlide2: current })  
          };*/

          var settings = {
            autoplay: true, 
            slidesToShow: 1, 
            slidesToScroll: 1,
            dots: true,
            infinite: false,
            slickGoTo: 2,
            afterChange: current => this.setState({ activeSlide2: current })
          };      

        return (

            <div className="setting-page">

                <div className="d-flex profile-header-top stickyProfileHead justify-content-between apd15 apr0 apl0 white-bg amb10 ">
                    <div class="heading-left-top d-flex">
                        <div className="align-self-center ">
                            <NavLink to="/app/Profile">
                                <div className="iconHolder align-self-center mr-1">
                                    <div className="backGlow">
                                        <div className="backIco"></div>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <span class="arrow-center align-self-center bold-font">
                            <Trans i18nKey="Edit Photo">Edit Photo</Trans></span>
                    </div>
    
                </div>

                <div className="settingsPageWidth profileSectionView">


                    <div className="ProfileSpace ">
                   
                        <div className="peopleINSpaceHolder ">
                        {/* <p>
          AfterChange => activeSlide: <strong>{this.state.activeSlide2}</strong>
        </p> */}
                            <div className="peopleINViewHolder initialCursor">
                                <div className="peopleINPic">                                     
                                    <Slider ref={slider => (this.slider = slider)} {...settings}>
                                    {this.state.image.map((image_tmp) =>
                                        <div> <img src={image_tmp} id="current_image" alt="" value={image_tmp} onChange={()=>{this.handleswipe(image_tmp)}} className="" afterChange={this.afterChangeHandler}/></div>
                                    )}   
                                    </Slider>

                                </div>
                                <div className="apd10 apt15 ">

                                    <div className="d-flex justify-content-end apb10">
                                            {this.state.photoLoaderBreak === 0 ?
                                                <form onSubmit={this.onFormSubmit}>
                                                      <div className="circularIcoHolder addphoto">
                                                        <label className=" custom-file-upload "> +
                                                            <input id="file-upload" type="file"  onChange={this.onChange} />
                                                        </label>
                                                      </div>    
                                                </form>
                                            :
                                            <div className="circularIcoHolder addphoto">    
                                                <Loader type="Bars" color="#ffffff" height={20} width={35} />
                                            </div> 
                                        }
                                            <div className={this.state.suc_msg}><Trans i18nKey={this.state.errors.uploadimage}>{this.state.errors.uploadimage}</Trans></div>
                                        <div className="circularIcoHolder">

                                        <Dropdown isOpen={this.state.dropdownOpen} toggle={this.imgUploader}>
                                                    <DropdownToggle className="RSoverride">
                                                    <div className="circularHorizontalDotIco"></div>
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropDownRSoverride imgUploaderDropPos">
                                                       <button className={this.state.activeSlide2 === 0 ? 'border-0 bg-white text-center pointCurs primary-color' : 'border-0 bg-white text-center pointCurs'} onClick={this.onFirstlook}> 
                                                        <Trans i18nKey="First Look">First Look</Trans> 
                                                       </button> 
                                                      <button className="border-0 bg-white text-center pointCurs" onClick={this.onDeleteimage}>   
                                                        <Trans i18nKey="Delete">Delete</Trans>
                                                      </button>   
                                                    </DropdownMenu>
                                                </Dropdown> 

                                           
                                        </div> 

                                    </div>



                                </div> 
                            </div>
                        </div>



                    </div>


                </div>



            </div>

        );
    }
}
 
export default withTranslation("translations")(ProfilePhotoEdit); 

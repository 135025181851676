
import React, { Component } from 'react';
import { Trans } from "react-i18next";
import ls from "local-storage";  
import { options } from "../../config/options"; 

import SideNav from "react-simple-sidenav";
import { NavLink } from "react-router-dom";
import SideBar from "../main/SideBar";
// import Footer from "../main/Footer";
import PropTypes from 'prop-types';
import Slider from "react-slick";  
import axios from 'axios';  
import Swiper from 'react-id-swiper';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
//import { GoogleComponent } from 'react-google-location';
import Autocomplete from 'react-google-autocomplete';
// import $ from 'jquery';
import Boost from "../main/Boost";
import "../../config/i18n";
import { withTranslation } from "react-i18next";  

const API_KEY = "AIzaSyDVVob6U7eho9MpD99_7UCFmEzoH6N3Rrw";

const Card = ({ property }) => {
   
    
}

Card.propTypes = {
    property: PropTypes.object.isRequired
}


class PeopleNear extends Component {
    constructor(props) {
        super(props);
        this.loggedIn = sessionStorage.getItem('loggedin');
        this.onSubmitlocationsearch = this.onSubmitlocationsearch.bind(this);
        this.state = {
            showNav3: false,
            locationerr: '',
            peoples: '',change_loc:'none',change_loc2:'none',
            property:'',api_url:localStorage.getItem('api_url'),
            newindex:'',liked:'',nope:'',like_txt:'',nope_txt:'',hide:'',appholder1:'appHolder',appholder2:'none',user_details:'',
            modal1: false,
            modal2: false,
            superlike_txt:'', 
            like:'like',
            superlike:'superlike',
            permium_superlikeCount: 0, 
            permium_boostCount: 0, 
            lang: ls.get('LangKeySet') || options[0],
            langKey: ls.get('LangValue'),   
            openModal:false,
            price: "12",
            subscribed:false,
            subscribed_status: '',
            noofdays :"30",

            superlikeItems: [],
            matches: '', 
            superlikeCount: 0,
            superlikePlanStatus: 0,
            selected_superlikepremium:'',
            superlikepremium_price:'', 

            imagess: [],
            itemss: [],activeItems: '',
            user_id:'',place: null,lat: null,lon: null,
            selected_premium:'',
            currency:'',payment:'',license_key:'',premium_price:'',undo:'1',undostatus:'true',tmp_image:'circle-ripple',circle_image:'none'
        }
        
    }

    addDefaultSrc(ev){
        ev.target.src =  require('../../assets/img/default_user.png')
    }
    
    handleItemClick(index) {
        this.setState({
          activeItems: index,
          selected_premium: index,
        })
        var premiumlist = new FormData();
        premiumlist.set('premiumid', index);
        premiumlist.set('language', this.state.langKey);  
        axios.defaults.headers.common['Authorization'] = JSON.parse(localStorage.getItem('session_token'));

        axios({
          method: 'post',
          url: this.state.api_url+'premiumid',
          data: premiumlist,
          config: { headers: {'Content-Type': 'multipart/form-data' }}
            })
            .then(res => {
              const data = res.data;
              this.setState({
                premium_price: data.result.price,
                permium_superlikeCount: data.result.superlikecount, 
                permium_boostCount: data.result.boostcount, 
            });
            localStorage.setItem('selected_premium',JSON.stringify(index));
            localStorage.setItem('premium_price',JSON.stringify(data.result.price));
            });
      }

    closeModal(tabId) {
        if(tabId === 'modal1') {
            var editprofile = new FormData();
            editprofile.set('swipe', '0');
            editprofile.set('user_id', JSON.parse(localStorage.getItem('session_userid')));
            editprofile.set('language', this.state.langKey);  
            axios.defaults.headers.common['Authorization'] = JSON.parse(localStorage.getItem('session_token'));

            axios({ 
                method: 'post',
                url: this.state.api_url+'websetprofile',
                data: editprofile,
                config: { headers: {'Content-Type': 'multipart/form-data' }}
            })
            
            let data = new FormData();
            data.append('user_id', JSON.parse(localStorage.getItem('session_userid')));  
            data.append('language', this.state.langKey); 
            axios.defaults.headers.common['Authorization'] = JSON.parse(localStorage.getItem('session_token'));

            axios({
                method: 'post',
                url: this.state.api_url+'webprofile',
                data: data, 
                config: { headers: {'Content-Type': 'multipart/form-data' }} 
            }).then(res => {
                if(res.data.status !== false) {
                    const user_details = res.data.result;
                    this.setState({
                        user_details: user_details,
                    });
                }
            })

            this.setState({
                [tabId]: false
            });                     

        } else {
            this.setState({
                [tabId]: false
            });
        } 
    }

    closeRealted() {
        this.setState({
            modal1: false
        });

        var editprofile = new FormData();
        editprofile.set('swipe', '0');
        editprofile.set('language', this.state.langKey);  
        editprofile.set('user_id', JSON.parse(localStorage.getItem('session_userid')));
        axios.defaults.headers.common['Authorization'] = JSON.parse(localStorage.getItem('session_token'));

        axios({
            method: 'post',
            url: this.state.api_url+'websetprofile',
            data: editprofile,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        }) 
        
        let data = new FormData();
        data.append('user_id', JSON.parse(localStorage.getItem('session_userid')));  
        data.append('language', this.state.langKey); 
        axios.defaults.headers.common['Authorization'] = JSON.parse(localStorage.getItem('session_token'));

        axios({
            method: 'post',
            url: this.state.api_url+'webprofile',
            data: data, 
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        }).then(res => {
            if(res.data.status !== false) {
                const user_details = res.data.result;
             
                this.setState({
                    user_details: user_details,
                });
            } 
        });               

        var match = new FormData();
        match.append('user_id', JSON.parse(localStorage.getItem('session_userid')));
        match.append('language', this.state.langKey);  
        
        axios.defaults.headers.common['Authorization'] = JSON.parse(localStorage.getItem('session_token'));
        axios({
            method: 'post',
            url: this.state.api_url+'webundomatch',
            data: match,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        }).then(res => {
            var date = new Date();
            var details = new FormData();
            details.append('user_id', JSON.parse(localStorage.getItem('session_userid')));
            details.append('timestamp', date.getTime());
            details.append('language', this.state.langKey);  
            
            axios.defaults.headers.common['Authorization'] = JSON.parse(localStorage.getItem('session_token'));
            axios({
                method: 'post',
                url: this.state.api_url+'webgetpeople',
                data: details,
                config: { headers: {'Content-Type': 'multipart/form-data' }}
            }).then(res => {
                const dataa = res.data;
                if(res.data.status === 'true') { 
                    if(dataa.peoples.length === this.state.property.index) {
                        this.setState({
                            newindex : this.state.property.index - 1
                        });
                    } else {
                        this.setState({
                            newindex : this.state.property.index
                        }); 
                    }

                    this.setState({
                        liked : '',
                        like_txt: '',
                        superlike_txt: '', 
                        nope:'',
                        nope_txt:'',    
                        peoples: dataa.peoples,
                        property: dataa.peoples[this.state.newindex],
                        imagess: dataa.peoples[this.state.newindex].imagess,
                    });
                    
                } else {
                    this.setState({
                        appholder1: 'appHolder',
                        appholder2: 'none',
                        change_loc:'amt20 apk-16 searchRipplePos',
                        change_loc2:'sidebarLastContent searchRipplePos2',
                    });
                }
            })             
        })                     
    }

    showModal(modal) {
        this.setState({
            [modal]: true
        });
    }

    closeModall(tabId) {
        this.setState({
            [tabId]: false
        });
    }

    showModall(modal) {
        if(this.state.user_details.premium_member === "true"){
            this.setState({
                modal3: true
            });
        }else{
            this.setState({
                modal2: true
            });
        }
    }

    Message(frienduser_id) { 
        var report = new FormData();
        report.append('sender_id', JSON.parse(localStorage.getItem('session_userid')));
        report.append('receiver_id',frienduser_id); 
        report.append('language', this.state.langKey); 
        axios.defaults.headers.common['Authorization'] = JSON.parse(localStorage.getItem('session_token'));

        axios({
            method: 'post',
            url: this.state.api_url+'webcreatchat',
            data: report,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        }).then(res => {
            const chatid = res.data.chatid;
            const friendid = frienduser_id; 
            // console.log("chatid",chatid);
            this.props.history.push(`/app/Message/MessageNew/${friendid}/${chatid}`) 
        })

    }

    handleLike(user_id, reqType) { 
        var match = new FormData();
        match.append('user_id', JSON.parse(localStorage.getItem('session_userid')));
        match.append('follow_id', user_id);
        match.append('type', reqType);  
        match.append('language', this.state.langKey);  
        
        axios.defaults.headers.common['Authorization'] = JSON.parse(localStorage.getItem('session_token'));
        axios({
            method: 'post',
            url: this.state.api_url+'webmatch',
            data: match,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
              })
              .then(res => {
                if(res.data.status === "true") {  
                    this.setState({
                        matches: ''   
                    });  
                    this.setState({
                            liked : 'likedS',          
                            nope:'',
                            nope_txt:'',
                        }); 

                    if(reqType === "superlike") {
                        this.setState({
                            like_txt: '', 
                            superlike_txt: 'Super like', 
                        }); 
                    } else {
                        this.setState({
                            like_txt: 'Like', 
                            superlike_txt: '', 
                        }); 
                    }

                    if(res.data.match === "1") {
                        this.setState({ 
                            matches: res.data.result   
                        });      
                        this.showModal('modal5');      
                    } 

                    var date = new Date();
                    var details = new FormData();
                    details.append('user_id', JSON.parse(localStorage.getItem('session_userid')));
                    details.append('timestamp', date.getTime());
                    details.append('limit', 20);    
                    details.append('language', this.state.langKey);  

                    axios.defaults.headers.common['Authorization'] = JSON.parse(localStorage.getItem('session_token'));
                    axios({
                        method: 'post',
                        url: this.state.api_url+'webgetpeople',
                        data: details,
                        config: { headers: {'Content-Type': 'multipart/form-data' }}
                        })
                        .then(res => {  
                           // console.log(res.data);
                            const dataa = res.data;
                            if(res.data.status === 'true'){
                                this.setState({
                                    undostatus: 'true',
                                    undo:'1'
                                    }); 
                            if(dataa.peoples.length === this.state.property.index){
                                this.setState({
                                newindex : this.state.property.index - 1
                                });
                            }else{
                                this.setState({
                                newindex : this.state.property.index
                            });
                            
                            }
                            this.setState({  
                            liked : '',
                            like_txt: '',
                            superlike_txt: '', 
                            nope:'',
                            nope_txt:'',    
                            peoples: dataa.peoples,
                            property: dataa.peoples[this.state.newindex],
                            imagess: dataa.peoples[this.state.newindex].imagess,
                            });
                            // console.log("IMAGESS " +dataa.peoples[this.state.newindex].imagess);
                            }else{
                                this.setState({
                                    appholder1: 'appHolder',
                                    appholder2: 'none',
                                    change_loc:'amt20 apk-16 searchRipplePos',
                                    change_loc2:'sidebarLastContent searchRipplePos2',
                                    });
                            }    
                    })
                } else {
                    this.setState({
                        liked : '',
                        like_txt: '', 
                        superlike_txt: '',             
                        nope:'',
                        nope_txt:'',
                    }); 

                    if(reqType === "superlike" && res.data.superlike_count === "0") {   
                        var premiumlist = new FormData();
                        premiumlist.append('user_id',JSON.parse(localStorage.getItem('session_userid')));
                        premiumlist.append('platform', 'web'); 
                        premiumlist.append('language', this.state.langKey); 
                        axios.defaults.headers.common['Authorization'] = JSON.parse(localStorage.getItem('session_token'));

                        axios({
                            method: 'post', 
                            url: this.state.api_url+'websuperlikelist', 
                            data: premiumlist,
                            config: { headers: {'Content-Type': 'multipart/form-data' }}
                        }).then(res => {
                            if(res.data.status === "true") {
                                const data = res.data; 
                                             
                                this.setState({
                                    superlikeItems: data.result, 
                                    activeItem: data.result[0].id,
                                    selected_superlikepremium: data.result[0].id,
                                    superlikepremium_price: data.result[0].price,
                                    currency: data.currency,
                                    payment: data.payment,
                                    license_key: data.license_key,
                                    superlikePlanStatus: 1,
                                    superlikeCount: data.superlike_count,   
                                });
                                localStorage.setItem('selected_superlikepremium',JSON.stringify(this.state.selected_superlikepremium));
                                localStorage.setItem('superlikepremium_price',JSON.stringify(this.state.superlikepremium_price));
                                localStorage.setItem('currency',JSON.stringify(this.state.currency));

                                this.showModal('modal6');   
                            }  
                        });

                    }
                   
                    // console.log('People Near you webmatch Error' + res.data.message);   
                }        
            })
        }

        handleSuperlikeItemClick(index) { 
            this.setState({
                activeItem: index,
                selected_superlikepremium: index,
            })
            
            var premiumsuperlikelist = new FormData();
            premiumsuperlikelist.set('premiumsuperlikeid', index);
            premiumsuperlikelist.set('language', this.state.langKey); 
            axios.defaults.headers.common['Authorization'] = JSON.parse(localStorage.getItem('session_token'));
            
            axios({
                method: 'post',
                url: this.state.api_url+'premiumsuperlikeid',
                data: premiumsuperlikelist,   
                config: { headers: {'Content-Type': 'multipart/form-data' }}
            }).then(res => {
                const data = res.data;
                this.setState({
                    superlikepremium_price: data.result.price, 
                });    
                localStorage.setItem('selected_superlikepremium',JSON.stringify(index));
                localStorage.setItem('superlikepremium_price',JSON.stringify(data.result.price));   
            });  
        } 

      componentDidMount() {
        localStorage.setItem('filter_page','1');
        this.setState({
            appholder1: 'appHolder',
            appholder2: 'none',
        });
        
        this.setState({
            tmp_image:'none',
            circle_image:'circle-ripple'
        });

        let data1 = new FormData();
        data1.append('user_id', JSON.parse(localStorage.getItem('session_userid')));  
        //data.append('language', this.state.langKey); 
        axios.defaults.headers.common['Authorization'] = JSON.parse(localStorage.getItem('session_token')); 
        axios({
            method: 'post',
            url: this.state.api_url+'subscriptionStatus',
            data: data1, 
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        }).then(res => {              
          if(res.data.subscribed == 0 || res.data.subscribed_status == "expired") {      
            setTimeout(() => {
                axios({
                    method: 'get',
                    url: this.state.api_url+'subscriptionDetails',                
                    
                  }).then(res => {
                    const data = res.data;
                    console.log(data);
                    this.setState({                         
                            currency: data.currency,
                            price: data.price,
                            noofdays: data.noofdays,
                            subscribed: "false",
                            
                          });
    
                          setTimeout(() => {
                            this.setState({
                                openModal: true
                                          
                            }); 
                        }, 100); 
    
                          localStorage.setItem('subscription_price',JSON.stringify(this.state.price));
                          localStorage.setItem('currency',JSON.stringify(this.state.currency));
                          localStorage.setItem('noofdays',JSON.stringify(this.state.noofdays));
                     
                  });
            }, 200); 
           
                       
            
          }else{               
              this.setState({                     
                  subscribed : "true"                 
              });
           
          }
        })
        
        let data = new FormData();
        data.append('user_id', JSON.parse(localStorage.getItem('session_userid')));  
        data.append('language', this.state.langKey);   
        axios.defaults.headers.common['Authorization'] = JSON.parse(localStorage.getItem('session_token'));
          
        axios({
            method: 'post',
            url: this.state.api_url+'webprofile',
            data: data, 
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        }).then(res => {
            if(res.data.status !== false) {
                const user_details = res.data.result;
                
                this.setState({
                    user_details: user_details,
                });
            }
        });           
          
        var date = new Date();
        var details = new FormData();
        details.append('user_id', JSON.parse(localStorage.getItem('session_userid')));
        details.append('timestamp', date.getTime());
        details.append('language', this.state.langKey);  
        
        axios.defaults.headers.common['Authorization'] = JSON.parse(localStorage.getItem('session_token'));
        axios({
            method: 'post',
            url: this.state.api_url+'webgetpeople',
            data: details,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        }).then(res => { 
            if(res.data.status === "false") { 
                this.setState({
                    appholder1: 'appHolder',
                    appholder2: 'none',
                    change_loc:'amt20 apk-16 searchRipplePos',
                    change_loc2:'sidebarLastContent searchRipplePos2',
                });    
            } else {
                const dataa = res.data;
                this.setState({
                  peoples: dataa.peoples,
                  property: dataa.peoples[0],
                  imagess: dataa.peoples[0].imagess,
                });
            
                setTimeout(() => {
                    this.setState({
                        appholder1: 'none',
                        appholder2:'appHolder',           
                    }); 
                }, 2000); 
            }
        });  

        var premiumlist = new FormData();
        premiumlist.set('platform', 'web');
        premiumlist.set('language', this.state.langKey);  
        axios.defaults.headers.common['Authorization'] = JSON.parse(localStorage.getItem('session_token'));

        axios({
            method: 'post',
            url: this.state.api_url+'premiumlistweb', 
            data: premiumlist,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        }).then(res => {
            const data = res.data;
        
            this.setState({
                itemss: data.result,
                activeItems: data.result[0].id,
                selected_premium: data.result[0].id,
                premium_price: data.result[0].price,
                permium_superlikeCount: data.result[0].superlikecount, 
                permium_boostCount: data.result[0].boostcount,   
                currency: data.currency,
                payment: data.payment,
                license_key: data.license_key,
            });
            localStorage.setItem('selected_premium',JSON.stringify(this.state.selected_premium));
            localStorage.setItem('premium_price',JSON.stringify(this.state.premium_price));
            localStorage.setItem('currency',JSON.stringify(this.state.currency));
        });        
    }    
 
      next_Property(user_id) { 
          //alert(user_id);
        if(this.state.user_details.swipe === '1'){
            this.setState({
                modal1: true
            });
        }
        this.setState({
            liked : '',
            like_txt: '',
            superlike_txt: '', 
            nope:'disLikedNope',
            nope_txt:'Nope',
            hide:'hideMe'
        });
        setTimeout(() => {
            this.setState({
                nope: '',
                nope_txt: '',
          })
        }, 100);
        var match = new FormData();
        match.append('user_id', JSON.parse(localStorage.getItem('session_userid')));
        match.append('follow_id', user_id);
        match.append('language', this.state.langKey);  
        
        axios.defaults.headers.common['Authorization'] = JSON.parse(localStorage.getItem('session_token'));
        axios({
            method: 'post',
            url: this.state.api_url+'webunmatch',
            data: match,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
              })
              .then(res => {
               // console.log(res.data.status);
                var date = new Date();
                var details = new FormData();
                details.append('user_id', JSON.parse(localStorage.getItem('session_userid')));
                details.append('timestamp', date.getTime());
                details.append('language', this.state.langKey);  
                
                axios.defaults.headers.common['Authorization'] = JSON.parse(localStorage.getItem('session_token'));
                axios({
                    method: 'post',
                    url: this.state.api_url+'webgetpeople',
                    data: details,
                    config: { headers: {'Content-Type': 'multipart/form-data' }}
                    })
                    .then(res => {
                       // console.log(res.data);
                        const dataa = res.data;
                        if(res.data.status === 'true'){
                            this.setState({
                                undostatus: 'true',
                                undo:'1'
                                });    
                        if(dataa.peoples.length === this.state.property.index){
                            this.setState({
                            newindex : this.state.property.index - 1,
                            undostatus: 'true',
                            undo:'1'
                            });
                        }else{
                            this.setState({
                            newindex : this.state.property.index
                        });
                        
                        }
                        this.setState({
                        liked : '',
                        like_txt: '',
                        superlike_txt: '', 
                        nope:'',
                        nope_txt:'',    
                        peoples: dataa.peoples,
                        property: dataa.peoples[this.state.newindex],
                        imagess: dataa.peoples[this.state.newindex].imagess, 
                        });
                       // console.log("IMAGESS " +dataa.peoples[this.state.newindex].imagess);
                        }else{
                            this.setState({
                                appholder1: 'appHolder',
                                appholder2: 'none',
                                change_loc:'amt20 apk-16 searchRipplePos',
                                change_loc2:'sidebarLastContent searchRipplePos2',
                                });
                        }
                    });
                        
            }) 

      }
      nextProperty = (user_id) => {
        
    }
    prevProperty = () => {
       if(this.state.user_details.premium_member !== 'true'){
        this.setState({
            modal2: 'true',
            });

       }else{
           if(this.state.undo === '1' && this.state.undostatus === 'true'){
                     
       var match = new FormData();
       match.append('user_id', JSON.parse(localStorage.getItem('session_userid')));
       match.append('language', this.state.langKey);  
       
       
       axios.defaults.headers.common['Authorization'] = JSON.parse(localStorage.getItem('session_token'));
       axios({
           method: 'post',
           url: this.state.api_url+'webundomatch',
           data: match,
           config: { headers: {'Content-Type': 'multipart/form-data' }}
             })
             .then(res => {
              // console.log(res.data.status);
              if(res.data.status !== false) {
                   var date = new Date();
                   var details = new FormData();
                   details.append('user_id', JSON.parse(localStorage.getItem('session_userid')));
                   details.append('timestamp', date.getTime());
                   details.append('language', this.state.langKey);  
                   
                   axios.defaults.headers.common['Authorization'] = JSON.parse(localStorage.getItem('session_token'));
                   axios({
                       method: 'post',
                       url: this.state.api_url+'webgetpeople',
                       data: details,
                       config: { headers: {'Content-Type': 'multipart/form-data' }}
                       })
                       .then(res => {
                           //console.log(res.data);
                           const dataa = res.data;
                           if(res.data.status === 'true'){
                            this.setState({
                                undostatus: 'false',
                                undo:'0'
                                });    
                           if(dataa.peoples.length === this.state.property.index){
                               this.setState({
                               newindex : this.state.property.index - 1
                               });
                           }else{
                               this.setState({
                               newindex : this.state.property.index
                           });
                           
                           }
                           this.setState({
                           liked : '',
                           like_txt: '',
                           superlike_txt: '',
                           nope:'',
                           nope_txt:'',    
                           peoples: dataa.peoples,
                           property: dataa.peoples[this.state.newindex],
                           imagess: dataa.peoples[this.state.newindex].imagess,
                           }); 
                          //  console.log("IMAGESS " +dataa.peoples[this.state.newindex].imagess);
                           }else{
                               this.setState({
                                   appholder1: 'appHolder',
                                   appholder2: 'none',
                                   change_loc:'amt20 apk-16 searchRipplePos',
                                    change_loc2:'sidebarLastContent searchRipplePos2',
                                   });
                           }
                           });
                }  
                           
             })
          }
        }
       
        
   }

    // prevProperty = () => {
    //     const newIndex = this.state.property.index - 1;
    //     this.setState({
    //         property: this.state.peoples[newIndex]
    //     })
    // }
    onSubmitlocationsearch(e){
        e.preventDefault();
        let errors = {};
       // alert(this.state.place);
        if(this.state.lat === "" ||this.state.lat === null ||  this.state.lon === "" || this.state.place=== "" || this.state.place=== null){
            //alert("tyu")
       
             this.setState({
            locationerr: "please select the location"
        });
            
        }
          else if(typeof(this.state.lat) === 'undefined' || typeof(this.state.lon) === 'undefined')
                {
                      
                             this.setState({
            locationerr: "please select the location from dropdown"
        });
                     // console.log(this.state.lat);
                       // console.log(this.state.lon);
                }
        else{
            var location = new FormData();
            if(this.state.place){
                var address=this.state.place;
                var value=address.split(","); 
                var count=value.length; 
                var country=value[count-1]; 
                var state=value[count-2]; 
                var city=value[count-3]; 
              //  console.log(city+" "+state+" "+country); 
                // location.append('place', city+", "+state+", "+country);
                // this.setState({
                //     loc: city+", "+state+", "+country
                // });
                 if(city != null){
                    //alert(city)
                    location.append('place', city+", "+state+", "+country);
                    this.setState({
                        loc: city+", "+state+", "+country
                    });
                }else{
                    location.append('place', state+", "+country);
                    this.setState({
                        loc: +state+", "+country
                    });
                }
            }
            location.append('lat', this.state.lat);
            location.append('lon', this.state.lon);
            location.append('language', this.state.langKey);  
            location.append('user_id', JSON.parse(localStorage.getItem('session_userid')));

            axios.defaults.headers.common['Authorization'] = JSON.parse(localStorage.getItem('session_token'));
         
        axios({ 
            method: 'post',
            url: this.state.api_url+'websetprofile', 
            data: location,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(res => {
            const status = res.data.status;
        //   console.log(res.data);
            if(status === "true"){
            // this.setState({ showNav3: true })
            
            this.setState({
                modal3: false
            });
            //this.props.history.push("/app/PeopleNear")
            window.location.reload();

            }else{

            }
        });    
    }
        this.setState({
            errors: errors
            });
            
    } 
      
  
    render() {
        const { t } = this.props; 

        const { property } = this.state; 
       
         
        // const { index, images, user_name, location, age,user_id,show_location } = property;
        
        const { index, user_name, location, age,user_id,show_location } = property;

        // var imagess = ["http://localhost/Howzu/4.0/uploads/user/1588246122.jpeg", "http://localhost/Howzu/4.0/uploads/user/1554466222.jpeg"];
        
        const settings = {
            dots: false,  
            arrows:false,
            infinite: false,
            speed: 500,
            autoplay: true, 
            slidesToShow: 1,
            slidesToScroll: 1,
          }; 
            
        const params = {
          pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
          },
          spaceBetween: 1,
          rebuildOnUpdate: true   
        }  

        const params2 = {
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev'
            },  
            slidesPerView: 3,
            slidesPerColumn: 1,
            breakpoints: {
                360: {
                    slidesPerView: 3,
                },
                359: {
                    slidesPerView: 2,
                }
            }
        }; 

        return (

            <div className="setting-page peopleNpage">

                <div className="d-flex profile-header-top stickyProfileHead justify-content-between apd15 apr0 apl0 white-bg amb10 ">
                    <div class="heading-left-top d-flex">
                        <div className="align-self-center d-block d-lg-none">
                            <div className="iconHolder align-self-center mr-1">
                                <div className="backGlow">
                                    <div className="sideBarToggler sidebarIcoPos" onClick={() => this.setState({ showNav3: true })}></div>
                                </div>
                            </div>
                        </div>
                        <span class="arrow-center align-self-center d-none ml-2 bold-font"><Trans i18nKey="People near you">People near you</Trans></span>
                        <Boost subscribed={this.state.subscribed}/>  
                    </div>
                       
                    <div className="align-self-center  d-block">
                        <span class="heading-left-top arrow-center align-self-center ml-2 bold-font"><Trans i18nKey="People near you">People near you</Trans></span>
                        <img src={require('../../assets/img/logo-c-64.png')} className="d-none sideLogo commonLogoC" alt="logo" />
                    </div>                     

                    
                    <div className="iconHolder align-self-center">
                    { (this.state.user_details.subscribed == "true" &&  this.state.user_details.subscribed_status != "expired")? 
                        <NavLink to={`/app/Filter`} >
                            <div className="backGlow">
                                <div className="sidebarIcoPos filterIcon align-self-center"></div>
                            </div>
                        </NavLink> : "" }
                    </div> 
                </div>

                { (this.state.subscribed == "true" &&  this.state.user_details.subscribed_status != "expired") ? 
                        <div className={this.state.appholder1}>

                        <div className="d-flex flex-column align-items-center relPos">
                        <div class={this.state.circle_image}>
                        <img src={this.state.user_details.images} alt="" class="profilePageDp" />
                        </div>
                        <div class={this.state.tmp_image}>
                        <img src={this.addDefaultSrc} alt="" class="profilePageDp" /> 
                        </div>
                        <div className={this.state.change_loc} ><Trans i18nKey="No people in your location">No people in your location</Trans></div>
                        <div class={this.state.change_loc2}>
                            <button class="btn-common w200px" onClick={this.showModall.bind(this, 'modal2')} >
                                <Trans i18nKey="Change Location">Change Location</Trans> 
                            </button>
                        <div></div></div>
                        </div>


                

                </div>: '' } 

                { (this.state.user_details.subscribed == "true" &&  this.state.user_details.subscribed_status != "expired") ?
                <div className={this.state.appholder2}>
                    <div className="ProfileSpace peopleSlides apd5">
                        <div className="peopleINSpaceHolder  peopleSlides">
                            <div className="peopleINViewHolder initialCursor">
                                <div className="peopleINPic relPos ">
                                    <button title={t('Rewind')} onClick={() => this.prevProperty()}  value={property.index} className="swiper-button-prev border-0"></button>
                                    <button title={t('Dislike')} onClick={() => this.next_Property(user_id)}  value={user_id} alt={user_id}  disabled={property.index === 0 ? '' : property.index === this.state.peoples.length - 1} className="swiper-button-next border-0"></button> 
                                    <div className="page">
                                    <div id={`card-${index}`} >

                                    {/*<NavLink to={`/app/PeopleNear/PeopleExplore/${user_id}`}>
                                        <img src={images} alt='' />  
                                    </NavLink>*/}  

                                    <Slider {...settings}> 
                                    {this.state.imagess.map((image_tmp) =>      
                                        <div> 
                                            <NavLink to={`/app/PeopleNear/PeopleExplore/${user_id}`}> 
                                                <img src={image_tmp} id="current_image" alt="" value={image_tmp} className="" />
                                            </NavLink> 
                                        </div> 
                                    )}  
                                    </Slider> 
                                    

                                        {/* 
                                        <span className="index">{index+1}</span>
                                        */}
                                        <NavLink to={`/app/PeopleNear/PeopleExplore/${user_id}`}><div className="peopleSlideDetails">
                                            <div className="media text-center">
                                                <div className="media-body pFHidden align-self-center">
                                                    <div className="text-truncate bold-font  apk-18 text-light apl10 apr10">{user_name}, {age}</div>
                                                    <div className="text-truncate col-6 offset-3 secondaryText rtlcls apk-16 regular-font text-light apl10 apr10">{show_location === true ? ''  : location}</div>
                                                </div>
                                            </div>
                                        </div></NavLink> 
                                        { (this.state.like_txt !== "") ?
                                                <div  className={this.state.liked}> <Trans i18nKey={this.state.like_txt}>{this.state.like_txt}</Trans> </div>
                                          :   <div  className={this.state.liked}> <Trans i18nKey={this.state.superlike_txt}>{this.state.superlike_txt}</Trans> </div>  
                                        }  
                                       <div ><div  className={this.state.nope}> <Trans i18nKey={this.state.nope_txt}>{this.state.nope_txt}</Trans> </div></div> 


                                         {this.state.like_txt && (
                                             <div className="likeButton" title={t('Like')} value={user_id} alt={user_id} ></div>
                                         )} 
                                            {!this.state.like_txt && (
                                              <div className="likeButton" title={t('Like')} value={user_id} alt={user_id} onClick={()=>{this.handleLike(user_id, this.state.like)}}></div>
                                         )} 


                                         {this.state.superlike_txt && (
                                             <div className="superlikeButton" title={t('Super like')} value={user_id} alt={user_id} ></div>
                                         )} 
                                            {!this.state.superlike_txt && (   
                                              <div className="superlikeButton" title={t('Super like')} value={user_id} alt={user_id} onClick={()=>{this.handleLike(user_id,this.state.superlike)}}></div> 
                                         )}  
                                       
                                    </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>










                    

                </div> : '' } 
                <div className="sidebarResp d-block d-lg-none">
                        <SideNav showNav={this.state.showNav3} onHideNav={() => this.setState({ showNav3: false })} title={<div></div>} items={[<SideBar />]} />
                    </div>
                <Modal isOpen={this.state.modal1} toggle={this.closeRealted.bind(this, 'modal1')} className="premiumPopup vertically-center apd5">
                        <ModalHeader toggle={this.closeRealted.bind(this, 'modal1')}>
                            <h4 class="modal-title">
                                <Trans i18nKey="Remove related matches ?">Remove related matches ?</Trans>
                            </h4></ModalHeader>
                        <ModalBody>
                            <div className="modal-body">
                                <div class="d-flex justify-content-end">
                                    <div class="mr-3"><button class="border-0 bg-white pink-color" onClick={this.closeModal.bind(this, 'modal1')}>
                                        <Trans i18nKey="Yes">Yes</Trans></button></div>
                                    <div><button class="border-0 bg-white"  onClick={this.closeRealted.bind(this, 'modal1')}>
                                        <Trans i18nKey="No">No</Trans></button></div>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>


                    {/* Premium Popup  */}
                    <Modal isOpen={this.state.modal2} toggle={this.closeModal.bind(this, 'modal2')} className="premiumPopup vertically-center apd5">
                        <ModalHeader toggle={this.closeModal.bind(this, 'modal2')}>
                            <h4 class="modal-title"><Trans i18nKey="Join Premium">Join Premium</Trans></h4>
                        </ModalHeader> 
                        <ModalBody>
                        <div className="premiumPop"> 
                            <div className="premiumImgs">
                                <Swiper {...params} shouldSwiperUpdate>  
                                 {this.state.permium_superlikeCount > 0 ?
                                  <div>
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sub_superlike.png")} alt="" className="img-fluid" />
                                      </div> 
                                      <div className="premiumInfoHolder">
                                          <div className="bold-font">
                                              <Trans i18nKey="Get">Get</Trans> {this.state.permium_superlikeCount} <Trans i18nKey="free super likes">free super likes</Trans>  
                                          </div>
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextSuper">Giving a Super like is a way to tell someone that you're especially interested in them</Trans>
                                          </div>
                                      </div>
                                  </div> : ''
                                 }

                                 {this.state.permium_boostCount > 0 ?
                                  <div>
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sub_boost.png")} alt="" className="img-fluid" />
                                      </div>
                                      <div className="premiumInfoHolder">  
                                          <div className="bold-font">
                                              <Trans i18nKey="Get">Get</Trans> {this.state.permium_boostCount} <Trans i18nKey="free boost">free boost</Trans>     
                                          </div>
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextBoost">Be the top profile in your area for 30minutes to get more matches, Keep Swipe right!</Trans>
                                          </div>
                                      </div>
                                  </div> : ''
                                 }
                  
                                  <div>
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sub_location.png")} alt="" className="img-fluid" />
                                      </div>
                                      <div className="premiumInfoHolder"> 
                                          <div className="bold-font">
                                              <Trans i18nKey="Change Location">Change Location</Trans>
                                          </div>
                                          <div className="premiumInfoSubText  secondaryText esmh-14 "> 
                                              <Trans i18nKey="premiumTextLocation">Get match and chat with others around the world by simply searching for a city</Trans>
                                          </div>
                                      </div>
                                  </div>

                                  <div> 
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sub_morelike.png")} alt="" className="img-fluid" />
                                      </div>
                                      <div className="premiumInfoHolder">
                                          <div className="bold-font">
                                              <Trans i18nKey="Unlimited Likes">Unlimited Likes</Trans>  
                                          </div>
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextUnlimited">Feel special again with 'Unlimited Likes'. Swipe right as much as you want!</Trans> 
                                          </div>
                                      </div>
                                  </div>


                                  <div>
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sud_add.png")} alt="" className="img-fluid" />
                                      </div>
                                      <div className="premiumInfoHolder"> 
                                          <div className="bold-font">
                                              <Trans i18nKey="Rewind">Rewind</Trans>
                                          </div>
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextRewind">Accidentally swiped on someone? Rewind lets you take back your last Like or Nope</Trans>
                                          </div>
                                      </div>
                                  </div>  

                                  <div> 
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sub_morelike.png")} alt="" className="img-fluid" />
                                      </div>
                                      <div className="premiumInfoHolder">
                                          <div className="bold-font">
                                              <Trans i18nKey="See who Likes you">See who Likes you</Trans>   
                                          </div> 
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextLikes">Likes You feature lets you see who likes you before you decide whether to Like or Nope</Trans> 
                                          </div>
                                      </div>
                                  </div>

                                  <div>
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sub_profile.png")} alt="" className="img-fluid" />
                                      </div>
                                      <div className="premiumInfoHolder">
                                          <div className="bold-font"> 
                                              <Trans i18nKey="Control your profile">Control your profile</Trans>   
                                          </div>  
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextProfile">Limit what others see and make parts of your profile information invisible to other people</Trans>
                                          </div>  
                                      </div>
                                  </div> 

                                  <div>
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sub_visited.png")} alt="" className="img-fluid" />
                                      </div>
                                      <div className="premiumInfoHolder">
                                          <div className="bold-font">
                                              <Trans i18nKey="See who visited your profile">See who visited your profile</Trans> 
                                          </div>
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextVisited">A Visitors feature lets you see who looked at your profile or ‘visited’ your profile
</Trans>
                                          </div>
                                      </div>
                                  </div>



                                  <div>
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sud_add.png")} alt="" className="img-fluid" />
                                      </div> 
                                      <div className="premiumInfoHolder">
                                          <div className="bold-font">
                                              <Trans i18nKey="Turn Off Ads">Turn Off Ads</Trans> 
                                          </div>
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextAds">Have fun swiping with uninterrupted ads</Trans>
                                          </div>  
                                      </div>
                                  </div> 

                              </Swiper> 
                            </div>   

                            <div className="premiumPopPlans">
                            <Swiper {...params2}>
                            {this.state.itemss.map((item, index) =>
                                <div className={this.state.activeItems === item.id ? 'navigation--active' : ''} onClick={this.handleItemClick.bind(this, item.id)} alt={item.id} value={item.id}>
                                <div className="premiumPrice">
                                    <div className="apd10 daysPrice">
                                    <div className="bold-font apk-30">{item.days}</div>
                                    <div className=" bold-font apk-24 mt-n2"><Trans i18nKey={item.type}>{item.type}</Trans></div>   
                                    </div>
                                    <div className="apd5 apk-16 mini-price">{this.state.currency}{item.price}</div>
                                </div>
                                </div>)}

                            </Swiper>
                            </div>


                            <div className="sidebarLastContent text-center">
                            <NavLink to="/app/Payment"> <button className="btn-common btn-block"><Trans i18nKey="Continue">Continue</Trans></button></NavLink>
                            </div>


                        </div>
                        </ModalBody>
                    </Modal>

                    <Modal isOpen={this.state.modal3} toggle={this.closeModal.bind(this, 'modal3')} className="premiumPopup vertically-center apd5">
                        <ModalHeader  toggle={this.closeModal.bind(this, 'modal3')}><h4 class="modal-title"><Trans i18nKey="Change Location">Change Location</Trans></h4></ModalHeader>
                        <ModalBody>
                        <div className="modal-body edit-profile-page">
                                    <form className="" onSubmit={this.onSubmitlocationsearch}>
                                        <div className="user-input-wrp amb35">
                                            <br />
                                            <Autocomplete
												apiKey={API_KEY}
												onPlaceSelected={(place) => {
												this.setState({ place: place.formatted_address, lat: place.geometry.location.lat() , lon: place.geometry.location.lng() })
												}}
												types={['(regions)']}
												//componentRestrictions={{country: "ru"}}
											/>
                                        
                                        </div>
                                        {/* <div className="errorMsg">{this.state.errors.locationsearch}</div> */}
                                        <div className="errorMsg"><Trans i18nKey={ this.state.locationerr }>{ this.state.locationerr }</Trans></div>
                                        <div className="premium"><button type="Submit" className="btn btn-bg width100 justify-content-center amg0"> <Trans i18nKey="Joinwith">Join with Blackroseinternational</Trans> </button></div>
                                    </form>
                            </div>
                        </ModalBody>
                    </Modal>  

                    <Modal isOpen={this.state.modal5} toggle={this.closeModall.bind(this, 'modal5')} className="premiumPopup matchPopup vertically-center apd5">
                        <ModalHeader toggle={this.closeModall.bind(this, 'modal5')}></ModalHeader>
                        <ModalBody>    
                            
                                <div class="modal-images media"> 
                                    <div class="matchmedia">
                                        <img src={this.state.matches.loguser_image} alt="" className="" />
                                    </div>
                                    <div class="matchlike"></div> 
                                    <div class="matchmedia">
                                        <img src={this.state.matches.frienduser_image} alt="" className="" />
                                    </div>
                                </div>  

                                <div class="justify-content-end text-center matchContent"><Trans i18nKey="It's a Match!">It's a Match!</Trans></div> 
                                <div class="justify-content-end text-center matchContentSub">You and {this.state.matches.frienduser_name} like each other</div> 
                                <div class="justify-content-end text-center"> 
                                    <button class="bg-white pink-color matchButton" onClick={this.Message.bind(this, this.state.matches.frienduser_id)} ><Trans i18nKey="Send message">Send message</Trans></button>  
                                </div>      
                            
                        </ModalBody>
                    </Modal>  

                    <Modal isOpen={this.state.modal6} toggle={this.closeModall.bind(this, 'modal6')} className="premiumPopup superlikePopup vertically-center apd5">
                        <ModalHeader toggle={this.closeModall.bind(this, 'modal6')}></ModalHeader> 
                        <ModalBody>    
                            <div className="boostPopupIcon"></div> 
                            <div className="premiumPop">    
                                { (this.state.superlikePlanStatus === 1) ?
                                    <div className="premiumPopPlans">
                                        <div className="text-center">
                                            <div class="bold-font"> 
                                                <Trans i18nKey="Out of Super Likes">Out of Super Likes</Trans>
                                            </div>
                                            <div class="premiumInfoSubText  secondaryText esmh-14"> 
                                                <Trans i18nKey="Giving a Super like is a way to tell someone that you're especially interested in them">
                                                    Giving a Super like is a way to tell someone that you're especially interested in them    
                                                </Trans>  
                                            </div> 
                                        </div>  
                                        <Swiper {...params2}>
                                            {this.state.superlikeItems.map((item, index) =>
                                                <div className={this.state.activeItem === item.id ? 'navigation--active' : ''} onClick={this.handleSuperlikeItemClick.bind(this, item.id)} alt={item.id} value={item.id}>
                                                    <div className="premiumPrice">
                                                        <div className="apd10 daysPrice">
                                                            <div className="bold-font apk-30">{item.superlike_count}</div>
                                                            <div className=" bold-font apk-20 mt-n2"><Trans i18nKey="Super likes">Super likes</Trans></div>    
                                                        </div> 
                                                        <div className="apd5 apk-16 mini-price">{this.state.currency}{item.price}</div> 
                                                    </div>
                                                </div>
                                            )} 
                                        </Swiper>   
                                    <div className="sidebarLastContent text-center">
                                        <NavLink to="/app/Superlikepayment"> <button className="btn-common btn-block"><Trans i18nKey="Continue">Continue</Trans></button></NavLink> 
                                    </div>
                                    </div> 
                                :
                                    <div className="premiumPopPlans text-center"> <Trans i18nKey="No Plans Available"> No Plans Available</Trans> </div> 
                                }    
                            </div>
                        </ModalBody> 
                    </Modal> 

                    <Modal isOpen={this.state.openModal} toggle={this.closeModal.bind(this, 'openModal')} className="premiumPopup vertically-center apd5">
          <ModalHeader toggle={this.closeModal.bind(this, 'openModal')}>            
          </ModalHeader>  
          <ModalBody>    
          <div>                         
            <div className="premiumInfoHolder reducemargin">
                <div className="bold-font">
                <h4 >
                <Trans i18nKey=">Activate your account">Activate your account</Trans> 
                </h4>
                </div>
                <div className="premiumInfoSubText  secondaryText esmh-14 ">
                    <Trans i18nKey="subscribeTextSuper">Get unlimited access nearby friends, likes, superlike, chat, etc</Trans>
                </div>
            </div>
            
        </div>     
        <div className="premiumPopPlans subscribeprice">
            <div class="swiper-slide navigation--active swiper-slide-active" style={{width:"152px"}}>
        <div class="premiumPrice" ><div class="apd10 daysPrice"><div class="bold-font apk-30"  style={{color:"black"}}>{this.state.noofdays}</div><div class=" bold-font apk-24 mt-n2" style={{color:"black"}}><Trans i18nKey="Days">Days</Trans></div></div><div class="apd5 apk-16 mini-price">{this.state.currency}{this.state.price}</div></div>
        </div>
        </div>
        <div className="sidebarLastContent text-center">
              <NavLink to="/app/SubscribePayment"> <button className="btn-common btn-block" onClick={this.closeModal.bind(this, 'modal4')}><Trans i18nKey="Subscribe">Subscribe</Trans></button></NavLink>  
        </div>
          </ModalBody>
        </Modal>

                <div class="footerSection">
                    {/* <Footer /> */}
                </div>


                

            </div>

        );
    }
}


export default withTranslation("translations")(PeopleNear);   
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import { Trans } from "react-i18next";
import Select from "react-select";
import ls from "local-storage"; 
import { options } from "../../config/options";
import "../../config/i18n";
import { withTranslation } from "react-i18next";  

import Cookies from 'universal-cookie';
import FacebookLogin from 'react-facebook-login';
import axios from 'axios';

class Welcome extends Component {
	constructor(props) {
        super(props);
        this.state = {
        	api_url:localStorage.getItem('api_url'),
        	lang: ls.get('LangKeySet') || options[0],
         langKey: ls.get('LangValue')
        };
      }
   
   changeLang = lang => {
		const { i18n } = this.props;
		const { value } = lang;
		this.setState({
			lang
		});
		ls.set('LangKeySet', lang);
      ls.set('LangValue', value);
		i18n.changeLanguage(value);
		window.location.reload();    
	};

	componentDidMount(){
		if (this.props.i18n.language) {
			this.setState(
				{
					lang: options.filter(item => item.value === this.props.i18n.language)
				},
				() => console.log(this.state)
			);
		}

		const cookies = new Cookies();
	   if(cookies.get('onboarded') === 'true'){
		 //alert("s");
		 this.props.history.push("/app")
		   
		 
		 }else{
			//  this.props.history.push("/login");
			//  window.location.reload();
   
		 }
   
   }
   
   responseFacebook(response) {
	const cookies = new Cookies();
	if(response.email && response.userID){
		var formdata = new FormData();
        formdata.set('email', response.email);
        formdata.set('fbid', response.userID);
        formdata.set('language', this.state.langKey);  
        axios({
            method: 'post',
            url: localStorage.getItem('api_url')+'check_fb_user',
            data: formdata,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
            }).then(res => {
            	var hosturl = window.location.href;
            	if(res.data===0){
            		cookies.set('fbemail', response.email);
            		formdata.set('fcmtoken', cookies.get('mytoken'));
            		formdata.set('language', this.state.langKey);  
            		axios({
			            method: 'post',
			            url: localStorage.getItem('api_url')+'webemaillogin',
			            data: formdata,
			            config: { headers: {'Content-Type': 'multipart/form-data' }}
			            }).then(res => {
			            	var loginstatus = new FormData();
							loginstatus.set('user_id', res.data.peoples.user_id);
							loginstatus.set('status', '1');
							loginstatus.set('language', this.state.langKey);    
							axios({
									method: 'post',
									url: localStorage.getItem('api_url')+'webonlinestatus',
									data: loginstatus,
									config: { headers: {'Content-Type': 'multipart/form-data' }}
									})
									
							let d = new Date();
							d.setTime(d.getTime() + (12*60*60*1000));   
							cookies.set('onboarded', 'true', {path: "/", expires: d});	 				
							localStorage.setItem('session_token',JSON.stringify(res.data.peoples.token));
							localStorage.setItem('session_userid',JSON.stringify(res.data.peoples.user_id));
							sessionStorage.setItem('loggedin', 'true');
							window.location.href = hosturl+"app/PeopleNear";
			            })

            	}else if(res.data===1){
            		cookies.set('fbemail', response.email);
					cookies.set('fbid', response.userID);
					cookies.set('fbname', response.name);
					cookies.set('fbpic', response.picture.data.url);
					cookies.set('signuptab', 'yes');
                	window.location.href = hosturl+"login";
                }
            })
	}
  }


	render() {
		const { lang } = this.state;
		return (

			<div className="welcomePage">
				
				<div className="col-12">
						<div className="apt20 apb5">  
							<div style={{ width: "150px" }}> 
								<Select
					            defaultValue={lang} 
					            options={options}
					            value={lang}
					            onChange={this.changeLang} 
					            className="App-Select"
					          />
							</div>
						</div>
					</div> 


				<div className="welcomePageCenterContent">

					<div className="welcomeLogo"></div>

					<div id="welcome" class="carousel slide amt40 amb10" data-ride="carousel">
						<ul class="carousel-indicators"><li data-target="#welcome" data-slide-to="0" class=""></li>
							<li data-target="#welcome" data-slide-to="1" class=""></li>
							<li data-target="#welcome" data-slide-to="2" class="active"></li></ul>
						<div class="carousel-inner">
							<div class="carousel-item text-center">
								<h4 class="amb15 white-color"><Trans i18nKey="Matches near you">Matches near you</Trans></h4>
								<p class="white-color amb50 sma-16"><Trans i18nKey="welcomeSlide1">We make it easy to navigate into the online dating world by making it a comfortable and fun experience</Trans></p>
							</div>
							<div class="carousel-item text-center">
								<h4 class="amb15 white-color"><Trans i18nKey="Swipe for more">Swipe for more</Trans></h4>
								<p class="white-color amb50 sma-16"><Trans i18nKey="welcomeSlide2">We helps you to find your soulmate who is just a few swipes away from you. Connect with people no matter where you are.</Trans></p>
							</div>
							<div class="carousel-item text-center active"> 
								<h4 class="amb15 white-color"><Trans i18nKey="Find the People near you">Find the People near you</Trans> </h4> 
								<p class="white-color amb50 sma-16"><Trans i18nKey="welcomeSlide3">Choose those who you are compatible can message you. So just say hi and find your next date.</Trans></p>
							</div>
						</div>
					</div> 

					{/*<div className="w-100">
					<FacebookLogin
					appId="718268961615879"
					autoLoad={false}
					textButton= {<Trans i18nKey="Login with Facebook">Login with Facebook</Trans>}
					fields="name,email,picture.width(400).height(400)"
					cssClass="btnsWelcome white-border border-radius text-light white-border"
					callback={this.responseFacebook}  
					/>	
					</div>*/}      

					<NavLink to="/login" className="btnsWelcome border-radius  white-border text-dark white-bg"> <Trans i18nKey="LoginwithMailid">Login with Mail id</Trans> </NavLink>
					
					<div class="white-color esmh-14 text-center amt10"><Trans i18nKey="signingIn"><span className="floating-label">By signing in, you agree with our</span> </Trans>
					  <span> <NavLink to="/helpmain" className="text-bold pink-color"> <Trans i18nKey="TermsandConditions"><span className="floating-label">Terms and Conditions</span> </Trans> </NavLink></span></div>

				</div> 




			</div>

		);
	}

}




export default withTranslation("translations")(Welcome);

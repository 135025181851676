import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import SideBar from "../main/SideBar";
import SideNav from "react-simple-sidenav";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Swiper from 'react-id-swiper';

class Explore extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showNav3: false,
            peopleDetails: [
                {
                    "name": "Milly Bobby",
                    "image": require("../../assets/img/f1.jpg"),
                    "age": 21,
                    "location": "Madurai - 07, Tamilnadu, India",
                },
                {
                    "name": "Jessica",
                    "image": require("../../assets/img/pic3.png"),
                    "age": 21,
                    "location": "Madurai - 07, Tamilnadu, India",
                },
                {
                    "name": "Emma",
                    "image": require("../../assets/img/gri2.jpg"),
                    "age": 23,
                    "location": "Chennai - 27, Tamilnadu, India",
                },
                {
                    "name": "Abinaya",
                    "image": require("../../assets/img/gri3.jpg"),
                    "age": 29,
                    "location": "Dubai",
                },
                {
                    "name": "Alexandria",
                    "image": require("../../assets/img/f2.jpg"),
                    "age": 24,
                    "location": "Coimbatore",
                },
                {
                    "name": "Saron Elizabeth",
                    "image": require("../../assets/img/new2.jpg"),
                    "age": 21,
                    "location": "Coimbatore",
                },
                {
                    "name": "Lisa Thomson",
                    "image": require("../../assets/img/new3.jpg"),
                    "age": 21,
                    "location": "Coimbatore",
                },
                {
                    "name": "Lisa Thomson",
                    "image": require("../../assets/img/profile1.jpg"),
                    "age": 21,
                    "location": "Coimbatore",
                },
                {
                    "name": "Lisa Thomson",
                    "image": require("../../assets/img/profile2.jpg"),
                    "age": 21,
                    "location": "Coimbatore",
                },
                {
                    "name": "Lisa Thomson",
                    "image": require("../../assets/img/profile3.jpg"),
                    "age": 21,
                    "location": "Coimbatore",
                },
            ],
            items: [{
                "number": "180",
                "days": "Days",
                "amount": "$15.0",
            },

            {
                "number": "120",
                "days": "Days",
                "amount": "$15.0",
                "offer": "Save 23%",
            },
            {
                "number": "150",
                "days": "Days",
                "amount": "$15.0",
            },
            {
                "number": "120",
                "days": "Days",
                "amount": "$15.0",
            },
            {
                "number": "10",
                "days": "Days",
                "amount": "$15.0",
            },
            {
                "number": "100",
                "days": "Days",
                "amount": "$15.0",
            },
            {
                "number": "180",
                "days": "Days",
                "amount": "$15.0",
            }

            ],


        }
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    handleItemClick(index) {
        this.setState({
            activeItem: index,
        })
    }
    render() {
        const params = {
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            },
            spaceBetween: 1
        }

        const params2 = {
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev'
            },  
            slidesPerView: 3,
            slidesPerColumn: 1,
            breakpoints: {
                360: {
                    slidesPerView: 3,
                },
                359: {
                    slidesPerView: 2,
                }
            }
        };
        return (

            <div className="setting-page">



                <div className="d-flex profile-header-top stickyProfileHead justify-content-between apd15 apr0 apl0 white-bg ">
                    <div class="heading-left-top d-flex">
                        <div className="align-self-center ">
                            <div className="iconHolder align-self-center mr-1">
                                <div className="backGlow  d-block d-lg-none">
                                    <div className="sideBarToggler sidebarIcoPos" onClick={() => this.setState({ showNav3: true })}></div>
                                </div>
                            </div>
                        </div>
                        <span class="arrow-center align-self-center ml-1 bold-font">Explore</span>
                    </div>
                    <div className="backGlow white-bg"></div>
                </div>



                <div className="peopleShowHolder">
                    <div className="row">
                        {this.state.peopleDetails.map((peopleDetails) =>
                            <div className="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-3 col-Resp">
                                <div className="peopleSpaceHolder">
                                    <div className="peopleViewHolder">
                                        <div className="peoplePic">
                                            <NavLink to="/app/Visitors/VisitorNew">
                                                <img src={peopleDetails.image} alt="" className="" />
                                            </NavLink>
                                            <div className={peopleDetails.online === "1"? "onlineN":"none"}></div>
                                        </div>
                                        <div className="apd10">
                                            <div className="media">
                                                <div className="media-body pFHidden align-self-center">
                                                    <NavLink to="/app/Visitors/VisitorNew">  <div className="text-truncate bold-font BtoRcolor apk-16">{peopleDetails.name}</div>  </NavLink>
                                                    <NavLink to="/app/Visitors/VisitorNew">  <div className="text-truncate secondaryText esmh-14 regular-font">{peopleDetails.location}</div>  </NavLink>
                                                </div>
                                                <div className="ml-1">
                                                    {peopleDetails.age}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>


                <div className="becomePremiumVisitorPage d-flex flex-column align-items-center mt-3 mb-3">
                    <div className="apk-30 bold-font mb-2">Find unlimited people</div>
                    <button className="btn-common" onClick={this.toggle}>Become a Premium Member</button>
                </div>
                {/* Premium Modal*/} 

                <Modal isOpen={this.state.modal} toggle={this.toggle} className="premiumPopup vertically-center apd5">
                    <ModalHeader toggle={this.toggle}><h4 class="modal-title">Join Premium</h4></ModalHeader>
                    <ModalBody>
                        <div className="premiumPop">
                            <div className="premiumImgs">
                                <Swiper {...params}>
                                    <div>
                                        <div className="premiumImgHolder">
                                            <img src={require("../../assets/img/sub-1.png")} alt="" className="img-fluid" />
                                        </div>
                                        <div className="premiumInfoHolder">
                                            <div className="bold-font">Change Your Location</div>
                                            <div className="premiumInfoSubText  secondaryText esmh-14 ">Lorem Ipsum passages, and more recently with desktop
															publishing software like Aldus PageMaker including versions of Lorem Ipsum</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="premiumImgHolder">
                                            <img src={require("../../assets/img/sub-2.png")} alt="" className="img-fluid" />
                                        </div>
                                        <div className="premiumInfoHolder">
                                            <div className="bold-font">Change Your Location</div>
                                            <div className="premiumInfoSubText  secondaryText esmh-14 ">Lorem Ipsum passages, and more recently with desktop
															publishing software like Aldus PageMaker including versions of Lorem Ipsum</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="premiumImgHolder">
                                            <img src={require("../../assets/img/sub-3.png")} alt="" className="img-fluid" />
                                        </div>
                                        <div className="premiumInfoHolder">
                                            <div className="bold-font">Change Your Location</div>
                                            <div className="premiumInfoSubText  secondaryText esmh-14 ">Lorem Ipsum passages, and more recently with desktop
															publishing software like Aldus PageMaker including versions of Lorem Ipsum</div>
                                        </div>
                                    </div>
                                </Swiper>
                            </div>
                            <div className="premiumPopPlans">
                                <Swiper {...params2}>
                                    {this.state.items.map((item, index) =>
                                        <div className={this.state.activeItem === index ? 'navigation--active' : ''} onClick={this.handleItemClick.bind(this, index)}>
                                            <div className="premiumPrice">
                                                <div className="apd10 daysPrice">
                                                    <div className="bold-font apk-30">{item.number}</div>
                                                    <div className=" bold-font apk-24 mt-n2">{item.days}</div>
                                                </div>
                                                <div className="apd5 apk-16 mini-price">{item.amount}</div>
                                            </div>
                                        </div>)}

                                </Swiper>
                            </div>


                            <div className="sidebarLastContent text-center">
                                <button className="btn-common btn-block">Continue</button>
                            </div>


                        </div>
                    </ModalBody>
                </Modal>


                <div className="sidebarResp d-block d-lg-none">
                    <SideNav showNav={this.state.showNav3} onHideNav={() => this.setState({ showNav3: false })} title={<div></div>} items={[<SideBar />]} />
                </div>

            </div>

        );
    }
}


export default Explore;
import React from 'react'; 
import { Trans, withTranslation } from "react-i18next";
import "../../config/i18n";

const NotFound = () =>
  <div className="notfound-txt">
    <h3><Trans i18nKey="404 page not found">404 page not found</Trans></h3>
    <p>
    	<Trans i18nKey="We are sorry but the page you are looking for does not exist.">
    		We are sorry but the page you are looking for does not exist.
    	</Trans> 
    </p>
  </div>

export default withTranslation("translations")(NotFound); 
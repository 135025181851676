import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { Trans, withTranslation } from "react-i18next";
import "../../config/i18n"; 
 
import ls from "local-storage"; 
import { options } from "../../config/options"; 

import SideNav from "react-simple-sidenav";
import SideBar from "../main/SideBar";
import axios from 'axios';
import Loader from 'react-loader-spinner';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';


import ReactHtmlParser from 'react-html-parser';

class Help extends Component {
    constructor(props) {
        super(props);
        this.state = {
            created_at : "",
            clientToken: null, api_url: localStorage.getItem('api_url'),
            subscription_valid_web:"",
            price: "",
            currency: "",  
            status: "",
            autorenewal:"",
            subModal : false,
            subpopup : false,
            isSubscribed : "false",
            "subscriptionamount" : "",
            "noofdays" : ""
        }

        this.showModal = this.showModal.bind(this);
        this.closeModal = this.closeModal.bind(this);

        this.showsubpopup = this.showsubpopup.bind(this);
        this.closesubpopup = this.closesubpopup.bind(this);


        this.cancelAutoRenewal = this.cancelAutoRenewal.bind(this);
    }
    cancelAutoRenewal(){
        var details = new FormData();
            details.append('user_id', JSON.parse(localStorage.getItem('session_userid')));      
            axios.defaults.headers.common['Authorization'] = JSON.parse(localStorage.getItem('session_token'));
            axios({
                method: 'post',    
                url: this.state.api_url+'cancelAutorenewal', 
                data: details,
                config: { headers: {'Content-Type': 'multipart/form-data' }}
            }).then(res => {               
                if(res.data.status == "true"){
                this.setState({                    
                    autorenewal : "no"                               
                });
            }
            }).catch(error => {
                this.props.history.push("/app/NotFound");
            });  
            this.setState({
                subModal: false
            });
    }
    showModal() {
        this.setState(prevState => ({
            subModal: !prevState.subModal
        }));
      }
      closeModal() {
        this.setState({
            subModal: false
        });
      }

      showsubpopup() {
        this.setState(prevState => ({
            subpopup: !prevState.subModal
        }));
      }
      closesubpopup() {
        this.setState({
            subpopup: false
        });
      }
        
    componentDidMount(){
        this.setState({
            loader:'loder-icon'
        });
        setTimeout(() => {
            this.setState({
                profilespace: 'content-wrapper homepg resp-width100 float-left',
                loader: 'none'
            });
          }, 2000);         

          axios({
            method: 'get',
            url: this.state.api_url+'subscriptionDetails',                
            
          }).then(res => {
            const data = res.data;
            console.log(data);
            this.setState({                         
                    currency: data.currency,
                    subscriptionamount: data.price,
                    noofdays: data.noofdays,
                  });
                  localStorage.setItem('subscription_price',JSON.stringify(this.state.price));
                  localStorage.setItem('currency',JSON.stringify(this.state.currency));
                  localStorage.setItem('noofdays',JSON.stringify(this.state.noofdays));
             
          });

          
       
            var details = new FormData();
            details.append('user_id', JSON.parse(localStorage.getItem('session_userid')));      
            axios.defaults.headers.common['Authorization'] = JSON.parse(localStorage.getItem('session_token'));
            axios({
                method: 'post',    
                url: this.state.api_url+'getSubscribedDetails', 
                data: details,
                config: { headers: {'Content-Type': 'multipart/form-data' }}
            }).then(res => { 
              
                if(res.data.status == "true"){
                    this.setState({ 
                        created_at: res.data.result.lastactivated,
                        subscription_valid_web: res.data.result.subscription_valid_web,
                        price: res.data.result.price,
                        currency: res.data.result.currency,  
                        status: res.data.result.status ,
                        autorenewal : res.data.result.autorenewal,    
                        isSubscribed : "true"                           
                    });
                }
     
                
            }).catch(error => {
                this.props.history.push("/app/NotFound");
            });  
        
       
    
    }

    render() {
        var style = {          
                       
            zIndex:10,
            backgroundColor:"white",
            position:"absolute",
            height:"80vh",
            width:"100%",
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
            //width:"100%",
            overflowY:"hidden",
            clsName:""
          };

          

          let statusTxt; 
          if(this.state.status == 'active'){
            statusTxt = "align-self-center bold-font text-truncate mx-3 activated";
          }else{
            statusTxt = "sucErralign-self-center bold-font text-truncate mx-3or expired";      
          }

         // alert(localStorage.getItem('subscribed'));       
        //alert(this.state.isSubscribed);
         let actBtn;
          if(this.state.isSubscribed == "false" ){
            actBtn = <div className="becomePremiumVisitorPage d-flex flex-column align-items-center mt-temp" style={{width:"100%"}}>
            <div className="apk-30 bold-font mb-2" ><Trans i18nKey="Activate your account">Activate your account</Trans></div> 
            <button className="btn-subscribe" onClick={this.showsubpopup}><Trans i18nKey="Subscribe">Subscribe</Trans></button> ;                                                     
        </div>
          }else{

            let cancelautorenbtn;
            if(this.state.status == 'active' && this.state.autorenewal == 'yes'){
               cancelautorenbtn = <div className="d-flex flex-column align-items-center mt-3">
              <button className="btn-common" onClick={this.showModal}><Trans i18nKey="Cancel Auto Renewal">Cancel Auto Renewal</Trans></button>
             </div>
            }else if(this.state.status == 'expired'){
               cancelautorenbtn = <div className="d-flex flex-column align-items-center mt-3">
               <button className="btn-common" onClick={this.showsubpopup}><Trans i18nKey="Subscribe">Subscribe</Trans></button> 
               </div>
            }

            actBtn = <div className="ProfileSpace apt20 apk-16">                                              

            <div className="d-flex justify-content-between apd20 apl0 apr0 border-bottom pointCurs">
                <div className="gray-color  bold-font"><Trans i18nKey="lastactivateddate">Last Activated Date</Trans> </div>
                <div className="d-flex overflow-hidden">
                    <div className="align-self-center bold-font text-truncate mx-3">
                    <Trans>{this.state.created_at}</Trans></div>                                                       
                </div>
            </div>

            <div className="d-flex justify-content-between apd20 apl0 apr0 border-bottom pointCurs">
                <div className="gray-color  bold-font"><Trans i18nKey="expireddate">Expired date</Trans> </div>
                <div className="d-flex overflow-hidden">
                    <div className="align-self-center bold-font text-truncate mx-3">
                    <Trans>{this.state.subscription_valid_web}</Trans></div>                                                       
                </div>
            </div>
            
            <div className="d-flex justify-content-between apd20 apl0 apr0 border-bottom pointCurs">
                <div className="gray-color  bold-font"><Trans i18nKey="priceinfo">Price information</Trans> </div>
                <div className="d-flex overflow-hidden">
                    <div className="align-self-center bold-font text-truncate mx-3">
                    <Trans>{this.state.currency}{this.state.price}</Trans></div>                                                       
                </div>
            </div>

            <div className="d-flex justify-content-between apd20 apl0 apr0 border-bottom pointCurs">
                <div className="gray-color  bold-font"><Trans i18nKey="status">Status</Trans> </div>
                <div className="d-flex overflow-hidden">
                    <div className={statusTxt}>
                    <Trans>{this.state.status}</Trans></div>                                                       
                </div>
            </div>
            {cancelautorenbtn}           
            </div>
            
          }

         
        
        return (
            <div className="">
                <div className="Help-page">
                    <div className="help-bg">

                        <div className="d-flex profile-header-top  justify-content-between apd15 apr0 apl0 white-bg stickypos">
                            <div class="heading-left-top d-flex">
                                <div className="align-self-center ">
                                    <div className="iconHolder align-self-center">
                                        <div className="backGlow d-flex d-lg-none">
                                            <div className="sideBarToggler sidebarIcoPos" onClick={() => this.setState({ showNav3: true })}></div>
                                        </div>
                                    </div>
                                </div>
                                <span class="arrow-center align-self-center bold-font ml-1">
                                    <Trans i18nKey="Subsmngepg">Subscription Management Page</Trans>
                                </span>
                            </div>
                            <div className="align-self-center  d-block d-lg-none mr-5"><img src={require('../../assets/img/logo-c-64.png')} className="sideLogo commonLogoC" alt="logo" /></div>
                            <div className="backGlow"></div>
                        </div>

                        <div className="container-fluid">
                        <div className={this.state.loader}>
                        <div style={style} id="loader">
                            <Loader type="Bars" color="#000000" height={30} width={50} />
                        </div>
                    </div>

                <div className={this.state.profilespace}>
                                <div className="right-bg white-bg">
                                    <div className="secondary_color white-bg sma-16 arm0">
                                        <div className="help-content apd20 arp0">
                                            <div className="container">

                                                <div className="row">
                                                
                                                    {actBtn}                                                   
                                                    
                                                </div>
                                            </div>

                                        </div>
                                    </div>


                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <Modal isOpen={this.state.subModal} toggle={this.closeModal} className="premiumPopup vertically-center apd5">
                    <ModalHeader toggle={this.closeModal}><h4 class="modal-title"><Trans i18nKey="Are you sure ?"><span className="floating-label">Are you sure ?</span> </Trans></h4></ModalHeader>
                    <ModalBody>
                        <div className="modal-body">
                           <div class="d-flex justify-content-end">
                            <div class="mr-3"><button class="border-0 bg-white pink-color" onClick={this.cancelAutoRenewal}><Trans i18nKey="Yes"><span className="floating-label">Yes</span> </Trans></button></div>
                            <div><button class="border-0 bg-white" onClick={this.closeModal}><Trans i18nKey="No"><span className="floating-label">No</span> </Trans></button></div>
                           </div>
                        </div>
                    </ModalBody>
                </Modal>

        <Modal isOpen={this.state.subpopup}  toggle={this.closesubpopup} className="premiumPopup vertically-center apd5">
          <ModalHeader toggle={this.closesubpopup}>            
          </ModalHeader>  
          <ModalBody>    
          <div>                         
            <div className="premiumInfoHolder reducemargin">
                <div className="bold-font">
                <h4 >
                <Trans i18nKey=">Activate your account">Activate your account</Trans> 
                </h4>
                </div>
                <div className="premiumInfoSubText  secondaryText esmh-14 ">
                    <Trans i18nKey="subscribeTextSuper">Get unlimited access nearby friends, likes, superlike, chat, etc</Trans>
                </div>
            </div>
            
        </div>     
        <div className="premiumPopPlans subscribeprice">
            <div class="swiper-slide navigation--active swiper-slide-active" style={{width:"152px"}}>
        <div class="premiumPrice" ><div class="apd10 daysPrice"><div class="bold-font apk-30"  style={{color:"black"}}>{this.state.noofdays}</div><div class=" bold-font apk-24 mt-n2" style={{color:"black"}}><Trans i18nKey="Days">Days</Trans></div></div><div class="apd5 apk-16 mini-price">{this.state.currency}{this.state.subscriptionamount}</div></div>
        </div>
        </div>
        <div className="sidebarLastContent text-center">
              <NavLink to="/app/SubscribePayment"> <button className="btn-common btn-block" onClick={this.closeModal.bind(this, 'modal4')}><Trans i18nKey="Subscribe">Subscribe</Trans></button></NavLink>  
        </div>
          </ModalBody>
        </Modal>
            
                <div className="sidebarResp d-block d-lg-none">
                    <SideNav showNav={this.state.showNav3} onHideNav={() => this.setState({ showNav3: false })} title={<div></div>} items={[<SideBar  showSubPop="false" />]} />
                </div>
            </div>);
    }
}
  
export default withTranslation("translations")(Help);  
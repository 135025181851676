import React, { Component } from 'react';
import { Trans, withTranslation } from "react-i18next";
import "../../config/i18n";

import { NavLink } from "react-router-dom";
import SideBar from "../main/SideBar";
import SideNav from "react-simple-sidenav";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Swiper from 'react-id-swiper';
import axios from 'axios';
import Admob from "../help/Admob";
import Loader from 'react-loader-spinner';

import ls from "local-storage"; 
import { options } from "../../config/options";   

class Message extends Component {
    constructor(props) {
        super(props);
        this.msgFilter = this.msgFilter.bind(this);
        this.msgSearch = this.msgSearch.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.state = {
            showNav3: false,cur_sort:'',
            modal: false,appof_day:[],appof_image: [],msg_search:'',api_url:localStorage.getItem('api_url'),
            lang: ls.get('LangKeySet') || options[0],
            langKey: ls.get('LangValue'),   
            subscribed:"false",openModal:false,
            messagers: [],clas1:'',clas2:'',clas3:'',clas4:'',clas5:'',txt1:'',txt2:'',fav_class:'messages-flex fav-notifier',limit:8,loadmore:'none'
        }
        this.toggle = this.toggle.bind(this);
    }
    closeModal(tabId) {
      this.setState({
          [tabId]: false
      });
  }

  showModal() {
      this.setState(prevState => ({
          openModal: !prevState.openModal
      }));
    }
    componentDidMount(){
        this.setState({
            loader:'loder-icon'
            });

            let data = new FormData();
            data.append('user_id', JSON.parse(localStorage.getItem('session_userid')));  
            //data.append('language', this.state.langKey); 
            axios.defaults.headers.common['Authorization'] = JSON.parse(localStorage.getItem('session_token')); 
            axios({
                method: 'post',
                url: this.state.api_url+'subscriptionStatus',
                data: data, 
                config: { headers: {'Content-Type': 'multipart/form-data' }}
            }).then(res => {
              
              if(res.data.subscribed == 0 || res.data.subscribed_status == "expired") {

                setTimeout(() => {
                  axios({
                      method: 'get',
                      url: this.state.api_url+'subscriptionDetails',                
                      
                    }).then(res => {
                      const data = res.data;
                      console.log(data);
                      this.setState({                         
                              currency: data.currency,
                              price: data.price,
                              noofdays: data.noofdays,                             
                              profilespace: 'none',     
                              loader: 'none',
                              subscribed: "false" 
                              
                            });
      
                            setTimeout(() => {
                              this.setState({
                                  openModal: true
                                            
                              }); 
                          }, 100); 
      
                            localStorage.setItem('subscription_price',JSON.stringify(this.state.price));
                            localStorage.setItem('currency',JSON.stringify(this.state.currency));
                            localStorage.setItem('noofdays',JSON.stringify(this.state.noofdays));
                       
                    });
              }, 200);                
               
              }else{
                setTimeout(() => {
                  this.setState({
                      profilespace: '',
                      loader: 'none',
                      subscribed : "true"
                  });
                }, 100);
              }
            })

           

        var app = new FormData();
        app.set('language', this.state.langKey);  

        axios({
          method: 'post',
          url: this.state.api_url+'appoftheday',
          data: app, 
          config: { headers: {'Content-Type': 'multipart/form-data' }}
        }).then(res => {     
              const appof_day = res.data.result;
			         this.setState({ 
                  appof_day,
                  appof_image: appof_day.images,

                });
		    }) 
        var message = new FormData();
        message.set('user_id', JSON.parse(localStorage.getItem('session_userid')));
        message.set('search_key', '');
        message.set('sort', '');
        message.set('timestamp', '');
        message.set('limit', this.state.limit);
        message.set('language', this.state.langKey); 
        axios({
          method: 'post',
          url: this.state.api_url+'webgetchat',
          data: message,
          config: { headers: {'Content-Type': 'multipart/form-data' }}
            })
            .then(res => {
              const messagers = res.data.matches;
              // console.log("message",res.data.matches);
              const tmp_limit = this.state.limit;
              // console.log("tmp_limit",tmp_limit);
              // console.log("total",res.data.total);
              if(res.data.status !== 'false'){
                const totalchats = res.data.total;
              this.setState({
                messagers: messagers,
                limit: this.state.limit + 8
            });
              // console.log("limit",tmp_limit+"@"+this.state.limit+"@"+messagers.length);
              //if(tmp_limit  === messagers.length + 1){
              if(tmp_limit  < totalchats){
                this.setState({ loadmore: 'loadMor btn-common'});
              }
            }else{
                this.setState({ clas1: 'noItemsContainers',clas2:'messagesNull',clas3:'nullMessage nullSize imgW',clas4:'text-dark bold-font text-center apk-20',clas5:'secondaryText apk-16 regular-font text-center',txt1:'No Message',txt2:'Start the chat!' });
            }
            });
            this.scrollToBottom();    
      }
      componentDidUpdate() {
        //this.scrollToBottom();
      }
      loadMore(){
        
        var app = new FormData();
        app.set('language', this.state.langKey);  

        axios({
          method: 'post',
          url: this.state.api_url+'appoftheday',
          data: app, 
          config: { headers: {'Content-Type': 'multipart/form-data' }}
        }).then(res => { 
            const appof_day = res.data.result;
            // console.log("appofthe_day",appof_day);
			 this.setState({ appof_day,appof_image: appof_day.images});
		})
        var message = new FormData();
        message.set('user_id', JSON.parse(localStorage.getItem('session_userid')));
        var searchtxt = document.getElementById('search-txt').value; 
        if(searchtxt)
        message.set('search_key', searchtxt);
        else 
        message.set('search_key', '');
        message.set('sort', '');
        message.set('timestamp', '');
        message.set('limit', this.state.limit);
        message.set('language', this.state.langKey); 
        axios({
          method: 'post',
          url: this.state.api_url+'webgetchat',
          data: message,
          config: { headers: {'Content-Type': 'multipart/form-data' }}
            })
            .then(res => {
              const messagers = res.data.matches;
              // console.log("message",res.data); 
              const tmp_limit = this.state.limit;
              if(res.data.status !== 'false'){
                const totalchats = res.data.total;
              this.setState({
                messagers: messagers,
                limit: this.state.limit + 8
            });
            //if(tmp_limit  === messagers.length + 1){
              if(tmp_limit  < totalchats){
                this.setState({ loadmore: 'loadMor btn-common'});
              }else{
                this.setState({ loadmore: 'none'}); 
              }
            }else{
                this.setState({ clas1: 'noItemsContainers',clas2:'messagesNull',clas3:'nullMessage nullSize imgW',clas4:'text-dark bold-font text-center apk-20',clas5:'secondaryText apk-16 regular-font text-center',txt1:'No Message',txt2:'Start the chat!' });
            }
            });
            //this.scrollToBottom();
       
    }
    scrollToBottom = () => {
        this.messagesEnd.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
     }
      msgFilter(e){
         // alert(e.target.value);

          var message = new FormData();
          message.set('user_id', JSON.parse(localStorage.getItem('session_userid')));
          message.set('search_key', '');
          message.set('sort', e.target.value);
          message.set('language', this.state.langKey); 
          this.setState({ cur_sort:e.target.value });
          message.set('timestamp', '');
                  axios({
                    method: 'post',
                    url: this.state.api_url+'webgetchat',
                    data: message,
                    config: { headers: {'Content-Type': 'multipart/form-data' }}
                      })
                      .then(res => {
                        const messagers = res.data.matches;
                        // console.log("message_filter",res.data.matches); 
                        if(res.data.status !== 'false'){
                             this.setState({ messagers,clas1: '',clas2:'',clas3:'',clas4:'',clas5:'',txt1:'',txt2:''});
                      }else{
                          this.setState({ messagers:[],clas1: 'noItemsContainers',clas2:'messagesNull',clas3:'nullMessage nullSize imgW',clas4:'text-dark bold-font text-center apk-20',clas5:'secondaryText apk-16 regular-font text-center',txt1:'No Message',txt2:'Start the chat!'});
                      }
                      });
              
      }
      msgSearch(e){
         //alert(e.target.value);
         const tmp = e.target.value;
         this.setState({msg_search:tmp});
        if(e.target.value != null){
         var message = new FormData();
         message.set('user_id', JSON.parse(localStorage.getItem('session_userid')));
         message.set('search_key', e.target.value);
         message.set('sort', '');
         message.set('timestamp', '');
         message.set('language', this.state.langKey); 
                 axios({
                   method: 'post',
                   url: this.state.api_url+'webgetchat',
                   data: message,
                   config: { headers: {'Content-Type': 'multipart/form-data' }}
                     })
                     .then(res => {
                      this.setState({ clas1: '',clas2:'',clas3:'',clas4:'',clas5:'',txt1:'',txt2:''});
                    
                       const messagers = res.data.matches;
                       // console.log("message_filter",res.data.matches);
                       const tmp_limit = 8;
                       if(res.data.status !== 'false'){
                          const totalchats = res.data.total;
                          // console.log("limit",tmp_limit+"@"+totalchats); 
              
                          if(tmp_limit  < totalchats){
                            this.setState({ loadmore: 'loadMor btn-common'});
                          }else{
                            this.setState({ loadmore: 'none'}); 
                          }

                           this.setState({ messagers});
                     }else{
                         this.setState({ messagers:[],clas1: 'noItemsContainers',clas2:'messagesNull',clas3:'nullMessage nullSize imgW',clas4:'text-dark bold-font text-center apk-20',clas5:'secondaryText apk-16 regular-font text-center',txt1:'No Message',txt2:'Start the chat!'});
                     }
                     });
                    }else{

                    }             
             
     }  
    favMsg(index,e){
      
        var message = new FormData();
        message.set('user_id', JSON.parse(localStorage.getItem('session_userid')));
        message.set('favorite_user_id', index);
        message.set('language', this.state.langKey); 
        axios({
          method: 'post',
          url: this.state.api_url+'webfavorite',
          data: message,
          config: { headers: {'Content-Type': 'multipart/form-data' }}
            })
            .then(res => {
                var message = new FormData();
                message.set('user_id', JSON.parse(localStorage.getItem('session_userid')));
                message.set('search_key', '');
                if(this.state.cur_sort){
                  message.set('sort', this.state.cur_sort);
                }else{
                  message.set('sort', '');
                }
                message.set('timestamp', '');
                message.set('language', this.state.langKey); 
                axios({
                  method: 'post',
                  url: this.state.api_url+'webgetchat',
                  data: message,
                  config: { headers: {'Content-Type': 'multipart/form-data' }}
                    })
                    .then(res => {
                      // const data = res.data;
                      // console.log("message",res.data); 
                      if(res.data.status !== 'false'){
                      this.setState({
                        messagers: res.data.matches,
                    });
                    }else{
                        this.setState({ clas1: 'noItemsContainers',clas2:'messagesNull',clas3:'nullMessage nullSize imgW',clas4:'text-dark bold-font text-center apk-20',clas5:'secondaryText apk-16 regular-font text-center',txt1:'No Message',txt2:'Start the chat!',messagers:[] });
                    }
                    });
            });
        
    }

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    render() {
        const { t } = this.props; 

        const params = {
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            },
            spaceBetween: 1
        }
        var style = {
            zIndex:10,
            backgroundColor:"white",
            position:"absolute",
            height:"80vh",
            width:"100%",
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
            //width:"100%",
            overflowY:"hidden"
          };
        return (

            <div className="setting-page">
                <div className="d-flex profile-header-top stickyProfileHead justify-content-between apd15 apr0 apl0 white-bg amb10 flex-column flex-md-row">
                    <div className="heading-left-top d-flex">
                        <div className="align-self-center ">
                            <div className="iconHolder align-self-center mr-1">
                                <div className="backGlow  d-block d-lg-none">
                                    <div className="sideBarToggler sidebarIcoPos" onClick={() => this.setState({ showNav3: true })}></div>
                                </div>
                            </div>
                        </div>
                        <select name="" id="" onChange={this.msgFilter} className="px-2 arrow-center align-self-center ml-1 bold-font border-0 topHeaderDrops">
                            <option value="0">{t("All Chats")}</option>   
                            <option value="unread">{t("Unread")}</option>
                            <option value="online">{t("Online")}</option> 
                            <option value="favourite">{t("Favorite")}</option> 
                        </select>
                    </div>

                    <div className="searchMessage">
                        <input type="text" onChange={this.msgSearch} value={this.state.msg_search} name="" id="search-txt" placeholder={t("Search")} />   
                    </div>

                </div>

              
                <div className={this.state.profilespace}>

                    <div className="messageSpace">

                      {(this.state.appof_day['app_settings'] === "enable") ?    
                        <div className="messagess">
                            <div className="media">
                                <div className="align-self-center mrgrl_spc"> 
                                    <img src={require("../../assets/img/app-of.png")} alt="" className="message-profile " />
                                </div>
                                <div className="media-body messages-flex" onClick={this.toggle}>
                                    <div className="text-truncate pink-color pointCurs">
                                      <Trans i18nKey="App of the day"><span className="floating-label">App of the day</span> </Trans>
                                    </div>
                                    <div className="messages-overview text-truncate secondaryText">
                                      <Trans i18nKey="What make a great app? Find here."><span className="floating-label">What make a great app? Find here.</span></Trans>
                                    </div>
                                </div>

                            </div>
                        </div>
                        : ""
                      }   

                    <div className={this.state.loader}>
                        <div style={style} id="loader">
                            <Loader type="Bars" color="#000000" height={30} width={50} />
                        </div>
                    </div>

                <div className={this.state.profilespace}>
                        {this.state.messagers.map((messagers) =>
                            <div className="messagess">
                                <div className="media">
                                    <div className="align-self-center mr-3 mrgrl_spc">
                                    {
                                      messagers.user_status === 2
                                      ?
                                        <img src={messagers.user_image} alt="" className="message-profile " />
                                      :
                                        <NavLink  to={`/app/Friends/FriendsNew/${messagers.user_id}`}>
                                        <img src={messagers.user_image} alt="" className="message-profile " /></NavLink>
                                    } 
                                    {/*<NavLink  to={`/app/Friends/FriendsNew/${messagers.user_id}`}>
                                    <img src={messagers.user_image} alt="" className="message-profile " /></NavLink>*/}
                                    <div className={messagers.online === 1 && messagers.block === 'false' ?"onlineN2":""}></div>
                                    </div>
                                    <div className="media-body messages-flex ">
                                        <NavLink  to={`/app/Message/MessageNew/${messagers.user_id}/${messagers.chat_id}`}>  <div className="text-truncate pointCurs">{messagers.user_name}</div> </NavLink>
                                        <NavLink to={`/app/Message/MessageNew/${messagers.user_id}/${messagers.chat_id}`}>     <div className="messages-overview text-truncate secondaryText">
                                            {messagers.imagename === '' || !('imagename' in messagers)  ? messagers.message : messagers.imagename }  </div> </NavLink>
                                    </div> 

                                    <div className="iconHolder align-self-center ml-1">
                                        <div className="backGlow">
                                            <div className={messagers.favorite === 'true' ?"messages-flex fav-notifier Star":"messages-flex fav-notifier"}  onClick={this.favMsg.bind(this, messagers.user_id)} value={messagers.user_id}></div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        )}
                        </div>
                        <div style={{ float:"left", clear: "both" }}
             ref={(el) => { this.messagesEnd = el; }}>
                     </div>
                    </div>
                </div> 



                <Modal isOpen={this.state.modal} toggle={this.toggle} className="premiumPopup appofDay vertically-center apd5">
                    <ModalHeader toggle={this.toggle}><h4 class="modal-title"><Trans i18nKey="App of the day"><span className="floating-label">App of the day</span> </Trans></h4></ModalHeader>
                    <ModalBody>
                        <div className="premiumPop">
                        <div class="wholecont">
                            <div className="premiumImgs">
                                <Swiper {...params}>
                                    {this.state.appof_image.map((appof_image) =>
                            <div>
                            <div className="appofDayImg">
                                <img src={appof_image} alt="" />
                            </div>
                              </div>
                        )}
                                </Swiper>
                            </div>

                            <div className="" style={{padding:'0px 15px'}}>

                                <div className="apk-30 bold-font appofDayTitle breakall">{this.state.appof_day['title']}</div>
                                <div className="apk-16" style={{padding: '0px 15px',height: '100%'}} > <pre className="pre21 regular-font">{this.state.appof_day['description']}</pre>
                                </div>  

                            </div> 
</div>     

                            <div className="sidebarLastContent text-center">
                                <a href={this.state.appof_day['view_url_web']} target="_blank" rel="noopener noreferrer">
                                  <button className="btn-common btn-block" >
                                    <Trans i18nKey="Open"><span className="floating-label">Open</span> </Trans>
                                  </button>
                                </a>  
                            </div>


                        </div>
                    </ModalBody>
                </Modal>

          <Modal isOpen={this.state.openModal} toggle={this.closeModal.bind(this, 'openModal')} className="premiumPopup vertically-center apd5">
          <ModalHeader toggle={this.closeModal.bind(this, 'openModal')}>            
          </ModalHeader>  
          <ModalBody>    
          <div>                         
            <div className="premiumInfoHolder reducemargin">
                <div className="bold-font">
                <h4 >
                <Trans i18nKey=">Activate your account">Activate your account</Trans> 
                </h4>
                </div>
                <div className="premiumInfoSubText  secondaryText esmh-14 ">
                    <Trans i18nKey="subscribeTextSuper">Get unlimited access nearby friends, likes, superlike, chat, etc</Trans>
                </div>
            </div>
            
        </div>     
        <div className="premiumPopPlans subscribeprice">
            <div class="swiper-slide navigation--active swiper-slide-active" style={{width:"152px"}}>
        <div class="premiumPrice" ><div class="apd10 daysPrice"><div class="bold-font apk-30"  style={{color:"black"}}>{this.state.noofdays}</div><div class=" bold-font apk-24 mt-n2" style={{color:"black"}}><Trans i18nKey="Days">Days</Trans></div></div><div class="apd5 apk-16 mini-price">{this.state.currency}{this.state.price}</div></div>
        </div>
        </div>
        <div className="sidebarLastContent text-center">
              <NavLink to="/app/SubscribePayment"> <button className="btn-common btn-block" onClick={this.closeModal.bind(this, 'modal4')}><Trans i18nKey="Subscribe">Subscribe</Trans></button></NavLink>  
        </div>
          </ModalBody>
        </Modal>

                <div className={this.state.clas1}>

                <div class={this.state.clas2}>
                    <div className={this.state.clas3}></div>
                    <div className={this.state.clas4}><Trans i18nKey={this.state.txt1}>{this.state.txt1}</Trans></div>  
                    
                </div>
              </div>


                <div className="sidebarResp d-block d-lg-none">
                    <SideNav showNav={this.state.showNav3} onHideNav={() => this.setState({ showNav3: false })} title={<div></div>} items={[<SideBar />]} />
                </div>
                <div className={this.state.loadmore} onClick={this.loadMore}> <Trans i18nKey="Load More !">Load More !</Trans></div> 
                <Admob></Admob>
            </div>

        );
    }
}
  
export default withTranslation("translations")(Message);  
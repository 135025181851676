import React, { Component } from 'react';
import { Trans } from "react-i18next";

import { NavLink } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Swiper from 'react-id-swiper';
import axios from 'axios';
import Cookies from 'universal-cookie';

import "../../config/i18n";
import { withTranslation } from "react-i18next"; 

import ls from "local-storage"; 
import { options } from "../../config/options";


class Settings extends Component { 
    constructor(props) {
        super(props);
        this.loggedIn = sessionStorage.getItem('loggedin');
        this.logout = this.logout.bind(this);
        this.delete = this.delete.bind(this);
        this.deactivate = this.deactivate.bind(this);
        this.onChangeagestatus = this.onChangeagestatus.bind(this);
        this.onChangelocationstatus = this.onChangelocationstatus.bind(this);
        this.onChangevisiblestatus = this.onChangevisiblestatus.bind(this);
        this.onChangehideadsstatus = this.onChangehideadsstatus.bind(this);
        this.onChangenewmsgstatus = this.onChangenewmsgstatus.bind(this);
        this.onChangelikesstatus = this.onChangelikesstatus.bind(this);
        this.profilechange = this.profilechange.bind(this);
        this.onChangepswd = this.onChangepswd.bind(this);
        this.onChangeold_pswd = this.onChangeold_pswd.bind(this);
        this.onChangenew_pswd = this.onChangenew_pswd.bind(this);
        this.onChangeretype_pswd = this.onChangeretype_pswd.bind(this);

        this.onChangefeedimagestatus = this.onChangefeedimagestatus.bind(this);
        this.onChangefeedlocationstatus = this.onChangefeedlocationstatus.bind(this);
        this.onChangefeedjobstatus = this.onChangefeedjobstatus.bind(this);
        this.onChangefeedbiostatus = this.onChangefeedbiostatus.bind(this);
        this.onChangefeedcompanystatus = this.onChangefeedcompanystatus.bind(this);
        this.onChangefeedschoolstatus = this.onChangefeedschoolstatus.bind(this); 
        
        this.state = {
            dd1: false,errorMsg:'',
            modal1: false,
            modal2: false,
            showNav3: false,
            user_details:'',
            items: [],errors:  {},
            peopleforid: '',api_url:localStorage.getItem('api_url'),
            agestatus:'',agestatus_check:'',
            location:'',location_check:'',
            visible:'',visible_check:'',
            hideads:'',hideads_check:'',
            newmsg:'',newmsg_check:'',
            likes:'',likes_check:'',
            old_pswd:'',new_pswd:'',retype_pswd:'',
            itemss: [],activeItems: '',
            user_id:'',
            selected_premium:'',suc_cls:'',
            currency:'',payment:'',license_key:'',premium_price:'',
            feed_image:'', feed_image_check:'',
            feed_location:'',feed_location_check:'',
            feed_job:'',feed_job_check:'',
            feed_bio:'',feed_bio_check:'',
            feed_company:'',feed_company_check:'',
            feed_school:'',feed_school_check:'',
            permium_superlikeCount: 0, 
            permium_boostCount: 0,  
            lang: ls.get('LangKeySet') || options[0],
            langKey: ls.get('LangValue'),   

        }
        this.dropdownToggle = this.dropdownToggle.bind(this);
    }
      addDefaultSrc(ev){
          ev.target.src =  require('../../assets/img/howzu-logo-default.png')
        }
    componentDidMount(){
        //  var userid = JSON.parse(localStorage.getItem('session_userid'));
        //  if(userid == '' || userid == null){
        //      alert("userid");
        //  }
        
        var datalist = new FormData();   
          datalist.set('user_id', JSON.parse(localStorage.getItem('session_userid'))); 
          datalist.set('language', this.state.langKey); 
          
          axios({
            method: 'post',
            url: this.state.api_url+'webprofile',
            data: datalist,    
            config: { headers: {'Content-Type': 'multipart/form-data' }}
              }).then(res => {
                if(res.data.status === 'true') {
                      const user_details = res.data.result;
                      //console.log("rrrrr",res.data.result.user_name,"rty",res.data.result.images);
                      this.setState({
                        user_details: user_details,
                        agestatus: user_details.show_age,
                        location: user_details.show_location,
                        visible: user_details.invisible,
                        hideads: user_details.hide_ads,
                        newmsg: user_details.message_notification,
                        likes: user_details.like_notification,
                        activeItem: user_details.people_for,
                        feed_image: user_details.feed_image,
                        feed_location: user_details.feed_location,
                        feed_job: user_details.feed_job,
                        feed_bio: user_details.feed_bio,
                        feed_company: user_details.feed_company,
                        feed_school: user_details.feed_school, 
                    });
                    if(this.state.agestatus === "true"){
                    this.setState({
                        agestatus_check: "true",    
                    });
                    }
                    if(this.state.location === "true"){
                        this.setState({
                            location_check: "true",    
                        });
                    }
                    if(this.state.visible === "true"){
                        this.setState({
                            visible_check: "true",    
                        });
                    }
                    if(this.state.hideads === "true"){
                        this.setState({
                            hideads_check: "true",    
                        });
                    }
                    if(this.state.newmsg === "true"){
                        this.setState({
                            newmsg_check: "true",    
                        });
                    }
                    if(this.state.likes === "true"){
                        this.setState({
                            likes_check: "true",    
                        });
                    }
                    if(this.state.feed_image === "true"){
                        this.setState({
                            feed_image_check: "true",    
                        });
                    }
                    if(this.state.feed_location === "true"){
                        this.setState({
                            feed_location_check: "true",    
                        });
                    }
                    if(this.state.feed_job === "true"){
                        this.setState({
                            feed_job_check: "true",    
                        });
                    }
                    if(this.state.feed_bio === "true"){
                        this.setState({
                            feed_bio_check: "true",    
                        });
                    }
                    if(this.state.feed_company === "true"){
                        this.setState({
                           feed_company_check: "true",    
                        });
                    }
                    if(this.state.feed_school === "true"){
                        this.setState({
                            feed_school_check: "true",      
                        });
                    }
                } else {
                    this.props.history.push("/login");   
                }
        }).catch(error => {
           this.props.history.push("/login"); 
        });
        
        axios.get(this.state.api_url+`admindatas`)
          .then(res => {
            const items = res.data.result.peoplefor;
            // console.log(res.data);
            this.setState({ items });
          })
          var premiumlist = new FormData();
          premiumlist.set('platform', 'web');
          premiumlist.set('language', this.state.langKey); 
          axios({
            method: 'post',
            url: this.state.api_url+'premiumlistweb',
            data: premiumlist,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
              })
              .then(res => {
                const data = res.data;
               // console.log("premium list",res.data);
                this.setState({
                  itemss: data.result,
                  activeItems: data.result[0].id,
                  selected_premium: data.result[0].id,
                  premium_price: data.result[0].price,
                  permium_superlikeCount: data.result[0].superlikecount, 
                  permium_boostCount: data.result[0].boostcount,   
                  currency: data.currency,
                  payment: data.payment,
                  license_key: data.license_key,
              });
              localStorage.setItem('selected_premium',JSON.stringify(this.state.selected_premium));
              localStorage.setItem('premium_price',JSON.stringify(this.state.premium_price));
              localStorage.setItem('currency',JSON.stringify(this.state.currency));
              });
                
    
      }
    handleInterestClick(id,e) {  
        this.setState({
            activeItem: id,
            peopleforid: e.target.alt,
            
        });
    }
    onChangeagestatus(e){
    if(this.state.user_details.premium_member === "true"){
        if(e.target.value === "false"){
            this.setState({
                agestatus: "true",
                agestatus_check: "true",    
            });
        }else{
            this.setState({
                agestatus: "false",
                agestatus_check: '',     
            });
        }
    }else{
        this.setState({
            modal2: true
        });

    }
    }
    onChangelocationstatus(e){
    if(this.state.user_details.premium_member === "true"){    
        if(e.target.value === "false"){
            this.setState({
                location: "true",
                location_check: "true"    
            });
        }else{
            this.setState({
                location: "false",
                location_check: '',     
            });
        }
    }else{
        this.setState({
            modal2: true
        });

    }    
    }
    onChangevisiblestatus(e){
    if(this.state.user_details.premium_member === "true"){    
        if(e.target.value === "false"){
            this.setState({
                visible: "true",
                visible_check: "true",    
            });
        }else{
            this.setState({
                visible: "false",
                visible_check: '',     
            });
        }
    }else{
        this.setState({
            modal2: true
        });

    } 
    }
    onChangehideadsstatus(e){
    if(this.state.user_details.premium_member === "true"){    
        if(e.target.value === "false"){
            this.setState({
                hideads: "true",
                hideads_check: "true",    
            });
        }else{
            this.setState({
                hideads: "false",
                hideads_check: '',     
            });
        }
    }else{
        this.setState({
            modal2: true
        });

    }    
    }
    onChangenewmsgstatus(e){
        if(e.target.value === "false"){
            this.setState({
                newmsg: "true",
                newmsg_check: "true",    
            });
        }else{
            this.setState({
                newmsg: "false",
                newmsg_check: '',     
            });
        }
    }
    onChangelikesstatus(e){
        if(e.target.value === "false"){
            this.setState({
                likes: "true",
                likes_check: "true",    
            });
        }else{
            this.setState({
                likes: "false",
                likes_check: '',     
            });
        }
    }

    onChangefeedimagestatus(e){
        if(e.target.value === "false"){
            this.setState({
                feed_image: "true",
                feed_image_check: "true",    
            });
        }else{
            this.setState({
                feed_image: "false",
                feed_image_check: '',     
            });
        }
    }

    onChangefeedlocationstatus(e){
        if(e.target.value === "false"){
            this.setState({
                feed_location: "true",
                feed_location_check: "true",    
            });
        }else{
            this.setState({
                feed_location: "false",
                feed_location_check: '',      
            });
        }
    }

    onChangefeedjobstatus(e){
        if(e.target.value === "false"){
            this.setState({
                feed_job: "true",
                feed_job_check: "true",    
            });
        }else{
            this.setState({
                feed_job: "false",
                feed_job_check: '',     
            });
        }
    }

    onChangefeedbiostatus (e){
        if(e.target.value === "false"){
            this.setState({
                feed_bio: "true",
                feed_bio_check: "true",    
            });
        }else{
            this.setState({
                feed_bio: "false",
                feed_bio_check: '',     
            });
        }
    }

    onChangefeedcompanystatus(e){
        if(e.target.value === "false"){
            this.setState({
                feed_company: "true",
                feed_company_check: "true",    
            });
        }else{
            this.setState({
                feed_company: "false",
                feed_company_check: '',     
            });
        }
    }

    onChangefeedschoolstatus (e){
        if(e.target.value === "false"){
            this.setState({
                feed_school: "true",
                feed_school_check: "true",    
            });
        }else{
            this.setState({
                feed_school: "false",
                feed_school_check: '',      
            });
        } 
    }

    profilechange(){
        let errors = {};
        var profile = new FormData();
        profile.append('show_age', this.state.agestatus);
        profile.append('show_location', this.state.location);
        profile.append('invisible', this.state.visible);
        profile.append('hide_ads', this.state.hideads);
        profile.append('message_notification', this.state.newmsg);
        profile.append('like_notification', this.state.likes);
        
        profile.append('feed_image', this.state.feed_image);
        profile.append('feed_location', this.state.feed_location);
        profile.append('feed_bio', this.state.feed_bio);
        profile.append('feed_job', this.state.feed_job);
        profile.append('feed_company', this.state.feed_company);
        profile.append('feed_school', this.state.feed_school);  
        
        profile.append('people_for', this.state.peopleforid);
        profile.append('user_id', JSON.parse(localStorage.getItem('session_userid')));
        profile.append('language', this.state.langKey);  

        axios.defaults.headers.common['Authorization'] = JSON.parse(localStorage.getItem('session_token'));
       
        axios({
            method: 'post',
            url: this.state.api_url+'websetprofile',
            data: profile,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
                 })
        .then(res => {
            const message = res.data.message;
           // console.log(res.data);
            errors["message1"] = message;
            this.setState({
                errors: errors,
                suc_cls: 'successMsg floatingAlerts1'
                });
                setTimeout(() => {
                    this.setState({
                        suc_cls: 'successMsghide'
                    });
                  }, 5000);    
            
            })
                
   
    }
    onChangeold_pswd(e){
        this.setState({
            old_pswd:e.target.value
        });
    }
    onChangenew_pswd(e){
        this.setState({
            new_pswd:e.target.value
        });
    }
    onChangeretype_pswd(e){
        this.setState({
            retype_pswd:e.target.value
        });
    }
    onChangepswd(e){
        e.preventDefault();
        let errors = {};
        if(this.state.old_pswd !== this.state.new_pswd){
        if(this.state.new_pswd === this.state.retype_pswd){
        var change_password = new FormData();
        change_password.set('user_id', JSON.parse(localStorage.getItem('session_userid')));
        change_password.set('old_password', this.state.old_pswd);
        change_password.set('new_password', this.state.new_pswd);
        change_password.set('language', this.state.langKey);  
            axios({
                method: 'post',
                url: this.state.api_url+'webchangepassword',
                data: change_password,
                config: { headers: {'Content-Type': 'multipart/form-data' }}
                            })
        .then(res => {
            const message = res.data.message;
            errors["changepswd"] = message;

            if(res.data.status === 'true'){
                errors["message1"] = message; 
                this.setState({
                    errors: errors,
                    modal1: false,
                    old_pswd: '',
                    new_pswd:'',
                    suc_cls: 'successMsg floatingAlerts1',
                    });
                    setTimeout(() => {
                        this.setState({
                            suc_cls: 'successMsghide'
                        });
                      }, 5000);   
            }
            this.setState({
                errors: errors,
                });
         })
        }else{
            errors["changepswd"] = "New Password and Re-type password must be same";
             
        }
    }else{
           errors["changepswd"] = "New Password and Old password must not be same";
    }
    this.setState({
        errors: errors,
        errorMsg:'errorMsg'
        });
        setTimeout(() => {
            this.setState({
                errorMsg: 'errorMsghide'
            });
          }, 5000); 
        this.setState({
            errors: errors,
            });   
    }      
    
    logout() {
        var loginstatus = new FormData();
            loginstatus.set('user_id', JSON.parse(localStorage.getItem('session_userid')));
            loginstatus.set('status', '0');
            loginstatus.set('language', this.state.langKey);  
            axios({
                    method: 'post',
                    url: this.state.api_url+'webonlinestatus',
                    data: loginstatus,
                   // config: { headers: {'Content-Type': 'multipart/form-data' }}
                    })
                    .then(res => {
                        const status = res.data.status;
                        if(status === 'true'){
                        const cookies = new Cookies();
                        cookies.set('onboarded', 'false', {path: "/"}); 
                        cookies.set('signuptab', false);
                        localStorage.setItem('session_token',JSON.stringify());
                        localStorage.setItem('session_userid',JSON.stringify());
                        this.props.history.push("/login")
                        //return false;
                        window.location.reload();

                        }
                        
                     })        

    }
    deactivate(){
       // alert("at");
        var deactivate = new FormData();
        //alert( JSON.parse(localStorage.getItem('session_userid')));
        deactivate.set('user_id', JSON.parse(localStorage.getItem('session_userid')));
        deactivate.set('language', this.state.langKey);   
        axios({
                method: 'post',
                url: this.state.api_url+'webdeleteaccount',
                data: deactivate,
                config: { headers: {'Content-Type': 'multipart/form-data' }}
                })
                .then(res => {
                    const status = res.data.status;
                    if(status === 'true'){
                        /*localStorage.setItem('session_token',JSON.stringify());
                        localStorage.setItem('session_userid',JSON.stringify());
                        this.props.history.push("/login")
                        window.location.reload();*/
                        const cookies = new Cookies();
                        var hosturl = localStorage.getItem('web_url');  
                        cookies.set('onboarded', 'false', {path: "/"}); 
                          cookies.set('signuptab', false);
                          localStorage.setItem('session_token',JSON.stringify());
                          localStorage.setItem('session_userid',JSON.stringify());
                          //this.props.history.push("/login")
                          hosturl = hosturl+"login"; 
                          window.location.assign(hosturl);

                        }
                 })  

    }

    delete() {
        var deleteData = new FormData();
        deleteData.set('user_id', JSON.parse(localStorage.getItem('session_userid')));
        deleteData.set('language', this.state.langKey);
        axios({
            method: 'post',
            url: this.state.api_url + 'anyDeleteuser',
            data: deleteData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
        .then(res => {
            const status = res.data.status;
            if (status === 'true') {
                const cookies = new Cookies();
                var hosturl = localStorage.getItem('web_url');
                cookies.set('onboarded', 'false', { path: "/" });
                cookies.set('signuptab', false);
                localStorage.setItem('session_token', JSON.stringify());
                localStorage.setItem('session_userid', JSON.stringify());
                hosturl = hosturl + "login";
                window.location.assign(hosturl);
            }
        })
    }


    handleItemClick(index) {
        this.setState({
          activeItems: index,
          selected_premium: index,
        })
        var premiumlist = new FormData();
        premiumlist.set('premiumid', index);
        premiumlist.set('language', this.state.langKey);      
        axios({
          method: 'post',
          url: this.state.api_url+'premiumid',
          data: premiumlist,
          config: { headers: {'Content-Type': 'multipart/form-data' }}
            })
            .then(res => {
              const data = res.data;
              this.setState({
                premium_price: data.result.price,
                permium_superlikeCount: data.result.superlikecount, 
                permium_boostCount: data.result.boostcount, 
            });
            });
        localStorage.setItem('selected_premium',JSON.stringify(this.state.selected_premium));
        localStorage.setItem('premium_price',JSON.stringify(this.state.premium_price));
      }
      onChangepack(e) {
        this.setState({
          selected_premium: e.target.value,
        })
    
      }


    dropdownToggle() {
        this.setState({
            dd1: !this.state.dd1
        });
    }
    closeModal(tabId) {
        this.setState({
            [tabId]: false
        });
    }
    showModal(modal) {
        this.setState({
            [modal]: true
        });
        // console.log(this.state); 
    }

    render() {
        const params = {
          pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
          },
          spaceBetween: 1,
          rebuildOnUpdate: true   
        }  

        const params2 = {
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev'
            },  
            slidesPerView: 3,
            slidesPerColumn: 1,
            breakpoints: {
                360: {
                    slidesPerView: 3,
                },
                359: {
                    slidesPerView: 2,
                }
            }
        };

        return (

            <div className="setting-page">

                <div className="d-flex profile-header-top stickyProfileHead justify-content-between apd15 apr0 apl0 white-bg amb10 ">
                    <div class="heading-left-top d-flex">
                        <div className="align-self-center ">
                            <NavLink to="/app/PeopleNear">
                                <div className="iconHolder align-self-center mr-1">
                                    <div className="backGlow">
                                        <div className="backIco"></div>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <span class="arrow-center align-self-center bold-font"><Trans i18nKey="Setting"><span className="floating-label">Setting</span> </Trans></span>
                    </div>
                    <div className="iconHolder align-self-center">
                        <div className="backGlow mr-2">
                            <button type="button" className="primary-color bold-font sma-16 setting-save bgT border-none apd0 amg0 "  onClick={this.profilechange}>
                                <Trans i18nKey="Save"><span className="floating-label">Save</span> </Trans> 
                            </button>
                        </div>
                    </div>
                </div>

                <div className="settingsPageWidth">
                
                    <div className="setFiltBox">
                        <div className="ProfileSpace">

                            <div className="bold-font apd10 apl0 apt0">
                                <Trans i18nKey="I am here to"><span className="floating-label">I am here to</span> </Trans> 
                            </div>

                            <div className="white-bg sma-16  settingInterestOverflow">


                                <div className="row justify-content-center justify-content-lg-start ">
                                {this.state.items.map((item, index) =>
                                <div className="">
                                    <div className="text-center" key={index} >
                                        <div className="iconimg apd15 apl20 apr20">
                                            <img onError={this.addDefaultSrc} src={item.icon} alt={item.id} className={this.state.activeItem === item.id ? 'navigation--active' : ''}
                                                onClick={this.handleInterestClick.bind(this, item.id)} value={item.id} />
                                            <h6 className="amb0 amt5" value={item.id}><Trans i18nKey={item.name}>{item.name}</Trans> </h6>
                                        </div>
                                    </div>
                                </div>
                                  )}
                                </div>

                            </div>

                        </div>

                        <div className="ProfileSpace">
                        <div className="bold-font apd10 apl0 apt20">
                            <Trans i18nKey="Dedicated profile space"><span className="floating-label">Dedicated profile space</span> </Trans>
                        </div>

                        <div className="sma-16 white-bg ">

                            <div class="d-flex justify-content-between apd10 apl0 apr0 apd10">
                                <div><Trans i18nKey="Don't show my age"><span className="floating-label">Don't show my age</span> </Trans></div>
                                
                                <div> <label className="switch amb0">
                               
                                    <input type="checkbox" id="agestatus"  value={this.state.agestatus} onChange={this.onChangeagestatus}  className="default" checked={this.state.agestatus_check}/>
                                    <span className="slider round"></span>
                                </label></div>
                            </div>

                            <div class="d-flex justify-content-between apd10 apl0 apr0">
                                <div><Trans i18nKey="Make my Location Invisible"><span className="floating-label">Make my Location Invisible</span> </Trans></div>
                                <div>        <label className="switch amb0">
                                    <input type="checkbox" id="location" value={this.state.location} onChange={this.onChangelocationstatus}  className="default" checked={this.state.location_check} />
                                    <span className="slider round"></span>
                                </label></div>
                            </div>


                            <div class="d-flex justify-content-between apd10 apl0 apr0">
                                <div><Trans i18nKey="Make me Invisible"><span className="floating-label">Make me Invisible</span> </Trans></div>
                                <div>        <label className="switch amb0">
                                    <input type="checkbox" value={this.state.visible} onChange={this.onChangevisiblestatus}  className="default" checked={this.state.visible_check} />
                                    <span className="slider round"></span>
                                </label></div>
                            </div>

                            <div class="d-flex justify-content-between apd10 apl0 apr0">
                                <div><Trans i18nKey="Hide Ads"><span className="floating-label">Hide Ads</span> </Trans></div>
                                <div>        <label className="switch amb0">
                                    <input type="checkbox"  value={this.state.hideads} onChange={this.onChangehideadsstatus}  className="default" checked={this.state.hideads_check}  />
                                    <span className="slider round"></span>
                                </label></div>
                            </div>
                        </div>
                    </div>

                    <div className="ProfileSpace">
                        <div className="bold-font apd10 apl0 apt20">
                        <Trans i18nKey="Notification settings ?"><span className="floating-label">Notification settings ?</span> </Trans>
                        </div>

                        <div className="sma-16 white-bg apd5">

                            <div class="d-flex justify-content-between apd10 apl0 apr0">
                                <div><Trans i18nKey="New messages"><span className="floating-label">New messages</span> </Trans></div>
                                <div> <label className="switch amb0">
                                    <input type="checkbox" className="default" value={this.state.newmsg} onChange={this.onChangenewmsgstatus} checked={this.state.newmsg_check} />
                                    <span className="slider round"></span>
                                </label></div>
                            </div>

                            <div class="d-flex justify-content-between apd10 apl0 apr0">
                                <div> <Trans i18nKey="Likes"><span className="floating-label">Likes</span> </Trans></div> 
                                <div>        <label className="switch amb0">
                                    <input type="checkbox" value={this.state.likes} onChange={this.onChangelikesstatus}  className="default" checked={this.state.likes_check} />
                                    <span className="slider round"></span>
                                </label></div>
                            </div>

                        </div>
                    </div>

                    <div className="ProfileSpace">
                        <div className="bold-font apd10 apl0 apt20"><Trans i18nKey="Feeds settings ?">Feeds settings ?</Trans></div>

                        <div className="sma-16 white-bg apd5">

                            <div class="d-flex justify-content-between apd10 apl0 apr0">
                                <div><Trans i18nKey="Feed Images">Feed Images</Trans> </div>  
                                <div> <label className="switch amb0">
                                    <input type="checkbox" className="default" value={this.state.feed_image} onChange={this.onChangefeedimagestatus} checked={this.state.feed_image_check} />
                                    <span className="slider round"></span>
                                </label></div>
                            </div>

                            <div class="d-flex justify-content-between apd10 apl0 apr0">
                                <div><Trans i18nKey="Feed Location">Feed Location</Trans> </div>
                                <div>        <label className="switch amb0">
                                    <input type="checkbox" value={this.state.feed_location} onChange={this.onChangefeedlocationstatus}  className="default" checked={this.state.feed_location_check} />
                                    <span className="slider round"></span>
                                </label></div>
                            </div>

                            <div class="d-flex justify-content-between apd10 apl0 apr0">
                                <div><Trans i18nKey="Feed Job">Feed job</Trans> </div>
                                <div>        <label className="switch amb0">
                                    <input type="checkbox" value={this.state.feed_job} onChange={this.onChangefeedjobstatus}  className="default" checked={this.state.feed_job_check} />
                                    <span className="slider round"></span>
                                </label></div>
                            </div>

                            <div class="d-flex justify-content-between apd10 apl0 apr0">
                                <div><Trans i18nKey="Feed Bio">Feed Bio</Trans> </div>
                                <div>        <label className="switch amb0">
                                    <input type="checkbox" value={this.state.feed_bio} onChange={this.onChangefeedbiostatus}  className="default" checked={this.state.feed_bio_check} />
                                    <span className="slider round"></span>
                                </label></div>
                            </div>

                            <div class="d-flex justify-content-between apd10 apl0 apr0">
                                <div><Trans i18nKey="Feed Company">Feed Company</Trans> </div> 
                                <div>        <label className="switch amb0">
                                    <input type="checkbox" value={this.state.feed_company} onChange={this.onChangefeedcompanystatus}  className="default" checked={this.state.feed_company_check} />
                                    <span className="slider round"></span>
                                </label></div>
                            </div>

                            <div class="d-flex justify-content-between apd10 apl0 apr0">
                                <div><Trans i18nKey="Feed School">Feed School</Trans> </div> 
                                <div>        <label className="switch amb0">
                                    <input type="checkbox" value={this.state.feed_school} onChange={this.onChangefeedschoolstatus}  className="default" checked={this.state.feed_school_check} />
                                    <span className="slider round"></span>
                                </label></div>
                            </div> 

                        </div>
                    </div>
                        <div className={this.state.suc_cls}><Trans i18nKey={this.state.errors.message1}>{this.state.errors.message1}</Trans></div>
                        <div className="ProfileSpace apt20 "><button className="setButton" onClick={this.showModal.bind(this, 'modal1')}>
                            <Trans i18nKey="Change Password"><span className="floating-label">Change Password</span> </Trans></button>
                        </div>
                        <div className="ProfileSpace apt10 "><button className="setButton" onClick={this.showModal.bind(this, 'modal4')}>
                            <Trans i18nKey="Logout"><span className="floating-label">Logout</span> </Trans></button>
                        </div>
                        <div className="ProfileSpace apt10 "><button className="setButton pink-color" onClick={this.showModal.bind(this, 'modal3')}>
                            <Trans i18nKey="Deactivate account"><span className="floating-label">Deactivate account</span> </Trans> 
                        </button></div> 
                        <div className="ProfileSpace apt10 "><button className="setButton pink-color" onClick={this.showModal.bind(this, 'modal5')}>
                            <Trans i18nKey="Delete account"><span className="floating-label">Delete account</span> </Trans>
                        </button></div>                  
                    </div>
                </div>








                {/* Change Password Popup */}
                <Modal isOpen={this.state.modal1} toggle={this.closeModal.bind(this, 'modal1')} className="premiumPopup vertically-center apd5">
                    <ModalHeader toggle={this.closeModal.bind(this, 'modal1')}><h4 class="modal-title">
                        <Trans i18nKey="Change Password"><span className="floating-label">Change Password</span> </Trans></h4>
                    </ModalHeader>
                    <ModalBody>
                    <div className="modal-body edit-profile-page">
                            <form className="" onSubmit={this.onChangepswd}>
                                <div className="user-input-wrp amb35">
                                    <br />
                                    <input className="inputText" id="old_pswd"  value={this.state.old_pswd} onChange={this.onChangeold_pswd} type="password" required />
                                    <span className="floating-label"><Trans i18nKey="Old Password">Old Password</Trans></span>
                                </div>
                                <div className="user-input-wrp amb35">
                                    <br />
                                    <input className="inputText" id="new_pswd"  value={this.state.new_pswd} onChange={this.onChangenew_pswd} type="password" required />
                                    <span className="floating-label"><Trans i18nKey="New password">New Password</Trans></span>
                                </div>

                                <div className="user-input-wrp amb35">
                                    <br />
                                    <input className="inputText" id="retype_pswd"  value={this.state.retype_pswd} onChange={this.onChangeretype_pswd} type="password" required />
                                    <span className="floating-label">
                                    <Trans i18nKey="Re-type new password">Re-type new password</Trans>
                                    </span>
                                </div>
                                <div className={this.state.errorMsg}><Trans i18nKey={this.state.errors.changepswd}>{this.state.errors.changepswd}</Trans> </div>  
                                <div className="premium"><button type="Submit" className="btn btn-bg width100 justify-content-center amg0">
                                    <Trans i18nKey="Save"><span className="floating-label">Save</span> </Trans>
                                </button></div>
                            </form>
                        </div>
                    </ModalBody>
                </Modal>




                {/* Premium Popup  */}
                <Modal isOpen={this.state.modal2} toggle={this.closeModal.bind(this, 'modal2')} className="premiumPopup vertically-center apd5">
                    <ModalHeader toggle={this.closeModal.bind(this, 'modal2')}><h4 class="modal-title"><Trans i18nKey="Join Premium">Join Premium</Trans></h4></ModalHeader>
                    <ModalBody>
                        <div className="premiumPop">
                            <div className="premiumImgs">
                                <Swiper {...params} shouldSwiperUpdate>  
                                 {this.state.permium_superlikeCount > 0 ?
                                  <div>
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sub_superlike.png")} alt="" className="img-fluid" />
                                      </div> 
                                      <div className="premiumInfoHolder">
                                          <div className="bold-font">
                                              <Trans i18nKey="Get">Get</Trans> {this.state.permium_superlikeCount} <Trans i18nKey="free super likes">free super likes</Trans>  
                                          </div>
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextSuper">Giving a Super like is a way to tell someone that you're especially interested in them</Trans>
                                          </div>
                                      </div>
                                  </div> : ''
                                 }

                                 {this.state.permium_boostCount > 0 ?
                                  <div>
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sub_boost.png")} alt="" className="img-fluid" />
                                      </div>
                                      <div className="premiumInfoHolder">  
                                          <div className="bold-font">
                                              <Trans i18nKey="Get">Get</Trans> {this.state.permium_boostCount} <Trans i18nKey="free boost">free boost</Trans>     
                                          </div>
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextBoost">Be the top profile in your area for 30minutes to get more matches, Keep Swipe right!</Trans>
                                          </div>
                                      </div>
                                  </div> : ''
                                 }
                  
                                  <div>
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sub_location.png")} alt="" className="img-fluid" />
                                      </div>
                                      <div className="premiumInfoHolder"> 
                                          <div className="bold-font">
                                              <Trans i18nKey="Change Location">Change Location</Trans>
                                          </div>
                                          <div className="premiumInfoSubText  secondaryText esmh-14 "> 
                                              <Trans i18nKey="premiumTextLocation">Get match and chat with others around the world by simply searching for a city</Trans>
                                          </div>
                                      </div>
                                  </div>

                                  <div> 
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sub_morelike.png")} alt="" className="img-fluid" />
                                      </div>
                                      <div className="premiumInfoHolder">
                                          <div className="bold-font">
                                              <Trans i18nKey="Unlimited Likes">Unlimited Likes</Trans>  
                                          </div>
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextUnlimited">Feel special again with 'Unlimited Likes'. Swipe right as much as you want!</Trans> 
                                          </div>
                                      </div>
                                  </div>


                                  <div>
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sud_add.png")} alt="" className="img-fluid" />
                                      </div>
                                      <div className="premiumInfoHolder"> 
                                          <div className="bold-font">
                                              <Trans i18nKey="Rewind">Rewind</Trans>
                                          </div>
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextRewind">Accidentally swiped on someone? Rewind lets you take back your last Like or Nope</Trans>
                                          </div>
                                      </div>
                                  </div>  

                                  <div> 
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sub_morelike.png")} alt="" className="img-fluid" />
                                      </div>
                                      <div className="premiumInfoHolder">
                                          <div className="bold-font">
                                              <Trans i18nKey="See who Likes you">See who Likes you</Trans>   
                                          </div> 
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextLikes">Likes You feature lets you see who likes you before you decide whether to Like or Nope</Trans> 
                                          </div>
                                      </div>
                                  </div>

                                  <div>
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sub_profile.png")} alt="" className="img-fluid" />
                                      </div>
                                      <div className="premiumInfoHolder">
                                          <div className="bold-font"> 
                                              <Trans i18nKey="Control your profile">Control your profile</Trans>   
                                          </div>  
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextProfile">Limit what others see and make parts of your profile information invisible to other people</Trans>
                                          </div>  
                                      </div>
                                  </div> 

                                  <div>
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sub_visited.png")} alt="" className="img-fluid" />
                                      </div>
                                      <div className="premiumInfoHolder">
                                          <div className="bold-font">
                                              <Trans i18nKey="See who visited your profile">See who visited your profile</Trans> 
                                          </div>
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextVisited">A Visitors feature lets you see who looked at your profile or ‘visited’ your profile
</Trans>
                                          </div>
                                      </div>
                                  </div>



                                  <div>
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sud_add.png")} alt="" className="img-fluid" />
                                      </div> 
                                      <div className="premiumInfoHolder">
                                          <div className="bold-font">
                                              <Trans i18nKey="Turn Off Ads">Turn Off Ads</Trans> 
                                          </div>
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextAds">Have fun swiping with uninterrupted ads</Trans>
                                          </div>  
                                      </div>
                                  </div> 

                              </Swiper> 
                            </div>    
                            <div className="premiumPopPlans">
                            <Swiper {...params2}>
                            {this.state.itemss.map((item, index) =>
                                <div className={this.state.activeItems === item.id ? 'navigation--active' : ''} onClick={this.handleItemClick.bind(this, item.id)} alt={item.id} value={item.id}>
                                <div className="premiumPrice">
                                    <div className="apd10 daysPrice">
                                    <div className="bold-font apk-30">{item.days}</div>
                                    <div className=" bold-font apk-24 mt-n2"><Trans i18nKey={item.type}>{item.type}</Trans></div>
                                    </div>
                                    <div className="apd5 apk-16 mini-price">{this.state.currency}{item.price}</div>
                                </div>
                                </div>)}

                            </Swiper>
                            </div>


                            <div className="sidebarLastContent text-center">
                            <NavLink to="/app/Payment"> <button className="btn-common btn-block"><Trans i18nKey="Continue">Continue</Trans></button></NavLink>
                            </div>


                        </div>
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.modal5} toggle={this.closeModal.bind(this, 'modal5')} className="premiumPopup vertically-center apd5">
                    <ModalHeader toggle={this.closeModal.bind(this, 'modal5')}><h4 class="modal-title"><Trans i18nKey="Are you sure ?"><span className="floating-label">Are you sure ?</span> </Trans></h4></ModalHeader>
                    <ModalBody>
                        <div className="modal-body">
                            <div class="d-flex justify-content-end">
                                <div class="mr-3"><button class="border-0 bg-white pink-color" onClick={this.delete}><Trans i18nKey="Yes"><span className="floating-label">Yes</span> </Trans></button></div>
                                <div><button class="border-0 bg-white" onClick={this.closeModal.bind(this, 'modal5')}><Trans i18nKey="No"><span className="floating-label">No</span> </Trans></button></div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.modal3} toggle={this.closeModal.bind(this, 'modal3')} className="premiumPopup vertically-center apd5">
                    <ModalHeader toggle={this.closeModal.bind(this, 'modal3')}><h4 class="modal-title"><Trans i18nKey="Are you sure ?"><span className="floating-label">Are you sure ?</span> </Trans></h4></ModalHeader>
                    <ModalBody>
                        <div className="modal-body">
                           <div class="d-flex justify-content-end">
                            <div class="mr-3"><button class="border-0 bg-white pink-color" onClick={this.deactivate}><Trans i18nKey="Yes"><span className="floating-label">Yes</span> </Trans></button></div>
                            <div><button class="border-0 bg-white" onClick={this.closeModal.bind(this, 'modal3')}><Trans i18nKey="No"><span className="floating-label">No</span> </Trans></button></div>
                           </div>
                        </div>
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.modal4} toggle={this.closeModal.bind(this, 'modal4')} className="premiumPopup vertically-center apd5">
                    <ModalHeader toggle={this.closeModal.bind(this, 'modal4')}><h4 class="modal-title"><Trans i18nKey="Are you sure ?"><span className="floating-label">Are you sure ?</span> </Trans></h4></ModalHeader>
                    <ModalBody>
                        <div className="modal-body">
                           <div class="d-flex justify-content-end">
                            <div class="mr-3"><button class="border-0 bg-white pink-color" onClick={this.logout}><Trans i18nKey="Yes"><span className="floating-label">Yes</span> </Trans></button></div>
                            <div><button class="border-0 bg-white" onClick={this.closeModal.bind(this, 'modal4')}><Trans i18nKey="No"><span className="floating-label">No</span> </Trans></button></div>
                           </div>
                        </div>
                    </ModalBody>
                </Modal>





            </div>
        )
    }
}

export default withTranslation("translations")(Settings);
import React, { Component } from 'react';
import axios from 'axios';
// import AdSense from 'react-adsense';  


class Admob extends Component { 
    constructor(props) {
        super(props);
        this.state = {
            adsense:'true',
            ad_unit_id:'',
            slot_id:'',
            api_url:localStorage.getItem('api_url'),   
        } 
    }
    componentDidMount(){
        (window.adsbygoogle = window.adsbygoogle || []).push({});

		axios.get(this.state.api_url+`admindatas`)
            .then(res => {
                const datas = res.data.result;
                
                this.setState({ 
                    adsense:datas['admin_enable_ads'],
                    // ad_unit_id:datas['web_ads_client'],
                    // slot_id:datas['web_ads_slot'],  
                });
            }); 
        
	}
    render() { 
        var style = {
            display:"inline-block",
            minWidth:"320px", 
            maxWidth:"728px", 
            width:"100%",
            height:"90px"  
          };

        return (
                <div className={this.state.adsense === 'true' ? "adMob": "none"}>     
                    {/*<AdSense.Google  
                      client="ca-app-pub-3940256099942544"     
                      slot="2934735716"    
                      style={{ display:"block", width:"100%", height:"90px" }}
                    /> */}  

                    <ins className="adsbygoogle"
                       style={style}    
                       data-ad-client="ca-app-pub-3940256099942544"   
                       data-ad-slot="2934735716"
                    />         
                </div>    
        );
    }
}

export default Admob;
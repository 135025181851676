import React, { Component } from 'react';
// import { Trans } from "react-i18next";

// import { NavLink } from 'react-router-dom';
import axios from 'axios';
// import Swiper from 'react-id-swiper';
// import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import "../../config/i18n";
import { withTranslation } from "react-i18next";

import ls from "local-storage"; 
import { options } from "../../config/options";

class Boost extends Component { 
    constructor(props) {
        super(props);
        // this.filterchange = this.filterchange.bind(this);
        this.handleSuperlikeItemClick = this.handleSuperlikeItemClick.bind(this);

        this.state = {
            user_id:'',
            loader:'', profilespace:'none',
            api_url:localStorage.getItem('api_url'),
            superlikeStatus: '', 
            superlikeCount: 0,
            superlikePlanStatus: 0,
            superlikeItems: [], selected_superlikepremium:'',
            currency:'',payment:'',license_key:'',superlikepremium_price:'',premium_button:'none',
            lang: ls.get('LangKeySet') || options[0],
            langKey: ls.get('LangValue'),  

        }

        this.toggle = this.toggle.bind(this);       

    } 

    toggle() {
        this.setState(prevState => ({ 
            modal: !prevState.modal
        }));
    }


    componentDidMount() {
        this.setState({
            loader:'loder-icon'
        });
        setTimeout(() => {
            this.setState({
                profilespace: 'ProfileSpace',
                loader: 'none'
            });
        }, 300);     
    
       // const { match: { params } } = this.props;   

        var premiumlist = new FormData();
        premiumlist.append('user_id',JSON.parse(localStorage.getItem('session_userid')));
        premiumlist.append('platform', 'web');
        premiumlist.append('language', this.state.langKey); 

        axios({
            method: 'post',
            url: this.state.api_url+'websuperlikelist', 
            data: premiumlist,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        }).then(res => {
            if(res.data.status === "true") {
                const data = res.data; 
                var responseSuperlikeStatus = (data.superlike_count > 0) ? 'available' : 'expired';
                 
                this.setState({
                    superlikeItems: data.result, 
                    activeItem: data.result[0].id,
                    selected_superlikepremium: data.result[0].id,
                    superlikepremium_price: data.result[0].price,
                    currency: data.currency,
                    payment: data.payment,
                    license_key: data.license_key,
                    superlikePlanStatus: 1,
                    superlikeStatus: responseSuperlikeStatus,
                    superlikeCount: data.superlike_count,   
                });
                localStorage.setItem('selected_superlikepremium',JSON.stringify(this.state.selected_superlikepremium));
                localStorage.setItem('superlikepremium_price',JSON.stringify(this.state.superlikepremium_price));
                localStorage.setItem('currency',JSON.stringify(this.state.currency));
            } 
        }); 
               

    } 
    
    closeModal(tabId) {
        this.setState({
            [tabId]: false
        });
    }
    showModal(modal) { 
        this.setState({
            [modal]: true
        }); 
    } 

    handleSuperlikeItemClick(index) { 
        this.setState({
            activeItem: index,
            selected_superlikepremium: index,
        })
        
        var premiumsuperlikelist = new FormData();
        premiumsuperlikelist.set('premiumsuperlikeid', index);
        premiumsuperlikelist.set('language', this.state.langKey);   
        
        axios({
            method: 'post',
            url: this.state.api_url+'premiumsuperlikeid',
            data: premiumsuperlikelist,   
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        }).then(res => {
            const data = res.data;
            this.setState({
                superlikepremium_price: data.result.price, 
            });    
            localStorage.setItem('selected_superlikepremium',JSON.stringify(index));
            localStorage.setItem('superlikepremium_price',JSON.stringify(data.result.price));   
        });  
    }  

    render() {  
        /*const params2 = { 
             navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev'
            },    
            slidesPerView: 3,
            slidesPerColumn: 1,
            breakpoints: {
                360: {
                    slidesPerView: 3, 
                },
                359: {
                    slidesPerView: 2,
                }
            }
        };*/  

        return ( 
            <div>   
                {/* (this.state.superlikeStatus === 'expired' && this.state.superlikeCount === 0) ? 
                <div className="becomePremiumVisitorPage d-flex flex-column align-items-center mt-3"> 
                    <div className="bold-font mb-2"> 
                        <Trans i18nKey="Get Unlimited Super likes">Get Unlimited Superlikes</Trans>
                    </div>  
                    <button className="btn-common" onClick={this.toggle}><Trans i18nKey="Buy">Buy</Trans> </button> 
                </div>     

                <Modal isOpen={this.state.modal} toggle={this.toggle} className="premiumPopup vertically-center apd5">
                    <ModalHeader toggle={this.toggle}></ModalHeader>  
                    <ModalBody>
                        <div className="premiumPop">   
                            { (this.state.superlikePlanStatus === 1) ?
                                <div className="premiumPopPlans">
                                    <div className="boostPlans"><Trans i18nKey="Super likes Premium Purchase">Superlikes Premium Purchase</Trans></div>  
                                <Swiper {...params2}>
                                    {this.state.superlikeItems.map((item, index) =>
                                        <div className={this.state.activeItem === item.id ? 'navigation--active' : ''} onClick={this.handleSuperlikeItemClick.bind(this, item.id)} alt={item.id} value={item.id}>
                                            <div className="premiumPrice">
                                                <div className="apd10 daysPrice">
                                                    <div className="bold-font apk-30">{item.superlike_count}</div>
                                                    <div className=" bold-font apk-20 mt-n2"><Trans i18nKey="Superlikes">Superlikes</Trans></div>    
                                                </div>
                                                <div className="apd5 apk-16 mini-price">{this.state.currency}{item.price}</div> 
                                            </div>
                                        </div>
                                    )} 
                                </Swiper> 
                                <div className="sidebarLastContent text-center">
                                    <NavLink to="/app/Superlikepayment"> <button className="btn-common btn-block"><Trans i18nKey="Continue">Continue</Trans></button></NavLink> 
                                </div>
                                </div> 
                            :
                                <div className="premiumPopPlans text-center"> <Trans i18nKey="No Plans Available">No Plans Available</Trans> </div> 
                            } 
                        </div>
                    </ModalBody>
                </Modal>  
                */ } 
            </div> 

        );
    }
}


export default withTranslation("translations")(Boost);  
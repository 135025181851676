import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import Cookies from 'universal-cookie';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Swiper from 'react-id-swiper';
import axios from 'axios';
 
import Select from "react-select";
import ls from "local-storage"; 
import { options } from "../../config/options";
import "../../config/i18n";
import { Trans } from "react-i18next";  
import { withTranslation } from "react-i18next"; 
import { useLocation } from "react-router-dom";


class SideBar extends Component {
  // static propTypes = {
  //   cookies: instanceOf(Cookies).isRequired
  // };
  constructor(props) {
    super(props);
    //handleItemClick
    this.loggedIn = sessionStorage.getItem('loggedin');
    this.handleItemClick = this.handleItemClick.bind(this);
    this.onChangepack = this.onChangepack.bind(this);
      this.hidecount = this.hidecount.bind(this);
    //const { Cookies } = props;
    this.state = {
      openModal : false,
      modal: false,
      ismsgshown: false,
      isreqshown: false,
      islikeshown: false, 
      isvisitorshown: false,
      activeItem: '',
      user_id:'',
       disp: '',
      user_details:'',api_url:localStorage.getItem('api_url'),
      selected_premium:'',notification_count:'',
      items: [],currency:'',payment:'',license_key:'',premium_price:'',abort:'',
      permium_superlikeCount: 0, 
      permium_boostCount: 0, 
      lang: ls.get('LangKeySet') || options[0],
      langKey: ls.get('LangValue'),
      price: "12",
      subscribed:false,
      noofdays :"30",
      
    };
  
    this.toggle = this.toggle.bind(this);
    this.toggleclose = this.toggleclose.bind(this);

    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  changeLang = lang => {  
    const { i18n } = this.props;
    const { value } = lang;
    this.setState({
      lang
    });
    // console.log("changeLang", lang);
    ls.set('LangKeySet', lang);
    ls.set('LangValue', value);
    i18n.changeLanguage(value);
    window.location.reload();  
  };
   
  componentDidMount() {
    
    if (this.props.i18n.language) {
      this.setState(
        {
          lang: options.filter(item => item.value === this.props.i18n.language)
        } 
      );
    }

    const cookies = new Cookies();
    
    if(cookies.get('onboarded') === 'true') {
      let data = new FormData();
      data.append('user_id', JSON.parse(localStorage.getItem('session_userid')));  
      data.append('language', this.state.langKey); 
      axios.defaults.headers.common['Authorization'] = JSON.parse(localStorage.getItem('session_token')); 
      axios({
          method: 'post',
          url: this.state.api_url+'webprofile',
          data: data, 
          config: { headers: {'Content-Type': 'multipart/form-data' }}
      }).then(res => {
        if(res.data.status !== false) {
          const user_details = res.data.result;
          
         // alert(user_details.subscribed);
          this.setState({
            user_details: user_details,
          },()=>{

            console.log("show popup",this.props.showSubPop);
           // alert(this.state.openModal)
            // if(!this.state.openModal && this.state.user_details.subscribed == 'false' && this.props.showSubPop != 'false'){   
            //   this.setState({
            //     openModal: true
            // });  
            // }     

          }); 

          //localStorage.setItem('subscribed',"true");
      
          if(res.data.result.userstatus === 1) {

            axios({
              method: 'get',
              url: this.state.api_url+'subscriptionDetails',                
              
            }).then(res => {
              const data = res.data;
              console.log(data);
              this.setState({                         
                      currency: data.currency,
                      price: data.price,
                      noofdays: data.noofdays,
                    });
                    localStorage.setItem('subscription_price',JSON.stringify(this.state.price));
                    localStorage.setItem('currency',JSON.stringify(this.state.currency));
                    localStorage.setItem('noofdays',JSON.stringify(this.state.noofdays));
               
            });
           
            
           
            
            var loginstatus = new FormData();
            loginstatus.set('user_id', JSON.parse(localStorage.getItem('session_userid')));
            loginstatus.set('status', '1');
            loginstatus.set('language', this.state.langKey); 

            axios({
              method: 'post',
              url: this.state.api_url+'webonlinestatus',
              data: loginstatus,
              config: { headers: {'Content-Type': 'multipart/form-data' }}
            }) 
            var user = new FormData();
            user.set('user_id', JSON.parse(localStorage.getItem('session_userid')));
            user.set('language', this.state.langKey); 
            axios({
              method: 'post',
              url: this.state.api_url+'webgetcounts',
              data: user,
              config: { headers: {'Content-Type': 'multipart/form-data' }}
            }).then(res => {
              this.setState({
                notification_count: res.data.result,
              });
              if(this.state.notification_count.message !== '0')
                   this.setState({ ismsgshown: true });
              if(this.state.notification_count.visitors !== '0')
                   this.setState({ isvisitorshown: true });
              if(this.state.notification_count.superlike_request !== '0')
                   this.setState({ isreqshown: true });
              if(this.state.notification_count.like_request !== '0')
                   this.setState({ islikeshown: true });  
            })

            var premiumlist = new FormData();
            premiumlist.set('platform', 'web');
            premiumlist.set('language', this.state.langKey); 
            axios({
              method: 'post',
              url: this.state.api_url+'premiumlistweb',   
              data: premiumlist,
              config: { headers: {'Content-Type': 'multipart/form-data' }}
            }).then(res => {
              const data = res.data;
              
              if(res.data.status==='true')
                this.setState({
                  items: data.result,
                  activeItem: data.result[0].id,
                  selected_premium: data.result[0].id,
                  premium_price: data.result[0].price,
                  permium_superlikeCount: data.result[0].superlikecount, 
                  permium_boostCount: data.result[0].boostcount,  
                  currency: data.currency,
                  payment: data.payment,
                  license_key: data.license_key,
                });
              localStorage.setItem('selected_premium',JSON.stringify(this.state.selected_premium));
              localStorage.setItem('premium_price',JSON.stringify(this.state.premium_price));
              localStorage.setItem('currency',JSON.stringify(this.state.currency));
            });
          }
        } else { 
          this.props.history.push("/login");  
        } 
      })
    } 
        
  }
  componentWillMount() { 
    const cookies = new Cookies();
    var hosturl = localStorage.getItem('web_url');  
    if(cookies.get('onboarded') === 'false') {
        hosturl = hosturl+"login";  
        window.location.assign(hosturl);
    }

    let data = new FormData();
    data.append('user_id', JSON.parse(localStorage.getItem('session_userid')));  
    data.append('language', this.state.langKey); 
    axios.defaults.headers.common['Authorization'] = JSON.parse(localStorage.getItem('session_token'));

    axios({
        method: 'post',
        url: this.state.api_url+'webprofile',
        data: data, 
        config: { headers: {'Content-Type': 'multipart/form-data' }}
    }).then(res => {
      if(res.data.status !== false) {    
        const user_details = res.data.result;
        this.setState({
          user_details: user_details,
        });
      } else {
        this.props.history.push("/login");   
      }
    }); 
  }
 
  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }
  toggleclose() {
    this.setState({
      modal: false
    });
  }

  showModal() {
    this.setState(prevState => ({
        openModal: !prevState.openModal
    }));
  }
  closeModal() {
    this.setState({
        openModal: false
    });
  }
  

  handleItemClick(index) {
    this.setState({
      activeItem: index,
      selected_premium: index,
    })
    var premiumlist = new FormData();
    premiumlist.set('premiumid', index);
    premiumlist.set('language', this.state.langKey);   
    axios({
      method: 'post',
      url: this.state.api_url+'premiumid',
      data: premiumlist,
      config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(res => {
          const data = res.data;
          this.setState({
            premium_price: data.result.price,
            permium_superlikeCount: data.result.superlikecount, 
            permium_boostCount: data.result.boostcount,  
        });
        localStorage.setItem('selected_premium',JSON.stringify(index));
        localStorage.setItem('premium_price',JSON.stringify(data.result.price));
       // alert(data.result.price); 
        });
      
    
  }
  onChangepack(e) {
    this.setState({
      selected_premium: e.target.value,
    })

  }

   hidecount(e) {

             this.setState({
      disp: 'none'
    })

  }
  render() {
    const { lang } = this.state;
    const { t } = this.props; 
        
    const params = {
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
      spaceBetween: 1,
      rebuildOnUpdate: true   
    }

    const params2 = {
      navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev'
            },    
      slidesPerView: 3,
      slidesPerColumn: 1,
      breakpoints: {
        360: {
          slidesPerView: 3,
        },
        359: {
          slidesPerView: 2,
        }
      }
    };
    let premium,premium_icon; 
    if(this.state.user_details.premium_member !== 'true'){
      if(this.state.user_details.subscribed != 'false')
        premium =  <button className="btn-common" onClick={this.toggle}>{t('Become a Premium Member')}</button> 
      else premium =  <button className="btn-common" onClick={this.showModal}>{t('Become a Premium Member')}</button> 
      premium_icon = ''
    }else{
      // premium = <button className="btn-common">{t('Premium Member')}</button> 
      premium_icon = <div class="preIcon"></div>
      premium = ""      
    }

    // const location = useLocation();
    // console.log("pname",location.pathname);
    //  if(this.state.user_details.subscribed == 'false'){   
    //               this.state.openModal = true;    
    //           }
            

    return (
      <div className="sidebarWrap">
        <div className="sideLogoSection text-center">
          <NavLink to="/app/PeopleNear">
            <img src={require('../../assets/img/logo-c-64.png')} className="sideLogo" alt="logo" />
          </NavLink>
        </div>

        <div className="sideProfileBody">
          <div className="media">
            <div className="align-self-center mx-3 relPos">  
              <NavLink to="/app/profile">
                <img className="currentUserDP" src={this.state.user_details.images} alt="" />  
                 {premium_icon}
              </NavLink>
             
            </div>

            <div className="media-body pFHidden align-self-center">
              <NavLink to="/app/Profile"><div className="text-truncate text-dark bold-font">{this.state.user_details.user_name}</div></NavLink>
              <NavLink to="/app/Profile">   <div className="text-truncate secondaryText esmh-14 regular-font ">{this.state.user_details.location}</div></NavLink>
            </div>

            <div className="align-self-center  ml-3">
              <NavLink to="/app/settings">
                <div className="iconHolder">
                  <div className="backGlow">
                    <div className="settingsIco"></div>
                  </div>
                </div>
              </NavLink>
            </div>
          </div>
        </div>


        <div className="sideListBody">

          <div className="media">
            <div className="align-self-center mr-3">
              <div className="nearYouico sidebarIcoPos"></div>
            </div>
            <div className="media-body pFHidden align-self-center">
              <NavLink to="/app/PeopleNear" activeClassName="active">
                <div className="text-truncate">
                  <Trans i18nKey="Peoplenearyou"><span className="floating-label">People near you</span> </Trans>
                </div>
              </NavLink>
            </div>
            <div className="align-self-center  ml-3">
            </div>
          </div>         

          <div className="media">
            <div className="align-self-center mr-3">
              <div className="sidebarIcoPos findNewPeopleIco"></div>
            </div>
            <div className="media-body pFHidden align-self-center">
              <NavLink to="/app/FindNewPeople" activeClassName="active">
                <div className="text-truncate">
                  <Trans i18nKey="Findnewpeople">Find new people</Trans> 
                </div>
              </NavLink>
            </div>
            <div className="align-self-center  ml-3">
            </div>
          </div>

          <div className="media">
            <div className="align-self-center mr-3">
              <div className="sidebarIcoPos subscription"></div>
            </div>
            <div className="media-body pFHidden align-self-center">
              <NavLink to="/app/Subscription" activeClassName="active">
                <div className="text-truncate">
                  <Trans i18nKey="Subsmngepg">Subscription</Trans> 
                </div>
              </NavLink>
            </div>
            <div className="align-self-center  ml-3">
            </div>
          </div>

          <div className="media" >
            <div className="align-self-center mr-3" >
              <div className="sidebarIcoPos visitorsIco"></div>
            </div>
        
            <div className="media-body pFHidden align-self-center" onClick={this.hidecount}>
              <NavLink to="/app/Visitors" activeClassName="active">
                <div className="text-truncate">
                  <Trans i18nKey="Visitors">Visitors</Trans></div>
              </NavLink>
            </div>
             {this.state.isvisitorshown && (
            <div className="align-self-center  ml-3" style={{display:this.state.disp}}>
              <div className={this.state.notification_count.visitors !== '0' ? "visitorsNotifierIco sidebarIcoPos NotifiersizePos" : "none"}>
                <span>{this.state.notification_count.visitors}</span> 
              </div>
            </div>
               )}
          </div>

          <div className="media">
            <div className="align-self-center mr-3">
              <div className="sidebarIcoPos messageIco"></div>
            </div>
            <div className="media-body pFHidden align-self-center"> 
              <NavLink to="/app/Message" activeClassName="active">
                <div className="text-truncate">
                  <Trans i18nKey="Message">Message</Trans>
                </div> 

              </NavLink>
            </div>
              {this.state.ismsgshown && (
         <div className={this.state.notification_count.message !== '0' ? "align-self-center  ml-3" : "none"}>
            <div className={this.state.notification_count.message !== '0' ? "messageNotifierIco sidebarIcoPos NotifiersizePos" : "none"}>
                <span>{this.state.notification_count.message}</span>
              </div>
              
            </div>
        )}
           
          </div>


          <div className="media">
            <div className="align-self-center mr-3">
              <div className="sidebarIcoPos feedIco"></div>   
            </div>
            <div className="media-body pFHidden align-self-center">
              <NavLink to="/app/Feeds" activeClassName="active">
                <div className="text-truncate"><Trans i18nKey="Feeds">Feeds</Trans></div> 
              </NavLink>
            </div>         
          </div>

          <div className="media">
            <div className="align-self-center mr-3">
              <div className="sidebarIcoPos friendsIco"></div>
            </div>
            <div className="media-body pFHidden align-self-center">
              <NavLink to="/app/Friends" activeClassName="active">
                <div className="text-truncate"><Trans i18nKey="Matches">Matches</Trans> </div>
              </NavLink>
            </div>
            <div className="align-self-center  ml-3">
            </div>
          </div>  

          <div className="media">
            <div className="align-self-center mr-3">
              <div className="sidebarIcoPos friendReqIco"></div>  
            </div>
            <div className="media-body pFHidden align-self-center">
              <NavLink to="/app/FriendRequests" activeClassName="active">
                <div className="text-truncate">
                  <Trans i18nKey="Superlikes">Super likes</Trans> 
                  {this.state.isreqshown && (
                      <span className={this.state.notification_count.superlike_request !== '0' ? "sidebarIcoPos" : "none"}>
                        ({this.state.notification_count.superlike_request})  
                      </span>  
                  )}
                </div>
              </NavLink>
            </div> 
          </div>

          <div className="media">
            <div className="align-self-center mr-3">
              <div className="sidebarIcoPos likesReqIco"></div> 
            </div>
            <div className="media-body pFHidden align-self-center">
              <NavLink to="/app/LikeRequests" activeClassName="active">     
                <div className="text-truncate">
                  <Trans i18nKey="Likes">Likes</Trans> 
                  {this.state.islikeshown && ( 
                      <span className={this.state.notification_count.like_request !== '0' ? "sidebarIcoPos" : "none"}>
                        ({this.state.notification_count.like_request}) 
                      </span>   
                  )}
                </div> 
              </NavLink>
            </div>
               
          </div>

          <div className="media">
            <div className="align-self-center mr-3">
              <div className="sidebarIcoPos helpIco"></div>
            </div>
            <div className="media-body pFHidden align-self-center">
              <NavLink exact to={"/app/Help/"} activeClassName="active">
                <div className="text-truncate"><Trans i18nKey="Help">Help</Trans> </div> 
              </NavLink>
            </div>
            <div className="align-self-center  ml-3">
            </div>
          </div>
        </div>
        
        <Select
          defaultValue={lang} 
          options={options}
          value={lang}
          onChange={this.changeLang} 
          className="App-Select"
        /> 

        <div className="sidebarLastContent mb-auto">
         {premium}
        </div>


        <Modal isOpen={this.state.modal} toggle={this.toggle} className="premiumPopup vertically-center apd5">
          <ModalHeader toggle={this.toggle}>
            <h4 class="modal-title">
              <Trans i18nKey="Join Premium">Join Premium</Trans> 
            </h4>
          </ModalHeader>  
          <ModalBody>
            <div className="premiumPop">
                <div className="premiumImgs">   
                    <Swiper {...params} shouldSwiperUpdate>  
                     {this.state.permium_superlikeCount > 0 ?
                      <div>
                          <div className="premiumImgHolder">
                              <img src={require("../../assets/img/sub_superlike.png")} alt="" className="img-fluid" />
                          </div> 
                          <div className="premiumInfoHolder">
                              <div className="bold-font">
                                  <Trans i18nKey="Get">Get</Trans> {this.state.permium_superlikeCount} <Trans i18nKey="free super likes">free super likes</Trans>  
                              </div>
                              <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                  <Trans i18nKey="premiumTextSuper">Giving a Super like is a way to tell someone that you're especially interested in them</Trans>
                              </div>
                          </div>
                      </div> : ''
                     }

                     {this.state.permium_boostCount > 0 ?
                      <div>
                          <div className="premiumImgHolder">
                              <img src={require("../../assets/img/sub_boost.png")} alt="" className="img-fluid" />
                          </div>
                          <div className="premiumInfoHolder">  
                              <div className="bold-font">
                                  <Trans i18nKey="Get">Get</Trans> {this.state.permium_boostCount} <Trans i18nKey="free boost">free boost</Trans>     
                              </div>
                              <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                  <Trans i18nKey="premiumTextBoost">Be the top profile in your area for 30minutes to get more matches, Keep Swipe right!</Trans>
                              </div>
                          </div>
                      </div> : ''
                     }
      
                      <div>
                          <div className="premiumImgHolder">
                              <img src={require("../../assets/img/sub_location.png")} alt="" className="img-fluid" />
                          </div>
                          <div className="premiumInfoHolder"> 
                              <div className="bold-font">
                                  <Trans i18nKey="Change Location">Change Location</Trans>
                              </div>
                              <div className="premiumInfoSubText  secondaryText esmh-14 "> 
                                  <Trans i18nKey="premiumTextLocation">Get match and chat with others around the world by simply searching for a city</Trans>
                              </div>
                          </div>
                      </div>

                      <div> 
                          <div className="premiumImgHolder">
                              <img src={require("../../assets/img/sub_morelike.png")} alt="" className="img-fluid" />
                          </div>
                          <div className="premiumInfoHolder">
                              <div className="bold-font">
                                  <Trans i18nKey="Unlimited Likes">Unlimited Likes</Trans>  
                              </div>
                              <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                  <Trans i18nKey="premiumTextUnlimited">Feel special again with 'Unlimited Likes'. Swipe right as much as you want!</Trans> 
                              </div>
                          </div>
                      </div>


                      <div>
                          <div className="premiumImgHolder">
                              <img src={require("../../assets/img/sud_add.png")} alt="" className="img-fluid" />
                          </div>
                          <div className="premiumInfoHolder"> 
                              <div className="bold-font">
                                  <Trans i18nKey="Rewind">Rewind</Trans>
                              </div>
                              <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                  <Trans i18nKey="premiumTextRewind">Accidentally swiped on someone? Rewind lets you take back your last Like or Nope</Trans>
                              </div>
                          </div>
                      </div>  

                      <div> 
                          <div className="premiumImgHolder">
                              <img src={require("../../assets/img/sub_morelike.png")} alt="" className="img-fluid" />
                          </div>
                          <div className="premiumInfoHolder">
                              <div className="bold-font">
                                  <Trans i18nKey="See who Likes you">See who Likes you</Trans>   
                              </div> 
                              <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                  <Trans i18nKey="premiumTextLikes">Likes You feature lets you see who likes you before you decide whether to Like or Nope</Trans> 
                              </div>
                          </div>
                      </div>

                      <div>
                          <div className="premiumImgHolder">
                              <img src={require("../../assets/img/sub_profile.png")} alt="" className="img-fluid" />
                          </div>
                          <div className="premiumInfoHolder">
                              <div className="bold-font"> 
                                  <Trans i18nKey="Control your profile">Control your profile</Trans>   
                              </div>  
                              <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                  <Trans i18nKey="premiumTextProfile">Limit what others see and make parts of your profile information invisible to other people</Trans>
                              </div>  
                          </div>
                      </div> 

                      <div>
                          <div className="premiumImgHolder">
                              <img src={require("../../assets/img/sub_visited.png")} alt="" className="img-fluid" />
                          </div>
                          <div className="premiumInfoHolder">
                              <div className="bold-font">
                                  <Trans i18nKey="See who visited your profile">See who visited your profile</Trans> 
                              </div>
                              <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                  <Trans i18nKey="premiumTextVisited">A Visitors feature lets you see who looked at your profile or ‘visited’ your profile
</Trans>
                              </div>
                          </div>
                      </div>



                      <div>
                          <div className="premiumImgHolder">
                              <img src={require("../../assets/img/sud_add.png")} alt="" className="img-fluid" />
                          </div> 
                          <div className="premiumInfoHolder">
                              <div className="bold-font">
                                  <Trans i18nKey="Turn Off Ads">Turn Off Ads</Trans> 
                              </div>
                              <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                  <Trans i18nKey="premiumTextAds">Have fun swiping with uninterrupted ads</Trans>
                              </div>  
                          </div>
                      </div> 

                  </Swiper> 
                </div>

              <div className="premiumPopPlans">
                <Swiper {...params2}>
                  {this.state.items.map((item, index) =>
                    <div className={this.state.activeItem === item.id ? 'navigation--active' : ''} onClick={this.handleItemClick.bind(this, item.id)} alt={item.id} value={item.id}>
                      <div className="premiumPrice">
                        <div className="apd10 daysPrice">
                          <div className="bold-font apk-30">{item.days}</div>
                          <div className=" bold-font apk-24 mt-n2"><Trans i18nKey={item.type}>{item.type}</Trans></div>   
                        </div>
                        <div className="apd5 apk-16 mini-price">{this.state.currency}{item.price}</div>
                      </div>
                    </div>)}

                </Swiper>
              </div>
              

              <div className="sidebarLastContent text-center">
              <NavLink to="/app/Payment"> <button className="btn-common btn-block" onClick={this.toggleclose}><Trans i18nKey="Continue">Continue</Trans></button></NavLink>  
        </div>


            </div>
          </ModalBody>
        </Modal>

        <Modal isOpen={(this.state.subscribed)? true : this.state.openModal}   className="premiumPopup vertically-center apd5">
          <ModalHeader toggle={this.closeModal} >            
          </ModalHeader>  
          <ModalBody>    
          <div>                         
            <div className="premiumInfoHolder reducemargin">
                <div className="bold-font">
                <h4 >
                <Trans i18nKey=">Activate your account">Activate your account</Trans> 
                </h4>
                </div>
                <div className="premiumInfoSubText  secondaryText esmh-14 ">
                    <Trans i18nKey="subscribeTextSuper">Get unlimited access nearby friends, likes, superlike, chat, etc</Trans>
                </div>
            </div>
            
        </div>     
        <div className="premiumPopPlans subscribeprice">
            <div class="swiper-slide navigation--active swiper-slide-active" style={{width:"152px"}}>
        <div class="premiumPrice" ><div class="apd10 daysPrice"><div class="bold-font apk-30"  style={{color:"black"}}>{this.state.noofdays}</div><div class=" bold-font apk-24 mt-n2" style={{color:"black"}}><Trans i18nKey="Days">Days</Trans></div></div><div class="apd5 apk-16 mini-price">{this.state.currency}{this.state.price}</div></div>
        </div>
        </div>
        <div className="sidebarLastContent text-center">
              <NavLink to="/app/SubscribePayment"> <button className="btn-common btn-block" onClick={this.closeModal.bind(this, 'modal4')}><Trans i18nKey="Subscribe">Subscribe</Trans></button></NavLink>  
        </div>
          </ModalBody>
        </Modal>

      








      </div>

    );
  }
}


export default withTranslation("translations")(SideBar); 
import React, { Component } from 'react';
import { Trans, withTranslation } from "react-i18next";
import "../../config/i18n";

import ls from "local-storage"; 
import { options } from "../../config/options"; 

import { NavLink } from 'react-router-dom';
// import Swiper from 'react-id-swiper';
import { Dropdown, DropdownToggle, DropdownMenu,Modal, ModalHeader, ModalBody } from 'reactstrap';
import Slider from "react-slick";
import axios from 'axios';
import Loader from 'react-loader-spinner';


class FriendsNew extends Component {
    constructor(props) {
        super(props);
        this.reportToggle = this.reportToggle.bind(this);
        this.SetReport = this.SetReport.bind(this);
        this.Unfriend = this.Unfriend.bind(this);
        this.Message = this.Message.bind(this);
        this.state = {
            dropdownOpen: false,
            profile_userid:'',
            user_details:'',
            lang: ls.get('LangKeySet') || options[0],
            langKey: ls.get('LangValue'),    
            image:[],api_url:localStorage.getItem('api_url'),
            interest:[],report:'',value_id:'',modal3:'',loader:'',profilespace:'none'
        }
    }


    addDefaultSrc(ev){
          ev.target.src =  require('../../assets/img/default_user.png')
        }

    componentDidMount() {
            this.slider.slickGoTo('3');
        this.setState({
            loader:'loder-icon'
            });
            setTimeout(() => {
                this.setState({
                    profilespace: 'ProfileSpace',
                    loader: 'none'
                });
              }, 2000);
        const { match: { params } } = this.props;
        // const profile_id = params.userId;
        var profile = new FormData();
        profile.append('user_id',JSON.parse(localStorage.getItem('session_userid')));
        profile.append('friend_id', params.userId);
        profile.append('language', this.state.langKey);  
        axios({
            method: 'post',
            url: this.state.api_url+'webprofilenew',
            data: profile,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
            })  
        .then(res => {
        const user_details = res.data.result;
        if( res.data.status === "true"){
        if(user_details.report === "false"){
            this.setState({ report: "Report User"});
        }else{
            this.setState({ report: "UnReport User"});
        }
        this.setState({
            user_details: user_details,
            profile_userid: user_details.user_id,
            image: user_details.imagess,
            interest: user_details.interest_other,
        });
        }
        }).catch(error => { 
            this.props.history.push("/login"); 
        });

        var visitprofile = new FormData();
        visitprofile.append('visit_user_id',params.userId);
        visitprofile.append('language', this.state.langKey); 
        visitprofile.append('user_id', JSON.parse(localStorage.getItem('session_userid')));
        axios({
            method: 'post',
            url: this.state.api_url+'webvisitprofile',
            data: visitprofile,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
            })  
      }
      SetReport(e) {
        var report = new FormData();
        report.append('user_id', JSON.parse(localStorage.getItem('session_userid')));
        report.append('report_user_id',this.state.profile_userid);
        report.append('language', this.state.langKey); 
        axios({
            method: 'post',
            url: this.state.api_url+'webreport',
            data: report,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
            })  
        .then(res => {
        // const user_details = res.data.result;
        // console.log("profile_det",res.data);
        if(this.state.report === "Report User"){
        this.setState({ report: "UnReport User"});
        }else{
        this.setState({ report: "Report User"});    
        }
        })
     
	  	this.closeModal('modal4');
        }
    Unfriend(e) {
        var report = new FormData();
        report.append('user_id', JSON.parse(localStorage.getItem('session_userid')));
        report.append('unfriend_user_id',this.state.profile_userid);
        report.append('language', this.state.langKey);  
        axios({
            method: 'post',
            url: this.state.api_url+'unfriendweb',
            data: report,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
            })  
        .then(res => {
        const status = res.data.status;
        if(status === 'true'){
            this.props.history.push("../../Friends");
        }
        })
        }
        closeModal(tabId) {
            this.setState({
                [tabId]: false
            });
        }
        showModal(modal) {
            this.setState({
                [modal]: true
            });
            // console.log(this.state); 
        }
        Message(){
            var report = new FormData();
            report.append('sender_id', JSON.parse(localStorage.getItem('session_userid')));
            report.append('receiver_id',this.state.profile_userid);
            report.append('language', this.state.langKey);    
            axios({
                method: 'post',
                url: this.state.api_url+'webcreatchat',
                data: report,
                config: { headers: {'Content-Type': 'multipart/form-data' }}
                })  
            .then(res => {
                const chatid = res.data.chatid;
               // console.log("chatid",chatid);
                this.props.history.push(`/app/Message/MessageNew/${this.state.profile_userid}/${chatid}`)
            })

        }
    reportToggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }
    render() {
         /* var settings = {
            //initialSlide: 1,
            dots: true,
            infinite: false,
            slickGoTo: 2,
            afterChange: current => this.setState({ activeSlide2: current })
          };*/ 

          const settings = {
            dots: true,
            arrows:false,
            infinite: false,
            speed: 500,
            autoplay: true, 
            slidesToShow: 1,
            slickGoTo: 2,
            slidesToScroll: 1,
          };
    
        let age,loc,txt,clas1,clas2,bio,job,company,school, borderFlag = 0;  
        if(this.state.user_details.show_age === 'true'){
         age =''
        }else{
         age = this.state.user_details.age;
        }
        
        if(this.state.user_details.bio){
            bio =<div className="interestsHolder apt5 apk-16 border-top breakall"> 
            <div className="apt10 apb10">
            {this.state.user_details.bio}
            </div>
         </div>
           }else{
            bio = '' 
           }

        if(this.state.user_details.job) {
            borderFlag = 1;
            job = <div className="interestsHolder apb5 apk-16 breakall">
                {this.state.user_details.job}
            </div> 
        }   

        if(this.state.user_details.company) {
            borderFlag = 1;
            company = <div className="interestsHolder apb5 apk-16 breakall">
                {this.state.user_details.company}
            </div> 
        }

        if(this.state.user_details.school) {   
            borderFlag = 1;
            school = <div className="interestsHolder apb5 apk-16 breakall"> 
                {this.state.user_details.school} 
            </div>
        }

        if(this.state.user_details.show_location === 'true'){
        loc = '';
        txt = '';
        }else{
        txt = 'Lives In'     
        loc = this.state.user_details.location;   
        }
        if(this.state.user_details.send_match === '1'){
        clas1 = 'circularIcoHolder circularIcoHolderMadjust';
        clas2 = 'circularMessageIco';
        }else{
        clas1 = '';
        clas2 = '';  
        }
      
        var style = {
            zIndex:10,
            backgroundColor:"white",
            height:"80vh",
            width:"100%",
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
            //width:"100%",
            overflowY:"hidden"
          };

        return (
      
            <div className="setting-page">

                <div className="d-flex profile-header-top stickyProfileHead justify-content-between apd15 apr0 apl0 white-bg amb10 ">
                    <div class="heading-left-top d-flex">
                        <div className="align-self-center ">
                            <NavLink to="/app/Friends">
                                <div className="iconHolder align-self-center ">
                                    <div className="backGlow">
                                        <div className="backIco"></div>
                                    </div>
                                </div>
                            </NavLink>
                        </div>

                    </div>
                </div>

                <div className="settingsPageWidth profileSectionView">
                <div className={this.state.loader}>
                        <div style={style} id="loader">
                            <Loader type="Bars" color="#000000" height={30} width={50} />
                        </div>
                    </div>

                    <div className={this.state.profilespace}>

                        <div className="peopleINSpaceHolder">

                            <div className="peopleINViewHolder">
                                <div className="peopleINPic relPos">
                                 <Slider ref={slider => (this.slider = slider)} {...settings}>
                                {this.state.image.map((image_tmp) =>
                                                                    <div> <img src={image_tmp} id="current_image" alt="" value={image_tmp} onChange={()=>{this.handleswipe(image_tmp)}} className="" afterChange={this.afterChangeHandler}/></div>
                                                                )}
                                </Slider>



                                <div class={this.state.user_details.premium_member === 'true' ? "preIcon2" : 'none'}></div>
                                </div>
                                <div className="apd10 apt25 ">

                                    <div className={(borderFlag === 1)?'media relPos apb15 border-bottom amb5':'media relPos apb15'}>
                                        <div className="media-body pFHidden align-self-center">
                                        <div className="text-truncate bold-font BtoRcolor">{this.state.user_details.user_name}, {age}</div>  
                                         <div className="text-truncate secondaryText esmh-14 regular-font">
                                            <Trans i18nKey={txt}>{txt}</Trans> {loc}</div>   
                                        </div>

                                        <div className="iconHolder align-self-end ml-1">
                                            <div className="backGlow">
                                                <Dropdown isOpen={this.state.dropdownOpen} toggle={this.reportToggle}>
                                                    <DropdownToggle className="RSoverride">
                                                        <div className="threeDotMenuIcon"></div>
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropDownRSoverride reportDropPos">
                                                    <button className="border-0 bg-white text-center" onClick={this.showModal.bind(this, 'modal4')} value={this.state.user_details.user_id}>
                                                        <Trans i18nKey={this.state.report}>{this.state.report}</Trans>
                                                    </button> 
                                                    <button className="border-0 bg-white text-center" onClick={this.showModal.bind(this, 'modal3')} >
                                                        <Trans i18nKey="Unfriend">Unfriend</Trans>   
                                                    </button> 
                            
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                       
                                        <div className={clas1}>
                                            <div className={clas2} onClick={this.Message}></div>
                                        </div>

                                    </div>
                                      {job}
                                    {company}
                                    {school} 

                                      {bio}
                                    <div className="interestsHolder apt5 apk-16 border-top">
                                    <div className="bold-font">{this.state.user_details.ex_web} <Trans i18nKey="interest">interest</Trans></div>

                                        <div className="d-flex flex-wrap apt10 apb10">
                                        {this.state.interest.map((interest_tmp) =>
                                            <div className="btn-border"> <Trans i18nKey={interest_tmp}>{interest_tmp}</Trans> </div>
                                                                )}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>



                    </div>


                </div>
                <Modal isOpen={this.state.modal3} toggle={this.closeModal.bind(this, 'modal3')} className="premiumPopup vertically-center apd5">
                    <ModalHeader toggle={this.closeModal.bind(this, 'modal3')}>
                        <h4 class="modal-title">
                            <Trans i18nKey="Are you sure ?">Are you sure ?</Trans>
                        </h4>
                    </ModalHeader>
                    <ModalBody>
                        <div className="modal-body">
                           <div class="d-flex justify-content-end">
                            <div class="mr-3"><button class="border-0 bg-white pink-color" onClick={this.Unfriend} value={this.state.user_details.user_id}><Trans i18nKey="Yes">Yes</Trans></button></div>
                            <div><button class="border-0 bg-white" onClick={this.closeModal.bind(this, 'modal3')}><Trans i18nKey="No">No</Trans></button></div>
                           </div>
                        </div>
                    </ModalBody>
                </Modal>

  <Modal isOpen={this.state.modal4} toggle={this.closeModal.bind(this, 'modal4')} className="premiumPopup vertically-center apd5">
                    <ModalHeader toggle={this.closeModal.bind(this, 'modal4')}><h4 class="modal-title"><Trans i18nKey="Are you sure ?">Are you sure ?</Trans></h4></ModalHeader>
                    <ModalBody>
                        <div className="modal-body">
                           <div class="d-flex justify-content-end">
                            <div class="mr-3"><button class="border-0 bg-white pink-color" onClick={this.SetReport}><Trans i18nKey="Yes">Yes</Trans></button></div>
                            <div><button class="border-0 bg-white" onClick={this.closeModal.bind(this, 'modal4')}><Trans i18nKey="No">No</Trans></button></div>
                           </div>
                        </div>
                    </ModalBody> 
                </Modal>


            </div>

        );
    }
}
   
export default withTranslation("translations")(FriendsNew); 

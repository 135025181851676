import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import SideBar from "./SideBar";
import Settings from "./Settings";
import Profile from '../profile/Profile';
import EditProfile from "../profile/EditProfile";
import ProfilePhotoEdit from "../profile/ProfilePhotoEdit";
import PeopleNear from "../findExplore/PeopleNearYou";
import Visitors from "../visitors/Visitors";
import Filter from './Filter';
import Feeds from './Feeds';
// import Boost from './Boost'; 
import SubscribePayment from './SubscribePayment';
import Payment from './Payment';
import Boostpayment from './Boostpayment';  
import Superlikepayment from './Superlikepayment';  
import Friends from "../friends/Friends";
import FriendRequests from "../friendRequests/FriendRequests";
import FindNewPeople from "../findExplore/FindNewPeople"; 
import Subscription from "../subscription/Subscription";
import Message from "../message/Message";
import MessageNew from "../message/MessageNew";
import VisitorNew from "../visitors/VisitorNew";
import FriendRequestNew from "../friendRequests/FriendRequestNew";
import FriendsNew from "../friends/FriendsNew";
import Help from "../help/Help";
import PeopleExplore from "../findExplore/PeopleExplore";
import Explore from "../findExplore/Explore";
//New Updates
import LikeRequests from "../friendRequests/LikeRequests";   
import LikeRequestNew from "../friendRequests/LikeRequestNew";      

import ls from "local-storage"; 
import { options } from "../../config/options";

import NotFound from '../NotFound/NotFound';
import Cookies from 'universal-cookie';
import axios from 'axios'; 
import Signin from "../welcome/Signin";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      activeItem: '',
      user_id:'',
      user_details:'',api_url:localStorage.getItem('api_url'),
      selected_premium:'',notification_count:'',
      lang: ls.get('LangKeySet') || options[0],
      langKey: ls.get('LangValue'),  
      items: [],currency:'',payment:'',license_key:'',premium_price:'',abort:''

    };
   

}
  componentDidMount(){
    const cookies = new Cookies();
    var hosturl = localStorage.getItem('web_url');
    if(cookies.get('onboarded') === 'true') {
      let data = new FormData();
      data.append('user_id', JSON.parse(localStorage.getItem('session_userid')));  
      data.append('language', this.state.langKey); 
        
      axios({
          method: 'post',
          url: this.state.api_url+'webprofile',
          data: data, 
          config: { headers: {'Content-Type': 'multipart/form-data' }}
      }).then(res => { 
        if(res.data.status !== false) {
          const user_details = res.data.result;
        
          this.setState({
            user_details: user_details,
          });
        
          if(res.data.result.userstatus === 0){
              cookies.set('onboarded', 'false', {path: "/"}); 
              cookies.set('signuptab', false);
              localStorage.setItem('session_token',JSON.stringify());
              localStorage.setItem('session_userid',JSON.stringify());
              //this.props.history.push("/login")
              hosturl = hosturl+"login";
              window.location.assign(hosturl);
          }   
        } else {
          this.props.history.push("/login");    
        }
      });     

    } else {
        cookies.set('onboarded', 'false', {path: "/"}); 
        cookies.set('signuptab', false);
        localStorage.setItem('session_token',JSON.stringify());
        localStorage.setItem('session_userid',JSON.stringify());
        hosturl = hosturl+"login";     
        window.location.assign(hosturl);
    }  
} 

  
render() {
    return (
      <Router basename={'/'}>
        <div class="home">
          <div className="leftSideMenu d-none d-lg-block"><SideBar /></div>
          <div className="rightSideContent">
  <SimpleBar style={{ height: '100vh' }}>
         <Switch>
          <Route exact path="/app" component={PeopleNear} />
            <Route exact path="/app/settings" component={Settings} />
            <Route exact path="/app/Profile" component={Profile} />
            <Route exact path="/app/Profile/EditProfile" component={EditProfile} />
            <Route exact path="/app/Profile/ProfilePhotoEdit" component={ProfilePhotoEdit} />
            <Route exact path="/app/PeopleNear" component={PeopleNear} />
            <Route exact path="/app/Filter" component={Filter} />
            <Route exact path="/app/Feeds" component={Feeds} /> 
            { /*<Route exact path="/app/Boost" component={Boost} />  */}
            <Route exact path="/app/SubscribePayment" component={SubscribePayment} />
            <Route exact path="/app/Payment" component={Payment} />
            <Route exact path="/app/Boostpayment" component={Boostpayment} />   
            <Route exact path="/app/Superlikepayment" component={Superlikepayment} />     
            <Route exact path="/app/Visitors" component={Visitors} />
            <Route exact path="/app/Friends" component={Friends} />
            <Route exact path="/app/FriendRequests" component={FriendRequests} /> 
            <Route exact path="/app/Subscription" component={Subscription} />
            <Route exact path="/app/FindNewPeople" component={FindNewPeople} />
            <Route exact path="/app/Explore" component={Explore} />
            <Route exact path="/app/Message" component={Message} />
            <Route exact path="/app/Message/MessageNew/:userId/:chatId" component={MessageNew} />
            <Route exact path="/app/Visitors/VisitorNew/:userId" component={VisitorNew} />
            <Route exact path="/app/Friends/FriendsNew/:userId" component={FriendsNew} />
            <Route exact path="/app/FriendRequest/FriendRequestNew/:userId" component={FriendRequestNew} />
            <Route exact path="/app/Help" component={Help} />
            <Route exact path="/app/Help/:Id" component={Help} />    
            <Route exact path="/login" component={Signin} />
            {/* <Route exact path="/app/HelpMain" component={Help} /> */}
            <Route exact path="/app/PeopleNear/PeopleExplore/:userId" component={ PeopleExplore} /> 
            <Route exact path="/app/LikeRequests" component={LikeRequests} />  
            <Route exact path="/app/LikeRequest/LikeRequestNew/:userId" component={LikeRequestNew} /> 
            <Route component={NotFound} /> 
           
            </Switch>
</SimpleBar>
          </div>
        </div>
      </Router>
    );
  }
}

export default Home;

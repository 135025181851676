import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import { registerServiceWorker } from "./register-sw";
import * as serviceWorker from './serviceWorker'; 
import "./assets/css/bootstrap-rtl.min.css"; 
import "./assets/css/bootstrap.min.css";
import "./assets/css/swiper.min.css";
import "./assets/css/App.css"
import "./assets/css/rangeslider.min.css";
import "./assets/css/VPyEor.css";
import { MemoryRouter } from 'react-router'
import { I18nextProvider } from "react-i18next";
import i18n from "./config/i18n";
import ls from "local-storage"; 

var hosturl = window.location.protocol +"//" + window.location.hostname;
let apiBaseURL = "https://yoodobuzz.blackrose.com.riddhi.info";
localStorage.setItem('api_url',apiBaseURL+"/api/");  
localStorage.setItem('web_url',hosturl+"/");     
registerServiceWorker(); 

var weburl = localStorage.getItem('web_url');
const style = document.getElementById('style-direction');
const langKey = ls.get('LangValue');
if (langKey === 'ar') {  
  style.href = weburl+'styles/rtl.css';
} else {
  style.href = weburl+'styles/ltr.css';     
}

//console.log('public url: ', process.env.PUBLIC_URL)
/*ReactDOM.render(<MemoryRouter><App/></MemoryRouter>, document.getElementById('root'));*/

ReactDOM.render(
    <I18nextProvider i18n={i18n}>
      <MemoryRouter><App/></MemoryRouter>
    </I18nextProvider>,
    document.getElementById("root")
  );
serviceWorker.register();   
import React, { Component } from 'react';
import { Trans, withTranslation } from "react-i18next";
import "../../config/i18n";
import { googleTranslate } from "../../config/googleTranslate";
import ImageZoom from 'react-medium-image-zoom';
import { NavLink } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, Modal, ModalHeader, ModalBody } from 'reactstrap';
import Loader from 'react-loader-spinner';
import axios, { post } from 'axios';
import 'moment-timezone';
import Moment from 'react-moment';
import ls from "local-storage"; 
import { options } from "../../config/options"; 
import socketIOClient from "socket.io-client";
Moment.globalFormat = 'D MMM YYYY hh mm A';
const cryptLib = require('@skavinvarnan/cryptlib');
const encryptKey = 'crypt@123';
var timerId = undefined; 
var typingTrack = 0; 

class MessageNew extends Component { 
    constructor(props) {
        super(props);
        this.onChangemessage = this.onChangemessage.bind(this);
        this.onSendmessage = this.onSendmessage.bind(this)
        this.reportToggle = this.reportToggle.bind(this);
        this.onClearchat = this.onClearchat.bind(this);
        this.onBlock = this.onBlock.bind(this);
        this.onUnfriend = this.onUnfriend.bind(this);
        this.groupData = this.groupData.bind(this);
        this.translateChat = this.translateChat.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this)
        this.onChange = this.onChange.bind(this)
        this.handleKeyUp = this.keyUpHandler.bind(this, 'msgInput')
        this.fileUpload = this.fileUpload.bind(this)
        this.SafetyLink = this.SafetyLink.bind(this); 
        this.changeHandler = this.changeHandler.bind(this);
        this.displayData = [];
        this.state = { 
            dropdownOpen: false, chats: [], user_details: '', message: '', response: false,
            endpoint: "https://blackroseinternational.com:8091", showdata: this.displayData,                
            postVal: "", loading: 'd-none', images: '', errors: {}, chat_image: '',
            modal1: false, 
            activeTab: '1', blocked_by_me: '', block: '', blocked_popup: '',
            modal2: false, limit: 8, loadmore: 'none',
            lang: ls.get('LangKeySet') || options[0],
            langKey: ls.get('LangValue'),     
            modal3: false, api_url: localStorage.getItem('api_url'),chatId:'',
            languageCodes: [],
            language: "",
            scrollDownFix: false,
            loaderDisplay : false,
            //instantChatTrans: "",
        }
        
        const socket = socketIOClient(this.state.endpoint);
        const { match: { params } } = this.props;

        socket.on('broad',  (data)=> { 
            this.setState({ loading: 'd-none' });
            // console.log('SENDER ID'+data.sender_id+ " // "+params.userId);
            if(data.sender_id===params.userId){    
                this.addMsg(data);
            }
        });   
        
        socket.on('receivetyping',  (data)=> { 
            // console.log(data.message+' || '+data.receiver_id+' '+JSON.parse(localStorage.getItem('session_userid')));  

            if(data.message === 'type') { 
                if(data.receiver_id === JSON.parse(localStorage.getItem('session_userid'))) {
                    this.setState({ loading: 'loadingTxts' });
                }
            } else {
                 this.setState({ loading: 'd-none' }); 
            }
        });

        socket.on('receiveuntyping',  (data)=> {
            //console.log(data.receiver_id+' '+JSON.parse(localStorage.getItem('session_userid')));
            if(data.receiver_id === JSON.parse(localStorage.getItem('session_userid'))) {
                this.setState({ loading: 'd-none' });
            }
        });
    }

    //chat translation
    changeHandler = language => {
        localStorage.setItem('chatTransLang',JSON.stringify(language));
        this.setState({
            scrollDownFix: true,
            language: language,
        });
    }
    translateChat(question,language,index){
        this.setState({
            scrollDownFix: true,
        });
        let temparray = [...this.state.chats]
        if(question && language){
            googleTranslate.translate(question, language, (err, translation) => {
                if(translation.translatedText) {
                    temparray[index].message = translation.translatedText;
                    this.setState({
                    chats:temparray,
                    })
                }
            }); 
        }
                
    }
      groupData(xs, key){
          return xs.reduce(function(rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
          }, {});
      }

      addMsg(data) {
        // Append the message to the component state
        console.log("full data");
        console.log(data);
        const { t } = this.props;
        const chats = this.state.chats;
        var months    = ['january','february','march','april','may','june','july','august','september','october','november','december'];  
        var d = new Date(parseInt(data.message.chatTime)*1000);
        var dt = d.getDate();
        var mon = months[d.getMonth()];
        var yr = d.getFullYear();
        var mydate = dt+', '+t(mon)+' '+yr;
        var transLangCode = 'en';
        var transtext ='';
        if(data.message.type ==='text'){
            var transtext = cryptLib.decryptCipherTextWithRandomIV(data.message.message,encryptKey);
            if(JSON.parse(localStorage.getItem('chatTransLang'))) {
                transLangCode = JSON.parse(localStorage.getItem('chatTransLang')).length===4 ? 'en' : JSON.parse(localStorage.getItem('chatTransLang'));
            }
            googleTranslate.translate(transtext,transLangCode, (err, translation) => {
                if(translation.translatedText) {
                    if(data.sender_id === JSON.parse(localStorage.getItem('session_userid'))) {
                        const recmsg = {
                            "message":transtext,
                            "sender_id":data.sender_id,
                            "receiver_id":data.receiver_id,
                            "image":"",
                            "date":mydate
                        }
                        chats.push(recmsg);
                        this.setState({ chats });
                        this.setState({
                        scrollDownFix: false,
                        })
                    } else {
                        const recmsg = {
                            "message":translation.translatedText,
                            "sender_id":data.sender_id,
                            "receiver_id":data.receiver_id,
                            "image":"",
                            "date":mydate
                        }
                        chats.push(recmsg);
                        this.setState({ chats });
                        this.setState({
                        scrollDownFix: false,
                        })
                    }
                    
                } else {
                    const recmsg = {
                        "message":transtext,
                        "sender_id":data.sender_id,
                        "receiver_id":data.receiver_id,
                        "image":"",
                        "date":mydate
                    }
                    chats.push(recmsg);
                    this.setState({ chats });
                    this.setState({
                    scrollDownFix: false,
                    })
                }
            });
           
        }else if(data.message.type ==='image'){
            const recmsg = {
                "message":"",
                "sender_id":data.sender_id,
                "receiver_id":data.receiver_id,
                "upload_image":data.message.upload_image,
                "date":mydate
            }
            chats.push(recmsg);
            this.setState({ chats });
        } else if(data.message.type ==='gif'){
            transtext = "Gif Images";
            if(JSON.parse(localStorage.getItem('chatTransLang'))) {
                transLangCode = JSON.parse(localStorage.getItem('chatTransLang')).length===4 ? 'en' : JSON.parse(localStorage.getItem('chatTransLang'));
            } else {
                transLangCode = 'en';
            }
            googleTranslate.translate(transtext,transLangCode, (err, translation) => {
                if(translation.translatedText) {
                    const recmsg = {
                        "message":translation.translatedText,
                        "sender_id":data.sender_id,
                        "receiver_id":data.receiver_id,
                        "image":"",
                        "date":mydate
                    }
                    chats.push(recmsg);
                    this.setState({ chats });
                } else {
                    const recmsg = {
                        "message":transtext,
                        "sender_id":data.sender_id,
                        "receiver_id":data.receiver_id,
                        "image":"",
                        "date":mydate
                    }
                    chats.push(recmsg);
                    this.setState({ chats });
                    this.setState({
                    scrollDownFix: false,
                    })
                }
            });
        } else if(data.message.type ==='audio_msg'){
            transtext = "Audio Message";
            if(JSON.parse(localStorage.getItem('chatTransLang'))) {
                transLangCode = JSON.parse(localStorage.getItem('chatTransLang')).length===4 ? 'en' : JSON.parse(localStorage.getItem('chatTransLang'));
            } else {
                transLangCode = 'en';
            }
            googleTranslate.translate(transtext,transLangCode, (err, translation) => {
                if(translation.translatedText) {
                    const recmsg = {
                        "message":translation.translatedText,
                        "sender_id":data.sender_id,
                        "receiver_id":data.receiver_id,
                        "image":"",
                        "date":mydate
                    }
                    chats.push(recmsg);
                    this.setState({ chats });
                } else {
                    const recmsg = {
                        "message":transtext,
                        "sender_id":data.sender_id,
                        "receiver_id":data.receiver_id,
                        "image":"",
                        "date":mydate
                    }
                    chats.push(recmsg);
                    this.setState({ chats });
                    this.setState({
                    scrollDownFix: false,
                    })
                }
            });
        }        
        // console.log(this.state.chats)
      }
    //  Scroll to Bottom Function
    scrollToBottom = () => {
        this.messagesEnd.scrollIntoView();
    }
    componentDidUpdate() {
        if(this.state.scrollDownFix === false)
            this.scrollToBottom();
    }
    componentDidMount() {
        /*console.log("sadasdasdasd");
        console.log(JSON.parse(localStorage.getItem('chatTransLang')));*/
        // load all of the language options from Google Translate to your app state
        googleTranslate.getSupportedLanguages("en", function(err, languageCodes) {
          getLanguageCodes(languageCodes); // use a callback function to setState
        });
        const getLanguageCodes = languageCodes => {
          this.setState({ languageCodes });
        };
        this.setState({
            loader: 'loder-icon'
        });
        setTimeout(() => {
            this.setState({
                profilespace: '',
                loader: 'none'
            });
        }, 2000);
        /* const { endpoint } = this.state;
        const socket = socketIOClient(endpoint);
       socket.on('broad',  (data)=> {
            console.log(data);
            this.setState({ response: data });
            this.append_receiver();
        }); */
        //socket.on("FromAPI", data => this.setState({ response: data }));
        const { match: { params } } = this.props;
        //console.log("params", params)
         
        this.setState({
             chatId: params.chatId
        });
       
        var dataProfile = new FormData();
        dataProfile.set('chat_userid', params.userId);
        dataProfile.set('chat_id', params.chatId);
        dataProfile.set('user_id', JSON.parse(localStorage.getItem('session_userid')));
        dataProfile.set('own_id', JSON.parse(localStorage.getItem('session_userid')));     
        dataProfile.set('language', this.state.langKey);  

        axios({
            method: 'post',
            url: this.state.api_url + 'webprofile',
            data: dataProfile,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then(res => {
                const user_details = res.data.result;
                if(res.data ===0){
                    this.props.history.push("/app/Message");
                    //alert("Access denied!");
                }
               // console.log("user_detail_full", user_details)
                this.setState({
                    user_details: user_details,
                });

            });
        var data_block = new FormData();
        data_block.set('user_id', JSON.parse(localStorage.getItem('session_userid')));
        data_block.set('chatid', params.chatId);
        data_block.set('language', this.state.langKey); 
        axios({
            method: 'post',
            url: this.state.api_url + 'blockstatus',
            data: data_block,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then(res => {
                const details = res.data;
                //console.log("bloc", res.data);
                this.setState({
                    block: details.block,
                    blocked_by_me: details.blocked_by_me,
                    chat_userstatus: details.chat_userstatus,
                });
                // alert(this.state.blocked_by_me)

            });
        var messages = new FormData();
        messages.set('user_id', JSON.parse(localStorage.getItem('session_userid')));
        messages.set('chat_id', params.chatId);
        messages.set('language', this.state.langKey); 
        axios({
            method: 'post',
            url: this.state.api_url + 'webgetchatmessage',
            data: messages,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then(res => {
                const data = res.data;
                //console.log(data);
                //var groubedByTeam=this.groupData(data.chats, 'date');
                //console.log(groubedByTeam);
                //console.log("indvidual_chatt", data)
                if (res.data.status !== 'false') {
                    this.setState({
                        chats: data.chats,
                    });
                }
            });

        const socket = socketIOClient(this.state.endpoint);    
        socket.emit('join', {
            join_id: JSON.parse(localStorage.getItem('session_userid')), 
        });
    }

    SafetyLink() { 
        this.props.history.push("/app/Help/2"); 
    }

    closeModal(tabId) {
        this.setState({
            [tabId]: false
        });
    }
    showModal(modal) {
        this.setState({
            [modal]: true
        });
        // console.log(this.state);
    }
    showModal2(modal) {
        this.setState({
            [modal]: true,
            activeTab: '2'
        });

    }
    showModal3(modal) {
        this.setState({
            [modal]: true,
            activeTab: '3'
        });
    }

    keyUpHandler(refName, e) {      
        const keycode = e.keyCode; 
        const { match: { params } } = this.props;
        const { endpoint } = this.state;
        const socket = socketIOClient(endpoint);

        if (typingTrack === 0 && keycode !== 13) {  
            socket.emit('typing', {
                chatid: params.chatId,
                sender_id: JSON.parse(localStorage.getItem('session_userid')),
                receiver_id: params.userId,
                message: 'type', 
            }); 
            typingTrack = 1; 
        }

        if (keycode === 13) {   
            this.onSendmessage();     
            return false; 
        } 

        if (typingTrack === 1) {
            clearTimeout(timerId); 
        }

        if(keycode !== 13) {  
            timerId = setTimeout(function () { 
                    typingTrack = 0; 

                    socket.emit('typing', {     
                        chatid: params.chatId,
                        sender_id: JSON.parse(localStorage.getItem('session_userid')),
                        receiver_id: params.userId,
                        message: 'untype',   
                    });  
                    // clearTimeout(timerId);  
            }, 2000);            
        }                   
    }

    onChangemessage(e) { 
        typingTrack = 0;   
        clearTimeout(timerId);      
        this.setState({
            message: e.target.value, 
        });  
    }

    onSendmessage() {
        typingTrack = 0;   
        clearTimeout(timerId);   

        this.setState({
            message: '', 
            scrollDownFix: false
        });
       // e.preventDefault()
        const { match: { params } } = this.props;
        //alert(params.userId);
        var messageInput = document.getElementById('messageinput');
        var newmsg =  messageInput.value;
        messageInput.value = "";
        if (newmsg.trim() !== "") {
            var send_message = new FormData();
            send_message.set('sender_id', JSON.parse(localStorage.getItem('session_userid')));
            send_message.set('chat_id', params.chatId);
            send_message.set('receiver_id', params.userId);
            send_message.set('message', newmsg);
            send_message.set('chat_time', '');
            send_message.set('upload_image', '');
            send_message.set('language', this.state.langKey); 

            const curtime = Math.floor(Date.now() / 1000).toString();
            this.scrollToBottom(); 
            this.addMsg({
                chatid: params.chatId,    
                sender_id: JSON.parse(localStorage.getItem('session_userid')),
                receiver_id: params.userId,
                transLanguage: JSON.parse(localStorage.getItem('chatTransLang')),
                message : {
                    message: cryptLib.encryptPlainTextWithRandomIV(newmsg, encryptKey),
                    upload_image:"",
                    userName:"",
                    userImage:"",
                    chatTime:curtime,
                    type:"text",
                } 
            });   

            axios({
                method: 'post',
                url: this.state.api_url + 'websendmessage',   
                data: send_message,
                config: { headers: { 'Content-Type': 'multipart/form-data' } }
            }).then(res => {
                    if (res.data.status !== 'false') {
                        const { endpoint } = this.state;
                        const socket = socketIOClient(endpoint);
                        socket.emit('message', {
                            chatid: params.chatId,   
                            sender_id: JSON.parse(localStorage.getItem('session_userid')),
                            receiver_id: params.userId,
                            transLanguage: JSON.parse(localStorage.getItem('chatTransLang')),
                            message : {
                                message: cryptLib.encryptPlainTextWithRandomIV(newmsg, encryptKey),
                                upload_image:"",
                                userName:"",
                                userImage:"",
                                chatTime:curtime,
                                type:"text",
                            }
                        });

                    } else {
                        this.props.history.push("/app/Message")    
                    }
                })    

        } else {
            let errors = {};
            errors["uploadimage"] = 'Please enter the message';  
            this.setState({
                errors: errors,
                suc_msg: 'errorMsg floatingAlerts1',
            });
            setTimeout(() => {
                this.setState({
                    suc_msg: 'errorMsghide'
                });
            }, 2000);


        }

    }
    componentWillMount() {
       
    }
    
    onFormSubmit(e) {
        this.fileUpload(this.state.images);
    }
    onChange(e) {
        var sFileName = e.target.files[0].name;
        this.setState({
            file: URL.createObjectURL(e.target.files[0])
        })
       
        let files = e.target.files || e.dataTransfer.files;
        var _validFileExtensions = [".png",".jpg",".jpeg",".gif",".JPG",".PNG",".JPEG",".GIF"];
        if (!files.length)
            return;
        else{
            
            var blnValid = false;
            for (var j = 0; j < _validFileExtensions.length; j++) {
                var sCurExtension = _validFileExtensions[j];
                if (sFileName.substr(sFileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() === sCurExtension.toLowerCase()) {
                    blnValid = true;
                    break;
                }
            }
            
            if (!blnValid) {
                alert("Sorry, Invalid file!");
                return false;
            }
                
            this.createImage(files[0]);
        }
    }
    createImage(file) {
        //console.log(file)
        let reader = new FileReader();
        reader.onload = (e) => {
            //console.log(e.target.result);
            this.setState({
                images: e.target.result
            })
        };
        reader.readAsDataURL(file);
        setTimeout(() => {
            this.onFormSubmit();
          }, 2000);
        
    }
    fileUpload(images) {
        const { match: { params } } = this.props;
        let errors = {};
        this.setState({
            loader: 'loder-icon'
        });
        if (this.state.images === null || this.state.images === '') {
            errors["uploadimage"] = 'Select a image';  
            this.setState({
                errors: errors,
                suc_msg: 'errorMsg floatingAlerts1',
            });
            setTimeout(() => {
                this.setState({
                    suc_msg: 'errorMsghide'
                });
            }, 2000);

        } else {
            this.setState({ 
                loaderDisplay : true,
            });
            const url = this.state.api_url + 'uploadimagechat';
            const formData = { file: this.state.images, language:this.state.langKey  }
           
            return post(url, formData)
                .then(res => {
                    //console.log("image_tmp", res.data)

                   if (res.data.status === 'true') {
                      this.setState({ 
                            loaderDisplay : false,
                        });
                        const { endpoint } = this.state;
                        const socket = socketIOClient(endpoint);
                       
                        const curtime = Math.floor(Date.now() / 1000).toString();

                        this.addMsg({
                            chatid: params.chatId,
                            sender_id: JSON.parse(localStorage.getItem('session_userid')),
                            receiver_id: params.userId,
                            transLanguage: JSON.parse(localStorage.getItem('chatTransLang')),
                            message : {
                                message: "",
                                upload_image:res.data.imageurl,
                                userName:"",
                                userImage:"",
                                chatTime:curtime,
                                type:"image",
                            }
                        });   

                        var send_message = new FormData();
                        send_message.set('sender_id', JSON.parse(localStorage.getItem('session_userid')));
                        send_message.set('chat_id', params.chatId);
                        send_message.set('receiver_id', params.userId);
                        send_message.set('message', '');
                        send_message.set('chat_time', '');
                        send_message.set('upload_image', res.data.image_name);
                        send_message.set('language', this.state.langKey); 
                        axios({
                            method: 'post',
                            url: this.state.api_url + 'websendmessage',
                            data: send_message,
                            config: { headers: { 'Content-Type': 'multipart/form-data' } }
                        }).then(result => {
                            if (result.data.status !== 'false') {
                                socket.emit('message', {
                                    chatid: params.chatId, 
                                    sender_id: JSON.parse(localStorage.getItem('session_userid')),
                                    receiver_id: params.userId,
                                    transLanguage: JSON.parse(localStorage.getItem('chatTransLang')),
                                    message : {
                                        message: "",
                                        upload_image:res.data.imageurl, 
                                        userName:"",
                                        userImage:"",
                                        chatTime:curtime,
                                        type:"image",
                                    }
                                });   
                            }
                        }) 

                    } else {
                        this.setState({ 
                            loaderDisplay : false,
                        });
                        errors["uploadimage"] = res.data.message;
                        this.setState({
                            errors: errors,
                            suc_msg: 'errorMsg floatingAlerts1',
                        });
                        setTimeout(() => {
                            this.setState({
                                suc_msg: 'errorMsghide'
                            });
                        }, 4000);
                    }


                })
        }
    }

    onClearchat() {
        const { match: { params } } = this.props;
        var clearchat = new FormData();
        clearchat.set('user_id', JSON.parse(localStorage.getItem('session_userid')));
        clearchat.set('chat_id', params.chatId);
        clearchat.set('language', this.state.langKey); 
        axios({
            method: 'post',
            url: this.state.api_url + 'webclearchat',
            data: clearchat,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then(res => {
                // const message = res.data.message;

                this.props.history.push("/app/Message")


            })

    }
    onBlock() {
        const { match: { params } } = this.props;
        var clearchat = new FormData();
        clearchat.set('user_id', JSON.parse(localStorage.getItem('session_userid')));
        clearchat.set('block_user_id', params.userId);
        clearchat.set('language', this.state.langKey); 
        axios({
            method: 'post',
            url: this.state.api_url + 'webblockchat',
            data: clearchat,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then(res => {
                // const message = res.data.message;
                this.props.history.push("/app/Message")


            })

    }
    onUnfriend() {
        //alert("sdsd");
        const { match: { params } } = this.props;
        var clearchat = new FormData();
        clearchat.set('user_id', JSON.parse(localStorage.getItem('session_userid')));
        clearchat.set('unfriend_user_id', params.userId);
        clearchat.set('language', this.state.langKey);   
        axios({
            method: 'post',
            url: this.state.api_url + 'webunfriend',
            data: clearchat,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then(res => {
               //  const message = res.data.message;

                this.props.history.push("/app/Message")


            })

    }
    reportToggle() { this.setState(prevState => ({ dropdownOpen: !prevState.dropdownOpen })); }

    render() {
        var style = {
            zIndex:10,
            backgroundColor:"#fff",
            height:40,
            width:40,
            position:"absolute",
            //display:"flex",
            justifyContent:"center",
            alignItems:"center",
            display: this.state.loaderDisplay === true ? "block" : "none" ,
            overflowY:"hidden"
          };
        var transLangCode;
        if(JSON.parse(localStorage.getItem('chatTransLang'))) {
            transLangCode = JSON.parse(localStorage.getItem('chatTransLang')).length === 4 ? '0' : JSON.parse(localStorage.getItem('chatTransLang'));
        } else {
            transLangCode = '0';
        }
        const { t } = this.props; 
        const { languageCodes } = this.state;
        let online_status, blocked_by_me, block_info, msg_sec, onlinebutton, blocked_popup, chat_userstatus;
        if (this.state.user_details.online === 1 && this.state.block !== 'true') {
            online_status = t('Online');    
            // lastseen = ''
            onlinebutton = 'onlineN2';
        } else if(this.state.block !== 'true'){
            // lastseen = 'last seen'
            // online_status = <Moment format="DD MMM YYYY, hh:mm A" unix>{this.state.user_details.timestamp}</Moment>
            online_status = this.state.user_details.onlinelasttime;    
              
            onlinebutton = ''
        }
        if (this.state.blocked_by_me === 'true') {
            blocked_by_me = 'unblock'
            chat_userstatus = ''
            blocked_popup = 'unblockpopup' 
            block_info = 'Unblock to message'
        } else {
            blocked_by_me = 'block'
            chat_userstatus = ''
            if (this.state.block === 'true') {
                blocked_by_me = ''
                chat_userstatus = ''
                blocked_popup = 'blockpopup'
                block_info = 'This User has Blocked you'  
            } else if(this.state.chat_userstatus === 'true') {
                blocked_by_me = ''
                blocked_popup = 'blockpopup'
                chat_userstatus = 'true'
                block_info = 'This account has been deleted.'
            }
        }
        if (this.state.block === 'true' || this.state.chat_userstatus === 'true') {
            msg_sec = <div className="mMessageInputs d-flex">

                <div className="align-self-center flex-fill">
                    <input class="chat-box-text border-none apd10 width100" name="text" value={this.state.message} placeholder={t("Send Message")}></input> 
                </div>


                <div class="align-self-center mr-3 d-flex">

                    <div className={this.state.suc_msg}><Trans i18nKey={this.state.errors.uploadimage}>{this.state.errors.uploadimage}</Trans></div> 
                </div>

                <div class="align-self-center">
                    <input type="button" class="send-option border-none" name="button" />
                </div>


            </div>
        } else {            
            msg_sec = <div className="mMessageInputs d-flex">

                <div className="align-self-center flex-fill">
                    
                    <input class="chat-box-text border-none apd10 width100" name="text" id="messageinput" value={this.state.message} onKeyUp={this.handleKeyUp} ref="msgInput" onChange={this.onChangemessage} rows={5} maxLength={500} placeholder={t("Send Message")}></input> 
                    
                </div>


                <div class="align-self-center mr-3 d-flex">

                    <form onSubmit={this.onFormSubmit} className="d-flex">
                        <div className="loder-icon">
                            <div style={style} className="loader_web">
                                <Loader type="Bars" color="#DD6193" height={30} width={50} />
                            </div>
                        </div>
                        <label for="file-upload" class="attachment-file amg10"></label>
                        <input id="file-upload" type="file" onChange={this.onChange} />
                    </form>



                    <div className={this.state.suc_msg}><Trans i18nKey={this.state.errors.uploadimage}>{this.state.errors.uploadimage}</Trans></div>    
                </div>

                <div class="align-self-center">
                    <input type="button" class="send-option border-none" onClick={this.onSendmessage} name="button" />
                </div>
            </div>

        }
        /*var style = {
            zIndex: 10,
            backgroundColor: "white",
            position: "absolute",
            height: "80vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            //width:"100%",
            overflowY: "hidden"
        };*/



        return (
            <div className="setting-page messageFlex">

                <div className="messageSection">

                    <div className="messageSpace messageSpaceH">

                        <div className="messagess messagee">
                            <div className="media">


                                <div className="align-self-center ">
                                    <NavLink to="/app/Message">
                                        <div className="iconHolder align-self-center mr-1">
                                            <div className="backGlow">
                                                <div className="backIco"></div>
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>

                                {/*<div className="align-self-center mx-3 relPos"> 
                                    <NavLink to={`/app/Friends/FriendsNew/${this.state.user_details.user_id}`}> <img src={this.state.user_details.images} alt="" className="message-profile " /></NavLink>
                                    <div class={onlinebutton}></div>
                                </div>*/}

                                <div className="align-self-center mx-3 relPos"> 
                                    {chat_userstatus !== "" ? <img src={this.state.user_details.images} alt="" className="message-profile "/> : <NavLink to={`/app/Friends/FriendsNew/${this.state.user_details.user_id}`}><img src={this.state.user_details.images} alt="" className="message-profile " /></NavLink>}
                                    {/*<NavLink to={`/app/Friends/FriendsNew/${this.state.user_details.user_id}`}> <img src={this.state.user_details.images} alt="" className="message-profile "/></NavLink>*/}
                                    <div class={onlinebutton}></div>
                                </div>

                                <div className="customSelect">
                                    <div className="media-body messages-flex">
                                        <div className="text-truncate  pointCurs">{this.state.user_details.user_name}</div>
                                        <div className="messages-overview text-truncate secondaryText">
                                            {online_status}</div>
                                    </div>

                                    <select
                                      className="select-language"
                                      value={JSON.parse(localStorage.getItem('chatTransLang'))===""?"none":JSON.parse(localStorage.getItem('chatTransLang'))}
                                      onChange={e => this.changeHandler(e.target.value)}
                                    >
                                    <option key="none" value="none"> None </option>
                                      {languageCodes.map(lang => (
                                        <option key={lang.language} value={lang.language}>
                                          {lang.name}
                                        </option>
                                      ))}
                                    </select>
                                </div>
                                <div className="align-self-center ml-1 d-flex">
                                  {/*<div className="iconHolder">
                                        <div className="backGlow">
                                            <div className="videoMICo"></div>
                                        </div>
                                    </div>
                                    <div className="iconHolder ml-2">
                                        <div className="backGlow">
                                            <div className="phoneMICo"></div>
                                        </div>
                                    </div>*/}

                                    <div className="iconHolder">
                                        <div className="backGlow">
                                            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.reportToggle}>
                                                <DropdownToggle className="RSoverride messageDropdown">
                                                    <div className="threeDotMenuIcon"></div>
                                                </DropdownToggle>
                                                <DropdownMenu className="dropDownRSoverride reportDropPos">
                                                    {blocked_by_me !== "" ?
                                                        <div className="apb5 apl15 pointCurs textcapfirst"> <button className="textBtn" onClick={this.showModal.bind(this, 'modal1')}><Trans i18nKey={blocked_by_me}>{blocked_by_me}</Trans></button></div>
                                                    : ""
                                                    }   
                                                    <div className="apb5 apl15 pointCurs"><button className="textBtn" onClick={this.showModal2.bind(this, 'modal2')}> <Trans i18nKey="Clear Chat">Clear Chat</Trans></button></div>
                                                    <div className="apb5 apl15 pointCurs"><button className="textBtn" onClick={this.SafetyLink}> <Trans i18nKey="Safety Tips">Safety Tips</Trans></button></div>   
                                                    {chat_userstatus !== "" ?
                                                        "" : <div className="apb5 apl15 pointCurs"><button className="textBtn" onClick={this.showModal3.bind(this, 'modal3')}> <Trans i18nKey="Unfriend">Unfriend</Trans></button></div>
                                                    } 
                                                </DropdownMenu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>

                        <div className="mChatbox apd15">
                            <div className="encryptTips" style={{"display":this.state.chats.length === 0 ? "block":"none"}}>
                                <i class="fa fa-lock" aria-hidden="true"></i>
                                <Trans i18nKey="Message to this chat and calls are now secured with end-to end encryption.">Message to this chat and calls are now secured with end-to end encryption.</Trans>
                            </div>
                            <div class="blockedInfo"><Trans i18nKey={block_info}>{block_info}</Trans> </div> 

                            {this.state.chats.map(((chats,index) =>
                                chats.type === "feed" ?
                                    <div className={chats.sender_id === JSON.parse(localStorage.getItem('session_userid')) ? "mRight mLeft apt5 apb5 d-flex flex-column" : "mLeft apt5 apb5  d-flex flex-column"}>
                                        <div className={chats.sender_id === JSON.parse(localStorage.getItem('session_userid')) ? "mRightChat  apd15 align-self-end mb-4" : "mLeftChat apk-14 apd15  align-self-start mb-4"}>
                                            <div class="messageImgs"> 

                                                {chats.feeds.feed_type==="image" ?
                                                    <ImageZoom image={{ src: chats.feeds.feed_content_images[0], alt: 'Golden Gate Bridge', className: 'img w-100 boxShadow borderRdus', }} />
                                                :
                                                    <ImageZoom image={{ src: this.state.user_details.images, alt: 'Golden Gate Bridge', className: 'img w-100 boxShadow borderRdus', }} />                                                    
                                                }  

                                                {chats.feeds.feed_type!=="image" ?
                                                    <div class="feedMsgContent pFHidden align-self-center">
                                                        <div class="text-truncate esmh-14 bold-font"><Trans i18nKey={chats.feeds.feed_type}>{chats.feeds.feed_type}</Trans></div>   
                                                        <div class="text-truncate bold-font esmh-12">{chats.feeds.feed_content}</div>
                                                    </div>  
                                                    : ""
                                                }
                                                <div class="feedMsgsLiked"></div> 
                                                <div class="feedMsgs">{chats.message}</div> 

                                            </div>

                                            <div className={chats.sender_id === JSON.parse(localStorage.getItem('session_userid')) ? "msgTimerFxRight" : "msgTimerFxleft"}>
                                                {chats.date}
                                                
                                                  
                                            </div>
                                        </div>
                                    </div> 
                                :
                                (chats.upload_image && chats.type !== "gif" ?

                                    <div className={chats.sender_id === JSON.parse(localStorage.getItem('session_userid')) ? "mRight mLeft apt5 apb5 d-flex flex-column" : "mLeft apt5 apb5  d-flex flex-column"}>
                                        <div className={chats.sender_id === JSON.parse(localStorage.getItem('session_userid')) ? "mRightChat  apd15 align-self-end mb-4" : "mLeftChat apk-14 apd15  align-self-start mb-4"}>

                                            <div class="messageImgs"> <ImageZoom image={{ src: chats.upload_image, alt: 'Golden Gate Bridge', className: 'img w-100 boxShadow borderRdus', }} /></div>
                                            <div className={chats.sender_id === JSON.parse(localStorage.getItem('session_userid')) ? "msgTimerFxRight" : "msgTimerFxleft"}>{chats.date}
                                    
                                                <div className="modal fade apd10" id={ "exampleModal-"+chats.upload_image.split('chat/')[1].split('.')[0]+""} tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div className="modal-dialog vertically-center" role="document">
                                                        <div className="modal-content border-radius ">
                                                            <div className="modal-body profile-page ">
                                                                <img class="chatimgdiv" src={chats.upload_image} alt="product" />

                                                            </div>  
                                                        </div>  
                                                    </div>  
                                                </div>


                                                 <Modal id={ "exampleModal-"+chats.upload_image.split('chat/')[1].split('.')[0]+""} isOpen={this.state.modal3} toggle={this.closeModal.bind(this, 'modal3')} className="premiumPopup vertically-center apd5">
                                                    <ModalHeader toggle={this.closeModal.bind(this, 'modal3')}><h4 class="modal-title"><Trans i18nKey="Do you want unfriend this user ?">Do you want unfriend this user ?</Trans></h4></ModalHeader>
                                                    <ModalBody>
                                                        <div className="modal-body">
                                                            <div class="d-flex justify-content-end">
                                                                <div class="mr-3"><button class="border-0 bg-white pink-color" onClick={this.onUnfriend}><Trans i18nKey="Yes">Yes</Trans></button></div>
                                                                <div><button class="border-0 bg-white" onClick={this.closeModal.bind(this, 'modal3')}><Trans i18nKey="No">No</Trans></button></div>
                                                            </div> 
                                                        </div>
                                                    </ModalBody>
                                                </Modal> 
                                            
                                            </div>
                                         

                                        </div>
                                    </div>
                                    :
                                    <div className={chats.sender_id === JSON.parse(localStorage.getItem('session_userid')) ? "mRight mLeft apt5 apb5 d-flex flex-column" : "mLeft apt5 apb5  d-flex flex-column"}>
                                        <div className={chats.sender_id === JSON.parse(localStorage.getItem('session_userid')) ? "mRightChat  apd15 align-self-end mb-4" : "mLeftChat apk-14 apd15  align-self-start mb-4"}>
                                            <div>{chats.message}<br/>
                                            {chats.sender_id === JSON.parse(localStorage.getItem('session_userid')) ? "" : <a href="#" style={{"display":(transLangCode==='0') ? "none":"block"}} onClick={this.translateChat.bind(this, chats.message,JSON.parse(localStorage.getItem('chatTransLang')),index)}><div className="translateImg"><img src={require('../../assets/img/translatesIcon.png')} alt="transIcon" width="20px"/></div></a>}
                                            </div><div className={chats.sender_id === JSON.parse(localStorage.getItem('session_userid')) ? "msgTimerFxRight" : "msgTimerFxleft"}>{chats.date}
                                            </div>
                                        </div>
                                    </div>)  
                                    

                            ))}
                            <div ref={(el) => { this.messagesEnd = el; }}> </div>
                        </div>

                        <div class={this.state.loading}>
                            <span><Trans i18nKey="Typing">Typing</Trans></span> <span class="blinking">.</span> <span class="blinking1">.</span> <span class="blinking2">.</span>
                        </div>
                        
                        {msg_sec}


                    </div>



                </div>






                <Modal isOpen={this.state.modal1} toggle={this.closeModal.bind(this, 'modal1')} className="premiumPopup vertically-center apd5">
                    <ModalHeader toggle={this.closeModal.bind(this, 'modal1')}><h4 class="modal-title"><Trans i18nKey={blocked_popup}>Do you want to {blocked_by_me} this user ?</Trans></h4></ModalHeader>
                    <ModalBody>
                        <div className="modal-body">
                            <div class="d-flex justify-content-end">
                                <div class="mr-3"><button class="border-0 bg-white pink-color" onClick={this.onBlock}><Trans i18nKey="Yes"><span className="floating-label">Yes</span> </Trans></button></div>
                                <div><button class="border-0 bg-white" onClick={this.closeModal.bind(this, 'modal1')}><Trans i18nKey="No"><span className="floating-label">No</span> </Trans></button></div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>


                <Modal isOpen={this.state.modal2} toggle={this.closeModal.bind(this, 'modal2')} className="premiumPopup vertically-center apd5"> 
                    <ModalHeader toggle={this.closeModal.bind(this, 'modal2')}><h4 class="modal-title"><Trans i18nKey="Do you want to clear chat ?">Do you want to clear chat ?</Trans></h4></ModalHeader>
                    <ModalBody>
                        <div className="modal-body">
                            <div class="d-flex justify-content-end">
                                <div class="mr-3"><button class="border-0 bg-white pink-color" onClick={this.onClearchat}><Trans i18nKey="Yes"><span className="floating-label">Yes</span> </Trans></button></div>
                                <div><button class="border-0 bg-white" onClick={this.closeModal.bind(this, 'modal2')}><Trans i18nKey="No"><span className="floating-label">No</span> </Trans></button></div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>

                <Modal isOpen={this.state.modal3} toggle={this.closeModal.bind(this, 'modal3')} className="premiumPopup vertically-center apd5">
                    <ModalHeader toggle={this.closeModal.bind(this, 'modal3')}><h4 class="modal-title"><Trans i18nKey="Do you want unfriend this user ?">Do you want unfriend this user ?</Trans></h4></ModalHeader>
                    <ModalBody>
                        <div className="modal-body">
                            <div class="d-flex justify-content-end">
                                <div class="mr-3"><button class="border-0 bg-white pink-color" onClick={this.onUnfriend}><Trans i18nKey="Yes">Yes</Trans></button></div>
                                <div><button class="border-0 bg-white" onClick={this.closeModal.bind(this, 'modal3')}><Trans i18nKey="No">No</Trans></button></div>
                            </div>
                        </div>
                    </ModalBody> 
                </Modal>

                
                 {/* <Modal isOpen={this.state.modal4} toggle={this.closeModal.bind(this, 'modal4')} className="premiumPopup vertically-center apd5">
                    <ModalHeader toggle={this.closeModal.bind(this, 'modal4')}></ModalHeader>
                    <ModalBody>
                        <div className="modal-body">
                           <div class="d-flex justify-content-end">

                         <img src="{chats.upload_image}"></img>
                           </div>
                        </div>
                    </ModalBody>
                </Modal> */}

            </div>

        );
    }
}
  
export default withTranslation("translations")(MessageNew); 

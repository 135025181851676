import React, { Component } from 'react';
import { Trans, withTranslation } from "react-i18next";
import "../../config/i18n";

import ls from "local-storage"; 
import { options } from "../../config/options";

import { NavLink } from 'react-router-dom';
import Swiper from 'react-id-swiper';  
import axios from 'axios';
import { Dropdown, DropdownToggle, DropdownMenu, Modal, ModalHeader, ModalBody } from 'reactstrap';
// import { BrowserRouter as Router, Route } from "react-router-dom";
import Slider from "react-slick";
import Loader from 'react-loader-spinner';

class FriendRequestNew extends Component {
    constructor(props) {
        super(props);
        this.reportToggle = this.reportToggle.bind(this);
        this.SetReport = this.SetReport.bind(this);
        this.Message = this.Message.bind(this); 
        this.state = {
            dropdownOpen: false,
            profile_userid:'',
            user_details:'',
            like:'like',
            superlike:'superlike',
            superlikeItems: [],
            superlikeCount: 0,
            superlikePlanStatus: 0,
            selected_superlikepremium:'',
            currency:'',payment:'',license_key:'',superlikepremium_price:'',
            lang: ls.get('LangKeySet') || options[0],
            langKey: ls.get('LangValue'),     

            image:[],api_url:localStorage.getItem('api_url'),
            interest:[],report:'',value_id:'',suc_msg:'',errors:'',loader:'',profilespace:'none'
        }
    }
    
    addDefaultSrc(ev){
        ev.target.src =  require('../../assets/img/howzu-logo-default.png')
    }

    componentDidMount() {
        this.setState({
            loader:'loder-icon'
        });
        setTimeout(() => {
            this.setState({
            profilespace: 'ProfileSpace',
            loader: 'none'
            });
        }, 300);

        const { match: { params } } = this.props;
        
        var profile = new FormData();
        profile.append('user_id',JSON.parse(localStorage.getItem('session_userid')));
        profile.append('friend_id', params.userId);
        profile.append('language', this.state.langKey); 

        axios({
            method: 'post',
            url: this.state.api_url+'webprofilenew',
            data: profile,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        }).then(res => {
            const user_details = res.data.result;
            //console.log("user_details",user_details);

            if( res.data.status === "true"){
                if(user_details.report === "false"){
                    this.setState({ report: "Report User"});
                } else {
                    this.setState({ report: "Unreport User"});
                }
                this.setState({
                    user_details: user_details,
                    profile_userid: user_details.user_id,
                    image: user_details.imagess,
                    interest: user_details.interest_other,
                }); 
            }
        }).catch(error => {
            this.props.history.push("/app/NotFound");
        });

        var visitprofile = new FormData();
        visitprofile.append('visit_user_id',params.userId);
        visitprofile.append('language', this.state.langKey);   
        visitprofile.append('user_id', JSON.parse(localStorage.getItem('session_userid')));
        axios({
            method: 'post',
            url: this.state.api_url+'webvisitprofile',
            data: visitprofile,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })  
    }
   
    closeModal(tabId) {
        this.setState({ 
            [tabId]: false
        });
    }
   
    showModal(modal) { 
        if(modal === "modal3") {
            var profile = new FormData();
            profile.append('user_id',JSON.parse(localStorage.getItem('session_userid')));
            profile.append('language', this.state.langKey); 
               
            axios({
                method: 'post',
                url: this.state.api_url+'webgetcheck', 
                data: profile,
                config: { headers: {'Content-Type': 'multipart/form-data' }}
            }).then(res => {
                // const user_details = res.data.result; 

                if( res.data.status === "true") {
                    if(res.data.superlike_count === "0") {
                        var premiumlist = new FormData();
                        premiumlist.append('user_id',JSON.parse(localStorage.getItem('session_userid')));
                        premiumlist.append('platform', 'web'); 
                        premiumlist.append('language', this.state.langKey); 

                        axios({
                            method: 'post', 
                            url: this.state.api_url+'websuperlikelist', 
                            data: premiumlist,
                            config: { headers: {'Content-Type': 'multipart/form-data' }}
                        }).then(res => {
                            if(res.data.status === "true") {
                                const data = res.data; 
                                             
                                this.setState({
                                    superlikeItems: data.result, 
                                    activeItem: data.result[0].id,
                                    selected_superlikepremium: data.result[0].id,
                                    superlikepremium_price: data.result[0].price,
                                    currency: data.currency,
                                    payment: data.payment,
                                    license_key: data.license_key,
                                    superlikePlanStatus: 1,
                                    superlikeCount: data.superlike_count,   
                                });
                                localStorage.setItem('selected_superlikepremium',JSON.stringify(this.state.selected_superlikepremium));
                                localStorage.setItem('superlikepremium_price',JSON.stringify(this.state.superlikepremium_price));
                                localStorage.setItem('currency',JSON.stringify(this.state.currency));

                                this.showModal('modal6');  
                            } 
                        }) 
                    } else {
                        this.setState({ 
                            [modal]: true
                        }); 
                    }
                } else {
                    this.setState({ 
                        [modal]: true
                    });
                }
            }) 
        } else { 
            this.setState({
                [modal]: true
            });
        } 
        // console.log(this.state);
    }

    showModal2(modal) {
        this.setState({
            [modal]: true,
            activeTab: '2'
        });
        
    }
    
    handleFriendRequest(user_id, reqType) { 
        let errors = {};  
        
        var match = new FormData();
        match.append('user_id', JSON.parse(localStorage.getItem('session_userid')));
        match.append('follow_id', user_id);
        match.append('type', reqType);  
        match.append('language', this.state.langKey); 
        
        axios.defaults.headers.common['Authorization'] = JSON.parse(localStorage.getItem('session_token'));
        axios({
            method: 'post',
            url: this.state.api_url+'webmatch',
            data: match,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        }).then(res => {
            if(res.data.status === "true") {  
                (reqType === "superlike") ? this.closeModal('modal3') : this.closeModal('modal1');  
                
                if(res.data.match === "1") { 
                    this.showModal('modal5');  
                } else {  
                    errors["frndreq"] = res.data.message; 
                    setTimeout(() => {
                        this.setState({
                            errors: errors,
                            suc_msg: 'successMsg floatingAlerts1'  
                            //suc_msg: 'successMsghide', 
                        });
                    }, 2000); 
                }  
            } else {
                (reqType === "superlike") ? this.closeModal('modal3') : this.closeModal('modal1');  

                if(reqType === "superlike" && res.data.superlike_count === "0") {   
                    var premiumlist = new FormData();
                    premiumlist.append('user_id',JSON.parse(localStorage.getItem('session_userid')));
                    premiumlist.append('platform', 'web'); 
                    premiumlist.append('language', this.state.langKey); 

                    axios({
                        method: 'post', 
                        url: this.state.api_url+'websuperlikelist', 
                        data: premiumlist,
                        config: { headers: {'Content-Type': 'multipart/form-data' }}
                    }).then(res => {
                        if(res.data.status === "true") {
                            const data = res.data; 
                                         
                            this.setState({
                                superlikeItems: data.result, 
                                activeItem: data.result[0].id,
                                selected_superlikepremium: data.result[0].id,
                                superlikepremium_price: data.result[0].price,
                                currency: data.currency,
                                payment: data.payment,
                                license_key: data.license_key,
                                superlikePlanStatus: 1,
                                superlikeCount: data.superlike_count,   
                            });
                            localStorage.setItem('selected_superlikepremium',JSON.stringify(this.state.selected_superlikepremium));
                            localStorage.setItem('superlikepremium_price',JSON.stringify(this.state.superlikepremium_price));
                            localStorage.setItem('currency',JSON.stringify(this.state.currency));

                            this.showModal('modal6');  
                        } 
                    });

                } else {

                    errors["frndreq"] = res.data.message; 
                    setTimeout(() => {
                        this.setState({
                            errors: errors,
                            suc_msg: 'successMsg floatingAlerts1'  
                            //suc_msg: 'successMsghide', 
                        });
                    }, 2000); 
                    // window.location.reload(); 
                } 
            }          
        }) 
    }

    handleSuperlikeItemClick(index) { 
        this.setState({
            activeItem: index,
            selected_superlikepremium: index,
        })
        
        var premiumsuperlikelist = new FormData();
        premiumsuperlikelist.set('premiumsuperlikeid', index);
        premiumsuperlikelist.set('language', this.state.langKey); 
        
        axios({
            method: 'post',
            url: this.state.api_url+'premiumsuperlikeid',
            data: premiumsuperlikelist,   
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        }).then(res => {
            const data = res.data;
            this.setState({
                superlikepremium_price: data.result.price, 
            });    
            localStorage.setItem('selected_superlikepremium',JSON.stringify(index));
            localStorage.setItem('superlikepremium_price',JSON.stringify(data.result.price));   
        });  
    }  

    disLike(user_id) {
        let errors = {}; 
        errors["frndreq"] = 'User declined sucessfully'; 
        
        this.setState({
            errors: errors,
            suc_msg: 'successMsg floatingAlerts1',
        });
        setTimeout(() => {
            this.setState({
                suc_msg: 'successMsghide'
            });
        }, 2000); 
        
        var match = new FormData();
        match.append('user_id', JSON.parse(localStorage.getItem('session_userid')));
        match.append('follow_id', user_id);
        match.append('language', this.state.langKey); 
        
        axios.defaults.headers.common['Authorization'] = JSON.parse(localStorage.getItem('session_token'));
        axios({
            method: 'post',
            url: this.state.api_url+'webunmatch',
            data: match,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        }).then(res => {
            // console.log(res.data.status);
            this.props.history.push("../../FriendRequests");   
            // window.location.reload();   
        })
    }

    Message() {
        var report = new FormData();
        report.append('sender_id', JSON.parse(localStorage.getItem('session_userid')));
        report.append('receiver_id',this.state.profile_userid);
        report.append('language', this.state.langKey); 

        axios({
            method: 'post',
            url: this.state.api_url+'webcreatchat',
            data: report,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        }).then(res => {
            const chatid = res.data.chatid;
            // console.log("chatid",chatid);
            this.props.history.push(`/app/Message/MessageNew/${this.state.profile_userid}/${chatid}`)
        })

    }
    
    reportToggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen 
        }));
    }
    
    SetReport(e) {
        var report = new FormData();
        report.append('user_id', JSON.parse(localStorage.getItem('session_userid')));
        report.append('report_user_id',this.state.profile_userid);
        report.append('language', this.state.langKey); 

        axios({
            method: 'post',
            url: this.state.api_url+'webreport',
            data: report,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        }).then(res => {
            // const user_details = res.data.result; 
            // console.log("profile_det",res.data);  
            if(this.state.report === "Report User"){
                this.setState({ report: "Unreport User"});  
            } else {
                this.setState({ report: "Report User"});    
            }
        })
        this.closeModal('modal4');
    }
    
    render() {
        const { t } = this.props;
        
        let age,loc,txt,bio,job,company,school, borderFlag = 0;  
        if(this.state.user_details.show_age === 'true'){
         age =''
        }else{
         age = this.state.user_details.age;
        }
        if(this.state.user_details.show_location === 'true'){
        loc = '';
        txt = '';
        }else{
        txt = 'Lives In '    
        loc = this.state.user_details.location;   
        }
        /*var settings = {
            dots: true,
            infinite: false,
            afterChange: current => this.setState({ activeSlide2: current })
          };*/
        const settings = {
            dots: true,
            arrows:false,
            infinite: false,
            speed: 500,
            autoplay: true, 
            slidesToShow: 1,
            slidesToScroll: 1, 
          };
        /*const params = {
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            spaceBetween: 1
        }*/
        if(this.state.user_details.bio){
            bio =<div className="interestsHolder apt5 apk-16 border-top breakall"> 
            <div className="apt10 apb10">
            {this.state.user_details.bio}
            </div>
         </div>
           }else{
            bio = '' 
           }

        if(this.state.user_details.job) {
            borderFlag = 1;
            job = <div className="interestsHolder apb5 apk-16 breakall">
                {this.state.user_details.job}
            </div> 
        }   

        if(this.state.user_details.company) {
            borderFlag = 1;
            company = <div className="interestsHolder apb5 apk-16 breakall">
                {this.state.user_details.company}
            </div> 
        }

        if(this.state.user_details.school) {   
            borderFlag = 1;
            school = <div className="interestsHolder apb5 apk-16 breakall"> 
                {this.state.user_details.school} 
            </div>
        }    

           var style = {
            zIndex:10,
            backgroundColor:"white",
            height:"80vh",
            width:"100%",
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
            //width:"100%",
            overflowY:"hidden"
          };

        const params2 = { 
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev'
            },   
            slidesPerView: 3,
            slidesPerColumn: 1,
            breakpoints: {
                360: {
                    slidesPerView: 3, 
                },
                359: {
                    slidesPerView: 2,
                }
            }
        };

        return (

            <div className="setting-page">

                <div className="d-flex profile-header-top stickyProfileHead justify-content-between apd15 apr0 apl0 white-bg amb10 ">
                    <div class="heading-left-top d-flex">
                        <div className="align-self-center ">
                            <NavLink to="/app/FriendRequests">
                                <div className="iconHolder align-self-center ">
                                    <div className="backGlow">
                                        <div className="backIco"></div>
                                    </div>
                                </div> 
                            </NavLink>
                        </div>

                    </div>
                </div>

                <div className="settingsPageWidth profileSectionView">
                <div className={this.state.loader}>
                        <div style={style} id="loader">
                            <Loader type="Bars" color="#000000" height={30} width={50} />
                        </div>
                    </div>

                    <div className={this.state.profilespace}>

                        <div className="peopleINSpaceHolder"> 

                            <div className="peopleINViewHolder">
                                <div className="peopleINPic relPos">
                                <Slider {...settings}>
                                {this.state.image.map((image_tmp) =>
                                    <div> <img onError={this.addDefaultSrc} src={image_tmp} id="current_image" alt="" value={image_tmp} onChange={()=>{this.handleswipe(image_tmp)}} className="" /></div>
                                )} 
                                </Slider>
                                <div class={this.state.user_details.premium_member === 'true' ? "preIcon2" : 'none'}></div>
                                </div>
                                <div className="apd10 apt25 ">

                                    <div className= {(borderFlag === 1)?'media relPos apb15 border-bottom amb5':'media relPos apb15'}>  
                                        <div className="media-body pFHidden align-self-center">
                                             <div className="text-truncate bold-font BtoRcolor">{this.state.user_details.user_name}, {age}</div>
                                            <div className="text-truncate secondaryText esmh-14 regular-font">
                                                <Trans i18nKey="Lives In"><span className="floating-label">{txt}</span> </Trans> {loc}
                                            </div>
                                        </div>

                                        <div className="iconHolder align-self-end ml-1"> 
                                            <div className="backGlow">
                                                <Dropdown isOpen={this.state.dropdownOpen} toggle={this.reportToggle}>
                                                    <DropdownToggle className="RSoverride">
                                                        <div className="threeDotMenuIcon"></div>
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropDownRSoverride reportDropPos">
                                                        <div className="text-center">  
                                                            <button className="textBtn" onClick={this.showModal.bind(this, 'modal4')} value={this.state.user_details.user_id}>
                                                                <Trans i18nKey={this.state.report}>{this.state.report}</Trans>
                                                            </button> 
                                                        </div> 
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </div>
                                        </div>

                                        {/* <div className="circularIcoHolder circularIcoHolderMadjust">
                                            <div className="circularMessageIco"></div>
                                        </div> */}

                                    </div>
                                    
                                    {job}
                                    {company}
                                    {school} 

                                    {bio}
                                    <div className="interestsHolder apt5 apk-16 border-top">
                                        <div className="bold-font">
                                            {this.state.user_details.ex_web} <Trans i18nKey="interest">interest</Trans>
                                        </div>

                                        <div className="d-flex flex-wrap apt10 apb10">
                                        {this.state.interest.map((interest_tmp) =>
                                            <div className="btn-border">
                                                <Trans i18nKey={interest_tmp}>{interest_tmp}</Trans>
                                            </div>
                                        )}
                                        </div>
                                    </div>
                                                    
                                    <div className="apt20 apk-16  d-flex justify-content-center amb20">
                                        <div className={this.state.suc_msg}> 
                                            <Trans i18nKey={this.state.errors.frndreq}></Trans> 
                                        </div>              
                                        <div className="likeButton2" title={t('Like')} onClick={this.showModal.bind(this, 'modal1')}></div>
                                        <div className="superlikeButton2 mx-4" title={t('Super like')} onClick={this.showModal.bind(this, 'modal3')}></div> 
                                        <div className="dislikeButton" title={t('Dislike')} onClick={this.showModal2.bind(this, 'modal2')}></div>
                                    </div> 
                               
                                </div>
                            </div>
                        </div>



                    </div>


                </div>
                <Modal isOpen={this.state.modal1} toggle={this.closeModal.bind(this, 'modal1')} className="premiumPopup vertically-center apd5">
                    <ModalHeader toggle={this.closeModal.bind(this, 'modal1')}>
                        <h4 class="modal-title">
                            <Trans i18nKey="Do you want like this user ?">Do you want like this user?</Trans>
                        </h4></ModalHeader>
                    <ModalBody>
                        <div className="modal-body">
                            <div class="d-flex justify-content-end">
                                <div class="mr-3">
                                    <button class="border-0 bg-white pink-color" onClick={()=>{this.handleFriendRequest(this.state.user_details.user_id, this.state.like)}}>
                                        <Trans i18nKey="Yes">Yes</Trans>            
                                    </button>
                                </div>
                                <div>
                                    <button class="border-0 bg-white" onClick={this.closeModal.bind(this, 'modal1')}><Trans i18nKey="No">No</Trans></button></div>
                            </div> 
                        </div>
                    </ModalBody>
                </Modal>


                <Modal isOpen={this.state.modal3} toggle={this.closeModal.bind(this, 'modal3')} className="premiumPopup vertically-center apd5">
                    <ModalHeader toggle={this.closeModal.bind(this, 'modal3')}>
                        <h4 class="modal-title">
                            <Trans i18nKey="Do you want superlike this user ?">Do you want superlike this user ?</Trans>
                        </h4>
                    </ModalHeader>
                    <ModalBody> 
                        <div className="modal-body">
                            <div class="d-flex justify-content-end">
                                <div class="mr-3">
                                    <button class="border-0 bg-white pink-color" onClick={()=>{this.handleFriendRequest(this.state.user_details.user_id, this.state.superlike)}}>
                                        <Trans i18nKey="Yes">Yes</Trans>
                                    </button>
                                </div>  
                                <div><button class="border-0 bg-white" onClick={this.closeModal.bind(this, 'modal3')}><Trans i18nKey="No">No</Trans> </button></div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>


                <Modal isOpen={this.state.modal2} toggle={this.closeModal.bind(this, 'modal2')} className="premiumPopup vertically-center apd5">
                    <ModalHeader toggle={this.closeModal.bind(this, 'modal2')}>
                        <h4 class="modal-title">
                            <Trans i18nKey="Do you want to decline ?">Do you want to decline ?</Trans> 
                        </h4>
                    </ModalHeader>
                    <ModalBody>
                        <div className="modal-body">
                            <div class="d-flex justify-content-end">
                                <div class="mr-3">
                                    <button class="border-0 bg-white pink-color" onClick={()=>{this.disLike(this.state.user_details.user_id)}}>
                                        <Trans i18nKey="Yes">Yes</Trans>
                                    </button>
                                </div>
                                <div><button class="border-0 bg-white" onClick={this.closeModal.bind(this, 'modal2')}><Trans i18nKey="No">No</Trans></button></div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
	            

                <Modal isOpen={this.state.modal4} toggle={this.closeModal.bind(this, 'modal4')} className="premiumPopup vertically-center apd5">
                    <ModalHeader toggle={this.closeModal.bind(this, 'modal4')}>
                        <h4 class="modal-title">
                            <Trans i18nKey="Are you sure ?">Are you sure ?</Trans>
                        </h4>
                    </ModalHeader>
                    <ModalBody>
                        <div className="modal-body">
                           <div class="d-flex justify-content-end">
                            <div class="mr-3">
                                <button class="border-0 bg-white pink-color" onClick={this.SetReport}>
                                    <Trans i18nKey="Yes">Yes</Trans> 
                                </button>
                            </div>
                            <div><button class="border-0 bg-white" onClick={this.closeModal.bind(this, 'modal4')}><Trans i18nKey="No">No</Trans> </button></div>
                           </div>
                        </div>
                    </ModalBody>
                </Modal>


                <Modal isOpen={this.state.modal5} toggle={this.closeModal.bind(this, 'modal5')} className="premiumPopup matchPopup vertically-center apd5">
                    <ModalHeader toggle={this.closeModal.bind(this, 'modal5')}></ModalHeader>
                    <ModalBody>  
                        
                            <div class="modal-images media"> 
                                <div class="matchmedia">
                                    <img src={this.state.user_details.loguser_image} alt="" className="" />
                                </div>
                                <div class="matchlike"></div> 
                                <div class="matchmedia">
                                    <img src={this.state.user_details.user_image} alt="" className="" />
                                </div>
                            </div>  

                            <div class="justify-content-end text-center matchContent">
                                <Trans i18nKey="It's a Match!">It's a Match!</Trans></div> 
                            <div class="justify-content-end text-center matchContentSub">You and {this.state.user_details.user_name} like each other</div> 
                            <div class="justify-content-end text-center">
                                <button class="bg-white pink-color matchButton" onClick={this.Message}>
                                    <Trans i18nKey="Send message">Send message</Trans>  
                                </button> 
                            </div>
                    </ModalBody>
                </Modal>
 

                <Modal isOpen={this.state.modal6} toggle={this.closeModal.bind(this, 'modal6')} className="premiumPopup superlikePopup vertically-center apd5">
                    <ModalHeader toggle={this.closeModal.bind(this, 'modal6')}></ModalHeader>
                    <ModalBody>   
                        <div className="boostPopupIcon"></div> 
                        <div className="premiumPop">   
                            { (this.state.superlikePlanStatus === 1) ?
                                <div className="premiumPopPlans">
                                <div className="text-center">
                                    <div class="bold-font"> 
                                        <Trans i18nKey="Out of Super Likes">Out of Super Likes</Trans>
                                    </div>
                                    <div class="premiumInfoSubText  secondaryText esmh-14"> 
                                        <Trans i18nKey="Giving a Super like is a way to tell someone that you're especially interested in them">
                                            Giving a Super like is a way to tell someone that you're especially interested in them    
                                        </Trans>  
                                    </div>    
                                </div> 
                                <Swiper {...params2}>
                                    {this.state.superlikeItems.map((item, index) =>
                                        <div className={this.state.activeItem === item.id ? 'navigation--active' : ''} onClick={this.handleSuperlikeItemClick.bind(this, item.id)} alt={item.id} value={item.id}>
                                            <div className="premiumPrice">
                                                <div className="apd10 daysPrice">
                                                    <div className="bold-font apk-30">{item.superlike_count}</div>
                                                    <div className=" bold-font apk-20 mt-n2"> 
                                                        <Trans i18nKey="Super likes">Super likes</Trans>
                                                    </div>     
                                                </div>
                                                <div className="apd5 apk-16 mini-price">{this.state.currency}{item.price}</div> 
                                            </div>
                                        </div>
                                    )} 
                                </Swiper> 
                                <div className="sidebarLastContent text-center">
                                    <NavLink to="/app/Superlikepayment"> <button className="btn-common btn-block"><Trans i18nKey="Continue">Continue</Trans> </button></NavLink> 
                                </div>
                                </div> 
                            :
                                <div className="premiumPopPlans text-center">
                                    <Trans i18nKey="No Plans Available">No Plans Available</Trans> 
                                </div> 
                            }    
                        </div>
                    </ModalBody>
                </Modal> 

            </div>

        );
    }
}


export default withTranslation("translations")(FriendRequestNew); 

import React, { Component } from 'react';
import { Trans } from "react-i18next";
import { NavLink } from 'react-router-dom';

import { Modal, ModalHeader, ModalBody, TabContent, TabPane } from 'reactstrap';
//import { GoogleComponent } from 'react-google-location';
import Autocomplete from 'react-google-autocomplete';
import axios, { post } from 'axios';
import OtpInput from 'react-otp-input';   
// import { geolocated } from 'react-geolocated';
import FilterResults from 'react-filter-search';
import Cookies from 'universal-cookie'; 

import DatePicker from 'react-date-picker'; 

import ls from "local-storage"; 
import { options } from "../../config/options";   
import "../../config/i18n";
import { withTranslation } from "react-i18next";  

const API_KEY = "AIzaSyDVVob6U7eho9MpD99_7UCFmEzoH6N3Rrw";    

var mine = [];

class Signin extends Component {
	constructor(props) {
		super(props);
		this.toggle = this.toggle.bind(this);
		this.onChangeName = this.onChangeName.bind(this);
		this.onChangePassword = this.onChangePassword.bind(this);
		this.onChangeEmail = this.onChangeEmail.bind(this);
		this.setGender = this.setGender.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.validate = this.validate.bind(this);
		this.onFormSubmit = this.onFormSubmit.bind(this)
		this.onSubmitlocationsearch = this.onSubmitlocationsearch.bind(this)
    	this.onChange = this.onChange.bind(this)
		this.fileUpload = this.fileUpload.bind(this)
		this.onChangeSigninemail = this.onChangeSigninemail.bind(this);
		this.onSubmitsignin = this.onSubmitsignin.bind(this);
		this.onChangeSigninpassword = this.onChangeSigninpassword.bind(this);
		this.onChangeForgotemail = this.onChangeForgotemail.bind(this);
		this.onSubmitforgot = this.onSubmitforgot.bind(this);
		this.state = {
			maxDate: "",
	      valueDate: "",
	      minDate: new Date("1901/01/01"),
	      disableIcon: null,
	      age:"",
	      closeCalendar: true,
			name:'',errors: {},ios_url:'',android_url:'',
			email:'',signin_email:'',fbid:'',fbpic:'',
			signin_password:'',errorMsg:'',
			forgotemail:'',
			password:'',
			dob:'',lat:'',lon:'',
			gender: 'male', 
			showNav3: false,
			clickOnce: 0, 
			modal: false,
			modal2: false,
			activeTab: '5',interest_arr:[],interest_items:[],mine: [], 
			items: [],image: '',interests:[],
			image_name: '',place: null,lat_search: null,lon_search: null,api_url:localStorage.getItem('api_url'),
			image_url: '',
			peopleforid: '',
			data: [],suc_cls:'',max_age:'',
            value: '',items_interest:[],isMJ: false,
			shown: true,file: require("../../assets/img/cap1.png"),
			activesignup:'no',
			nointerest:'',
			otp: '',
	      numInputs: 4,
	      separator: '-',
	      isDisabled: false,
	      hasErrored: false,
	      isInputNum: true,
	      lang: ls.get('LangKeySet') || options[0],
			langKey: ls.get('LangValue'), 
			fileUploaded: 'none',    
		}
	}
	  addDefaultSrc(ev){
		  ev.target.src =  require('../../assets/img/howzu-logo-default.png')
		}

	componentDidMount() {

		const cookies = new Cookies(); 
		var signuptab = cookies.get('signuptab'); 
		
		if(signuptab==='yes'){
			// console.log('signuptab '+ typeof signuptab);
			this.setState({
				activesignup:'yes',
			});
		}
		// console.log("fbemail",cookies.get('fbemail'));
		this.setState({
			email:cookies.get('fbemail'),
			fbid:cookies.get('fbid'),
			name:cookies.get('fbname'),
			fbpic:cookies.get('fbpic')
		});

		if(cookies.get('onboarded') === 'true'){ 
			this.props.history.push("/app")
		}
		axios.get(this.state.api_url+`admindatas`)
		.then(res => {
			const datas = res.data.result;
			const items = res.data.result.peoplefor;
			const interests = res.data.result.interests;
			this.setState({ ios_url:datas['ios_url'],android_url:datas['android_url'],max_age:datas['max_age'] });
			this.setState({ items,interests });
		})
	
	} 
	componentWillMount() {
		axios.get(this.state.api_url+`admindatas`)
		 .then(res => {
		   const data = res.data.result.interests;
		   this.setState({ data });
		 })
	  }
	  onChangeSigninpassword(e){
		this.setState({
			signin_password:e.target.value
		});
		}
		onChangeSigninemail(e){
		this.setState({
			signin_email:e.target.value
		});
		// console.log(e.target.value);
		}
		onSubmitsignin(e){
			e.preventDefault();
			let errors = {};
			const cookies = new Cookies();
        	/*messaging.onMessage((payload) => // console.log('Message received. ', payload));
        	navigator.serviceWorker.addEventListener("message", (message) => // console.log(message));
			*/
			var signin = new FormData();
			signin.set('email', this.state.signin_email);
			signin.set('password', this.state.signin_password);
			signin.set('fcmtoken', cookies.get('mytoken'));
			signin.set('language', this.state.langKey);  
			
			axios({
				method: 'post',
				url: this.state.api_url+'webemaillogin',
				data: signin,
				config: { headers: {'Content-Type': 'multipart/form-data' }}
							})
			.then(res => {
				const message = res.data.message;
				// console.log(res.data);
				let formIsValid = true;
				if(res.data.status !== "true"){
				//	// console.log(message);
					formIsValid = false;
					errors["login"] = message;
					this.setState({
						errors: errors,
						errorMsg:'errorMsg'
						});
						setTimeout(() => {
								this.setState({
										errorMsg: 'errorMsghide'
								});
							}, 2000);
				}else{
					var loginstatus = new FormData();
					loginstatus.set('user_id', res.data.peoples.user_id);
					loginstatus.set('status', '1');
					loginstatus.set('language', this.state.langKey);  
					axios({
							method: 'post',
							url: this.state.api_url+'webonlinestatus',
							data: loginstatus,
							config: { headers: {'Content-Type': 'multipart/form-data' }}
							})
					localStorage.setItem('session_token',JSON.stringify(res.data.peoples.token));
					localStorage.setItem('session_userid',JSON.stringify(res.data.peoples.user_id));

					sessionStorage.setItem('loggedin', 'true');
					sessionStorage.setItem('subscribe_status', res.data.peoples.subscribe_status);
					//const cookie = new cookie();
					//cookies.set('userimage',res.data.peoples.user_image);
					
          			let d = new Date();
					d.setTime(d.getTime() + (12*60*60*1000));   
					cookies.set('onboarded', 'true', {path: "/", expires: d});
					// console.log("cookies1",cookies.get('onboarded'));
				
					this.props.history.push("/app")
			}
				this.setState({
					errors: errors
					});
					return formIsValid;
				})
			}
	
			onChangeForgotemail(e){
				this.setState({
					forgotemail:e.target.value
				});
				// console.log(e.target.value);
				}
				onSubmitforgot(e){
				e.preventDefault();
					let errors = {};
					var forgotemail = new FormData();
					forgotemail.set('email', this.state.forgotemail);
					forgotemail.set('language', this.state.langKey);  
							axios({
							method: 'post',
							url: this.state.api_url+'webforgot',
							data: forgotemail,
							config: { headers: {'Content-Type': 'multipart/form-data' }}
							})
							.then(res => {
									const message = res.data.message;
								
									errors["forgot"] = message;
								
										this.setState({
											errors: errors,
											errorMsg:'errorMsg'
											});
											setTimeout(() => {
													this.setState({
															errorMsg: 'errorMsghide'
													});
												}, 2000);	
									if(res.data.status === 'true'){
									//	errors["forgot_suc"] = message;
						this.setState({
											//modal1: false,
											forgotemail:'',
										//	errors: errors,
										//	suc_cls: 'successMsg floatingAlerts1',
										});
									}
									// console.log(message);
									})
					}	
	getChckeboxValue(event) {
		  // const value = event.target.value;
	  }
	onChangeName(e){
		this.setState({
			name:e.target.value,
			lon:e.target.alt
		});
		}
	restrict = (event) => {
		const regex = new RegExp("^[a-zA-Z ]+$");
		const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
		if (!regex.test(key)) {
		event.preventDefault(); return false;
		}
		}	
	onChangePassword(e){
	this.setState({
		password:e.target.value
	});
	}
	onChangeEmail(e){ 
		this.setState({
			email:e.target.value,
			lat:e.target.alt
		});
	}

	setGender(e) {
		this.setState({
			gender:e.target.value
		});  
	}  

	onSubmit(e) { 
		e.preventDefault();
		let errors = {};
		let clickOn = 0;

		if (this.state.email !== null && this.state.dob !== null && this.state.age !== null && this.state.email !== "" && this.state.dob !== "" && this.state.age !== "" && clickOn === 0) {  
			var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

        	if (!pattern.test(this.state.email)) { 
         	errors["signup"] = "Please enter valid email";     
				this.setState({ 
					errors: errors,
					errorMsg:'errorMsg'
				});
				setTimeout(() => {
					this.setState({ 
						errorMsg: 'errorMsghide'
					});
				}, 4000); 
				clickOn = 0;
				return;  
        	}

    		var signup = new FormData();
			signup.append('email', this.state.email);
			signup.append('language', this.state.langKey); 
			clickOn = 1;

			axios({	
				method: 'post', 
				url: this.state.api_url+'webcheckemail',
				data: signup,
				config: { headers: {'Content-Type': 'multipart/form-data' }}
			}).then(res => {
				const message = res.data.message;
				if(res.data.status === "true") {
					if(res.data.email_verified === 'disabled') {
						this.setState({
							activeTab: '1'  
						});
					}
					this.setState({
						modal2: true
					});
				} else {
					signup.set('email', ''); 
					errors["signup"] = message;
					this.setState({
						errors: errors,
						errorMsg:'errorMsg'
					});
					setTimeout(() => {
						this.setState({
							errorMsg: 'errorMsghide'
						});
					}, 2000);
				}
				this.setState({
					errors: errors
				});
				clickOn = 0;
			})
		} else {
			errors["signup"] = "Please fill the details"; 
			this.setState({ 
				errors: errors,
				errorMsg:'errorMsg'
			});
			setTimeout(() => {
				this.setState({ 
					errorMsg: 'errorMsghide'
				});
			}, 4000); 
			clickOn = 0;    
		}
	}
		onSubmitlocationsearch(e){
			e.preventDefault();
			let errors = {};
			let clickOnce = this.state.clickOnce;
			
			if(typeof(this.state.lat_search) === 'undefined' || typeof(this.state.lon_search) === 'undefined' || this.state.lat_search === 'undefined' || this.state.lat_search === "" || this.state.lat_search === null || this.state.lon_search === "" || this.state.lon_search === 'undefined' || this.state.place=== "" || this.state.place=== null || this.state.place === 'undefined'){
					errors["locationsearch"] = "Select location properly or change location";
					this.setState({   
						errors: errors,
						errorMsg:'errorMsg'
						});
						setTimeout(() => {
								this.setState({
										errorMsg: 'errorMsghide'
								});
							}, 2000);					
			} else {
				if(clickOnce === 0) {  
					this.setState({
	                clickOnce: 1
	            });   
					var signup = new FormData();
					signup.set('check', this.state.interest_arr);
					signup.set('name', this.state.name);
					signup.set('email', this.state.email);
					signup.set('password', this.state.password);
					signup.set('dob', this.state.dob);
					signup.set('age', this.state.age);
					if(this.state.gender === "" || this.state.gender === null || this.state.gender === 'undefined'){
						signup.set('gender', 'female');
					} else {
						signup.set('gender', this.state.gender); 
					}
				
					if(this.state.fbpic){
						signup.set('image_name', '');
						signup.set('image_url', '');
						signup.set('fbpic', this.state.fbpic);
						
					} else {
						signup.set('fbpic', '');
						signup.set('image_name', this.state.image_name);
						signup.set('image_url', this.state.image_url);
					}
					signup.set('peopleforid', this.state.peopleforid);
					signup.set('lat', this.state.lat_search);
					signup.set('lon', this.state.lon_search);
					signup.set('location', this.state.place);
					signup.set('language', this.state.langKey);  
					
					
					axios({
						method: 'post',
						url: this.state.api_url+'signupweb',
						data: signup,
						config: { headers: {'Content-Type': 'multipart/form-data' }}
					}).then(res => {  
						if(res.data.status !== "true") { 
							errors["locationsearch"] = res.data.message;  
							this.setState({
								errors: errors,
								errorMsg:'errorMsg'
							});
							setTimeout(() => {
								this.setState({
										errorMsg: 'errorMsghide'
								});
							}, 2000); 
							this.setState({
			                clickOnce: 0
			            });  
						} else { 
							signup.set('check', '');
							signup.set('name', '');
							signup.set('email', '');
							signup.set('password', '');
							signup.set('dob', '');
							signup.set('gender', '');
							signup.set('image_name', '');
							signup.set('image_url', '');
							signup.set('peopleforid', '');
							signup.set('lat', '');
							signup.set('lon', '');
							signup.set('location', '');
							
							var signin = new FormData();
							signin.set('email', this.state.email); 
							signin.set('password', this.state.password);
							signin.set('language', this.state.langKey);  

							axios({
								method: 'post',
								url: this.state.api_url+'webemaillogin',
								data: signin,
								config: { headers: {'Content-Type': 'multipart/form-data' }}
							}).then(res => {
								let formIsValid = true;
								if(res.data.status !== "true"){
									errors["locationsearch"] = res.data.message;    
									this.setState({ 
										errors: errors,
										errorMsg:'errorMsg'
									});
									setTimeout(() => {
										this.setState({
												errorMsg: 'errorMsghide'
										});
									}, 2000);
									this.setState({
					                clickOnce: 0 
					            }); 

								} else {
									var loginstatus = new FormData();
									loginstatus.set('user_id', res.data.peoples.user_id);
									loginstatus.set('status', '1');
									loginstatus.set('language', this.state.langKey);  
									axios({  
										method: 'post',
										url: this.state.api_url+'webonlinestatus',
										data: loginstatus,
										config: { headers: {'Content-Type': 'multipart/form-data' }}
									})
									let d = new Date();
									const cookies = new Cookies(); 
									d.setTime(d.getTime() + (12*60*60*1000));   
									cookies.set('onboarded', 'true', {path: "/", expires: d});
											
									localStorage.setItem('session_token',JSON.stringify(res.data.peoples.token));
									localStorage.setItem('session_userid',JSON.stringify(res.data.peoples.user_id));
									sessionStorage.setItem('loggedin', 'true');
									sessionStorage.setItem('subscribe_status', res.data.peoples.subscribe_status);
									cookies.set('fbemail', '');
									cookies.set('fbid', '');
									cookies.set('fbname', '');
									cookies.set('fbpic', '');   
									this.props.history.push("/app/PeopleNear")
								}
					
								return formIsValid;
							})
						}   
					})
				} 
			}
			this.setState({
				errors: errors
			});
		
		}	
	handleItemClick(index) {
		this.setState({
			activeItem: index,
		})
	}

	closeModal(tabId) {
		this.setState({
			[tabId]: false
		});
	}
	showModal(modal) {
		this.setState({
			[modal]: true
		});
		// console.log(this.state);
	}
	handlehereClick(index,e) {
		this.setState({
            activehere: index,
            peopleforid: e.target.alt,   
        });
        localStorage.setItem('peopleforid', JSON.stringify(e.target.alt));
    }
    validate(e) {
    e.preventDefault(); 
    let errors = {};    
    if(this.state.peopleforid === "" || this.state.peopleforid === null){
        errors["here_to"] = "Please select any one";
				this.setState({
					errors: errors,
					errorMsg:'errorMsg'
					});
					setTimeout(() => {
							this.setState({
									errorMsg: 'errorMsghide'
							});
						}, 2000);
    }else{
    	if(this.state.fbpic){
    		this.setState({
				activeTab: '3'
			});
    	}else{
	        this.setState({
				activeTab: '2'
			});
    	}
    }
	}
	onFormSubmit(e){
        //e.preventDefault() 
        this.fileUpload(this.state.image);
      }
      onChange(e) {
        this.setState({
          file: URL.createObjectURL(e.target.files[0])
        })
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length)
        	return;
        // console.log(files[0])
        this.createImage(files[0]);
      }
      createImage(file) {
        let reader = new FileReader();  
        reader.onload = (e) => {
          this.setState({
            image: e.target.result
          })
          this.onFormSubmit();
        };
        reader.readAsDataURL(file);
        
	  }
	  fileUpload(image){
        let errors = {};
         
        if((this.state.image === null) || (this.state.image === "")) {
        	this.setState({
        		fileUploaded: 'none',  
        	});
          errors["uploadimage"] = "Please atleast select one Image";
					this.setState({
						errors: errors,
						errorMsg:'errorMsg'
						});
						setTimeout(() => {
								this.setState({
										errorMsg: 'errorMsghide'
								});
							}, 2000);
        } else {
        	this.setState({
        		fileUploaded: 'upload',
        	});

        const url = this.state.api_url+'uploadimageweb';
        const formData = {file: this.state.image, language: this.state.langKey }
        return  post(url, formData)
                .then(res => {
								// console.log(res.data)
						if(res.data.status === 'true'){
							const image_url = res.data.imageurl;
	                  const image_name = res.data.image_name;
	                  errors["uploadimage"] = "";
	                  errors["uploadimage_suc"] = "Image uploaded sucessfuly";
	                  this.setState({
	                    image_name: image_name,
	                    image_url: image_url,
	                    errors: errors,
	                    
						 		});
								 this.setState({
									errors: errors,
									errorMsg:'errorMsg'
									});
									setTimeout(() => {
											this.setState({
													errorMsg: 'errorMsghide'
											});
										}, 2000);  
                localStorage.setItem('image_name', JSON.stringify(this.state.image_name));
								localStorage.setItem('image_url', JSON.stringify(this.state.image_url));
								this.setState({
									activeTab: '3'
								});
      
								} else {
									errors["uploadimage"] = res.data.message;
                  			this.setState({
										errors: errors,
										errorMsg:'errorMsg',
										fileUploaded: 'none',   
										});
										setTimeout(() => {
												this.setState({
														errorMsg: 'errorMsghide'
												});
											}, 2000);
          
								}
                  
                })

              }        
      }
	onToggle(index, e){
	let newItems = this.state.interests.slice();
	newItems[index].checked = !newItems[index].checked

	 if(e.target.value){
            var cur_val = e.target.value;
            //// console.log(mine.includes(cur_val));
            index = mine.indexOf(cur_val);
            if (index > -1) {
              mine.splice(index, 1);
             this.setState({ checked: !this.state.checked });
              
            }
            else{
                mine.push(cur_val);
              
                  
            }
            
        }
	}
	onSubmitinterest = (e) => {
		e.preventDefault();
		// let errors = {};
		// console.log("change_int",mine);
     
		if(mine.length === 0)  
		{
		   this.setState({
		        nointerest: 'Please select the interest',
		    });
		} else {

			this.setState({
				interest_arr: mine 
				
			});

			this.setState({
				activeTab: '4'
			}); 
		} 
   } 
	toggle(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			});
		}
	}
    handleChange = event => {
		const { value } = event.target;
		this.setState({ value });
		   this.setState({ interest_items: mine })
         
		// console.log(this.state.interest_items); 
	  };

	handleOtpChange = otp => {
    	this.setState({ otp });
  	};

  	clearOtp = () => {
    	this.setState({ otp: '' });
  	};

  	handleResendOtp = e => {
    	e.preventDefault(); 

    	let errors = {};
    	var signup = new FormData();
		signup.append('email', this.state.email);
		signup.append('language', this.state.langKey);  

    	axios({
			method: 'post',
			url: this.state.api_url+'webcheckemail',
			data: signup,
			config: { headers: {'Content-Type': 'multipart/form-data' }}
		}).then(res => { 
			 	errors["otpverify"] = res.data.message;  
				this.setState({
					errors: errors,
					errorMsg:'errorMsg'
				});
				setTimeout(() => {
					this.setState({
							errorMsg: 'errorMsghide'  
					});
				}, 2000);  
			
				signup.set('email', '');    
		})
  	};

  	handleSubmit = e => {
    	e.preventDefault(); 

    	let errors = {};
    	var emailVerify = new FormData();
		emailVerify.set('otp', this.state.otp);
		emailVerify.set('email', this.state.email);
		emailVerify.set('language', this.state.langKey);   
    	
    	axios({
			method: 'post',
			url: this.state.api_url+'verifyotp',
			data: emailVerify,
			config: { headers: {'Content-Type': 'multipart/form-data' }}
		}).then(res => { 
			if(res.data.status !== "true") { 
			 	errors["otpverify"] = res.data.message;  
				this.setState({
					errors: errors,
					errorMsg:'errorMsg'
				});
				setTimeout(() => {
					this.setState({
							errorMsg: 'errorMsghide'
					});
				}, 2000);  
			} else { 
				emailVerify.set('otp', ''); 
				emailVerify.set('email', '');

				this.setState({
					activeTab: '1' 
				});
			}
		})
  	}; 

  	handleOpenPicker = date => {
    var setLimit = new Date();
    setLimit.setFullYear(setLimit.getFullYear() - 18);
    this.setState({ 
      valueDate: setLimit,    
      maxDate: setLimit 
    });  

    var month = '' + (setLimit.getMonth() + 1), 
		day = '' + setLimit.getDate(),
		year = setLimit.getFullYear();

		if (month.length < 2) 
			month = '0' + month;
		if (day.length < 2) 
			day = '0' + day;

		var newDate = [year, month, day].join('-');

		var ageDiff = Date.now() - setLimit.getTime();
		var ageDate = new Date(ageDiff); 
		var yearsDiff = Math.abs(ageDate.getUTCFullYear() - 1970);

		this.setState({
			age: yearsDiff,
			dob: newDate    
		});        
  }

  	handleDOBChange = date => { 
		this.setState({
			valueDate: date 
		});

		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) 
			month = '0' + month;
		if (day.length < 2) 
			day = '0' + day;

		var newDate = [year, month, day].join('-');

		var ageDiff = Date.now() - date.getTime();
		var ageDate = new Date(ageDiff); 
		var yearsDiff = Math.abs(ageDate.getUTCFullYear() - 1970);

		this.setState({
			age: yearsDiff,
			dob: newDate    
		});

		// alert(newDate+ " - AGE: "+yearsDiff);   
	};

	render() {
		const { data, value } = this.state; 
		const { t } = this.props; 

		const {
	      otp,
	      numInputs,
	      separator,
	      isDisabled,
	      hasErrored,
	      isInputNum,
    	} = this.state;

		return (
			<div className="signInPage">
				<div className="border-bottom welcomeHeader white-bg">
					<div className="container">
						<div className="d-flex  apt5 apb5">
							<div className="align-self-center ">
								<div className="iconHolder align-self-center mr-1">
									<div className="backGlow">
										<NavLink exact to="/">
											<div className="backIco"></div>
										</NavLink>
									</div>
								</div>
							</div>
							<div className="align-self-center ml-auto mr-auto"><img src={require('../../assets/img/logo-c-64.png')} className="sideLogo commonLogoC mr-5" alt="logo" /></div>

						</div>
					</div>
				</div>

				<div className="signInForms mb-auto align-self-center">
					<ul class="nav nav-tabs justify-content-center">
						<li class="nav-item flex-fill text-center">

							<a id="signin-link" className={"nav-link " + (this.state.activesignup==='no' ? 'active':'')} data-toggle="tab" href="#signin">
								<Trans i18nKey="Signin"><span className="floating-label">Signin</span> </Trans>
							</a>
						</li>
						<li class="nav-item flex-fill text-center">
							<a id="signup-link" className={"nav-link " + (this.state.activesignup==='yes' ? 'active':'')} data-toggle="tab" href="#signup">
								<Trans i18nKey="Signup"><span className="floating-label">Signup</span> </Trans>
							</a> 
						</li>
					</ul>

          <div className={this.state.suc_cls}>{this.state.errors.forgot_suc}</div>
					<div class="tab-content edit-profile-page">
						<div id="signin" className={"container tab-pane " + (this.state.activesignup==='no' ? 'active':'fade')}>
 
						<form className="apb30 apt30" onSubmit={this.onSubmitsignin}> 
								<div className="edit-profile-page">
									<div className="user-input-wrp amb25">
										<br />
										<input className="inputText" type="email" id="signin_email" value={this.state.signin_email} onChange={this.onChangeSigninemail} placeholder=" " required />
										<span className="floating-label"><Trans i18nKey="Email">Email</Trans></span>
									</div>
								</div>

								<div className="edit-profile-page">
									<div className="user-input-wrp amb25">
										<br />
										<input className="inputText" id="signin_password" value={this.state.signin_password} onChange={this.onChangeSigninpassword}  type="password" placeholder=" " required />
										<span className="floating-label"><Trans i18nKey="Password">Password</Trans></span>
									</div>
								</div>



                                <div className={this.state.errorMsg}><Trans i18nKey={this.state.errors.login}>{this.state.errors.login}</Trans></div>
								<div className="premium amt35 amb15">
									
										<button className="btn btn-bg d-flex justify-content-center width100" type="submit"> <Trans i18nKey="Signin">Signin</Trans> </button>
								</div>

								<div className="float-right secondary_color forgot-password pointCurs"  onClick={this.showModal.bind(this, 'modal1')}>  <Trans i18nKey="ForgotPassword?">Forgot Password?</Trans> </div>
							</form>




							{/* Forgot Password Popup */}
							<Modal isOpen={this.state.modal1} toggle={this.closeModal.bind(this, 'modal1')} className="premiumPopup vertically-center apd5">
								<ModalHeader toggle={this.closeModal.bind(this, 'modal1')}><h4 class="modal-title"><Trans i18nKey="ForgotPassword">Forgot Password</Trans></h4></ModalHeader>
								<ModalBody>

									<div className="modal-body edit-profile-page">
									<form className=" " onSubmit={this.onSubmitforgot}>
												<div className="user-input-wrp amb25">
													<br />
													<input className="inputText" name="forgotemail"  type="email" id="forgotemail" value={this.state.forgotemail} onChange={this.onChangeForgotemail} placeholder=" " required />
													<span className="floating-label"><Trans i18nKey="Email">Email</Trans></span>
												</div>
												<div className={this.state.errorMsg}><Trans i18nKey={this.state.errors.forgot}>{this.state.errors.forgot}</Trans> </div>
												<button className="btn btn-bg d-flex justify-content-center width100 amg0" type="submit"> <Trans i18nKey="Reset">Reset</Trans> </button>  
											</form> 

									</div>
								</ModalBody>
							</Modal>



						</div>

						<div id="signup" className={"container tab-pane " + (this.state.activesignup==='yes' ? 'active':'fade')} onSubmit={this.onSubmit}>

							<form className="apb30 apt30">
								<div className="edit-profile-page">
									<div className="user-input-wrp amb25">
										<br />
										<input className="inputText" id="name" value={this.state.name} onChange={this.onChangeName} alt={this.props.coords && this.props.coords.longitude} maxLength={20} minLength={3} onKeyPress={(e) => this.restrict(e)} placeholder=" " required />
										<span className="floating-label"><Trans i18nKey="Name">Name</Trans></span>
									</div>
								</div>
								<div className="edit-profile-page">
									<div className="user-input-wrp amb25">
										<br />
										<input className="inputText" type="email" id="email" value={this.state.email} alt={this.props.coords && this.props.coords.latitude} onChange={this.onChangeEmail} placeholder=" " />
										<span className="floating-label"><Trans i18nKey="Email">Email</Trans></span>  
									</div>
								</div>
								<div className="edit-profile-page">
									<div className="user-input-wrp amb25">
										<br />
										<input className="inputText" id="password" value={this.state.password} onChange={this.onChangePassword}  type="password" maxLength={15} minLength={6} placeholder=" " required />
										<span className="floating-label"><Trans i18nKey="Password">Password</Trans></span>
									</div>
								</div>

								<div className="form-group amb35">
									<label className="amt15"><Trans i18nKey="Date of Birth">Date of Birth</Trans></label>    
									<DatePicker
							          onChange={this.handleDOBChange}  
							          onCalendarOpen={this.handleOpenPicker} 
							          value={this.state.valueDate}
							          maxDate={this.state.maxDate} 
							          minDate={this.state.minDate}
							          format="y - MM - dd"
							          name="CalendarCustomValue" 
							          clearIcon={this.state.disableIcon}
							          closeCalendar={this.state.closeCalendar} 
							          yearPlaceholder={t("Year")}
							          monthPlaceholder={t("Month")}
							          dayPlaceholder={t("Date")}      
							      />
							   </div> 

								<input className="inputText" type="hidden" id="fbid" value={this.state.fbid} />
								<input className="inputText" type="hidden" id="lat" value={this.props.coords && this.props.coords.latitude} alt={this.props.coords && this.props.coords.longitude} />
								<div className="form-group" >
									<label className=""><span className="floating-label"><Trans i18nKey="Gender">Gender</Trans></span></label> 
									<div className="row">
										<div className="col-3">
											<label className="radio-btn secondary_color"><Trans i18nKey="Male">Male</Trans>    
 												<input type="radio" onClick={this.setGender}  value="male" name="radio" defaultChecked /> 
												<span className="checkmark"></span>
											</label>
										</div>
										<div className="col-5 col-sm-3">
											<label className="radio-btn secondary_color"><Trans i18nKey="Female">Female</Trans>
  												<input type="radio" onClick={this.setGender} value="female" name="radio" />
												<span className="checkmark"></span>
											</label>
										</div>
										<div className="col-3">
											<label className="radio-btn secondary_color"><Trans i18nKey="Other">Other</Trans>    
 												<input type="radio" onClick={this.setGender}  value="other" name="radio" />
												<span className="checkmark"></span>  
											</label>
										</div>
									</div>  
								</div>


								<div className={this.state.errorMsg}><Trans i18nKey={this.state.errors.signup}>{this.state.errors.signup}</Trans></div>
								<div className="premium amt35 amb15">
									<button className="btn btn-bg d-flex justify-content-center width100" value={this.props.coords && this.props.coords.latitude} alt={this.props.coords && this.props.coords.longitude} type="submit" > 
										<Trans i18nKey="Signup">Signup</Trans>
									</button> 
								</div>
							</form>

						</div>
					</div>
				</div>

				<div class="footerSty">
					<div class="container">
						<div class="row">
							<div class="col-12 col-sm-6 col-md-7 col-lg-7 col-xl-8  text-center text-sm-left">
								<span class="sma-16"></span>
							</div>
							<div class="col-12 col-sm-6 col-md-5 col-lg-5 col-xl-4">
								<div class="text-center text-sm-right mt-2 mt-sm-0"><div>
								<a target="_blank" rel="noopener noreferrer"  href={this.state.ios_url}>
									<div class="inline-block aml15 amr15">
										<span class="apr5 apl5 align-middle"><i class="fa fa-apple apk-20 " aria-hidden="true"></i></span>
										<Trans i18nKey="AppStore"><span className="floating-label">App Store</span> </Trans> 
									</div>
								</a>
								<a target="_blank" rel="noopener noreferrer" href={this.state.android_url}>
									<div class="inline-block">
										<span class="apr5 apl5 align-middle"><i class="fa fa-android apk-20" aria-hidden="true"></i></span>
										<Trans i18nKey="GooglePlay"><span className="floating-label">Google Play</span> </Trans>
									</div>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div> 

				{/* Forgot Password Popup */}
				<Modal isOpen={this.state.modal2}  className="premiumPopup appofDay signinPagePop  apd5 par">
					<ModalHeader  className="apk-24 d-none"></ModalHeader>


					<div>

						<TabContent activeTab={this.state.activeTab}>
		
							<TabPane tabId="1">
								<ModalHeader ><h4 class="modal-title"><Trans i18nKey="Iamhereto">I am here to</Trans></h4></ModalHeader>
								<ModalBody>
									<div className="premiumPop apl25 apr25 apt5 apb5">

										<div className="row signininterest justify-content-center justify-content-lg-start setting-page">
										{this.state.items.map((item, index) =>
											<div className="">
												<div className="text-center" key={index} >
													<div className="iconimg apd15 apl20 apr20">
														<img onError={this.addDefaultSrc} src={item.icon} alt={item.id} className={this.state.activehere === index ? 'navigation--active' : ''}
															onClick={this.handlehereClick.bind(this, index)} value={item.id} />
														<h6 className="amb0 amt5" value={item.id}><Trans i18nKey={item.name}>{item.name}</Trans> </h6>
													</div>
												</div>
											</div>
										)}
										</div>
                                        <div className={this.state.errorMsg} style={{ paddingLeft: "25px" }}>
                                        	<Trans i18nKey={this.state.errors.here_to}>{this.state.errors.here_to}</Trans>
                                        </div>
										<div className="sidebarLastContent text-center" data-toggle="modal" data-target="#next-page">
											<button className="btn-common btn-block" onClick={this.validate}><Trans i18nKey="Next">Next</Trans></button>
										</div>

									</div>
								</ModalBody>
							</TabPane>
							
							<TabPane tabId="2">
								<ModalBody>
								<div className="signStepSecondWrapper relPos">
									<div className="centerImgSelecting photo-rotation">
										<label className=" custom-file-upload" >
											<form onSubmit={this.onFormSubmit}>
												<input type="file" onChange={this.onChange} style={{opacity:"0"}} />
												<img id="image1" src={this.state.file} alt="" />
											</form>
										</label>
									</div>
									<div className="blueBannerSignIn"> <div className="text-center apk-30 text-light pt-2"><Trans i18nKey="Upload great photos">Upload great photos</Trans></div></div>
									<div style={{ height: "180px" }}></div>
									<div className={this.state.errorMsg} style={{ paddingLeft: "25px" }}>
										<Trans i18nKey={this.state.errors.uploadimage}>{this.state.errors.uploadimage}</Trans>
									</div> 
									<div className="successMsg" style={{ paddingLeft: "25px" }}>
										<Trans i18nKey={this.state.errors.uploadimage_suc}>{this.state.errors.uploadimage_suc}</Trans>   
									</div>
									<div className="d-flex justify-content-between apd25 apb15">
										<span className="apk-18 bold-font pointCurs" onClick={() => { this.toggle('3'); }}><Trans i18nKey="Skip">Skip</Trans></span>

										{ (this.state.fileUploaded === "none") ?
											<form onSubmit={this.onFormSubmit}>
												<span className="apk-18 bold-font pink-color">
												<label className=" custom-file-upload primary-color"> <Trans i18nKey="Add Photos">Add Photos</Trans>
												<input id="file-upload" type="file"  onChange={this.onChange} />
												</label></span>
												
											</form>
											: ''
										}  
										
									</div>
									
								</div>
								</ModalBody>
							</TabPane>

							<TabPane tabId="3">
								<ModalHeader><h4 class="modal-title"><Trans i18nKey="interest">Interest</Trans></h4></ModalHeader>
								<ModalBody>
								<form onSubmit={this.onSubmitinterest}>
						<div className="modal-content border-radius border-none" style={{padding:"1rem"}}>
							<div className="modal-body profile-page ">
								<div className="input-group border-bottom apb10">
									<span className="input-group-append">
                                <button className="btn search-btn border-none" type="button">
                                    <img src={require("../../assets/img/search.png")} alt="logo" className="" />
                                </button>
                            </span>
                            <input className="form-control py-2  border-none" type="search" value={value} onChange={this.handleChange} placeholder={t("Search your Interest")} />    
                        </div>

                       
                        <div className="interst-list appodDayoverflow">
									<FilterResults value={value} data={data} renderResults={results => (
										<div>
											{results.map((el,i) => {
												if(mine !== null){

													if(mine.indexOf(el.name) > -1){
														return <label key={el.name} className="interst-option amb0 apt10 apb10 border-bottom"><span>{el.name}</span>
															<input type="checkbox" onChange={this.onToggle.bind(this, i)} name="interestcheck" value={el.name}  defaultChecked={mine.indexOf(el.name) > -1 ? 'true':''}/>
															<span className="checkmark"></span>
														</label>
													}else{
														return <label key={el.name} className="interst-option amb0 apt10 apb10 border-bottom"><span>{el.name}</span>
															<input type="checkbox" onChange={this.onToggle.bind(this, i)} name="interestcheck" value={el.name}/>
															<span className="checkmark"></span>
														</label>
													}

												}
												else
												{
													return <label key={el.name} className="interst-option amb0 apt10 apb10 border-bottom"><span>{el.name}</span>
														<input type="checkbox" onChange={this.onToggle.bind(this, i)} name="interestcheck" value={el.name}/>
														<span className="checkmark"></span>
													</label>
												}
											})}
										</div>
									)}/>
									<br/>
								</div>  

                    </div>
                      <div className="errorMsg" style={{ paddingLeft: "25px" }}><Trans i18nKey={this.state.nointerest}>{this.state.nointerest}</Trans></div>
                 
                    <div className=" interest-save border-top-none text-right">
                    <div class="premium amt30 amb10"><button class="btn btn-bg d-flex justify-content-center width100" type="submit"> <Trans i18nKey="Save">Save</Trans> </button></div>
                    </div>
                </div>
                </form>
								</ModalBody>
							</TabPane>

							<TabPane tabId="4">
								<ModalHeader><h4 class="modal-title"><Trans i18nKey="location">location</Trans></h4></ModalHeader>
								<ModalBody className="apd5Imp">
								<div className="modal-body edit-profile-page">
                                    <form className="" onSubmit={this.onSubmitlocationsearch}>
                                        <div className="user-input-wrp amb35">
                                            <br />
                                            <Autocomplete
												apiKey={API_KEY}
												onPlaceSelected={(place) => {
												this.setState({ place: place.formatted_address, lat_search: place.geometry.location.lat() , lon_search: place.geometry.location.lng() })
												}}
												types={['(regions)']}
												//componentRestrictions={{country: "ru"}}
											/>
                                        </div>
                                        <div className={this.state.errorMsg}><Trans i18nKey={this.state.errors.locationsearch}>{this.state.errors.locationsearch}</Trans></div>  
                                        <div className="premium"><button type="Submit" className="btn btn-bg width100 justify-content-center amg0"> <Trans i18nKey="Joinwith">Join with Blackroseinternational</Trans> </button></div>
                                    </form>
                            </div>
								</ModalBody> 
							</TabPane>

							<TabPane tabId="5"> 
								<ModalHeader>
									<h4 class="otp-modal-title"><Trans i18nKey="Verification">Verification</Trans></h4>
								</ModalHeader>
								<ModalBody className="apd5Imp">
									<div className="modal-body edit-profile-page">
                              <form className="" onSubmit={this.handleSubmit}>
                                 <div className="user-input-wrp amb20 otpHolder">
													<p class="otp-sub-title text-center"><Trans i18nKey="OTPCODE">Get OTP code from registered mail</Trans></p> 
													<div className="otp-cnt">
														<OtpInput
														inputStyle="inputStyle"
														numInputs={numInputs}
														isDisabled={isDisabled}
														hasErrored={hasErrored}
														errorStyle="error"
														onChange={this.handleOtpChange}
														separator={<span>{separator}</span>}
														isInputNum={isInputNum}
														shouldAutoFocus
														value={otp}
														/>
													</div>
													<div className={this.state.errorMsg}><Trans i18nKey={this.state.errors.otpverify}>{this.state.errors.otpverify}</Trans></div> 
													<div className="btn-row text-center">
														<button className="btn margin-top--large clrBtn" type="button" 
														disabled={isDisabled} onClick={this.clearOtp} >
															<Trans i18nKey="Clear">Clear</Trans> 
														</button>
														
														<button className="btn margin-top--large vryBtn" disabled={otp.length < numInputs}>
															<Trans i18nKey="Verify">Verify</Trans>   
														</button>
													</div> 

													<div className="btn-row text-center">												
														<button className="btn margin-top--large vryBtn" onClick={this.handleResendOtp} >
															<Trans i18nKey="Resend">Resend</Trans>     
														</button>
													</div>

													
												</div>                               
                              </form>
                            </div>
								</ModalBody>
							</TabPane>

						</TabContent>

					</div>

				</Modal>


			</div>
		);
	}

}


export default withTranslation("translations")(Signin); 



import i18n from "i18next";
import { initReactI18next } from "react-i18next"; 
import en from "../locales/en";
import fr from "../locales/fr";
import ls from "local-storage";
import { options } from "../config/options"; 

ls.set('LangKeySet', ls.get('LangKeySet') || options[0]); 
ls.set('LangValue', ls.get('LangValue') || "en");      

i18n.use(initReactI18next).init({
  resources: { 
    en,
    fr
  },
  fallbackLng: ls.get('LangValue') || "en",       
  debug: true,
  ns: ["translations"],
  defaultNS: "translations",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
    formatSeparator: ","
  },
  react: {
    wait: true
  }
});

export default i18n;

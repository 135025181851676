import React, { Component } from 'react';
import { Trans, withTranslation } from "react-i18next";
import "../../config/i18n";
import ls from "local-storage"; 
import { options } from "../../config/options";  

import { NavLink,Link } from 'react-router-dom';
import SideBar from "../main/SideBar";
import axios from 'axios';     
import SideNav from "react-simple-sidenav";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Swiper from 'react-id-swiper';  
import Admob from "../help/Admob";   
import Loader from 'react-loader-spinner';   


class LikeRequests extends Component {   
    constructor(props) {
        super(props);
        this.handleItemClick = this.handleItemClick.bind(this);
        this.onChangepack = this.onChangepack.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.state = {
            showNav3: false,user_details:'',
            peopleDetails: [],clas1:'',clas2:'',clas3:'',clas4:'',clas5:'',txt1:'',txt2:'',
            selected_premium:'',imgblur:'',
            items: [],api_url:localStorage.getItem('api_url'), 
            permium_superlikeCount: 0, 
            permium_boostCount: 0, 
            lang: ls.get('LangKeySet') || options[0],
            langKey: ls.get('LangValue'),  subscribed: "false", openModal: false,
            currency:'',payment:'',license_key:'',premium_price:'',premium_button:'none',limit:8,type:'like',loadmore:'none'
        } 
        this.toggle = this.toggle.bind(this);
        this.showModal = this.showModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }
    toggle() {
        this.setState(prevState => ({ 
            modal: !prevState.modal
        }));
    }

    closeModal(tabId) {
      this.setState({
          [tabId]: false
      });
  }

  showModal() {
      this.setState(prevState => ({
          openModal: !prevState.openModal
      }));
    }

    componentDidMount() {
      this.setState({
        loader:'loder-icon'
      });
      setTimeout(() => {
        this.setState({
            profilespace: '',
            loader: 'none'
        });
      }, 100);

      let data1 = new FormData();
            data1.append('user_id', JSON.parse(localStorage.getItem('session_userid')));  
            //data.append('language', this.state.langKey); 
            axios.defaults.headers.common['Authorization'] = JSON.parse(localStorage.getItem('session_token')); 
            axios({
                method: 'post',
                url: this.state.api_url+'subscriptionStatus',
                data: data1, 
                config: { headers: {'Content-Type': 'multipart/form-data' }}
            }).then(res => {              
              if(res.data.subscribed == 0 || res.data.subscribed_status == "expired") {     
              
                  axios({
                      method: 'get',
                      url: this.state.api_url+'subscriptionDetails',                
                      
                    }).then(res => {
                      const data = res.data;
                      console.log(data);
                      this.setState({                         
                              currency: data.currency,
                              price: data.price,
                              noofdays: data.noofdays, 
                              loader: 'none',
                              feedscontainer: 'none',  
                              subscribed : 'false',  
                              openModal:true,
                              profilespace: 'none'
                              
                            });
      
                            setTimeout(() => {
                              this.setState({
                                  openModal: true
                                            
                              }); 
                          }, 30); 
      
                            localStorage.setItem('subscription_price',JSON.stringify(this.state.price));
                            localStorage.setItem('currency',JSON.stringify(this.state.currency));
                            localStorage.setItem('noofdays',JSON.stringify(this.state.noofdays));
                       
                    });
                   
               
              }else{               
                  this.setState({                     
                      subscribed : "true",
                      profilespace: '',
                  });
               
              }
            })

        

      let data = new FormData();
      data.append('user_id', JSON.parse(localStorage.getItem('session_userid')));  
      data.append('language', this.state.langKey);  
      
      axios({
          method: 'post',
          url: this.state.api_url+'webprofile',
          data: data, 
          config: { headers: {'Content-Type': 'multipart/form-data' }}
      }).then(res => { 
        if(res.data.status !== false) {
          const user_details = res.data.result;
          
          this.setState({
            user_details: user_details,
          });

          if(res.data.result.premium_member !== 'true') { 
            this.setState({
              premium_button: 'becomePremiumVisitorPage d-flex flex-column align-items-center mt-3',
              imgblur:'img-blur'
            });
          }
        } else {
          this.props.history.push("/login");    
        } 
      }).catch(error => {
         this.props.history.push("/login"); 
      }); 

      var profile = new FormData();
      profile.set('user_id', JSON.parse(localStorage.getItem('session_userid')));
      profile.set('limit', this.state.limit);
      profile.set('type', this.state.type); 
      profile.set('language', this.state.langKey); 
      axios({
        method: 'post',
        url: this.state.api_url+'webfriendrequests',
        data: profile,
        config: { headers: {'Content-Type': 'multipart/form-data' }}
      }).then(res => {
        
        if(res.data.status === "true") {
          const peopleDetails = res.data.peoples;
          
          const tmp_limit = this.state.limit;
          this.setState({ peopleDetails:res.data.peoples,limit: this.state.limit + 8});
          if(tmp_limit  === peopleDetails.length){
            this.setState({ loadmore: 'loadMor btn-common'});
          }
        } else { 
          this.setState({ clas1: 'noItemsContainers',clas2:'friendsRequestNull',clas3:'nullFriendsRequest nullSize',clas4:'text-dark bold-font text-center apk-20',clas5:'secondaryText apk-16 regular-font text-center',txt1:'No Likes',txt2:'Update your profile',peopleDetails:[] });
        }
      })  

      var premiumlist = new FormData();
      premiumlist.set('platform', 'web');
      premiumlist.set('language', this.state.langKey); 
      axios({
        method: 'post',
        url: this.state.api_url+'premiumlistweb',   
        data: premiumlist,
        config: { headers: {'Content-Type': 'multipart/form-data' }}
      }).then(res => {
        const data = res.data;
        
        this.setState({
          items: data.result,
          activeItem: data.result[0].id,
          selected_premium: data.result[0].id,
          premium_price: data.result[0].price,
          permium_superlikeCount: data.result[0].superlikecount, 
          permium_boostCount: data.result[0].boostcount,   
          currency: data.currency,
          payment: data.payment,
          license_key: data.license_key,
        });
        localStorage.setItem('selected_premium',JSON.stringify(this.state.selected_premium));
        localStorage.setItem('premium_price',JSON.stringify(this.state.premium_price));
        localStorage.setItem('currency',JSON.stringify(this.state.currency));
      }); 
    }

    componentDidUpdate() {
      this.scrollToBottom();
    } 

    loadMore(){
      var profile = new FormData();
      profile.set('user_id', JSON.parse(localStorage.getItem('session_userid')));
      profile.set('limit', this.state.limit);
      profile.set('type', this.state.type);  
      profile.set('language', this.state.langKey);  
      axios({
        method: 'post',
        url: this.state.api_url+'webfriendrequests',
        data: profile,
        config: { headers: {'Content-Type': 'multipart/form-data' }}
      }).then(res => {
        //alert(res.data.status);
        if(res.data.status === "true"){
          const peopleDetails = res.data.peoples;
          // console.log("frienstreq",res.data); 
          const tmp_limit = this.state.limit;
          this.setState({ peopleDetails,limit: this.state.limit + 8});   
          if(tmp_limit  === peopleDetails.length){
              this.setState({ loadmore: 'loadMor btn-common'});
          } else {
              this.setState({ loadmore: 'none'});   
          }
        } else {
          //alert("fq");
          this.setState({ clas1: 'noItemsContainers',clas2:'friendsRequestNull',clas3:'nullFriendsRequest nullSize',clas4:'text-dark bold-font text-center apk-20',clas5:'secondaryText apk-16 regular-font text-center',txt1:'No Likes',txt2:'Update your profile' });
        }
      })
      this.scrollToBottom();   
    } 

    scrollToBottom = () => { 
        this.messagesEnd.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
     }    

    handleItemClick(index) {
      this.setState({
        activeItem: index,
        selected_premium: index,
      })
      var premiumlist = new FormData();
      premiumlist.set('premiumid', index);
      premiumlist.set('language', this.state.langKey);  
      axios({
        method: 'post',
        url: this.state.api_url+'premiumid',
        data: premiumlist,
        config: { headers: {'Content-Type': 'multipart/form-data' }}
      }).then(res => {
        const data = res.data;
        this.setState({
          premium_price: data.result.price,
          permium_superlikeCount: data.result.superlikecount, 
          permium_boostCount: data.result.boostcount, 
        });
        localStorage.setItem('selected_premium',JSON.stringify(index));
        localStorage.setItem('premium_price',JSON.stringify(data.result.price));
      });
    }
      
    onChangepack(e) {
      this.setState({
        selected_premium: e.target.value,
      })
    }

    render() { 
        const params = {
          pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
          },
          spaceBetween: 1,
          rebuildOnUpdate: true   
        }  
      
          const params2 = {
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev'
            },  
            slidesPerView: 3,
            slidesPerColumn: 1,
            breakpoints: {
              360: {
                slidesPerView: 3,
              },
              359: {
                slidesPerView: 2,
              }
            }
          };
          var style = {
            zIndex:10,
            backgroundColor:"white",
            height:"80vh",
            position:"absolute",
            width:"100%",
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
            //width:"100%",
            overflowY:"hidden"
          };

        return (

            <div className="setting-page">

                <div className="d-flex profile-header-top stickyProfileHead justify-content-between apd15 apr0 apl0 white-bg ">
                    <div class="heading-left-top d-flex">
                        <div className="align-self-center ">
                            <div className="iconHolder align-self-center mr-1">
                                <div className="backGlow  d-block d-lg-none">
                                    <div className="sideBarToggler sidebarIcoPos" onClick={() => this.setState({ showNav3: true })}></div>
                                </div>
                            </div>
                        </div>
                        <span class="arrow-center align-self-center ml-1 bold-font">
                          <Trans i18nKey="Likes">Likes</Trans>
                        </span>    
                    </div>
                    <div className="backGlow white-bg"></div>
                </div>
              
                {(this.state.subscribed == 'true') ?
               <div className={this.state.premium_button}>
                  <div className="apk-30 bold-font mb-2">
                    <Trans i18nKey="Find unlimited people">Find unlimited people</Trans> 
                  </div> 
                  <button className="btn-common" onClick={this.toggle}>
                    <Trans i18nKey="Become a Premium Member">Become a Premium Member</Trans>  
                  </button>
                  {/* <div className="premiumCurve mt-3 d-none d-lg-block"></div> */}
              </div> : ""}

              {(this.state.subscribed == 'false') ?
               <div className={this.state.premium_button}>
                  <div className="apk-30 bold-font mb-2">
                    <Trans i18nKey="Find unlimited people">Find unlimited people</Trans> 
                  </div> 
                  <button className="btn-common" onClick={this.showModal}>
                    <Trans i18nKey="Become a Premium Member">Become a Premium Member</Trans>  
                  </button>
                  {/* <div className="premiumCurve mt-3 d-none d-lg-block"></div> */}
              </div> : ""}
               
              <div className={this.state.loader}>
                        <div style={style} id="loader">
                            <Loader type="Bars"color="#000000" height={30} width={50} />
                        </div>
                    </div>
               
                <div className={this.state.profilespace}>
                <div className="peopleShowHolder">
                    <div className="row">
                      {this.state.peopleDetails.map((peopleDetails =>
                         this.state.user_details.premium_member === "false" ?
                         <div className="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-3 col-Resp col-xl-Resp">
                            <div className="peopleSpaceHolder" onClick={this.toggle}>
                                <div className="peopleViewHolder">
                                    <div className="peoplePic"> 
                                            <img src={peopleDetails.user_image} alt="" className={this.state.imgblur} />
                                            <div className={peopleDetails.online === "1"? "onlineN":"none"}></div>
                                    </div>
                                    <div className="apd10 cardMin">
                                        <div className="media">
                                            <div className="media-body pFHidden align-self-center">
                                               <div className="text-truncate bold-font BtoRcolor apk-16">{peopleDetails.user_name}</div> 
                                                <div className="text-truncate secondaryText esmh-14 regular-font">{peopleDetails.show_distance === '1' ? " " :peopleDetails.location }</div>
                                            </div>
                                            <div className="ml-1">
                                            {peopleDetails.show_age === 1 ? " " :peopleDetails.age}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-3 col-Resp col-xl-Resp">
                            <div className="peopleSpaceHolder" onClick={this.toggle}>
                                <div className="peopleViewHolder">
                                    <div className="peoplePic">
                                    <Link  to={`/app/LikeRequest/LikeRequestNew/${peopleDetails.user_id}`}> 
                                            <img src={peopleDetails.user_image} alt="" className={this.state.imgblur} />
                                        </Link>
                                        <div className={peopleDetails.online === "1"? "onlineN":"none"}></div>    
                                    </div>
                                    <div className="apd10 cardMin">
                                        <div className="media">
                                            <div className="media-body pFHidden align-self-center">
                                                <Link  to={`/app/LikeRequest/LikeRequestNew/${peopleDetails.user_id}`}>  <div className="text-truncate bold-font BtoRcolor apk-16">{peopleDetails.user_name}</div>  </Link> 
                                                <Link  to={`/app/LikeRequest/LikeRequestNew/${peopleDetails.user_id}`}>   <div className="text-truncate secondaryText esmh-14 regular-font">{peopleDetails.show_distance === 1 ? " " : peopleDetails.location}</div>  </Link>
                                            </div> 
                                            <div className="ml-1">
                                            {peopleDetails.show_age === 1 ? " " :peopleDetails.age}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                      ) )}
                      <div style={{ float:"left", clear: "both" }}   
                      ref={(el) => { this.messagesEnd = el; }}>
                      </div>    
                    </div>
                </div>
                </div> 
                <div className={this.state.clas1}>

                        <div class={this.state.clas2}>
                            <div className={this.state.clas3}></div>
                            <div className={this.state.clas4}>
                              <Trans i18nKey={this.state.txt1}>{this.state.txt1}</Trans>
                            </div> 
                        </div>                    
                     </div>
                     <Modal isOpen={this.state.modal} toggle={this.toggle} className="premiumPopup vertically-center apd5">
          <ModalHeader toggle={this.toggle}><h4 class="modal-title"><Trans i18nKey="Join Premium">Join Premium</Trans></h4></ModalHeader> 
          <ModalBody>
            <div className="premiumPop">
              <div className="premiumImgs">
                                <Swiper {...params} shouldSwiperUpdate>  
                                 {this.state.permium_superlikeCount > 0 ?
                                  <div>
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sub_superlike.png")} alt="" className="img-fluid" />
                                      </div> 
                                      <div className="premiumInfoHolder">
                                          <div className="bold-font">
                                              <Trans i18nKey="Get">Get</Trans> {this.state.permium_superlikeCount} <Trans i18nKey="free super likes">free super likes</Trans>  
                                          </div>
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextSuper">Giving a Super like is a way to tell someone that you're especially interested in them</Trans>
                                          </div>
                                      </div>
                                  </div> : ''
                                 }

                                 {this.state.permium_boostCount > 0 ?
                                  <div>
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sub_boost.png")} alt="" className="img-fluid" />
                                      </div>
                                      <div className="premiumInfoHolder">  
                                          <div className="bold-font">
                                              <Trans i18nKey="Get">Get</Trans> {this.state.permium_boostCount} <Trans i18nKey="free boost">free boost</Trans>     
                                          </div>
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextBoost">Be the top profile in your area for 30minutes to get more matches, Keep Swipe right!</Trans>
                                          </div>
                                      </div>
                                  </div> : ''
                                 }
                  
                                  <div>
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sub_location.png")} alt="" className="img-fluid" />
                                      </div>
                                      <div className="premiumInfoHolder"> 
                                          <div className="bold-font">
                                              <Trans i18nKey="Change Location">Change Location</Trans>
                                          </div>
                                          <div className="premiumInfoSubText  secondaryText esmh-14 "> 
                                              <Trans i18nKey="premiumTextLocation">Get match and chat with others around the world by simply searching for a city</Trans>
                                          </div>
                                      </div>
                                  </div>

                                  <div> 
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sub_morelike.png")} alt="" className="img-fluid" />
                                      </div>
                                      <div className="premiumInfoHolder">
                                          <div className="bold-font">
                                              <Trans i18nKey="Unlimited Likes">Unlimited Likes</Trans>  
                                          </div>
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextUnlimited">Feel special again with 'Unlimited Likes'. Swipe right as much as you want!</Trans> 
                                          </div>
                                      </div>
                                  </div>


                                  <div>
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sud_add.png")} alt="" className="img-fluid" />
                                      </div>
                                      <div className="premiumInfoHolder"> 
                                          <div className="bold-font">
                                              <Trans i18nKey="Rewind">Rewind</Trans>
                                          </div>
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextRewind">Accidentally swiped on someone? Rewind lets you take back your last Like or Nope</Trans>
                                          </div>
                                      </div>
                                  </div>  

                                  <div> 
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sub_morelike.png")} alt="" className="img-fluid" />
                                      </div>
                                      <div className="premiumInfoHolder">
                                          <div className="bold-font">
                                              <Trans i18nKey="See who Likes you">See who Likes you</Trans>   
                                          </div> 
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextLikes">Likes You feature lets you see who likes you before you decide whether to Like or Nope</Trans> 
                                          </div>
                                      </div>
                                  </div>

                                  <div>
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sub_profile.png")} alt="" className="img-fluid" />
                                      </div>
                                      <div className="premiumInfoHolder">
                                          <div className="bold-font"> 
                                              <Trans i18nKey="Control your profile">Control your profile</Trans>   
                                          </div>  
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextProfile">Limit what others see and make parts of your profile information invisible to other people</Trans>
                                          </div>  
                                      </div>
                                  </div> 

                                  <div>
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sub_visited.png")} alt="" className="img-fluid" />
                                      </div>
                                      <div className="premiumInfoHolder">
                                          <div className="bold-font">
                                              <Trans i18nKey="See who visited your profile">See who visited your profile</Trans> 
                                          </div>
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextVisited">A Visitors feature lets you see who looked at your profile or ‘visited’ your profile
</Trans>
                                          </div>
                                      </div>
                                  </div>



                                  <div>
                                      <div className="premiumImgHolder">
                                          <img src={require("../../assets/img/sud_add.png")} alt="" className="img-fluid" />
                                      </div> 
                                      <div className="premiumInfoHolder">
                                          <div className="bold-font"> 
                                              <Trans i18nKey="Turn Off Ads">Turn Off Ads</Trans> 
                                          </div>
                                          <div className="premiumInfoSubText  secondaryText esmh-14 ">
                                              <Trans i18nKey="premiumTextAds">Have fun swiping with uninterrupted ads</Trans>
                                          </div>  
                                      </div>
                                  </div> 

                              </Swiper> 
                            </div>     
              <div className="premiumPopPlans">
                <Swiper {...params2}>
                  {this.state.items.map((item, index) =>
                    <div className={this.state.activeItem === item.id ? 'navigation--active' : ''} onClick={this.handleItemClick.bind(this, item.id)} alt={item.id} value={item.id}>
                      <div className="premiumPrice">
                        <div className="apd10 daysPrice">
                          <div className="bold-font apk-30">{item.days}</div>
                          <div className=" bold-font apk-24 mt-n2"><Trans i18nKey={item.type}>{item.type}</Trans></div>     
                        </div>
                        <div className="apd5 apk-16 mini-price">{this.state.currency}{item.price}</div>
                      </div>
                    </div>)}

                </Swiper>
              </div>
              

              <div className="sidebarLastContent text-center">
              <NavLink to="/app/Payment"> <button className="btn-common btn-block" onClick={this.toggleclose}><Trans i18nKey="Continue">Continue</Trans></button></NavLink> 
        </div>


            </div>
          </ModalBody>
        </Modal>
                       

                <div className="sidebarResp d-block d-lg-none">
                    <SideNav showNav={this.state.showNav3} onHideNav={() => this.setState({ showNav3: false })} title={<div></div>} items={[<SideBar />]} />
                </div>
               
                <div className={this.state.loadmore} onClick={this.loadMore}> <Trans i18nKey="Load More !">Load More !</Trans></div> 
                <Admob></Admob>     

                <Modal isOpen={this.state.openModal} toggle={this.closeModal.bind(this, 'openModal')} className="premiumPopup vertically-center apd5">
          <ModalHeader toggle={this.closeModal.bind(this, 'openModal')}>            
          </ModalHeader>  
          <ModalBody>    
          <div>                         
            <div className="premiumInfoHolder">
                <div className="bold-font">
                <h4 >
                <Trans i18nKey=">Activate your account">Activate your account</Trans> 
                </h4>
                </div>
                <div className="premiumInfoSubText  secondaryText esmh-14 ">
                    <Trans i18nKey="subscribeTextSuper">Get unlimited access nearby friends, likes, superlike, chat, etc</Trans>
                </div>
            </div>
            
        </div>     
        <div className="premiumPopPlans subscribeprice">
            <div class="swiper-slide navigation--active swiper-slide-active" style={{width:"152px"}}>
        <div class="premiumPrice" ><div class="apd10 daysPrice"><div class="bold-font apk-30">{this.state.noofdays}</div><div class=" bold-font apk-24 mt-n2"><Trans i18nKey="Days">Days</Trans></div></div><div class="apd5 apk-16 mini-price">{this.state.currency}{this.state.price}</div></div>
        </div>
        </div>
        <div className="sidebarLastContent text-center">
              <NavLink to="/app/SubscribePayment"> <button className="btn-common btn-block" onClick={this.closeModal.bind(this, 'modal4')}><Trans i18nKey="Subscribe">Subscribe</Trans></button></NavLink>  
        </div>
          </ModalBody>
        </Modal>  


            </div>

        );
    }
}
 
export default withTranslation("translations")(LikeRequests); 
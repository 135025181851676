import React, { Component } from 'react';
import { Trans } from "react-i18next"; 

import DropIn from "braintree-web-drop-in-react";
import axios from 'axios';
import Loader from 'react-loader-spinner';

import ls from "local-storage"; 
import { options } from "../../config/options";

import "../../config/i18n";
import { withTranslation } from "react-i18next"; 

class Boostpayment extends Component {  
  instance;

  state = {
    modal: false,
    buybutton: 'buybutton',
    clientToken: null, api_url: localStorage.getItem('api_url'),
    lang: ls.get('LangKeySet') || options[0],
    langKey: ls.get('LangValue'),  
  };

  async componentDidMount() {
    axios.get(this.state.api_url + `generateclienttoken`)
      .then(res => {
        // console.log(res.data); 
        if (res.data.token != null) {
          this.setState({
            clientToken: res.data.token
          });
        }

        if (!this.state.clientToken) {
          this.setState({
            buybutton: 'buybutton'
          });

        } else {
          setTimeout(() => {
            this.setState({
              buybutton: 'buybuttonshow',

            });
          }, 2000);
        }
      })

    // If returned as JSON string


  }

  componentWillMount() {

    //alert(this.state.buybutton) 
  }

  async buy() {
    //console.log(this.instance);
    
    // Send the nonce to your server
    const { nonce } = await this.instance.requestPaymentMethod();
    if(nonce){
      this.setState({
        buybutton: 'buybutton'
      });
    }
    var payment = new FormData();
    payment.set('pay_nonce', nonce);
    payment.set('price', JSON.parse(localStorage.getItem('boostpremium_price')));
    payment.set('user_id', JSON.parse(localStorage.getItem('session_userid')));
    payment.set('boost_id', JSON.parse(localStorage.getItem('selected_boostpremium')));
    payment.set('currency_code', JSON.parse(localStorage.getItem('currency')));
    payment.set('language', this.state.langKey);  
    
    axios({
      method: 'post',
      url: this.state.api_url+'webpayboost',
      data: payment,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    })
      .then(res => {
        
        // console.log("premium boost "+res.data);
        localStorage.setItem('selected_boostpremium', '');
        localStorage.setItem('boostpremium_price', ''); 
        if (res.data.status === "true") {
          localStorage.setItem('boost_premium_popup', '1'); 
          this.props.history.push("/app/Profile")

        } else { 
          this.props.history.push("/app/Profile")
        }
      })
  }

  render() {
    var style = {
      zIndex: 10,
      backgroundColor: "white",
      height: "100vh",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      //width:"100%",
      overflowY: "hidden" 
    };
    if (this.state.clientToken) {
      return (
        <div className="peopleShowHolder">
          <div style={{
            display: "flex",
            flexDirection: "column",
            height: "90vh",
            justifyContent: "center"
          }}>

            <DropIn
              options={{ authorization: this.state.clientToken }}
              onInstance={instance => (this.instance = instance)}
            />
            {this.state && this.state.clientToken &&
              <button className={this.state.buybutton} onClick={this.buy.bind(this)}>
                <Trans i18nKey="Buy">Buy</Trans>  
              </button>
            }
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="loder-icon">
            <div style={style} id="loader">
              <Loader type="Bars" color="#ff4081" height={30} width={50} />
            </div>
          </div>
        </div>
      );

    }
  }
}

export default withTranslation("translations")(Boostpayment);
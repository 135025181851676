import React, { Component } from 'react';
import { Trans, withTranslation } from "react-i18next";
import "../../config/i18n"; 
 
import ls from "local-storage"; 
import { options } from "../../config/options"; 

import SideNav from "react-simple-sidenav";
import SideBar from "../main/SideBar";
import axios from 'axios';
import Loader from 'react-loader-spinner';


import ReactHtmlParser from 'react-html-parser';

class Help extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showNav3: false,helppages:[],current_id:'',current_des:'',current_title:'',social_link:[],fb_link:'',
            google_link:'',twitter_link:'',api_url:localStorage.getItem('api_url'),limit:8,loadmore:'none',
            lang: ls.get('LangKeySet') || options[0],
            langKey: ls.get('LangValue')  
        }
    }
        
    componentDidMount(){
        this.setState({
            loader:'loder-icon'
        });
        setTimeout(() => {
            this.setState({
                profilespace: 'content-wrapper homepg resp-width100 float-left',
                loader: 'none'
            });
          }, 2000);

        const { match: { params } } = this.props;
        var helpId = (typeof params.Id === 'undefined') ? 0 : (params.Id - 1);  
            helpId = (helpId > 0) ? helpId : 0;  

        var details = new FormData();
        details.append('user_id', JSON.parse(localStorage.getItem('session_userid')));
        details.append('language', this.state.langKey);   

        axios.defaults.headers.common['Authorization'] = JSON.parse(localStorage.getItem('session_token'));
        axios({
            method: 'post',    
            url: this.state.api_url+'webhelppages', 
            data: details,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        }).then(res => {     
            const helppages = res.data.helppages;
            const social_link = res.data.landingpage;

            helpId = (typeof helppages[helpId] === 'undefined') ? 0 : helpId; 
 
            this.setState({ 
                helppages: res.data.helppages,
                current_id: helppages[helpId].id,
                current_title: helppages[helpId].title,
                current_des: helppages[helpId].description, 
                fb_link: social_link[helpId].fb_link,
                google_link: social_link[1].google_link,
                twitter_link: social_link[2].twitter_link,
            });
        }).catch(error => {
            this.props.history.push("/app/NotFound");
        });  
    
    }

    render() {
        var style = {
            zIndex:10,
            backgroundColor:"white",
            position:"absolute",
            height:"80vh",
            width:"100%",
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
            //width:"100%",
            overflowY:"hidden"
          };
        
        return (
            <div className="">
                <div className="Help-page">
                    <div className="help-bg">

                        <div className="d-flex profile-header-top  justify-content-between apd15 apr0 apl0 white-bg stickypos">
                            <div class="heading-left-top d-flex">
                                <div className="align-self-center ">
                                    <div className="iconHolder align-self-center">
                                        <div className="backGlow d-flex d-lg-none">
                                            <div className="sideBarToggler sidebarIcoPos" onClick={() => this.setState({ showNav3: true })}></div>
                                        </div>
                                    </div>
                                </div>
                                <span class="arrow-center align-self-center bold-font ml-1">
                                    <Trans i18nKey="Help">Help</Trans>
                                </span>
                            </div>
                            <div className="align-self-center  d-block d-lg-none mr-5"><img src={require('../../assets/img/logo-c-64.png')} className="sideLogo commonLogoC" alt="logo" /></div>
                            <div className="backGlow"></div>
                        </div>

                        <div className="container-fluid">
                        <div className={this.state.loader}>
                        <div style={style} id="loader">
                            <Loader type="Bars" color="#000000" height={30} width={50} />
                        </div>
                    </div>

                <div className={this.state.profilespace}>
                                <div className="right-bg white-bg">
                                    <div className="secondary_color white-bg sma-16 arm0">
                                        <div className="help-content apd20 arp0">
                                            <div className="container">

                                                <div className="row">
                                                    <div className="help col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 apd0">
                                                        <div className="row">
                                                            <div className="help-leftpart resp-none apl10 apr10">
                                                                <ul className="help-left nav nav-tabs no-hor-padding border-none">
                                                                   
                                                                    {this.state.helppages.map((helppages) =>  
                                                                        <li className="block nav-item amb10">
                                                                            <a className={helppages.id === this.state.current_id ? "nav-link active secondary_color apd0 border-none" : "nav-link secondary_color apd0  border-none"} data-toggle="tab" href={helppages.id} role="tab" value={helppages.id} onClick={() => this.setState({ current_id: helppages.id,current_title: helppages.title,current_des: helppages.description  })} >
                                                                                <Trans i18nKey={helppages.title}>{helppages.title}</Trans>
                                                                            </a>
                                                                        </li>)} 
                                                                    <div className="social-media amt25">
                                                                        <h6 className="bold-font"><Trans i18nKey="Social media"><span className="floating-label">Social media</span> </Trans>:</h6>
                                                                        <div className="social-media-icon amt15">
                                                                            <span className="apr5" >
                                                                                <a href={`${this.state.fb_link}`} target="_blank" rel="noopener noreferrer">  
                                                                                    <img src={require("../../assets/img/facebook.png")} alt="facebook" />  
                                                                                </a>   
                                                                            </span>
                                                                            <span className="apr5">
                                                                                <a href={`${this.state.twitter_link}`} target="_blank" rel="noopener noreferrer">  
                                                                                    <img src={require('../../assets/img/twitter.png')} alt="twitter" />
                                                                                </a>  
                                                                            </span>
                                                                            <span className="apr5">
                                                                                <a href={`${this.state.google_link}`} target="_blank" rel="noopener noreferrer">  
                                                                                    <img src={require('../../assets/img/google-plus.png')} alt="google plus" />
                                                                                </a>      
                                                                            </span>  
                                                                        </div>
                                                                    </div>
                                                                </ul>
                                                            </div>

                                                            <div className="social-media resp-diplay none text-center width100">

                                                                <div className="social-media-icon amt20 armt10">
                                                                    <span className="apr5" >
                                                                        <a href={`${this.state.fb_link}`} target="_blank" rel="noopener noreferrer">  
                                                                            <img src={require("../../assets/img/facebook.png")} alt="facebook" />  
                                                                        </a>   
                                                                    </span>
                                                                    <span className="apr5">
                                                                        <a href={`${this.state.twitter_link}`} target="_blank" rel="noopener noreferrer">  
                                                                            <img src={require('../../assets/img/twitter.png')} alt="twitter" />
                                                                        </a>  
                                                                    </span>
                                                                    <span className="apr5">
                                                                        <a href={`${this.state.google_link}`} target="_blank" rel="noopener noreferrer">  
                                                                            <img src={require('../../assets/img/google-plus.png')} alt="google plus" />
                                                                        </a>      
                                                                    </span>     
                                                                </div>
                                                            </div>

                                                            <div className="help-left-r dropdown none resp-diplay resp-width100 armt20">
                                                                <ul className="nav nav-pills border-bottom border-top" id="pills-tab" role="tablist">
                                                                    <li className="nav-item dropdown">
                                                                        <a href="#as" className="nav-link dropdown-toggle border-none" data-toggle="dropdown" role="button"
                                                                            aria-haspopup="true" aria-expanded="false"><Trans i18nKey="Help Links">Help Links</Trans></a> 
                                                                        <div className="dropdown-menu">

                                                                            <a className="dropdown-item" href="#home"
                                                                                role="tab" data-toggle="pill" aria-controls="pills-dropdown1"><Trans i18nKey="Terms of Condition">Terms of Condition</Trans></a>
                                                                            <a className="dropdown-item"
                                                                                href="#menu1" role="tab" data-toggle="pill" aria-controls="pills-dropdown2"><Trans i18nKey="Terms of Service">Terms of Service</Trans></a>
                                                                            <a className="dropdown-item"
                                                                                href="#menu2" role="tab" data-toggle="pill" aria-controls="pills-dropdown2"><Trans i18nKey="Faq">Faq</Trans></a> 
                                                                        </div>   
                                                                    </li>
                                                                </ul>

                                                            </div>


                                                            <div className="help-right arp20 helpcont">
                                                                <div className="tab-content">
                                                                    <div id={this.state.current_id} className="tab-pane fade show active" role="tabpanel" aria-labelledby="pills-home-tab">
                                                                        <h4 className="bold-font resp-apk-20 amb20"><Trans i18nKey={this.state.current_title}>{this.state.current_title}</Trans></h4>
                                                                        {ReactHtmlParser(this.state.current_des)}



                                                                    </div>
                                                                    <div id="menu1" className="tab-pane fade" role="tabpanel" aria-labelledby="pills-profile-tab">
                                                                        <h4 className="bold-font resp-apk-20 amb20">Terms of service</h4>
                                                                        <p>  typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
                                                                            scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in
							the 1960s with the release of Letraset sheets containing </p>

                                                                        <p>Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                                                        It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less
                                                                        normal distribution of lette making it look like readable English. Many desktop publishing packages and web page editors now use Lorem
							Ipsum as their default model text, and a search.</p>

                                                                    </div>
                                                                    <div id="menu2" className="tab-pane fade" role="tabpanel" aria-labelledby="pills-dropdown1-tab">
                                                                        <h4 className="bold-font resp-apk-20 amb20">Faq</h4>
                                                                        <p> Blackroseinternational is designed to be as intuitive as possible,
						but don't worry, we know that you might need a little help when learning your way round!</p>
                                                                        <p> When you sign up for the first time please make sure that you add a good photo </p>

                                                                        <p> When you sign up for the first time please make sure that you add a good photo
						of yourself and write a little bit about you to show other users who you are.</p>

                                                                        <p> When you sign up for the first time please make sure that you add a good photo
						of yourself and write a little bit about you to show other users who you are.</p>

                                                                        <p> When you sign up for the first time please make sure that you add a good photo
						of yourself and write a little bit about you to show other users who you are.</p>

                                                                        <p> When you sign up for the first time please make sure that you add a good photo
						of yourself and write a little bit about you to show other users who you are.</p>


                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>


                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            
                <div className="sidebarResp d-block d-lg-none">
                    <SideNav showNav={this.state.showNav3} onHideNav={() => this.setState({ showNav3: false })} title={<div></div>} items={[<SideBar />]} />
                </div>
            </div>);
    }
}
  
export default withTranslation("translations")(Help);  